import { LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { deleteMailchimpIfEnabled } from '../Settings'
import { toggleLinkActive } from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useCurrentPagePermissions } from '../../../../utils/useGetCurrentPagePermissions'
import { useDeleteLinkWithAutosave } from '../../deleteUtils'
import { useDispatch } from 'react-redux'
import { useEditLinkMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/linkMutations'
import { useLinkEditDrawerState } from '../../../profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.hooks'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'
import { useState } from 'react'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export interface UseManagerSettingsState {
  onDeleteLink: () => Promise<void>
  deleteLinkModeActive: boolean
  toggleDeleteMode: () => void
  showEditLink: boolean
  setShowEditLink: (shown: boolean) => void
  toggleActivate: () => void
  isActive: boolean
  hasViewOnlyAccess: boolean
  toggleEditMode: () => void
  managerContainerStyles: {
    deactivatedContainerStyles: { backgroundColor?: string; boxShadow?: string }
    titleColor: string
    iconOpacity: string
    textOpacity: string
  }
  childOnClick: (() => void) | undefined
  isTemplateConfigLocked: boolean
}

export const useManagerSettings = (
  link: LinkInfoFragment,
  editWidget: () => void,
  empty?: boolean
): UseManagerSettingsState => {
  const [deleteLinkMode, setDeleteLinkMode] = useState(false)
  const [showEditLink, setShowEditLink] = useState(false)
  const deleteLink = useDeleteLinkWithAutosave()
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const dispatch = useDispatch()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const currentPage = useCurrentPage()
  const [editLinkMutation] = useEditLinkMutation()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const isTemplateConfigLocked = Boolean(link?.templateConfig?.lockAll) && !isTemplateEditor
  const toggleDeleteMode = (): void => {
    setDeleteLinkMode(!deleteLinkMode)
    events.Flowpage_PageProfile_Clicked_Cancel_Delete_Link()
  }

  const { onDrawerClose } = useLinkEditDrawerState()

  const onDeleteLink = async (): Promise<void> => {
    try {
      deleteLink({
        linkId: link.id,
        linkOrder: link.order
      })
      if (!shouldEditPageInRedux) {
        await deleteMailchimpIfEnabled(link, dispatch, currentPage?.id ?? '')
      }
      link.provider === LinkProvider.WIDGET
        ? events.userDeletedWidget({ link_id: link.id })
        : events.userDeletedLink({ link_id: link.id })

      setDeleteLinkMode(false)
    } catch (err) {
      console.error(err)
    }
  }

  const toggleActivate = (): void => {
    const {
      __typename,
      thumbNailImgUrl: _image,
      childLinks: _childLinks,
      position: _position,
      ...rest
    } = link
    try {
      shouldEditPageInRedux
        ? dispatch(
            toggleLinkActive({
              linkId: link.id
            })
          )
        : editLinkMutation({
            linkId: link.id,
            linkInput: {
              pageId: currentPage?.id,
              ...rest,
              active: !link.active
            },
            deleteImage: false,
            isTemplateEditor
          })

      events.Flowpage_PageProfile_Clicked_HideLink({
        linkId: link.id,
        actionType: link?.active ? 'hide' : 'unhide'
      })
    } catch (e) {
      console.error('error toggling active link', e)
    }
  }

  // deactivate / activate manager styles
  const employDeactivateStyles = !link?.active
  const deactivatedContainerStyles = employDeactivateStyles
    ? {
        backgroundColor: '#F5F5F5'
      }
    : {}
  let titleColor = empty ? 'secondary.placeholder' : 'text.primary'
  if (employDeactivateStyles || hasViewOnlyAccess || isTemplateConfigLocked)
    titleColor = 'primary.grey'
  const iconOpacity = employDeactivateStyles ? '0.5' : '1'

  const managerContainerStyles = {
    deactivatedContainerStyles,
    titleColor,
    iconOpacity: isTemplateConfigLocked ? '0.35' : iconOpacity,
    textOpacity: isTemplateConfigLocked ? '0.35' : '1'
  }

  const toggleEditMode = (): void => {
    setDeleteLinkMode(false)
    if (showEditLink) {
      setShowEditLink(false)
    } else {
      setShowEditLink(true)
      editWidget()
    }
  }

  const childOnClick =
    hasViewOnlyAccess || isTemplateConfigLocked ? undefined : () => toggleEditMode()

  return {
    onDeleteLink,
    deleteLinkModeActive: deleteLinkMode,
    toggleDeleteMode,
    showEditLink,
    setShowEditLink: _showEditLink => {
      setShowEditLink(_showEditLink)
      if (!_showEditLink) {
        // reset drawer content when link form is closed - This ensures the rendered preview does not persist when user has not saved their changes
        onDrawerClose()
      }
    },
    toggleActivate,
    isTemplateConfigLocked,
    hasViewOnlyAccess,
    isActive: link?.active ?? true,
    managerContainerStyles,
    toggleEditMode,
    childOnClick
  }
}
