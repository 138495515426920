import * as React from 'react'
import { ToggleButtonBase } from './styles'
import { ToggleButtonProps } from '../../..'

export const ToggleButton = ({
  children,
  colorVariant = 'invisible',
  sizeVariant = 'large',
  selected = false,
  value = '',
  type = 'button',
  onClick,
  inverse = false,
  ...rest
}: ToggleButtonProps): JSX.Element => {
  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (onClick) onClick(event, value)
  }

  return (
    <ToggleButtonBase
      colorVariant={selected ? 'primary' : colorVariant}
      sizeVariant={sizeVariant}
      color={selected && inverse ? 'primary.flowBlue' : 'primary.black'}
      backgroundColor={selected && inverse ? 'primary.white' : undefined}
      selected={selected}
      value={value}
      type={type}
      onClick={handleChange}
      aria-pressed={selected}
      {...rest}
    >
      {children}
    </ToggleButtonBase>
  )
}
