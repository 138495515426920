import { deleteLink } from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useDeleteLinkMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/linkMutations'
import { useDispatch } from 'react-redux'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'

export function useDeleteLinkWithAutosave(): (args: {
  linkId: string
  linkOrder?: number
  deleteImage?: boolean
}) => Promise<void> {
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const dispatch = useDispatch()
  const page = useCurrentPage()
  const pageId = page?.id ?? ''
  const { isTemplateEditor } = usePageTemplateEditorState()
  const [deleteLinkMutation] = useDeleteLinkMutation()

  return async ({ linkId, linkOrder, deleteImage = false }) => {
    const isExistingLink = page?.links?.some(link => link.id === linkId)
    if (shouldEditPageInRedux) {
      dispatch(deleteLink({ linkId, linkOrder: linkOrder ?? 0 }))
    } else if (isExistingLink) {
      deleteLinkMutation({
        linkId,
        pageId: isTemplateEditor || !pageId ? undefined : pageId,
        deleteImage
      })
    }
  }
}
