import { SVGProps, memo } from 'react'

function YieldSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 16" {...rest}>
      <g stroke={color || stroke} strokeWidth={1.5}>
        <path d="M7.967 2.282L1.362 12.508c-1.181 1.83.132 4.242 2.31 4.242h12.783c2.141 0 3.461-2.34 2.354-4.172L12.63 2.352C11.579.612 9.069.575 7.967 2.282z" />
        <path strokeLinecap="round" strokeLinejoin="round" d="M10.25 7.5V10m0 2v.5" />
      </g>
    </svg>
  )
}

export const Yield = memo(YieldSvg)
