import { ActionDataTypes, ActionDataWidgets } from './types'
import { EditLinkMutation, Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import {
  addLink,
  editLink
} from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { defaultWidgetLink } from '../../constants'
import { getChildLinkImageIdsToDelete } from './utils'
import { isFileString } from '@dtx-company/true-common/src/utils/blob'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import {
  useCreateLinkMutation,
  useCreateOrEditLinkThemeMutation,
  useEditLinkMutation
} from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/linkMutations'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useCurrentPageId } from '@dtx-company/inter-app/src/hooks/useCurrentPageId'
import { useDispatch } from 'react-redux'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface SubmitWidgetProps<T extends ActionDataWidgets> {
  curr?: LinkType
  actionData: ActionDataTypes[T]
  widgetType: T
  fields: {
    description?: string
    title?: string
    id: string
    embed?: boolean
    order: number
    displayType?: string
    provider?: string
    childLinks?: Link[]
  }
  image?: string | null
  handleClose?: () => void
  linkTheme?: LinkType['linkTheme']
}
export const useSubmitWidget = <T extends ActionDataWidgets>(): ((
  _0: SubmitWidgetProps<T>
) => Promise<void>) => {
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const dispatch = useDispatch()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const [editLinkMutation] = useEditLinkMutation()

  const [createLinkMutation] = useCreateLinkMutation()
  const [createOrEditLinkThemeMutation] = useCreateOrEditLinkThemeMutation()
  const page = useCurrentPage()
  const pageId = page?.id ?? ''

  return async ({ curr, actionData, widgetType, fields, handleClose, image, linkTheme = null }) => {
    try {
      const filterChildlinks = widgetType !== 'socialLink'
      const linkId = curr ? curr.id : fields.id
      const order = fields.order ?? curr?.order ?? -1
      const isExistingLink = page?.links?.some(link => link.id === curr?.id)

      if (curr && isExistingLink) {
        const newFields = {
          type: widgetType || null,
          actionData,
          ...fields,
          order // important order is last to prevent being overridden
        }
        const { thumbNailImgUrl, __typename: _, linkTheme: currLinkTheme, ...restOfLink } = curr
        const { childLinks: __, ...childlessRestOfLink } = restOfLink
        if (shouldEditPageInRedux) {
          dispatch(
            editLink({
              link: {
                ...curr,
                thumbNailImgUrl: image,
                ...newFields,
                linkTheme
              },
              linkId
            })
          )
        } else {
          const { position: _position, ...linkInput } = {
            pageId,
            ...(filterChildlinks ? { ...childlessRestOfLink } : { ...restOfLink }),
            ...newFields
          }
          const editLinkMutationResponse = await editLinkMutation({
            isTemplateEditor,
            editImage: image && image !== thumbNailImgUrl ? image : undefined,
            deleteImage: Boolean(thumbNailImgUrl && !image),
            currentImage: image ?? undefined,
            linkId,
            linkInput,
            childLinkImageIdsToDelete: filterChildlinks
              ? []
              : getChildLinkImageIdsToDelete(linkInput, curr.childLinks ?? [])
          })
          const editLinkMutationResponseData = editLinkMutationResponse as {
            data: EditLinkMutation
          }
          const editLinkMutationResponseLinkId = editLinkMutationResponseData?.data?.editLink?.id
          if (linkTheme) {
            createOrEditLinkThemeMutation({
              isTemplateEditor,
              linkIds: [editLinkMutationResponseLinkId || linkId],
              linkThemeInput: linkTheme
            })
          }
        }
      } else {
        const hasImageFileString = image && isFileString(image)
        const { thumbNailImgUrl: _, __typename, ...newLink } = defaultWidgetLink
        if (shouldEditPageInRedux) {
          dispatch(
            addLink({
              link: {
                ...defaultWidgetLink,
                actionData,
                type: widgetType,
                provider: fields?.provider || LinkProvider.WIDGET,
                thumbNailImgUrl: hasImageFileString ? image : null,
                ...fields,
                linkTheme
              }
            })
          )
        } else {
          await createLinkMutation({
            isTemplateEditor,
            linkInput: {
              ...newLink,
              actionData,
              type: widgetType,
              provider: fields?.provider || LinkProvider.WIDGET,
              ...fields
            },
            pageId,
            addImage: hasImageFileString ? image : undefined
          })
          if (linkTheme) {
            await createOrEditLinkThemeMutation({
              isTemplateEditor,
              linkIds: [linkId],
              linkThemeInput: linkTheme
            })
          }
        }
      }

      if (curr) {
        events.userEditsFlowpageWidget({ link_id: curr.id })
      } else {
        events.userEditsFlowpageWidget({ link_id: fields.id })
      }

      handleClose?.()
    } catch (e) {
      console.error('error in useSubmitWidget', e)
      sendErrorNotification("Couldn't add this link")
    }
  }
}

interface ClosePollProps {
  curr?: LinkType
}
export const useClosePoll = (): ((_0: ClosePollProps) => Promise<void>) => {
  const dispatch = useDispatch()
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const [editLinkMutation] = useEditLinkMutation()
  const pageId = useCurrentPageId()
  return async ({ curr }) => {
    if (curr) {
      try {
        shouldEditPageInRedux
          ? dispatch(
              editLink({
                link: {
                  ...curr,
                  actionData: { ...curr.actionData, closed: true }
                },
                linkId: curr.id
              })
            )
          : await editLinkMutation({
              linkInput: {
                provider: curr.provider,
                pageId,
                order: curr.order,
                actionData: { ...curr.actionData, closed: true }
              },
              linkId: curr.id,
              deleteImage: false
            })
      } catch (e) {
        sendErrorNotification('Error closing poll')
      }
    }
  }
}
