import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { FC, ReactNode } from 'react'
import { OpacityProps, TypographyProps } from 'styled-system'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'

export type LabelTextProps = TypographyProps &
  OpacityProps & {
    selected: boolean
    label: string
    display?: BoxProps['display']
  }

export const LabelText: FC<LabelTextProps> = ({ selected, label, ...rest }) => (
  <Text
    variant="body/small"
    fontWeight={selected ? 'bold' : 'inherit'}
    color={selected ? 'text.hover' : 'text.primary'}
    textTransform="capitalize"
    margin={'8px 5px'}
    {...rest}
  >
    {label}
  </Text>
)

export const CardBase: FC<{
  children: ReactNode
  justify: string
  padding?: string | string[]
  backgroundColor?: string
}> = ({
  children,
  justify,
  padding = '32px 20px',
  backgroundColor = 'primary.white'
}: {
  children: ReactNode
  justify: string
  padding?: string | string[]
  backgroundColor?: string
}) => (
  <Box
    margin="16px 0px 24px 0px "
    padding={padding}
    border="1px solid secondary.border"
    bg={backgroundColor}
    borderRadius={1}
    maxWidth={['100%', '100%', 450, 450]}
    justifyContent={justify}
  >
    {children}
  </Box>
)
