import { FC } from 'react'
import { ImageEditorProps } from './ImageEditor.types'
import dynamic from 'next/dynamic'

const ImageEditorProvider = dynamic(() =>
  import(/* webpackChunkName: "dtx-image-editor" */ './ImageEditorContext').then(
    mod => mod.ImageEditorProvider
  )
)
const ImageEditorDialog = dynamic(() =>
  import(/* webpackChunkName: "dtx-image-editor" */ './ImageEditorDialog').then(
    mod => mod.ImageEditorDialog
  )
)

export const ImageEditor: FC<ImageEditorProps> = ({ open, file, onClose, title, ...rest }) => {
  return file && open ? (
    <ImageEditorProvider file={file} {...rest}>
      <ImageEditorDialog open={open} onClose={onClose} title={title} />
    </ImageEditorProvider>
  ) : null
}
