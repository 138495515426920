import { Box } from '../../atoms/Box/index'
import { Icon } from '../../atoms/Icon/index'
import { TOOLTIP_VARIANTS, distanceStyles, offsetStyles } from '../../../constants'
import { TooltipBaseProps } from '../../../types'
import { color, layout, padding, variant } from 'styled-system'
import { theme } from '../../../theme'
import styled, { AnyStyledComponent } from 'styled-components'

export const TooltipBase: AnyStyledComponent = styled(Box).attrs({
  role: 'tooltip'
})<TooltipBaseProps>`
  display: block;
  position: absolute;
  padding: 21px;
  z-index: 1;
  border-radius: 8px;
  background-color: ${theme.colors.primary.black};
  :after {
    position: absolute;
    z-index: 3;
    content: '';
    width: 0;
    height: 0;
    border-top: 8px solid ${theme.colors.primary.black};
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  ${variant({
    prop: 'layout',
    variants: TOOLTIP_VARIANTS
  })}

  ${({ layout, offset, additionalOffset }) => offsetStyles[layout](offset, additionalOffset)}
  ${({ layout, distance }) => distanceStyles[layout](distance)}

  ${color}
  ${layout}
  ${padding}
`

export const TooltipIcon = styled(Icon)`
  font-size: 8px;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  padding: 0;
  background: none;
  border: none;
  :focus,
  :active,
  :hover {
    background: none;
    outline: none;
  }
`
