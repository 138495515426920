import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC, useMemo } from 'react'
import { IconSize, IconStyle } from '../../../components/widgets/SocialLink/types'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { LinkCardBaseProps } from '../FlowpageLinkCard'
import { SocialLinkChip } from '@dtx-company/inter-app/src/components/singleSignOn/styles'
import { getLinkCardStyles } from '../FlowpageLinkCard/utils/getLinkCardStyles'
import { logoUrlForType } from '../../../utils/main'
import { processSocialUrl } from '../../../components/widgets/SocialLink/helperFunctions/helperFunctions'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../../components/profile/Customize/hooks'

export const SocialLinkIconCard: FC<LinkCardBaseProps> = ({
  link,
  newTab,
  preview,
  editLinkMode,
  widgetId
}: LinkCardBaseProps) => {
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: Boolean(editLinkMode)
  })
  const addingLink = (link.childLinks ?? []).length === 0

  const { theme } = useFlowpageTheme()
  const linkStyles = getLinkCardStyles({
    theme,
    linkTheme: link.linkTheme
  })
  const resolvedActionData = useMemo(() => link?.actionData ?? {}, [link?.actionData]) //linter sugggestion
  const resolvedChildLinks = useMemo(() => link?.childLinks ?? [], [link?.childLinks])
  //sort by order 0-10
  const sortedSocialProviderArray = useMemo(
    () =>
      !addingLink
        ? [...resolvedChildLinks]
            .sort((a, b) => a.order - b.order)
            .map(child => child?.provider ?? '')
        : Object.keys(resolvedActionData)
            .filter((adKey: string) => (resolvedActionData[adKey].order ?? -1) > -1)
            .sort(function (a, b) {
              return resolvedActionData[a].order - resolvedActionData[b].order
            }),
    [resolvedActionData, resolvedChildLinks, addingLink]
  )
  const getIconDimension = (radius?: boolean): string => {
    switch (resolvedActionData['iconSize']) {
      case IconSize.SMALL:
      case IconSize.MEDIUM:
        return radius ? '16px' : '32px'
      case IconSize.LARGE:
        return radius ? '22px' : '44px'
      default:
        return radius ? '22px' : '44px'
    }
  }
  const getInnerImageDimension = (): string => {
    switch (resolvedActionData['iconSize']) {
      case IconSize.SMALL:
      case IconSize.MEDIUM:
        return '16px'
      case IconSize.LARGE:
        return '28px'
      default:
        return '28px'
    }
  }

  return (
    <Box
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      maxWidth="100%"
      padding="8px"
      flexWrap="wrap"
      ml="auto"
      mr="auto"
    >
      {sortedSocialProviderArray.map((socialLink: string) => {
        const targetChild = !addingLink
          ? resolvedChildLinks.find(child => child?.provider === socialLink)
          : resolvedActionData[socialLink]
        const socialUrl = processSocialUrl(
          !addingLink ? targetChild.actionData.link : targetChild.link,
          socialLink
        )
        const childLinkId = !addingLink ? targetChild.id : link.id
        const parentLinkId = !addingLink ? link.id : undefined
        const trackingHandler = (): void =>
          trackLinkClick({ link_id: childLinkId, parent_link_id: parentLinkId }, { useHeap: true })
        const iconSrc =
          targetChild.thumbNailImgUrl && targetChild.thumbNailImgUrl.length > 0
            ? targetChild.thumbNailImgUrl
            : logoUrlForType(socialLink)
        if (resolvedActionData['iconStyle'] === IconStyle.FLOATING) {
          return (
            <SocialLinkChip
              href={socialUrl}
              {...(newTab && { target: '_blank' })}
              onClick={trackingHandler}
              rel="noopener"
              data-link_id={link.id}
              data-testid="flowpage-link"
              {...(widgetId && { 'data-widget': widgetId })}
              key={`${socialLink}-link`}
            >
              <Image
                height={getIconDimension()}
                width={getIconDimension()}
                margin="8px"
                src={iconSrc}
                borderRadius="4px"
                objectFit="cover"
                alt={`${socialLink} icon`}
              />
            </SocialLinkChip>
          )
        } else {
          return (
            <Box
              borderRadius={getIconDimension(true)}
              padding="8px"
              cursor="pointer"
              margin="8px"
              {...linkStyles}
              key={`${socialLink}-link`}
            >
              <SocialLinkChip
                href={socialUrl}
                {...(newTab && { target: '_blank' })}
                onClick={trackingHandler}
                rel="noopener"
                data-link_id={link.id}
                data-testid="flowpage-link"
                {...(widgetId && { 'data-widget': widgetId })}
              >
                <Image
                  src={iconSrc}
                  width={getInnerImageDimension()}
                  height={getInnerImageDimension()}
                  alt={`${socialLink} icon`}
                  maxWidth="100%"
                  maxHeight="100%"
                  objectFit="cover"
                  borderRadius="4px"
                />
              </SocialLinkChip>
            </Box>
          )
        }
      })}
    </Box>
  )
}
