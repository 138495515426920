import { FC } from 'react'
import { IconButton } from '@mui/material'
import { TemplateLockProps } from './types'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import { useTemplateLinkLock } from './useTemplateLinkLock'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

export const ToggleTemplateLinkLock: FC<TemplateLockProps> = ({ link }) => {
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const { locked, toggleLocked } = useTemplateLinkLock({ link })
  return <LockToggle disabled={hasViewOnlyAccess} locked={locked} toggleLocked={toggleLocked} />
}
export const LockToggle: FC<{
  locked: boolean
  toggleLocked: () => Promise<void>
  disabled?: boolean
}> = ({ locked, toggleLocked, disabled }) => (
  <IconButton
    disabled={disabled}
    sx={{ background: 'white', marginRight: 2 }}
    onClick={async () => {
      await toggleLocked()
    }}
  >
    {locked ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
  </IconButton>
)
