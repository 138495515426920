import { ActionDataTypes } from '../types'
import { DESCRIPTION_MAX, DESCRIPTION_MAX_ERROR, TITLE_MAX, TITLE_MAX_ERROR } from './../validation'
import { DISPLAY_TYPE } from '../Destination/constants'
import {
  ICONS_ONLY,
  UseSocialLinkFormStateType,
  adjustedSocialLinks,
  emptySocialLinkFields
} from './types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { SocialLinkFields } from '../Destination/SocialLinkFields.types'
import { SocialLinkFormFields, SocialLinkUrlFields } from '../Destination/types'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import {
  cleanSocialActionData,
  generateChildlinks,
  validateSocialLinkInput
} from './helperFunctions/helperFunctions'
import { defaultWidgetLink } from './../../../constants/index'
import { getCashAppUserName } from '@dtx-company/true-common/src/utils/flowpage'
import { getInitialFormValueForProvider } from './helperFunctions/getInitialFormValueForProvider'
import { getValidatedActionData } from '../../../components/widgets/typeUtils'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form-deprecated'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { useRestrictedFlowpageLinkTypes } from '@app/code/src/components/utils/useRestrictedFlowpageLinkTypes'
import { useSetValueWithPreview } from './../utils'
import { useSubmitWidget } from '../submitUtils'
import clone from 'lodash/clone'

export const useSocialLinkFormState = (
  order: number,
  curr: LinkType | undefined,
  handleClose: () => void,
  id: string
): UseSocialLinkFormStateType => {
  const restrictedSocialLinks = useRestrictedFlowpageLinkTypes()
  const actionData = getValidatedActionData<'socialLink'>(curr?.actionData, 'link')
  const [displayMoreOptions, setDisplayMoreOptions] = useState<boolean>(false)
  const [selectedLinks, setSelectedLinks] = useState<Array<string>>(
    curr?.actionData
      ? Object.keys(curr?.actionData)
          .filter(
            (item: string) =>
              typeof curr?.actionData[item] === 'object' && curr?.actionData[item].link !== ''
          )
          .sort(function (a, b) {
            return curr?.actionData[a].order - curr?.actionData[b].order
          })
      : []
  )
  const [expandedChildLinks, setExpandedChildLinks] = useState<string[]>(curr ? [] : selectedLinks)
  const defaultSocialFormValues = Object.values(SocialLinkUrlFields).reduce(
    (accumulator, provider) => {
      return {
        ...accumulator,
        [provider]: getInitialFormValueForProvider(provider, curr)
      }
    },
    {}
  )

  const {
    register,
    setValue: setFormValue,
    handleSubmit,
    errors,
    watch,
    trigger
  } = useForm<SocialLinkFormFields>({
    defaultValues: {
      ...defaultSocialFormValues,
      displayType: curr?.displayType || ICONS_ONLY,
      iconSize:
        !curr?.actionData?.iconSize || curr?.actionData?.iconSize === 'large' ? 'large' : 'medium',
      iconStyle: curr?.actionData.iconStyle || 'circle'
    },
    mode: 'onBlur'
  })
  const submitWidget = useSubmitWidget()
  const onSubmit = async ({ displayType }: SocialLinkFormFields): Promise<void> => {
    try {
      const newActionData = Object.entries(watchAll).reduce(
        (acc, current) =>
          current[1]?.order !== -1 && current[0] !== DISPLAY_TYPE
            ? { ...acc, [current[0]]: current[1] }
            : acc,
        {}
      ) as ActionDataTypes['socialLink']
      const childLinks = generateChildlinks(watchAll, id)
      const cleanedActionData = cleanSocialActionData(newActionData)

      await submitWidget({
        curr,
        linkTheme,
        actionData: cleanedActionData || {},
        widgetType: 'socialLink',
        fields: {
          id,
          order,
          displayType,
          childLinks
        }
      })
      handleClose()
    } catch (err) {
      console.error('Error saving widget:', err)
    }
  }

  //pass an undefined nestedItem to reset the values for the social field
  const updateFormFieldValue = async (
    value: string | number,
    social?: SocialLinkUrlFields,
    nestedItem?: keyof SocialLinkFields
  ): Promise<void> => {
    const updatedValue =
      social === SocialLinkUrlFields.CASH_APP && typeof value === 'string' && nestedItem === 'link'
        ? getCashAppUserName(value)
        : value

    if (!social) return
    let updated = clone(watchAll[social]) ?? emptySocialLinkFields
    if (nestedItem) {
      updated = {
        ...updated,
        [nestedItem]: updatedValue
      }
    } else {
      updated = emptySocialLinkFields
    }
    setValue(`${social}`, updated)
    await (nestedItem ? trigger(`${social}.${nestedItem}`) : trigger(`${social}`))
  }

  const updateSelectedLinks = (linkType: SocialLinkUrlFields): void => {
    let linkSet = [...selectedLinks]
    if (linkSet.includes(linkType)) {
      let adjustLinkOrder = false
      linkSet = linkSet.filter((thisSocial: string) => {
        const shouldMaintainLink = thisSocial !== linkType
        if (adjustLinkOrder) {
          const linkTypeToAdjust = thisSocial as SocialLinkUrlFields
          const updatedOrder = (watchAll[linkTypeToAdjust]?.order ?? 0) - 1
          updateFormFieldValue(updatedOrder ?? -1, linkTypeToAdjust, 'order')
        }
        if (!shouldMaintainLink) {
          adjustLinkOrder = true
          updateFormFieldValue(0, linkType)
        }
        return shouldMaintainLink
      })
    } else {
      linkSet.push(linkType)
      updateFormFieldValue(selectedLinks.length, linkType, 'order')
      setExpandedChildLinks([...expandedChildLinks, linkType ?? ''])
    }
    setSelectedLinks(linkSet)
  }
  const watchAll = watch()
  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const previewLink = useMemo(() => {
    const { displayType, ...actionData } = watchAll
    return {
      ...defaultWidgetLink,
      id: id,
      type: 'socialLink',
      displayType,
      linkTheme,
      actionData
    }
  }, [id, watchAll, linkTheme])

  const { setValue, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setFormValue,
    setLinkTheme
  )

  const disabled = selectedLinks.length === 0
  const edit = Boolean(curr)
  /*eslint-disable*/
  // TODO - fix missing dependency (selectedLinks) error - FP team
  useEffect(() => {
    register(DISPLAY_TYPE)
    register('iconSize')
    register('iconStyle')
    adjustedSocialLinks.forEach((field: SocialLinkType) => {
      register(`${field.provider}.title`, {
        required:
          watchAll.displayType !== ICONS_ONLY &&
          (watchAll[field.provider as keyof SocialLinkFormFields]?.link.length ?? 0) > 0,
        maxLength: {
          value: TITLE_MAX,
          message: TITLE_MAX_ERROR
        }
      })
      register(`${field.provider}.description`, {
        maxLength: {
          value: DESCRIPTION_MAX,
          message: DESCRIPTION_MAX_ERROR
        }
      })
      register(`${field.provider}.thumbNailImgUrl`)
      register(`${field.provider}.order`)
      register(`${field.provider}.id`)
      register(`${field.provider}.active`)
      register(`${field.provider}.provider`)
      register(`${field.provider}.type`)
      register(`${field.provider}.link`, {
        validate: value => {
          const isValid = validateSocialLinkInput(value, field.provider, selectedLinks)
          return isValid
        }
      })
    })
  }, [watchAll, register])

  const allowedSocialLinks = adjustedSocialLinks.filter(
    link => !restrictedSocialLinks.includes(link.provider)
  )
  return {
    actionData,
    setValue,
    handleSubmit,
    errors,
    onSubmit,
    watchAll,
    disabled,
    edit,
    linkTheme,
    setLinkTheme: setLinkThemeValue,
    trigger,
    selectedLinks,
    setSelectedLinks,
    updateSelectedLinks,
    displayMoreOptions,
    setDisplayMoreOptions,
    updateFormFieldValue,
    allowedSocialLinks,
    expandedChildLinks,
    setExpandedChildLinks
  }
}
