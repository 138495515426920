import { SVGProps, memo } from 'react'

function ContactCollectionSvg({
  stroke = '#111',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M20.3551 5.00561L16.5844 1.23495C16.0844 0.734947 15.4057 0.453613 14.6991 0.453613H5.13639C3.66306 0.453613 2.46973 1.64695 2.46973 3.12028V21.7869C2.46973 23.2603 3.66306 24.4536 5.13639 24.4536H18.4697C19.9431 24.4536 21.1364 23.2603 21.1364 21.7869V6.89095C21.1364 6.18428 20.8551 5.50561 20.3551 5.00561Z"
        fill="#33B4FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.581 0.453613H5.13639C3.66306 0.453613 2.46973 1.64695 2.46973 3.12028V21.7869C2.46973 23.2603 3.66306 24.4536 5.13639 24.4536H11.581V0.522792V0.453613Z"
        fill="#008EE0"
      />
      <rect x="0.720825" y="6.14868" width="3.49773" height="2.16772" rx="1" fill="black" />
      <rect x="0.720825" y="11.3698" width="3.49773" height="2.16772" rx="1" fill="black" />
      <rect x="0.720825" y="16.5908" width="3.49773" height="2.16772" rx="1" fill="black" />
      <circle cx="11.9996" cy="9.87801" r="2.64547" fill="#D6F0FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9744 17.8107H7.02527C7.13152 15.155 9.31805 13.0347 11.9998 13.0347C14.6816 13.0347 16.8681 15.155 16.9744 17.8107Z"
        fill="#D6F0FF"
      />
    </svg>
  )
}

export const ContactCollection = memo(ContactCollectionSvg)

function ContactCollectionBWSvg({
  stroke = '#111',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M20.3551 5.00561L16.5844 1.23495C16.0844 0.734947 15.4057 0.453613 14.6991 0.453613H5.13639C3.66306 0.453613 2.46973 1.64695 2.46973 3.12028V21.7869C2.46973 23.2603 3.66306 24.4536 5.13639 24.4536H18.4697C19.9431 24.4536 21.1364 23.2603 21.1364 21.7869V6.89095C21.1364 6.18428 20.8551 5.50561 20.3551 5.00561Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.581 0.453613H5.13639C3.66306 0.453613 2.46973 1.64695 2.46973 3.12028V21.7869C2.46973 23.2603 3.66306 24.4536 5.13639 24.4536H11.581V0.522792V0.453613Z"
        fill="#fff"
      />
      <rect x="0.720825" y="6.14868" width="3.49773" height="2.16772" rx="1" fill="black" />
      <rect x="0.720825" y="11.3698" width="3.49773" height="2.16772" rx="1" fill="black" />
      <rect x="0.720825" y="16.5908" width="3.49773" height="2.16772" rx="1" fill="black" />
      <circle cx="11.9996" cy="9.87801" r="2.64547" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9744 17.8107H7.02527C7.13152 15.155 9.31805 13.0347 11.9998 13.0347C14.6816 13.0347 16.8681 15.155 16.9744 17.8107Z"
        fill="#000"
      />
    </svg>
  )
}

export const ContactCollectionBW = memo(ContactCollectionBWSvg)

function ContactCollectionOutlinedSvg({
  color = '#111',
  fill,
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="20" height="24" viewBox="0 0 20 24" fill="none" {...props}>
      <path
        d="M18.121 4.928l-3.588-3.58a2.515 2.515 0 00-1.785-.74H3.673a2.525 2.525 0 00-2.535 2.535v17.714a2.525 2.525 0 002.535 2.535h12.654a2.534 2.534 0 002.535-2.535V6.722a2.534 2.534 0 00-.74-1.794v0z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.862 6.94h-5.07a1.272 1.272 0 01-1.262-1.272V.608M14.04 19.595a3.485 3.485 0 00-.883-1.301v0a3.598 3.598 0 00-2.43-.95H9.273c-.9.002-1.768.34-2.43.95v0c-.39.363-.694.807-.893 1.3M9.995 14.335a2.06 2.06 0 100-4.12 2.06 2.06 0 000 4.12z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ContactCollectionOutlined = memo(ContactCollectionOutlinedSvg)
