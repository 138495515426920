import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC, ReactElement, ReactNode } from 'react'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Tooltip } from '@dtx-company/shared-components/src'
import { questionMarkBlue } from '@app/code/src/assets'

interface TitleProps {
  title: string
  icon?: ReactNode
  tooltip?: string
}

export const FormTitle: FC<TitleProps> = ({ title, icon, tooltip }: TitleProps) => {
  return (
    <Box alignItems="center">
      {icon}
      <Spacer mr="8px" />
      <Text variant="button/general">{title}</Text>
      <Spacer mr="8px" />
      {tooltip && tooltip.length > 0 && (
        <Tooltip buttonImage={questionMarkBlue} id="embedded-media-types" content={tooltip} />
      )}
    </Box>
  )
}

export const PreviewTitle = (): ReactElement => (
  <Text
    color="primary.black"
    textAlign={['left', 'left', 'center']}
    variant="body/small"
    fontWeight={600}
    mb={1}
  >
    Preview
  </Text>
)
