import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { LinkStyleForm } from './LinkStyleForm'
import { LinkStyleToggle } from './LinkStyleToggle'
import { LinkStyle as LinkStyleTypes } from '../../../../constants/customizeTheme'
import { LinkThemeState } from './types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { useFlowpageTheme } from '../../../../components/profile/Customize/hooks'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

/**
 * Renders a toggle that unfolds a customization form to change link color,
 * link text color and  link text size
 */
export const LinkStyle: FC<LinkThemeState> = ({
  linkTheme,
  hiddenFields,
  setLinkTheme,
  disabled
}) => {
  const { theme: pageTheme } = useFlowpageTheme()
  const isLinkStyleInset = pageTheme?.linkStyle === LinkStyleTypes.INSET
  return (
    <Box data-testid="link-style-form-container" flexDirection="column">
      <LinkStyleToggle
        checked={Boolean(linkTheme?.active)}
        onChange={checked => {
          events.Flowpage_PageProfile_Toggled_Link_Level_Advanced_Customization(
            checked ? 'on' : 'off'
          )
          setLinkTheme({ active: checked })
        }}
        disabled={disabled}
      />
      {Boolean(linkTheme?.active) && (
        <>
          <Spacer mb="16px" />
          <LinkStyleForm
            hiddenFields={{
              ...hiddenFields,
              shadowColor: !isLinkStyleInset ? !isLinkStyleInset : hiddenFields?.shadowColor, // hide shadowColor field if link style is not "Drop Shadow" aka "inset"
              borderColor: isLinkStyleInset ? isLinkStyleInset : hiddenFields?.borderColor // hide borderColor field if link style is "Drop Shadow" aka "inset"
            }}
            linkTheme={linkTheme}
            setLinkTheme={setLinkTheme}
            disabled={disabled}
          />
        </>
      )}
    </Box>
  )
}
