import { FC } from 'react'
import { Tooltip } from '@mui/material'
import { theme } from '@dtx-company/shared-components/src/theme'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'

export const LockedTemplateField: FC = () => {
  return (
    <Tooltip
      sx={{
        borderRadius: '50%',
        backgroundColor: theme.colors.masterWeb.grey3,
        padding: 1,
        margin: 1
      }}
      title="Reach out to the template creator if you wish to edit this element"
    >
      <LockOutlinedIcon htmlColor="#888888" />
    </Tooltip>
  )
}
