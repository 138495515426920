import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button, Stack } from '@dtx-company/design-system/src'
import { ColorInputHex } from '@dtx-company/shared-components/src/components/molecules/ColorInputHex'
import { EditTheme } from './types'
import { FC } from 'react'
import { LockedFeatureWrapper } from '@app/common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { Select } from '@dtx-company/shared-components/src/components/atoms/Select'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/Spacer'
import { TEXT_SIZE_OPTIONS } from '../../../components/widgets/components/LinkStyle/LinkStyleForm'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
interface FontStylePickerProps {
  fontColor?: string | null
  /** The color of the title and description of the page */
  titleAndDescriptionColor?: string | null
  handleChange: (values: EditTheme) => void
  textSize?: number | null
  disabled?: boolean
}

const ResetButton = ({ onClick }: { onClick: () => void }): JSX.Element => {
  return (
    <Button variant="text" size="small" onClick={onClick}>
      Reset
    </Button>
  )
}

const FontOptions: FC<FontStylePickerProps> = ({
  handleChange,
  textSize,
  fontColor,
  titleAndDescriptionColor,
  disabled = false
}) => (
  <Box {...stopPropagationEvents} flexWrap={'wrap'}>
    <Stack>
      <ColorInputHex
        label="Bio Font Color"
        disabled={disabled}
        color={titleAndDescriptionColor ?? ''}
        onColorChange={color => {
          fireAnalyticsEvent('Flowpage_PageProfile_Selected_Page_Bio_Font_Color', { color })
          handleChange({ titleColor: color })
        }}
        width={'144px'}
      />
      <ResetButton
        onClick={() => {
          fireAnalyticsEvent('Flowpage_PageProfile_Reset_Page_Bio_Font_Color')
          handleChange({ titleColor: null })
        }}
      />
    </Stack>
    <Spacer mr="8px" />
    <Stack>
      <ColorInputHex
        label="Font Color"
        disabled={disabled}
        color={fontColor ?? ''}
        onColorChange={color => {
          events.Flowpage_PageProfile_Selected_Page_Level_Font_Color(color)
          handleChange({ fontColor: color })
        }}
        width={'144px'}
      />
      <ResetButton
        onClick={() => {
          fireAnalyticsEvent('Flowpage_PageProfile_Reset_Page_Level_Font_Color')
          handleChange({ fontColor: null })
        }}
      />
    </Stack>
    <Spacer mr="8px" />
    <Box width={'144px'} flexDirection="column">
      <Stack>
        <Select
          label="Text Size"
          disabled={disabled}
          labelProps={{ mb: '8px' }}
          options={TEXT_SIZE_OPTIONS}
          value={{
            label: textSize?.toString() ?? '',
            value: textSize?.toString() ?? ''
          }}
          name="textSize"
          onChange={option => {
            const fontSize = parseInt(option.value)
            events.Flowpage_PageProfile_Selected_Page_Level_Text_Size(fontSize)
            handleChange({ textSize: fontSize })
          }}
          selectButtonProps={{ bg: 'primary.white' }}
          selectContainerProps={{ width: '100%' }}
        />
        <ResetButton
          onClick={() => {
            fireAnalyticsEvent('Flowpage_PageProfile_Reset_Page_Level_Text_Size')
            handleChange({ textSize: undefined })
          }}
        />
      </Stack>
    </Box>
  </Box>
)

export const FontStylePicker: FC<FontStylePickerProps> = props => {
  const { hasOperation } = useAuthState()
  const userCanViewFontOptions = hasOperation('view:page_style_options')
  const { hasViewOnlyAccess } = useCurrentPagePermissions()

  return (
    <>
      <Spacer mb={{ _: 0, md: '16px' }} />
      {userCanViewFontOptions ? (
        <FontOptions {...props} disabled={hasViewOnlyAccess || props.disabled} />
      ) : (
        <LockedFeatureWrapper
          featureName="view:page_style_options"
          onOpen={() => {
            events.Flowcode_PageProfile_Clicked_Font_Style_Lock()
          }}
        >
          <Box flexDirection="column">
            <FontOptions {...{ ...props, disabled: true }} />
          </Box>
        </LockedFeatureWrapper>
      )}
    </>
  )
}
