import { AutomaticDefaultCropperComponent } from '@dtx-company/shared-components/src/components/organisms/ImageEdit/subcomponents'
import { FC, useCallback, useEffect } from 'react'
import { LinkImageEditModalStateType } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice.types'
import { MaskType } from '@app/common/src/components/ImageEditor/ImageEditor.types'
import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import {
  clearImageEditModalState,
  setImageEditModalState
} from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { useDispatch } from 'react-redux'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export interface LinkImageEditModalState {
  isImageEditModalOpen: boolean
  openImageEditModal: (payload: Omit<Partial<LinkImageEditModalStateType>, 'isOpen'>) => void
  closeImageEditModal: () => void
  setResultImageUrl: (resultImageUrl: string, maskType?: MaskType) => void
  sourceImageUrl: string
  resultImageUrl: string
  maskType: MaskType
  hideMask: boolean
  initialAspectRatio?: number
  originalImageUrl: string
  setOriginalImageUrl: (originalImageUrl: string) => void
  defaultCroppedImageUrl: string
  AutomaticDefaultCropper: FC
}

export const useLinkImageEditModalState = (): LinkImageEditModalState => {
  const dispatch = useDispatch()
  const {
    isOpen,
    sourceImageUrl,
    resultImageUrl,
    maskType,
    defaultCroppedImageUrl,
    originalImageUrl,
    hideMask,
    initialAspectRatio
  } = usePageSelector<LinkImageEditModalStateType>(
    (state: RootPageState) => state.linkEditReducer.linkImageEditModalState
  ) as LinkImageEditModalStateType & { maskType: MaskType } // override maskType type bc we can't import MaskType from @app into inter-app

  const openImageEditModal = useCallback(
    ({
      sourceImageUrl,
      maskType,
      hideMask,
      initialAspectRatio
    }: Omit<Partial<LinkImageEditModalStateType>, 'isOpen'>): void => {
      dispatch(
        setImageEditModalState({
          isOpen: true,
          sourceImageUrl,
          maskType,
          hideMask,
          initialAspectRatio
        })
      )
    },
    [dispatch]
  )

  const setResultImageUrl = useCallback(
    (resultImageUrl: string, maskType?: MaskType): void => {
      dispatch(
        setImageEditModalState({
          resultImageUrl,
          isOpen: false,
          sourceImageUrl: '',
          maskType
        })
      )
    },
    [dispatch]
  )

  const setDefaultCroppedImageUrl = useCallback(
    (imageUrl: string): void => {
      dispatch(
        setImageEditModalState({
          defaultCroppedImageUrl: imageUrl
        })
      )
    },
    [dispatch]
  )

  const setOriginalImageUrl = useCallback(
    (imageUrl: string): void => {
      dispatch(
        setImageEditModalState({
          originalImageUrl: imageUrl
        })
      )
    },
    [dispatch]
  )

  const closeImageEditModal = (): void => {
    dispatch(
      setImageEditModalState({
        isOpen: false
      })
    )
    events.Flowpage_PageProfile_Clicked_Exit_Icon_Image_Window()
  }

  const clearImageEditModal = useCallback((): void => {
    dispatch(clearImageEditModalState())
  }, [dispatch])

  useEffect(() => {
    return () => {
      clearImageEditModal()
    }
  }, [clearImageEditModal])

  const AutomaticDefaultCropper: FC = useCallback(
    () => (
      <AutomaticDefaultCropperComponent
        imageUrl={originalImageUrl}
        maskType={maskType as MaskType}
        onDefaultCropDone={croppedImageUrl => {
          setDefaultCroppedImageUrl(croppedImageUrl)
        }}
      />
    ),
    [originalImageUrl, maskType, setDefaultCroppedImageUrl]
  )

  return {
    isImageEditModalOpen: isOpen,
    openImageEditModal,
    closeImageEditModal,
    setResultImageUrl,
    sourceImageUrl,
    resultImageUrl,
    maskType,
    AutomaticDefaultCropper,
    originalImageUrl,
    setOriginalImageUrl,
    defaultCroppedImageUrl,
    hideMask,
    initialAspectRatio
  }
}

export const useIsLinkImageEditModalOpen = (): boolean => {
  const { isOpen } = usePageSelector(
    (state: RootPageState) => state.linkEditReducer.linkImageEditModalState
  )
  return isOpen
}
