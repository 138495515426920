import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import {
  BoxProps,
  ButtonProps,
  ButtonStyleProps,
  DropdownMenu,
  theme
} from '@dtx-company/shared-components/src'
import {
  Button,
  PrimaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { ButtonColorOptions } from '@dtx-company/shared-components/src/constants'
import {
  ChangeEvent,
  FC,
  ForwardRefExoticComponent,
  Fragment,
  MouseEvent,
  RefAttributes,
  forwardRef,
  useMemo
} from 'react'
import { CheckBox } from '@dtx-company/shared-components/src/components/atoms/Checkbox/index'
import { DeepMap, FieldError } from 'react-hook-form-deprecated'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { Menu } from '@dtx-company/shared-components/src/components/atoms/Menu/index'
import { MenuItem } from '@dtx-company/shared-components/src/components/atoms/MenuItem/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { tzOptionsWithoutOffset } from '@app/common/src/constants/timezones'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'

export interface InputProps {
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  errors:
    | DeepMap<{ title: string; description: string; link: string; image: string }, FieldError>
    | DeepMap<{ [key: string]: string }, FieldError>
  placeholder?: string
  label?: string
  defaultValue?: string
  charMax?: number
  disabled?: boolean
  endAdornment?: JSX.Element
  controlled?: boolean
}
export interface TimeInputProps {
  disabled?: boolean
  key?: string | number
  value: string
  label?: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  defaultValue: string
}
export const TitleInput: ForwardRefExoticComponent<InputProps> = forwardRef<
  HTMLInputElement,
  InputProps
>(
  (
    {
      onChange,
      value,
      errors,
      placeholder,
      label = 'Link title',
      disabled = false,
      endAdornment = undefined,
      controlled = false
    }: InputProps,
    ref
  ) => {
    const { title: titleError } = errors

    return (
      <Input
        ref={ref}
        type="text"
        labelProps={{ marginBottom: '6px' }}
        error={Boolean(titleError)}
        placeholder={placeholder || 'Title'}
        helperText={titleError ? titleError.message || 'Title is required' : ''}
        value={value}
        maxWidth="100%"
        label={label}
        id="link-title-input"
        onChange={e => {
          events.Flowpage_PageProfile_Typed_Link_Title()
          onChange?.(e)
        }}
        {...stopPropagationEvents}
        disabled={disabled}
        endAdornment={endAdornment}
        controlled={controlled}
      />
    )
  }
)
TitleInput.displayName = 'TitleInput'

const StyledInput = styled(Input)`
  input {
    padding-left: 6px;
  }
`
export const TimeInput: ForwardRefExoticComponent<TimeInputProps> = forwardRef<
  HTMLInputElement,
  TimeInputProps
>(({ disabled = false, key, onChange, label, value, defaultValue }: TimeInputProps, ref) => {
  return (
    <StyledInput
      ref={ref}
      value={value}
      onChange={onChange}
      disabled={disabled}
      type="time"
      label={label}
      defaultValue={defaultValue}
      {...(key && { key })}
      {...stopPropagationEvents}
    />
  )
})
TimeInput.displayName = 'TimeInput'

export const LinkInput: ForwardRefExoticComponent<
  InputProps & {
    onBlur?: (() => void) | undefined
  } & RefAttributes<HTMLInputElement>
> = forwardRef<HTMLInputElement, InputProps & { onBlur?: () => void }>(
  (
    {
      onChange,
      value,
      onBlur,
      placeholder,
      errors,
      defaultValue,
      label,
      disabled = false,
      endAdornment = undefined
    }: InputProps & { onBlur?: () => void },
    ref
  ) => {
    const { link: linkError } = errors
    return (
      <Input
        ref={ref}
        labelProps={{ marginBottom: '6px' }}
        type="text"
        error={Boolean(linkError)}
        placeholder={placeholder || 'Enter a URL'}
        helperText={linkError?.message}
        value={value}
        label={label ?? 'URL'}
        maxWidth="100%"
        id="link-link-input"
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        disabled={disabled}
        endAdornment={endAdornment}
        {...stopPropagationEvents}
      />
    )
  }
)
LinkInput.displayName = 'LinkInput'

export const DescriptionInput: ForwardRefExoticComponent<InputProps & { label?: string }> =
  forwardRef<HTMLInputElement, InputProps & { label?: string }>(
    (
      {
        onChange,
        value,
        errors,
        label = 'Description',
        placeholder = 'Description',
        charMax
      }: InputProps & { label?: string },
      ref
    ) => {
      const { description: descError } = errors
      const charCountColor = useMemo(
        () => (value.length > (charMax ?? 100) ? 'status.errorDark' : 'primary.grey'),
        [value, charMax]
      )
      const charLimitingOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
        if (charMax) {
          if (e.target.value.length > charMax) {
            e.target.value = e.target.value.substring(0, charMax)
          }
        }
        onChange?.(e)
        events.Flowpage_PageProfile_Typed_Link_Description()
      }
      return (
        <Box display="block">
          <Input
            ref={ref}
            type="textarea"
            multiline
            labelProps={{ marginBottom: '6px' }}
            maxWidth="100%"
            placeholder={placeholder}
            helperText={descError?.message}
            value={value}
            error={Boolean(descError)}
            height="105px"
            label={label}
            {...stopPropagationEvents}
            onChange={charLimitingOnChange}
          />
          {charMax ? (
            <Box mt="8px" justifyContent="right">
              <Text
                variant="body/small"
                color={charCountColor}
              >{`${value.length}/${charMax}`}</Text>
            </Box>
          ) : (
            ''
          )}
        </Box>
      )
    }
  )
DescriptionInput.displayName = 'DescriptionInput'

export const SubmitButton: FC<
  {
    disabled?: boolean
    onClick?: (e: MouseEvent) => void
    isEdit?: boolean
    colorVariant?: ButtonColorOptions
    title?: string
    buttonProps?: ButtonStyleProps
  } & BoxProps
> = ({
  disabled = false,
  onClick,
  isEdit = false,
  title = 'Link',
  colorVariant = 'primary',
  buttonProps,
  ...rest
}: {
  disabled?: boolean
  onClick?: (e: MouseEvent) => void
  isEdit?: boolean
  colorVariant?: ButtonColorOptions
  title?: string
  buttonProps?: ButtonStyleProps
}) => {
  return (
    <Box
      position={['fixed', 'fixed', 'fixed', 'absolute']}
      bottom={[48, null, null, isEdit ? '58px' : '30px']}
      left={[null, null, null, 'calc(50% + 12px)']}
      width={['100%', '100%', '100%', 'calc(50% - 50px)']}
      bg="transparent"
      zIndex={9}
      {...rest}
    >
      <Button
        type="submit"
        data-test="link-form-submit"
        onClick={onClick}
        sizeVariant="mobileFullWidth"
        disabled={disabled}
        colorVariant={colorVariant}
        {...buttonProps}
      >
        {`Save ${capitalize(title)}`}
      </Button>
    </Box>
  )
}

interface TClearButtonProps {
  onClick?: (e: MouseEvent) => void
  title?: string
  disabled: boolean
}
const RedPrimaryButton = styled(PrimaryButton)<ButtonProps>`
  background: ${({ theme }) => theme.colors.status.errorDark};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.status.errorLight};
  }
`
export const ClearButton: FC<TClearButtonProps> = ({
  onClick,
  title = 'Link',
  disabled
}: TClearButtonProps) => {
  return (
    <Box
      zIndex={9}
      position={['fixed', 'fixed', 'absolute']}
      bottom={30}
      cursor="pointer"
      left={[null, null, 'calc(50% + 12px)']}
      width={['100%', '100%', 'calc(50% - 50px)']}
      bg={['primary.white', 'primary.white', 'transparent', 'transparent']}
    >
      <RedPrimaryButton
        border="none"
        height="44px"
        onClick={onClick}
        width={['calc(100% - 48px)', 'calc(100% - 48px)', '100%']}
        label={`Clear ${capitalize(title)}`}
        disabled={disabled}
      />
    </Box>
  )
}

interface CheckboxOption {
  checked: boolean
  onChange: () => void
  label: string
}
interface CheckboxGroupProps {
  options: CheckboxOption[]
}

export const CheckboxGroup: FC<CheckboxGroupProps> = ({ options }: CheckboxGroupProps) => {
  return (
    <Box flexDirection="column">
      {options.map((option: CheckboxOption, idx: number) => (
        <CheckBox
          padding={'4px 0px'}
          key={idx}
          label={option.label}
          checked={option.checked}
          onChange={option.onChange}
        />
      ))}
    </Box>
  )
}

export const TimezonePicker: FC<{
  onSelect: ({ value, label }: { value: string; label: string }) => void
}> = ({ onSelect }) => {
  return (
    <DropdownMenu
      width="140px"
      padding="0px 4px"
      borderRadius="4px"
      position="relative"
      height="32px"
      header={
        <Box alignItems="center" pl="2px">
          <Text>Timezone</Text>
        </Box>
      }
    >
      <Menu
        backgroundColor="primary.white"
        border={`1px solid ${theme.colors.secondary.border}`}
        borderRadius="12px"
        maxHeight="200px"
        overflow="scroll"
        padding="0px 4px"
        width="140px"
        position="absolute"
        zIndex={6}
      >
        {tzOptionsWithoutOffset.map(({ value, label }, idx) => (
          <Fragment key={`${value}-${idx}`}>
            <MenuItem
              padding="2px"
              onClick={() => {
                onSelect({ value, label })
              }}
            >
              <Text>{label}</Text>
            </MenuItem>
          </Fragment>
        ))}
      </Menu>
    </DropdownMenu>
  )
}

export const UsernameInput: ForwardRefExoticComponent<
  InputProps & {
    onBlur(): void
  }
> = forwardRef<
  HTMLInputElement,
  InputProps & {
    onBlur(): void
  }
>(({ onChange, value, onBlur, errors }: InputProps & { onBlur(): void }, ref) => {
  const { link: linkError } = errors
  return (
    <Input
      ref={ref}
      startAdornment={
        <Text pl="2px" fontSize={[3, 3, 2]} variant="body/small">
          @
        </Text>
      }
      type="text"
      labelProps={{ marginBottom: '6px' }}
      placeholder="myhandle"
      helperText={linkError?.message}
      value={value}
      maxWidth="100%"
      error={Boolean(linkError)}
      label="Username"
      onBlur={onBlur}
      onChange={onChange}
    />
  )
})
UsernameInput.displayName = 'UsernameInput'
