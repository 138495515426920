import { Box } from '../../atoms/Box/index'
import { Button } from '../../atoms/Button/index'
import {
  ButtonContentContainer,
  CircleButton,
  HiddenInput,
  SquareButton,
  StyledInput,
  StyledLine,
  UnstyledButton
} from './styles'
import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import { FileUploadProps, ImageEditProp } from '../../../types/organisms/imageedit.types'
import { Icon } from '../../atoms/Icon/index'
import { MaskObj } from './constants'
import { MaskType, ModalVariants } from '../../../types'
import { Modal } from '../Modal'
import { SIZES } from '@dtx-company/true-common/src/constants/mediaQueries'
import { Slider } from '../../atoms/Slider/index'
import { Text } from '../../atoms/Text/index'
import { blobToBase64, useMediaQuery } from '../../../utils'
import { canvasToBlob } from '@dtx-company/true-common/src/utils/blob'
import { exceedsMaxFileSize } from './utils'
import { getBase64Strings } from 'exif-rotate-js'
import { theme } from '../../../../src/theme'
import AvatarEditor from 'react-avatar-editor'
import deleteIcon from '../../../static/icons/delete.svg'
export const FileUploadInput: FC<FileUploadProps> = ({
  id,
  handleUpload,
  accept,
  multiple,
  children
}: FileUploadProps) => {
  return (
    <>
      <HiddenInput
        type="file"
        accept={accept || 'image/png,image/jpeg,image/svg'}
        onChange={e => {
          handleUpload(e)
          e.target.value = ''
        }}
        multiple={multiple || false}
        id={id}
        data-testid="FileUploadInput"
      />
      <label htmlFor={id}>{children}</label>
    </>
  )
}

// These styles provide a checkered background to make working with transparent pngs more manageable
// Lite version of code lifted from https://stackoverflow.com/a/47061022
const EDITOR_STYLES = {
  background: `linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0), white`,
  backgroundRepeat: `repeat, repeat`,
  backgroundPosition: `0px 0, 5px 5px`,
  backgroundSize: `10px 10px, 10px 10px`
}

/**
 * @deprecated use [ImageEditor](@app/common/src/components/ImageEditor/ImageEditor.tsx) instead
 */
export const ImageEditModal: FC<ImageEditProp> = ({
  title,
  file,
  submitting,
  openModal,
  hideMask,
  maskType,
  hideDelete,
  useObjectURL = false,
  handleUploadImage,
  handleDeleteImage,
  handleClose,
  onPositionChange,
  onSlide,
  onUpload,
  onSave: _onSave,
  spinner,
  ...rest
}: ImageEditProp) => {
  const [isOpen, toggleOpen] = useState(openModal || false)
  const [image, setImage] = useState<string | null>(null)
  const [imageSize, setImageSize] = useState(0)
  const [scale, setScale] = useState(1)
  const [rotate] = useState(0)
  const avatarEditorRef = useRef<AvatarEditor>(null)
  const [selectedMaskType, setSelectedMaskType] = useState<MaskType>(maskType)
  const [mimeType, setMimeType] = useState('image/png')
  const handleImageUploadDialog = useCallback(
    async (newFile?: File | string): Promise<void> => {
      if (newFile) {
        let imageUrl

        if (newFile instanceof File) {
          setImageSize(newFile.size)
          setMimeType(newFile.type)
          if (useObjectURL) {
            imageUrl = URL.createObjectURL(newFile)
          } else {
            const imgStrs = await getBase64Strings([newFile as Blob])
            imageUrl = imgStrs[0]
          }
        } else {
          const fetchedImage = await fetch(newFile).then(res => res.blob())
          imageUrl = URL.createObjectURL(fetchedImage)
        }
        setImage(imageUrl)
        toggleOpen(true)
      }
    },
    [useObjectURL]
  )

  useEffect(() => {
    if (maskType) {
      setSelectedMaskType(maskType)
    }
  }, [maskType])

  useEffect(() => {
    toggleOpen(openModal)
  }, [openModal])

  useEffect(() => {
    if (openModal) {
      handleImageUploadDialog(file)
    }
  }, [file, openModal, handleImageUploadDialog])

  const onSave = async (): Promise<void> => {
    if (avatarEditorRef.current) {
      const canvas = avatarEditorRef.current?.getImage()
      const blob = await canvasToBlob(canvas, mimeType)
      let imageUrl
      if (useObjectURL) {
        imageUrl = URL.createObjectURL(blob)
      } else {
        imageUrl = await blobToBase64(blob)
      }
      handleUploadImage(imageUrl, selectedMaskType, imageSize)
    }
    _onSave?.()
  }

  const tablet = useMediaQuery(SIZES.tablet)
  return (
    <>
      <Modal
        variant={tablet ? ModalVariants.FULLPAGE : ModalVariants.DESKTOP}
        delay={250}
        title={title}
        open={isOpen}
        disableAutoClose
        onClose={handleClose}
        height="100%"
        width={['100%', '100%', '600px']}
        zIndex={1301}
        {...rest}
      >
        <>
          <Box
            flexDirection="column"
            alignItems="center"
            justifyContent="start"
            width="100%"
            maxWidth="600px"
            margin={tablet ? '58px 0px' : '58px 0 40px'}
            fontFamily={theme.fontFamily}
          >
            <Box
              backgroundColor="primary.black"
              width="100%"
              height="100%"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              {submitting && spinner}
              {image && (
                <AvatarEditor
                  ref={avatarEditorRef}
                  image={image}
                  onPositionChange={() => onPositionChange?.()}
                  border={50}
                  borderRadius={MaskObj[selectedMaskType]?.borderRadius || 0}
                  color={[25, 25, 25, 0.5]}
                  scale={scale}
                  rotate={rotate}
                  width={MaskObj[selectedMaskType]?.width || undefined}
                  height={MaskObj[selectedMaskType]?.height || undefined}
                  style={EDITOR_STYLES}
                />
              )}
              <Box position="absolute" bottom="10px">
                <Text
                  variant="body/medium"
                  color="primary.white"
                  fontWeight="600"
                  fontFamily={theme.fontFamily}
                >
                  Drag to reposition photo
                </Text>
              </Box>
            </Box>
            <Box width="100%" p="0 30px" justifyContent="space-between">
              <Box
                flexDirection="column"
                pt="20px"
                alignItems="center"
                width={hideMask ? '100%' : '70%'}
              >
                <Text variant="body/small" mb="12px" fontFamily={theme.fontFamily}>
                  Zoom
                </Text>
                <Slider
                  value={scale.toString()}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void => {
                    setScale(+e.target.value)
                    onSlide?.()
                  }}
                  aria-labelledby="continuous-slider"
                  step="0.1"
                  min=".2"
                  max="4"
                />
              </Box>
              {!hideMask && (
                <Box flexDirection="column" pt="20px" mr="6px" alignItems="center">
                  <Text variant="body/small" fontFamily={theme.fontFamily}>
                    Shape
                  </Text>
                  <Box pt="8px">
                    <CircleButton
                      $selected={selectedMaskType === MaskType.CIRCLE}
                      onClick={() => setSelectedMaskType(MaskType.CIRCLE)}
                    />
                    <SquareButton
                      $selected={selectedMaskType === MaskType.SQUARE}
                      onClick={() => setSelectedMaskType(MaskType.SQUARE)}
                    />
                  </Box>
                </Box>
              )}
            </Box>

            <StyledLine />
            <Box
              alignItems="space-between"
              justifyContent="space-between"
              width="90%"
              paddingTop="20px"
            >
              <Box alignItems="center" justifyContent="space-between">
                {!hideDelete && (
                  <UnstyledButton onClick={handleDeleteImage}>
                    <ButtonContentContainer>
                      <Icon alt="delete-button" src={deleteIcon} />
                      <Text
                        color="primary.flowBlue"
                        fontWeight={600}
                        variant="body/small"
                        lineHeight="2.2"
                        pl="4px"
                        mt="4px"
                        fontFamily={theme.fontFamily}
                      >
                        Delete
                      </Text>
                    </ButtonContentContainer>
                  </UnstyledButton>
                )}
              </Box>

              <Box alignItems="space-between" justifyContent="space-between" width="180px">
                <FileUploadInput
                  handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      handleImageUploadDialog(e.target.files[0])
                      onUpload?.()
                    }
                  }}
                  id="change2-avatar"
                >
                  <StyledInput data-testid="upload-button">
                    {file ? 'Change' : 'Upload'}
                  </StyledInput>
                </FileUploadInput>
                <Button
                  disabled={exceedsMaxFileSize(imageSize)}
                  padding="10px 40px"
                  height="35px"
                  onClick={onSave}
                  type="button"
                >
                  Save
                </Button>
              </Box>
            </Box>
            {exceedsMaxFileSize(imageSize) && (
              <Text marginTop="10px" color="status.errorDark">
                The maximum file size is 10MB
              </Text>
            )}
          </Box>
        </>
      </Modal>
    </>
  )
}
