import { AddNewLinkType, AddWidget } from './addUtils'
import { AddWidgetType } from '../../widgets/types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC, useMemo } from 'react'
import { HIDE_WIDGETS_FEATURE_FLAG } from '../../../constants'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { SectionHeader } from '../SectionHeader/SectionHeader'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import {
  Spacer,
  VerticalSpacer
} from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Star } from '@dtx-company/shared-components/src/foundation/Icons/Product/Star'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { WIDGETS, widgetTypes } from '../../widgets/manifest'
import { WidgetType } from '../../widgets/types/widgetType'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useFlowFeature } from '@dtx-company/inter-app/src/hooks/useFlowFeature/useFlowFeature'
import { useIsFlowcodeEmployee } from '../../../utils/useIsFlowcodeEmployee'
import { useIsPagePublished } from '../../../utils/useIsPagePublished'
import { useMediaQuery } from '@dtx-company/true-common/src/hooks/useMediaQuery'
import { usePageHasLinks } from '../../../hooks/usePageCanBePublished'

export const AddWidgets: FC<{
  onClick: (link: SocialLinkType | AddWidgetType) => void
  handleClose?: () => void
}> = ({ onClick, handleClose }) => {
  const isPublished = useIsPagePublished()
  const { hasOperation } = useAuthState()
  const showAppStoreLink = hasOperation('view:fp_add_app_store_link')
  const [_, { hide_widgets }] = useFlowFeature(HIDE_WIDGETS_FEATURE_FLAG)
  const lessThan1254 = useMediaQuery('(max-width:1254px)')
  const { isFlowcodeEmployee } = useIsFlowcodeEmployee()
  const addWidgetTypes = useMemo(() => widgetTypes.filter(({ id }) => Boolean(WIDGETS[id].Add)), [])

  return (
    <Box
      flexDirection="column"
      borderRadius={['8px', '8px', '8px 8px 0px 0px']}
      p={'16px'}
      minHeight={['150px', '150px', null]}
    >
      {
        <>
          <Box position="relative" alignItems="center">
            <Star />
            <Spacer mr="8px" />
            <Text variant={'body/small'} fontWeight={600}>
              Select an advanced link type
            </Text>
          </Box>
          <Spacer mb="8px" />
        </>
      }
      <Box
        display={['block', 'block', 'flex']}
        flexDirection={['column', 'row']}
        flexWrap={[null, null, 'wrap']}
        alignSelf="stretch"
        mb={'24px'}
      >
        {addWidgetTypes.map(widget => {
          const widgetType = widget.type as WidgetType
          const add = WIDGETS[widgetType]?.Add
          let hideWidget = hide_widgets?.hide.includes(String(widgetType)) && !isFlowcodeEmployee
          if (widgetType === 'productForSale' && !isPublished) hideWidget = true
          if (widgetType === 'appStoreLink' && !showAppStoreLink) hideWidget = true
          return (
            !hideWidget &&
            add && (
              <Box
                {...stopPropagationEvents}
                key={widget.id}
                flex={{ _: 'unset', md: '0 50%' }}
                justifyContent="center"
                width={lessThan1254 ? '100%' : '50%'}
              >
                <AddWidget collapsed={false} link={widget} linkInfo={add} onClick={onClick} />
              </Box>
            )
          )
        })}
      </Box>
      {handleClose && (
        <SecondaryButton
          margin={['inherit', '8px 6px 0 6px']}
          width={['inherit', 'calc(100% - 12px)']}
          onClick={handleClose}
          borderColor="status.errorDark"
        >
          <Text variant="button/general" color="status.errorDark">
            Cancel
          </Text>
        </SecondaryButton>
      )}
    </Box>
  )
}

export const AddNew: FC<{
  onClick: (link: AddNewLinkType) => void
}> = ({ onClick }) => {
  const pageHasLinks = usePageHasLinks()
  const isEmptyPage = !pageHasLinks
  const showBorder = isEmptyPage
  return (
    <Box
      border={showBorder ? `1px solid ${theme.colors.primary.flowBlue}` : undefined}
      flexDirection="column"
      width="100%"
      borderRadius="10px"
      minHeight="min-content"
    >
      <Box justifyContent="space-between" flexDirection={'column'}>
        <AddWidgets onClick={onClick} />
      </Box>
    </Box>
  )
}
const AddNewWrapper: FC<{
  onClick: (link: SocialLinkType | AddWidgetType) => void
  open?: boolean
  noLinks?: boolean
}> = ({ onClick, noLinks }) => {
  return (
    <>
      {<SectionHeader title="Add links" icon="/icons/product/my-links.svg" />}
      {noLinks && (
        <>
          <Spacer mb="8px" />
          <Text pl="24px" variant="body/medium">
            Add your first link below!
          </Text>
          <Spacer mb="8px" />
        </>
      )}
      <VerticalSpacer mb="8px" />
      <Box pl={[0, 0, '24px']}>
        <AddNew onClick={onClick} />
      </Box>
    </>
  )
}

export default AddNewWrapper
