import {
  InputMaybe,
  TemplateConfig,
  TemplateInput
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { useToggleLockedSlot } from '../../FlowpageTemplates/hooks/useToggleLockedSlot'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpageTemplates'

export function useToggleRemoveBrandingSlot(): {
  toggleLocked: (props: {
    slotId?: string
    existingTemplateConfig?: TemplateConfig
    newSlot?: {
      pageId: string
      slotMetaType: string
      value: Record<string, string | boolean>
      templateConfigInput?: InputMaybe<TemplateInput> | undefined
    }
  }) => Promise<void>
  trackingCall: () => void
} {
  const toggleLocked = useToggleLockedSlot()
  return {
    toggleLocked,
    trackingCall: () => events.Flowpage_TemplateEditor_Clicked_ToggleLockedField('removeBranding')
  }
}
