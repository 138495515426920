import { BackgroundImageState } from './types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { MouseEvent, useState } from 'react'
import { PageType, UnauthPageQueryTheme } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { Section } from '@dtx-company/shared-components/src/components/atoms/Section/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Spinner } from '@dtx-company/shared-components/src/components/atoms/Spinner/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Theme, ThemeInput } from '@dtx-company/flow-codegen/src/page/generated.types'
import { defaultUserTheme } from '../../../constants'
import { editTheme } from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { loadImageWithEXIF } from '../../../utils/imageEditor'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useCurrentPageId } from '@dtx-company/inter-app/src/hooks/useCurrentPageId'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import { useDispatch } from 'react-redux'
import { useEditThemeMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/theme'
import { usePageDisplayContext } from '../../../context/page-display'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useRouter } from 'next/router'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'

export function useFlowpageTheme(): { theme: Theme | null | UnauthPageQueryTheme } {
  const { isAuthenticated } = useAuthState()
  const currentPage = useCurrentPage()
  const { unauthTheme } = usePageDisplayContext()
  const router = useRouter()
  const isProfileRoute = router.pathname === Routes.PAGE_EDITOR
  if (
    isAuthenticated &&
    currentPage &&
    (currentPage.theme?.id === unauthTheme?.id || isProfileRoute)
  ) {
    return { theme: currentPage.theme }
  }
  return { theme: unauthTheme ?? null }
}

export function useEditThemeWithAutosave(): ({
  themeInput,
  themeId,
  backgroundImage,
  deleteImage,
  isTemplateEditor
}: {
  themeInput: Partial<Theme>
  backgroundImage?: string
  themeId: string
  deleteImage?: boolean
  isTemplateEditor?: boolean
}) => Promise<void> {
  const dispatch = useDispatch()
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const [editThemeMutation] = useEditThemeMutation()
  const pageId = useCurrentPageId()
  return async ({
    themeInput,
    themeId,
    backgroundImage,
    deleteImage,
    isTemplateEditor = false
  }) => {
    const { id: _id, backgroundImgUrl: bgImage, ...themeMutationInput } = themeInput

    shouldEditPageInRedux
      ? dispatch(
          editTheme({
            theme: {
              ...themeInput,
              ...((backgroundImage || bgImage) && { backgroundImgUrl: backgroundImage || bgImage }),
              ...(deleteImage && { backgroundImgUrl: undefined })
            }
          })
        )
      : await editThemeMutation({
          isTemplateEditor,
          themeId: themeId,
          themeInput: { ...themeMutationInput, pageId },
          backgroundImage,
          deleteImage
        })
  }
}

export function useBackgroundImageWithAutosave(): ({
  themeInput,
  themeId,
  backgroundImage,
  deleteImage
}: {
  themeInput: ThemeInput
  themeId: string
  backgroundImage?: string
  deleteImage?: boolean
  isTemplateEditor?: boolean
}) => Promise<void> {
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const dispatch = useDispatch()
  const [editThemeMutation] = useEditThemeMutation()
  const pageId = useCurrentPageId()
  return async ({ themeId, themeInput, backgroundImage, deleteImage, isTemplateEditor }) => {
    if (deleteImage) {
      shouldEditPageInRedux
        ? dispatch(
            editTheme({
              theme: {
                ...themeInput,
                backgroundImgUrl: undefined
              }
            })
          )
        : await editThemeMutation({
            isTemplateEditor,
            themeId: themeId,
            themeInput: { ...themeInput, pageId },
            deleteImage
          })
    } else {
      shouldEditPageInRedux
        ? dispatch(
            editTheme({
              theme: {
                ...themeInput,
                backgroundImgUrl: backgroundImage
              }
            })
          )
        : await editThemeMutation({
            isTemplateEditor,
            themeId: themeId,
            themeInput: { ...themeInput, pageId },
            backgroundImage
          })
    }
  }
}
const MAX_FILE_SIZE = 20000000

export function useBackgroundImageSelector({ page }: { page?: PageType }): BackgroundImageState {
  const bgImage = page?.theme?.backgroundImgUrl
  const { isViewer } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const editTheme = useEditThemeWithAutosave()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const [imageSubmitting, setImageSubmitting] = useState<boolean>(false)
  const handleDeleteImage = async (e: MouseEvent): Promise<void> => {
    e.stopPropagation()
    setImageSubmitting(true)
    if (page?.id) {
      const { __typename, id, ...curTheme } = page?.theme || defaultUserTheme

      editTheme({
        isTemplateEditor,
        themeInput: curTheme,
        themeId: id,
        deleteImage: true
      })
    }
    setImageSubmitting(false)
  }

  const handleUploadImage = async (newFile: File): Promise<void> => {
    if (newFile.size > MAX_FILE_SIZE) {
      sendErrorNotification(
        `Image is too large. Please upload an image less than ${MAX_FILE_SIZE / 1000000}MB.`
      )
      return
    }
    setImageSubmitting(true)

    const backgroundImgUrl = await loadImageWithEXIF(newFile)
    if (backgroundImgUrl) {
      const { __typename, id, ...curTheme } = page?.theme || defaultUserTheme

      if (page?.id) {
        editTheme({
          themeInput: {
            ...curTheme
          },
          isTemplateEditor,
          themeId: id,
          backgroundImage: backgroundImgUrl
        })
      }
    }

    setImageSubmitting(false)
  }

  const bgImageText = bgImage ? 'Replace' : 'Upload'
  const BgImage = (
    <Box justifyContent="center" width="100%">
      {bgImage ? (
        <Image src={bgImage} width="140px" borderRadius="8px" />
      ) : (
        <Section
          width="113.45px"
          height="216.665px"
          mr="12px"
          bg={'primary.grey'}
          borderRadius="8px"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Image src="/icons/placeholders/image-placeholder.svg" />
            <Spacer mb="16px" />
            <Text variant="button/input" color="primary.white">
              Add Image
            </Text>
          </Box>
          {imageSubmitting && <Spinner height="40px" width="40px" />}
        </Section>
      )}
    </Box>
  )
  return {
    handleDeleteImage,
    handleUploadImage,
    BgImage,
    bgImageText,
    bgImage,
    isViewer: hasViewOnlyAccess || isViewer
  }
}
