import {
  GetPageDetailsQuery,
  MutationResetLinkThemesArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { Maybe, Scalars } from '@dtx-company/flow-codegen/src/code/generated.types'
import { RESET_LINK_THEMES } from '@dtx-company/flow-codegen/src/page/mutations'
import { RootState } from '../../../redux/types'
import { flowpageApi } from './empty'
import { flowpageAutosaveMessaging } from '@dtx-company/true-common/src/constants/messages'
import { nullifyLinksThemes } from './linkMutationsHelpers/utils.ts/nullifyLinksThemes'
import { pageQueries } from './pageQueries'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'

export const resetLinkThemes = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    resetLinkThemes: builder.mutation<Maybe<Scalars['Boolean']>, MutationResetLinkThemesArgs>({
      query: ({ pageId }) => ({
        document: RESET_LINK_THEMES,
        variables: {
          pageId
        },
        authOnly: true
      }),
      async onQueryStarted({ pageId }, { dispatch, getState, queryFulfilled }) {
        const id = (getState() as RootState).pageReducer.pageEditorReducer.pageId
        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            { id, isPageTemplate: false },
            (draft: GetPageDetailsQuery) => {
              Object.assign(draft, {
                Page: { ...draft.Page, links: nullifyLinksThemes(draft.Page.links) }
              })
            }
          )
        )

        try {
          await queryFulfilled
          sendSuccessNotification(flowpageAutosaveMessaging.resetLinkThemes.success)
        } catch {
          patchResult.undo()
          sendErrorNotification(flowpageAutosaveMessaging.resetLinkThemes.error)
        }
      }
    })
  }),
  overrideExisting: true
})

export const { useResetLinkThemesMutation } = resetLinkThemes
