import { EMBED } from './constants'
import { EmbedProps } from './types'
import { FC } from 'react'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { ToggleButton } from '@dtx-company/shared-components/src/components/atoms/ToggleButton/index'
import styled from 'styled-components'

export const StyledToggleButton = styled(ToggleButton)`
  border-radius: 5px;
  &[aria-pressed='true'] {
    background-color: #e6eeef;
    border: solid 2px #033ddd;
  }
`

const StyledToggle = styled(Toggle)`
  p {
    flex-shrink: 10;
  }
`
export const EmbedToggle: FC<EmbedProps> = ({ value, setValue }: EmbedProps) => {
  const handleEmbedChange = (newEmbed: boolean): void => {
    setValue(EMBED, newEmbed)
  }

  return (
    <>
      <Text>Embed</Text>
      <StyledToggle
        checked={value}
        onChange={() => handleEmbedChange(!value)}
        label={
          value
            ? 'This wil play directly in your Flowpage.'
            : 'Appears as a normal link.  Clicking it will open a new tab to play'
        }
      />
    </>
  )
}
