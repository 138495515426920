import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ChangeEvent, FC } from 'react'
import { FileUploadInput } from '../../util/SettingsUtil'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import {
  PrimaryButton,
  SecondaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { trashCanRed } from '@app/code/src/assets'
import { useBackgroundImageSelector } from './hooks'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface CardProps {
  page: PageType | undefined
  disabled?: boolean
}

export const BackgroundImage: FC<CardProps> = ({ page, disabled }) => {
  const { isViewer, BgImage, handleDeleteImage, handleUploadImage, bgImage, bgImageText } =
    useBackgroundImageSelector({ page })
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const viewOnly = isViewer || hasViewOnlyAccess || disabled
  return (
    <Box flexDirection="column" alignItems="center" justifyContent="center" width="100%">
      <Box
        {...stopPropagationEvents}
        flexDirection={['column', 'column', 'row']}
        justifyContent={['center', 'center', 'flex-start', 'flex-start']}
        alignItems={['center', 'center', 'flex-start', 'flex-start']}
      >
        {viewOnly ? (
          <Box
            opacity={viewOnly ? 0.35 : 1}
            mt="12px"
            justifyContent={['space-between', 'space-between', 'initial']}
          >
            {BgImage}
          </Box>
        ) : (
          <FileUploadInput
            position="relative"
            handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
              const files = e?.target?.files
              if (files && files.length > 0) {
                handleUploadImage(files[0])
                events.Flowpage_PageProfile_Added_BackgroundImage()
              }
            }}
            id="background-image-upload"
          >
            <Box>{BgImage}</Box>
            <Spacer mr="16px" />
          </FileUploadInput>
        )}
        <Spacer margin={['0 0 8px 0', null, null, '0 8px 0 0']} />
        <Box width="100%">
          <Box width={'48%'} opacity={viewOnly ? 0.35 : 1}>
            <SecondaryButton
              disabled={!bgImage || viewOnly}
              borderColor="status.errorDark"
              onClick={e => {
                handleDeleteImage(e)
                events.Flowpage_PageProfile_Clicked_DeletedBackgroundImageButton()
              }}
              type="button"
              width={'100%'}
            >
              <Image minWidth={'16px'} src={trashCanRed} />
              <Spacer mr="4px" />
              <Text color="status.errorDark" variant="button/general">
                Delete
              </Text>
            </SecondaryButton>
          </Box>
          <Spacer ml="8px" />
          <Box width={'52%'}>
            {viewOnly ? (
              <PrimaryButton width={'100%'} label={bgImageText} disabled={viewOnly} />
            ) : (
              <FileUploadInput
                position="relative"
                width={'100%'}
                handleUpload={(e: ChangeEvent<HTMLInputElement>) => {
                  const files = e?.target?.files
                  if (files && files.length > 0) {
                    handleUploadImage(files[0])
                  }
                  events.Flowpage_PageProfile_Selected_UploadBackgroundImageButton()
                }}
                id="background-image-upload-button"
              >
                <PrimaryButton width={'100%'} label={bgImageText} />
              </FileUploadInput>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
