import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DeleteV2, theme } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import styled from 'styled-components'

interface DeleteModeDrawerProps {
  deleteModeActive: boolean
  toggleDeleteMode: () => void
  onDelete: () => void
}

export const DeleteModeDrawer: FC<DeleteModeDrawerProps> = ({
  deleteModeActive,
  toggleDeleteMode,
  onDelete
}) => {
  return (
    <StyledDrawer expanded={deleteModeActive} data-testid="delete-mode-drawer">
      {deleteModeActive && (
        <Box alignItems="center" width="100%">
          <StyledDrawerButton
            border="none"
            bg={theme.colors.status.errorDark}
            onClick={onDelete}
            data-testid="delete-mode-delete-button"
          >
            <DeleteV2 fill="white" height="24px" width="24px" />
            <Spacer mr="8px" />
            <Text color="primary.white" fontWeight={600} fontSize="16px">
              Delete
            </Text>
          </StyledDrawerButton>
          <Spacer mr="8px" />
          <StyledDrawerButton
            onClick={toggleDeleteMode}
            bg={theme.colors.primary.white}
            data-testid="delete-mode-cancel-button"
            border={`1px solid ${theme.colors.secondary.border}`}
          >
            <Text color="primary.flowBlue" fontWeight={600} fontSize="16px">
              Cancel
            </Text>
          </StyledDrawerButton>
        </Box>
      )}
    </StyledDrawer>
  )
}

const StyledDrawer = styled(Box)<{ expanded: boolean }>`
  width: 100%;
  border-top: ${({ expanded }) =>
    expanded ? `1px solid ${theme.colors.secondary.border}` : 'none'};
  padding: ${({ expanded }) => (expanded ? '16px' : '0')};
  height: auto;
  max-height: ${({ expanded }) => (expanded ? '70px' : '0')};
  transition: max-height, 0.5s;
`

const StyledDrawerButton = styled.button<{ bg?: string; border?: string }>`
  type: button;
  cursor: pointer;
  border-radius: 4px;
  height: 40px;
  width: 100%;
  background: ${({ bg }) => bg};
  border: ${({ border }) => border};
  display: flex;
  align-items: center;
  justify-content: center;
  :focus {
    outline: 2px solid rgba(3, 61, 221, 0.5);
  }
  :hover {
    opacity: 0.5;
  }
`
