import { BoxProps, theme } from '@dtx-company/shared-components/src'
import { LinkStyle } from '../../../../constants/customizeTheme'
const { colors, shadows } = theme

export const getLinkStylesForFeaturedLink = ({
  style
}: {
  style: LinkStyle
}): Omit<BoxProps, 'children' | 'onClick'> => {
  const boxShadow = style === LinkStyle.INSET ? 'rgb(0 0 0 / 35%) 7px 7px 0px 0px' : shadows.float
  return {
    background: colors.primary.black,
    color: colors.primary.white,
    boxShadow
  }
}
