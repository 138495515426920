import { FC } from 'react'
import { MenuItemProps, MenuItemStyleProps } from '../../..'
import { border, boxShadow, color, layout, space } from 'styled-system'
import styled from 'styled-components'

const MenuItemBase = styled.li<MenuItemStyleProps>`
  list-style: none;
  padding: 0;
  margin: 0;
  ${boxShadow}
  ${space}
    ${layout}
    ${border}
    ${color}
`

export const MenuItem: FC<MenuItemProps> = ({
  children,
  component = 'li',
  onClick,
  ...rest
}: MenuItemProps): JSX.Element => (
  <MenuItemBase as={component} onClick={onClick} {...rest}>
    {children}
  </MenuItemBase>
)
