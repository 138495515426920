import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { fireAnalyticsEvent } from '@dtx-company/inter-app/src/event-tracking/helpers/fireAnalyticsEvent'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useResetCustomizationModal } from '../../FlowpageTemplates/hooks/useResetCustomizationModal'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

export const ResetPageTheme: FC<{
  themeId: string
}> = ({ themeId }) => {
  const { setOpen } = useResetCustomizationModal()

  return (
    <>
      <Box alignItems="center" {...stopPropagationEvents}>
        <RestartAltIcon />
        <Spacer display={['none', 'none', 'flex']} mr="8px" />
        <Text
          color="primary.black"
          variant={['body/small', 'body/small', 'body/medium']}
          fontWeight={[600, 600, 'bold']}
        >
          Reset all customizations
        </Text>
      </Box>
      <Spacer mb="16px" />
      <SecondaryButton
        color={'status.errorDark'}
        onClick={() => {
          setOpen({
            themeId
          })
          fireAnalyticsEvent('ResetPageTheme_ResetAllCustomizationsButton_Clicked')
        }}
        borderColor="status.errorDark"
        label="Reset all customizations"
      />
    </>
  )
}
