import * as React from 'react'
import { AutocompleteType } from '../../../types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Call } from '@mui/icons-material'
import { CarouselContext, CarouselProvider, Slide, Slider } from 'pure-react-carousel'
import { ContactCollection } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/ContactCollection'
import { ContactFormState, ContactInfoDisplayTypes, LocationProps, ToggleProps } from './types'
import { ContactIconComponent } from './ContactIcon'
import { ContactInfoDisplayToggle } from './ContactInfoDisplayToggle'
import {
  ContactInfoFields,
  contactInfoFormTransforms,
  getLabelForContactInput,
  parseTypes
} from './utils'
import { ContactInfoInput } from './ContactInfoInput'
import { ContactInfoLinkStyleForm } from './subcomponents/ContactInfoLinkStyleForm'
import { Divider } from '@dtx-company/shared-components/src/components/atoms/Divider/index'
import { DocAndPen } from '@dtx-company/shared-components/src/foundation/Icons/Product/Info'
import { FirstNameLastNameInput } from './subcomponents/FirstNameLastNameInput'
import { FormProps, StyledForm, StyledFormV2 } from '../components/Layout'
import { FormTitle } from '../components/Title'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkShapes } from '@dtx-company/shared-components/src/foundation/Icons/Product/LinkShapes'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { MenuItem } from '../../../components/profile/PageEditor/components/NewMenuContent/MenuItem'
import { Next, Tooltip, theme } from '@dtx-company/shared-components/src'
import {
  PrimaryButton,
  SecondaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { StyledDot } from './ContactForm.styles'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { trashCanRed } from '@app/code/src/assets'
import { useContactForm } from './hooks'
import { useContext, useEffect, useRef, useState } from 'react'
import { useIsMobile } from '@dtx-company/true-common/src/hooks/useIsMobile'
import useScript from '../../../utils/useScript'

export const AllowDownloadingVCardToggle: React.FC<ToggleProps> = ({
  setValueAndPreview,
  resetDisplayTypeValueWhenUnchecked,
  value,
  disabled
}: ToggleProps) => (
  <Box alignItems="center">
    <Tooltip
      paddingLeft={4}
      paddingRight={4}
      layout="up"
      id="share-contact-card"
      content="When enabled, this will allow visitors to your Flowpage to click on 'add to contacts' and the above information can be saved to their phone's contacts."
    />
    <Spacer mr="10px" />
    <Toggle
      toggleInputProps={{ style: { width: '45px' } }}
      disabled={disabled}
      label="Allow your visitors to download your virtual contact card."
      checked={value}
      onChange={e => {
        const { checked } = e.target
        setValueAndPreview(ContactInfoFields.CARD, checked)
        if (!checked) resetDisplayTypeValueWhenUnchecked?.()
      }}
    />
  </Box>
)

export const LocationForm: React.FC<LocationProps> = ({ setValue, value }: LocationProps) => {
  const autoCompleteRef = useRef<HTMLInputElement | null>(null)
  let autoComplete: AutocompleteType | undefined

  const [loaded, error] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_CLOUD_API_KEY}&libraries=places`
  )

  const handlePlaceSelect = async (): Promise<void> => {
    const addressObject = autoComplete?.getPlace()
    const query = addressObject?.formatted_address
    if (query) {
      const address = addressObject?.address_components?.reduce((acc, curr) => {
        const key = parseTypes(curr.types)
        return {
          ...acc,
          ...(key && { [key]: curr.long_name })
        }
      }, {} as Record<string, string> | null)
      setValue(ContactInfoFields.LOCATION, query)
      if (address && query) {
        address?.street1 && setValue(ContactInfoFields.STREET1, address.street1)
        address?.street2 && setValue(ContactInfoFields.STREET2, address.street2)
        address?.state && setValue(ContactInfoFields.STATE, address.state)
        address?.city && setValue(ContactInfoFields.CITY, address.city)
        address?.zip_code && setValue(ContactInfoFields.ZIPCODE, address.zip_code)
      }
    }
  }

  const handleScriptLoad = (): void => {
    if (window.google && autoCompleteRef.current) {
      const el = document.getElementById('location-gmaps-contact-form')
      autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current)
      autoComplete?.setFields(['address_components', 'formatted_address'])
      autoComplete?.addListener('place_changed', () => handlePlaceSelect())
      if (el) {
        window.google.maps.event.addDomListener(el, 'keydown', function (event) {
          if ((event as KeyboardEvent).key === 'Enter') {
            event.preventDefault()
          }
        })
      }
    }
  }
  useEffect(() => {
    if (loaded && !error) {
      handleScriptLoad()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, error])

  return (
    <Box
      boxShadow={'float'}
      margin="4px 0px"
      border={`1px solid ${theme.colors.secondary.border}`}
      alignItems="end"
      borderRadius="6px"
      justifyContent="center"
      padding="8px"
      bg="secondary.backgroundDark"
      {...stopPropagationEvents}
    >
      <Box margin="0 8px 10px">
        <ContactIconComponent type={'location'} isOutline={true} />
      </Box>
      <Input
        onChange={e => {
          if (e.target.value === '') {
            setValue(ContactInfoFields.LOCATION, '')
            setValue(ContactInfoFields.STREET1, '')
            setValue(ContactInfoFields.STREET2, '')
            setValue(ContactInfoFields.STATE, '')
            setValue(ContactInfoFields.CITY, '')
            setValue(ContactInfoFields.ZIPCODE, '')
          }
        }}
        type="text"
        value={value}
        error={error}
        inputProps={{
          id: 'location-gmaps-contact-form',
          ref: autoCompleteRef
        }}
        minWidth="100%"
        placeholder="123 Main St, New York, NY 10001"
        label={getLabelForContactInput('LOCATION')}
        labelProps={{ mb: '4px' }}
      />
    </Box>
  )
}
export const ContactForm: React.FC<FormProps> = (props: FormProps) => {
  const {
    mobile,
    disableCard,
    watchAll,
    contactInputs,
    handleCountryCodeChange,
    setValue,
    errors,
    handleSubmit,
    codes,
    initialEmpty,
    onSubmit,
    clearContactInfo,
    clearErrors
  } = useContactForm(props)
  const edit = !initialEmpty

  return (
    <StyledForm
      title={
        <FormTitle
          icon={<ContactCollection />}
          title={`${edit ? 'Edit' : 'Add'} your contact info`}
        />
      }
      onSubmit={handleSubmit(onSubmit)}
      subheader={
        <Text mt="4px" variant="body/medium">
          Add your contact information for instant connections with people you meet, your fans, and
          your customers
        </Text>
      }
      noPreview
    >
      {contactInputs.map((type, idx) => {
        const lowerType = type.toLowerCase() as ContactInfoFields
        return (
          <ContactInfoInput
            mobile={mobile}
            key={idx}
            handleCountryCodeChange={handleCountryCodeChange}
            type={type as ContactInfoFields}
            onChange={e => {
              clearErrors(lowerType)
              setValue(
                lowerType,
                contactInfoFormTransforms[lowerType]?.(e.target.value) ?? e.target.value
              )
            }}
            errors={errors}
            codes={codes}
            value={String(indexInterfaceWithVar(watchAll, lowerType) || '')}
          />
        )
      })}

      <LocationForm
        setValue={(name, value) => setValue(name as ContactInfoFields, value)}
        value={watchAll?.location || ''}
      />
      <Input
        type="text"
        multiline
        labelProps={{ marginBottom: '6px' }}
        maxWidth="100%"
        placeholder={'Title'}
        helperText={errors?.title?.message}
        value={watchAll.title || ''}
        error={Boolean(errors?.title?.message)}
        label={'Title'}
        border={`1px solid ${theme.colors.secondary.border}`}
        borderRadius="6px"
        padding="8px"
        bg="secondary.backgroundDark"
        onChange={e => {
          setValue(ContactInfoFields.TITLE, e.target.value)
        }}
      />
      <Input
        type="text"
        multiline
        labelProps={{ marginBottom: '6px' }}
        maxWidth="100%"
        placeholder={'Company'}
        helperText={errors?.company?.message}
        value={watchAll.company || ''}
        error={Boolean(errors?.company?.message)}
        label={'Company'}
        onChange={e => {
          setValue(ContactInfoFields.COMPANY, e.target.value)
        }}
      />
      <Box justifyContent="space-between" flexDirection={['column', 'column', 'row']}>
        <ContactInfoDisplayToggle setValue={setValue} displayType={watchAll.displayType} />
        <AllowDownloadingVCardToggle
          disabled={disableCard}
          setValueAndPreview={setValue}
          value={Boolean(indexInterfaceWithVar(watchAll, ContactInfoFields.CARD))}
        />
      </Box>
      <Box flexDirection={['column', 'column', 'row']} justifyContent="space-between" width="100%">
        <Spacer mb="8px" />
        <PrimaryButton
          width="100%"
          sizeVariant="mobileFullWidth"
          label="Save Contact Info"
          type="submit"
        />
        <Spacer mr={['0', '0', '10px']} mb={['10px', '10px', '0']} />
        <SecondaryButton
          sizeVariant={'mobileFullWidth'}
          width="100%"
          border="none"
          type="button"
          colorVariant="tertiaryOnDark"
          backgroundColor="status.errorDark"
          onClick={clearContactInfo}
          color="primary.white"
          label="Delete Contact Info"
        />
      </Box>
      <Spacer mb="8px" />
    </StyledForm>
  )
}

export const InfoFormContent: React.FC<ContactFormState> = ({
  contactInputs,
  mobile,
  handleCountryCodeChange,
  setValueAndPreview,
  errors,
  clearErrors,
  codes,
  watchAll,
  disableCard
}) => (
  <>
    <FirstNameLastNameInput
      values={{ firstName: watchAll.firstName, lastName: watchAll.lastName }}
      setValueAndPreview={setValueAndPreview}
    />
    {contactInputs.map((type, idx) => {
      const lowerType = type.toLowerCase() as ContactInfoFields
      const label = getLabelForContactInput(type)
      return (
        <ContactInfoInput
          mobile={mobile}
          key={idx}
          handleCountryCodeChange={handleCountryCodeChange}
          type={type as ContactInfoFields}
          onChange={e => {
            clearErrors?.(lowerType)
            setValueAndPreview(
              lowerType,
              contactInfoFormTransforms[lowerType]?.(e.target.value) ?? e.target.value
            )
          }}
          errors={errors}
          codes={codes}
          label={label}
          value={String(indexInterfaceWithVar(watchAll, lowerType) || '')}
        />
      )
    })}
    <LocationForm
      setValue={(name, value) => setValueAndPreview(name as ContactInfoFields, value)}
      value={watchAll?.location || ''}
    />
    <Box
      boxShadow="float"
      margin="4px 0px"
      border={`1px solid ${theme.colors.secondary.border}`}
      alignItems="center"
      borderRadius="6px"
      justifyContent="center"
      padding="8px"
      bg="secondary.backgroundDark"
      {...stopPropagationEvents}
    >
      <Input
        type="text"
        multiline
        labelProps={{ marginBottom: '6px' }}
        maxWidth="100%"
        placeholder={'Title'}
        helperText={errors?.title?.message}
        value={watchAll.title || ''}
        error={Boolean(errors?.title?.message)}
        label={'Title'}
        onChange={e => {
          setValueAndPreview(ContactInfoFields.TITLE, e.target.value)
        }}
      />
    </Box>
    <Box
      boxShadow="float"
      margin="4px 0px"
      border={`1px solid ${theme.colors.secondary.border}`}
      alignItems="center"
      borderRadius="6px"
      justifyContent="center"
      padding="8px"
      bg="secondary.backgroundDark"
      {...stopPropagationEvents}
    >
      <Input
        type="text"
        multiline
        labelProps={{ marginBottom: '6px' }}
        maxWidth="100%"
        placeholder={'Company'}
        helperText={errors?.company?.message}
        value={watchAll.company || ''}
        error={Boolean(errors?.company?.message)}
        label={'Company'}
        onChange={e => {
          setValueAndPreview(ContactInfoFields.COMPANY, e.target.value)
        }}
      />
    </Box>

    <Spacer mb="16px" />
    <AllowDownloadingVCardToggle
      disabled={disableCard}
      setValueAndPreview={setValueAndPreview}
      resetDisplayTypeValueWhenUnchecked={() => {
        // If user unchecked the "Allow visitors to download vCard" and the current displayType
        // was ContactInfoDisplayTypes.LINK_CARD, reset displayType to ContactInfoDisplayTypes.DEFAULT
        // because we only allow displaying the contactInfo link as a link-card when
        // "Allow visitors to download vCard" is checked (on)
        if (watchAll.displayType === ContactInfoDisplayTypes.LINK_CARD)
          setValueAndPreview(ContactInfoFields.DISPLAY_TYPE, ContactInfoDisplayTypes.DEFAULT)
      }}
      value={Boolean(indexInterfaceWithVar(watchAll, ContactInfoFields.CARD))}
    />
  </>
)

const MobileContactFormProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <CarouselProvider
      naturalSlideWidth={500}
      naturalSlideHeight={200}
      totalSlides={2}
      isIntrinsicHeight
    >
      {children}
    </CarouselProvider>
  )
}

const MobileContactForm: React.FC<ContactFormState> = formProps => {
  // Get the carousel context
  const carouselContext = useContext(CarouselContext)
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide)

  // Subscribe to changes to the current slide index
  useEffect(() => {
    const onChange = (): void => {
      setCurrentSlide(carouselContext.state.currentSlide)
    }
    carouselContext.subscribe(onChange)
    return () => carouselContext.unsubscribe(onChange)
  }, [carouselContext])

  return (
    <Box padding="16px" flexDirection="column">
      <Box width="100%" justifyContent="center" alignItems="center">
        <StyledDot slide={0}>
          <>
            <DocAndPen />
            <Spacer mb="5px" />
            <Text fontSize="12px" fontWeight={700}>
              Info
            </Text>
          </>
        </StyledDot>
        <Spacer mr="16px" />
        <StyledDot slide={1}>
          <>
            <LinkShapes />
            <Spacer mb="5px" />
            <Text fontSize="12px" fontWeight={700}>
              Style
            </Text>
          </>
        </StyledDot>
      </Box>
      <Box
        width="100%"
        maxWidth="calc(100vw - 32px)"
        maxHeight={currentSlide === 0 ? undefined : '260px'}
        position="relative"
      >
        <Slider style={{ width: '100%' }}>
          <Slide index={0}>
            <div key={`contact-menu-slide 0`} className="af-s1">
              <InfoFormContent {...formProps} />
            </div>
          </Slide>
          <Slide index={1}>
            <div key={`contact-menu-slide 1`}>
              <ContactInfoLinkStyleForm
                setValueAndPreview={formProps.setValueAndPreview}
                watchAll={formProps.watchAll}
              />
            </div>
          </Slide>
        </Slider>
      </Box>
    </Box>
  )
}

const DesktopContactForm: React.FC<ContactFormState & { isEdit: boolean }> = ({
  isEdit,
  ...formProps
}) => {
  const [isStyleForm, setIsStyleForm] = React.useState(false)
  const [isInfoForm, setIsInfoForm] = React.useState(true)
  return (
    <Box flexDirection="column" padding="16px">
      <Box width="100%">
        <Call sx={{ fontSize: '20px' }} />
        <Spacer mr="8px" />
        <Text color="primary.black" variant="button/general">
          {`${isEdit ? 'Edit' : 'Add'} Contact Information`}
        </Text>
      </Box>
      <Divider display={['none', 'none', 'block']} />
      <Box
        display={['flex', 'flex', 'none']}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <MenuItem
          selected={isStyleForm}
          onClick={() => {
            setIsStyleForm(true)
            setIsInfoForm(false)
          }}
          Icon={
            <LinkShapes
              fill={isStyleForm ? theme.colors.primary.flowBlue : theme.colors.primary.black}
            />
          }
          label="Style"
        />
        <Spacer mr="16px" />
        <MenuItem
          selected={isInfoForm}
          onClick={() => {
            setIsStyleForm(false)
            setIsInfoForm(true)
          }}
          Icon={
            <DocAndPen
              fill={isStyleForm ? theme.colors.primary.black : theme.colors.primary.flowBlue}
            />
          }
          label="Info"
        />
      </Box>
      <Box
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsInfoForm(prev => !prev)}
      >
        <Box alignItems="center">
          <DocAndPen fill={theme.colors.primary.flowBlue} />
          <Spacer mr="16px" />
          <Text variant="button/general" color="primary.flowBlue">
            Information
          </Text>
        </Box>
        <Box ml="8px" rotation={isInfoForm ? -90 : 90}>
          <Next color={theme.colors.primary.flowBlue} />
        </Box>
      </Box>
      {isInfoForm && <InfoFormContent {...formProps} />}
      <Divider display={['none', 'none', 'block']} />
      <Box
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsStyleForm(prev => !prev)}
      >
        <Box alignItems="center">
          <LinkShapes fill={theme.colors.primary.flowBlue} />
          <Spacer mr="16px" />
          <Text color="primary.flowBlue" variant="button/general">
            Style
          </Text>
        </Box>
        <Box ml="8px" rotation={isStyleForm ? -90 : 90}>
          <Next color={theme.colors.primary.flowBlue} />
        </Box>
      </Box>
      <Divider display={['none', 'none', 'block']} />
      {isStyleForm && (
        <ContactInfoLinkStyleForm
          watchAll={formProps.watchAll}
          setValueAndPreview={formProps.setValueAndPreview}
        />
      )}
    </Box>
  )
}

export const ContactInfoFormV2: React.FC<FormProps> = props => {
  const { curr: currLink, ...rest } = props
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const curr = React.useMemo(() => currLink, [])
  const formProps = useContactForm({ ...rest, curr })
  const mobile = useIsMobile()

  return (
    <StyledFormV2 onSubmit={formProps.handleSubmit(formProps.onSubmit)}>
      {mobile ? (
        <MobileContactFormProvider>
          <MobileContactForm {...formProps} />
        </MobileContactFormProvider>
      ) : (
        <DesktopContactForm isEdit={Boolean(curr)} {...formProps} />
      )}

      <Box flexDirection={'column'} padding="16px">
        <Spacer mb="16px" />
        <LinkStyleGated
          hiddenFields={{
            shadowColor: true,
            textSize: true,
            borderColor: true,
            fontColor: true
          }}
          linkTheme={formProps.linkTheme}
          setLinkTheme={formProps.setLinkTheme}
        />
      </Box>

      <Box {...stopPropagationEvents} justifyContent="space-between" padding="16px" width="100%">
        <SecondaryButton
          height="40px"
          width="48%"
          borderColor={'status.errorDark'}
          onClick={formProps.clearContactInfo}
          type="button"
        >
          <Image src={trashCanRed} />
          <Spacer mr="8px" />
          <Text variant="button/general" color="status.errorDark" data-testid="delete-link-btn">
            Delete Link
          </Text>
        </SecondaryButton>
        <PrimaryButton width="48%" height="40px" type="submit" label="Save Link" />
      </Box>
    </StyledFormV2>
  )
}
