import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DESCRIPTION, LINK, TITLE, imageDisplayOptions } from './constants'
import { DESCRIPTION_MAX, DESCRIPTION_MAX_ERROR } from '../validation'
import { DescriptionInput, LinkInput, TitleInput } from '../components/Inputs'
import { FC, useMemo } from 'react'
import { FormFields } from '../types'
import { FormProps, StyledFormV2 } from '../components/Layout'
import { FormTitle } from '../components/Title'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { ImageDisplayToggle } from './ImageDisplayToggle'
import { ImageEdit } from '../components/ImageEdit'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkStyleGated } from '../components/LinkStyle/LinkStyleGated'
import { MaskType } from '@dtx-company/shared-components/src'
import { MinimalDisplayToggle } from '../components/DisplayToggle'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { SettingsAndSaveLink } from '../components/Settings'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Spinner } from '@dtx-company/shared-components/src/components/atoms/Spinner/index'
import { logoUrlForType, useIsMobile } from '../../../utils/main'
import { useDestinationFormState } from './hooks'
import { useRestrictedFlowpageLinkTypes } from '@app/code/src/components/utils/useRestrictedFlowpageLinkTypes'
import { validateLink } from '../utils'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export const StandardLinkForm: FC<FormProps> = props => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const curr = useMemo(() => props.curr, [])
  const {
    watchAll,
    onSubmit,
    setPreviewAndFormValue,
    fetchOgInfoFromLink,
    isFetchingOgInfo,
    clearOGInfo,
    cancelFetchOgInfoFromLink,
    errors,
    trigger,
    meta,
    id,
    setMeta,
    linkTheme,
    setLinkTheme,
    register,
    validateTitle
  } = useDestinationFormState({ ...props, curr, order: curr ? curr.order : props.order })
  const isEdit = Boolean(curr)
  const isMobile = useIsMobile()

  // BLM or AAPI campaign links. Not to be confused with link.displayType = 'featured'
  const isFeaturedLink = curr?.provider === LinkProvider.FEATURE
  const restrictedLinks = useRestrictedFlowpageLinkTypes()

  return (
    <StyledFormV2 onSubmit={onSubmit}>
      <Box flexDirection="column" px={'16px'}>
        <Spacer mt="16px" />
        <FormTitle
          icon={<Image width="24px" height="24px" src={icons.linkBlack} />}
          title={`${isEdit ? 'Edit' : 'Add'} a standard link`}
        />
        <Spacer mb="16px" />
        <LinkInput
          {...register(LINK, {
            validate: value => {
              return validateLink(value, setMeta, false, restrictedLinks)
            }
          })}
          value={watchAll.link}
          errors={errors}
          onBlur={() => {
            trigger(LINK)
            events.Flowpage_PageProfile_StandardLinkForm_Typed_LinkUrl({
              linkId: id,
              typedUrl: watchAll.link
            })
          }}
          onChange={e => {
            setPreviewAndFormValue(LINK, e.target.value, { shouldValidate: true })
          }}
          label="Link URL"
          disabled={isFetchingOgInfo}
          endAdornment={
            isFetchingOgInfo ? (
              <SecondaryButton
                color="status.errorDark"
                height="30px"
                borderColor="status.errorDark"
                onClick={cancelFetchOgInfoFromLink}
              >
                <Spinner height="14px" width="14px" />
                Cancel
              </SecondaryButton>
            ) : (
              <Box mr="4px" justifyContent="space-between">
                {watchAll.title || watchAll.description || watchAll.image ? (
                  <SecondaryButton type="button" height="30px" onClick={() => clearOGInfo()}>
                    Clear Form
                  </SecondaryButton>
                ) : (
                  <SecondaryButton
                    disabled={!watchAll.link}
                    height="30px"
                    type="button"
                    onClick={() => fetchOgInfoFromLink(watchAll.link)}
                  >
                    Autofill
                  </SecondaryButton>
                )}
              </Box>
            )
          }
        />

        <Spacer mb="16px" />
        {isMobile ? (
          <MinimalDisplayToggle
            displayOptions={imageDisplayOptions}
            displayType={watchAll.displayType}
            onChange={(_e, type) => setPreviewAndFormValue(FormFields.DISPLAY_TYPE, type)}
          />
        ) : (
          <Box flexDirection="column" display={['none', 'none', 'flex']}>
            <ImageDisplayToggle
              displayType={watchAll.displayType}
              setValue={setPreviewAndFormValue}
              fetching={isFetchingOgInfo}
            />
          </Box>
        )}
        <Spacer mb="16px" />
        {watchAll.displayType !== 'noImage' && (
          <>
            <ImageEdit
              imageUrl={
                watchAll.image ||
                (meta.provider !== LinkProvider.LINK
                  ? logoUrlForType(meta.provider, meta?.type)
                  : undefined)
              }
              setValue={setPreviewAndFormValue}
              defaultImage={
                meta.provider !== LinkProvider.LINK ? logoUrlForType(meta.provider, meta?.type) : ''
              }
              id={id}
              active={props.curr ? props.curr.active : true}
              maskType={watchAll.displayType === 'default' ? MaskType.SQUARE : MaskType.FEATURED}
              performAutomaticDefaultCrop={!isEdit}
            />
          </>
        )}
        <Spacer mb="16px" />
        <TitleInput
          {...register(TITLE, {
            validate: value => validateTitle(value)
          })}
          value={watchAll.title}
          errors={errors}
          onChange={e => setPreviewAndFormValue(TITLE, e.target.value, { shouldValidate: true })}
          placeholder={isFetchingOgInfo ? 'Fetching data from URL…' : 'Enter a link title'}
          label="Link Title"
          disabled={isFetchingOgInfo}
          endAdornment={isFetchingOgInfo ? <Spinner height="14px" width="14px" /> : undefined}
          controlled
        />
        <Spacer mb="16px" />
        <DescriptionInput
          {...register(DESCRIPTION, {
            maxLength: {
              value: DESCRIPTION_MAX,
              message: DESCRIPTION_MAX_ERROR
            }
          })}
          value={watchAll.description}
          errors={errors}
          onChange={e =>
            setPreviewAndFormValue(DESCRIPTION, e.target.value, { shouldValidate: true })
          }
          placeholder={isFetchingOgInfo ? 'Fetching data from URL…' : 'Enter a link description'}
          label="Link Description (optional)"
          disabled={isFetchingOgInfo}
          endAdornment={isFetchingOgInfo ? <Spinner height="14px" width="14px" /> : undefined}
          charMax={DESCRIPTION_MAX}
        />
        {!isFeaturedLink && <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkTheme} />}
        <Spacer mb="16px" />
        <SettingsAndSaveLink curr={curr} handleClose={props.handleClose} />
        <Spacer mb="16px" />
      </Box>
    </StyledFormV2>
  )
}
