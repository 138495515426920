export enum LinkEditDrawerContent {
  DESIGN = 'design',
  ADD_LINK = 'addLink',
  ADD_SECTION = 'addSection',
  SETTINGS = 'settings',
  SOCIALS = 'socials',
  DESTINATION = 'destination',
  ADD_WIDGET = 'addWidget',
  WIDGET_FORM = 'widgetForm',
  CONTACT_INFO = 'contactInfo'
}

export enum AddLinkType {
  SOCIAL_LINK = 'socialLink',
  ADVANCED_LINK = 'advancedLink',
  STANDARD_LINK = 'standardLink',
  TEXT_BOX = 'textBox',
  CONTACT_INFO = 'contactInfo',
  IMAGE = 'bigImage',
  DIVIDER = 'divider',
  CONTACT_COLLECTION_FORM = 'contactCollection'
}
