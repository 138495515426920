import { ActionDataTypes } from '../../types'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { SocialLinkFields } from '../../Destination/SocialLinkFields.types'
import { SocialLinkFormFields } from '../../Destination/types'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import { addProtocol } from '../../../../components/profile/LinkForm/utils'
import { adjustedSocialLinks } from '../types'
import { validateLink, validateUsernameInput } from '../../utils'

//removes accidental linkPrefixes included in username socials
//remove extra fields from childlink conversion
export const cleanSocialActionData = (
  actionData: ActionDataTypes['socialLink']
): ActionDataTypes['socialLink'] => {
  let cleanedActionData = { ...actionData }
  Object.keys(cleanedActionData).forEach((k: string) => {
    const key = k as keyof typeof cleanedActionData
    if (!excludedActionDataKeys.includes(key)) {
      const sanitizedLink = sanitizeExtraPrefixFromSocialLinkUrl(
        key,
        (actionData?.[key] as SocialLinkFields)?.link ?? ''
      )
      const adKeyConfig = cleanedActionData[key] as SocialLinkFields
      cleanedActionData = {
        ...cleanedActionData,
        [key]: {
          thumbNailImgUrl: adKeyConfig.thumbNailImgUrl ?? '',
          title: adKeyConfig.title ?? '',
          description: adKeyConfig.description ?? '',
          order: adKeyConfig.order ?? 0,
          link: sanitizedLink
        }
      }
    }
  })
  // sanitize nulls/undefined
  return JSON.parse(
    JSON.stringify(cleanedActionData, (key, value) => {
      return value === null && key !== 'type' ? undefined : value
    })
  )
}

const sanitizeExtraPrefixFromSocialLinkUrl = (
  provider: keyof ActionDataTypes['socialLink'],
  rawLink: string
): string => {
  const linkPrefix = socialLinkPrefixes?.[provider] ?? null
  return linkPrefix && rawLink.includes(linkPrefix)
    ? rawLink.substring(linkPrefix.length, rawLink.length)
    : rawLink
}

export const socialLinkPrefixes: Record<string, string | undefined> = adjustedSocialLinks.reduce(
  (acc, curr) => {
    return {
      ...acc,
      ...(curr.isUsernameLink && { [curr.provider]: curr.prefix })
    }
  },
  {}
)

export const processSocialUrl = (urlOrUsername: string, socialLinkType: string): string => {
  if (socialLinkType in socialLinkPrefixes) {
    return `${socialLinkPrefixes[socialLinkType]}${urlOrUsername}`
  } else {
    return addProtocol(urlOrUsername)
  }
}

const excludedActionDataKeys = ['iconSize', 'iconStyle', 'displayType']

export const generateChildlinks = (
  watchAll: SocialLinkFormFields,
  parentlinkId: string
): Link[] => {
  const childLinks = Object.entries(watchAll)
    .map(([key, value]) => {
      if (excludedActionDataKeys.includes(key)) return undefined
      if (value.order === -1) return undefined
      const sanitizedLink = sanitizeExtraPrefixFromSocialLinkUrl(
        key as keyof ActionDataTypes['socialLink'],
        value.link
      )

      // do not include thumbNailImgUrl field, will be uploaded later
      return {
        provider: key,
        actionData: { link: sanitizedLink },
        active: value.active ?? true,
        order: value.order ?? 0,
        title: value.title ?? '',
        description: value.description ?? '',
        id: value.id ?? undefined,
        parentLinkId: parentlinkId,
        type: null
      }
    })
    .filter(item => item !== undefined)
  return childLinks as Link[]
}

export const validateSocialLinkInput = (
  value: string,
  provider: string,
  selectedLinks: string[]
): boolean | string => {
  const socialLinkType = adjustedSocialLinks.find(
    (item: SocialLinkType) => item.provider === provider
  )
  const validateThisURL =
    (value?.length === 0 || socialLinkType?.isUsernameLink === false) &&
    selectedLinks.find(item => item === provider)
  const validateThisUsername =
    socialLinkType?.isUsernameLink === true && selectedLinks.find(item => item === provider)
  if (validateThisUsername) return validateUsernameInput(value)
  const isValid = validateThisURL ? validateLink(value) : true
  return isValid
}
