import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'

export interface DesignMenuState {
  setTab: (_arg0: DesignTabs) => void
  curTab: DesignTabs
  pageTheme: PageType['theme']
}

export enum DesignTabs {
  COLOR,
  LINKS,
  BACKGROUND,
  TEXT,
  BRANDING
}
