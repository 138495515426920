import { PreviewModeManager } from './types'
import { setPreviewMode } from '@dtx-company/inter-app/src/redux/slices/editorUI'
import { useDispatch } from 'react-redux'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'

export function useEditPageNav(): PreviewModeManager {
  const dispatch = useDispatch()
  const previewMode = usePageSelector(state => state.editorUIReducer.previewMode)
  const togglePreviewMode = (): void => {
    dispatch(setPreviewMode(!previewMode))
  }
  return {
    previewMode,
    togglePreviewMode
  }
}
