import { ParsedUrlQueryInput, parse, stringify } from 'querystring'

export const mergeParams = (
  link: string,
  newUtmParams: ParsedUrlQueryInput,
  newPixelParams: ParsedUrlQueryInput
): string => {
  const [linkStem, strParams] = link.split('?')
  const mergedParams = { ...parse(strParams || ''), ...newUtmParams, ...newPixelParams }
  return linkStem + '?' + stringify(mergedParams)
}
