import { AddWidgetType } from '../../../../../components/widgets/types'
import { LinkEditDrawerContent } from './LinkEditDrawer.constants'
import { LinkEditDrawerState } from './LinkEditDrawer.types'
import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import {
  clearDrawerContent,
  clearPreviewLink,
  setDrawerContent,
  setIsNewPreviewLink
} from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { useDispatch } from 'react-redux'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'

export function useLinkEditDrawerState(): LinkEditDrawerState {
  const dispatch = useDispatch()

  const onDrawerOpen = (): void => {
    // pass
  }

  const onDrawerClose = (): void => {
    dispatch(clearDrawerContent({}))
    dispatch(clearPreviewLink({}))
  }
  const { drawerOpen, drawerContentState, previewLink } = usePageSelector(
    (state: RootPageState) => state.linkEditReducer
  )

  const onClickAddWidget = (link: AddWidgetType | SocialLinkType): void => {
    dispatch(clearPreviewLink({}))
    dispatch(
      setDrawerContent({
        drawerContentState: LinkEditDrawerContent.WIDGET_FORM,
        drawerContentMeta: { widgetObj: link }
      })
    )
    dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
  }

  return {
    drawerContentState: drawerContentState as LinkEditDrawerContent,
    drawerOpen,
    onDrawerClose,
    onClickAddWidget,
    onDrawerOpen,
    previewLink
  }
}
