import { DraggableProvided, DraggingStyle } from 'react-beautiful-dnd'
import { getPixelParams, getUtmParams } from './main'
import { mergeParams } from '../components/flowpage/FlowpageLinkCard/utils'
import { useEffect, useMemo, useRef } from 'react'
import { usePageDisplayContext } from '../context/page-display'
import { useRouter } from 'next/router'
import ReactDOM from 'react-dom'

export function useGetLinkUrlWithParams(url?: string): string | undefined {
  const { utmPassthrough: utmFlag } = usePageDisplayContext()
  const { pixelPassthrough: pixelFlag } = usePageDisplayContext()

  const router = useRouter()

  return useMemo(() => {
    if ((!utmFlag && !pixelFlag) || !url) return url
    return mergeParams(
      url,
      !utmFlag ? {} : getUtmParams(router.query),
      !pixelFlag ? {} : getPixelParams(router.query)
    )
  }, [url, utmFlag, pixelFlag, router.query])
}

//needed for using react-beautiful-dnd in a modal
export const useDraggableInPortal = (): ((
  render: (provided: DraggableProvided) => JSX.Element
) => (provided: DraggableProvided) => JSX.Element) => {
  const ref = useRef<{ el: Element | null }>({ el: null })

  useEffect(() => {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.pointerEvents = 'none'
    div.style.top = '0'
    div.style.width = '100%'
    div.style.height = '100%'
    if (ref?.current) {
      ref.current.el = div
    }

    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [ref])

  return render =>
    (provided: DraggableProvided): JSX.Element => {
      const element = render(provided)
      if (
        (provided?.draggableProps?.style as DraggingStyle)?.position === 'fixed' &&
        ref?.current?.el
      ) {
        return ReactDOM.createPortal(element, ref.current.el)
      }
      return element
    }
}
