import { SVGProps, memo } from 'react'

function FileUploadSvg({ color = '#B30B00', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="48" height="48" viewBox="0 0 73 87" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M70.7627 23.0064L49.1627 1.4064C48.2963 0.54 47.0939 0 45.7667 0C43.1171 0 40.9691 2.1504 40.9667 4.8C40.9667 6.1248 41.5043 7.3272 42.3731 8.196L33.1115 17.4576L54.7115 39.0576L63.9731 29.796C64.8419 30.6624 66.0419 31.2 67.3667 31.2C70.0163 31.2 72.1691 29.0496 72.1667 26.4C72.1667 25.0752 71.6291 23.8752 70.7627 23.0064Z"
        fill="#E1E8ED"
      />
      <path
        d="M45.7667 0H9.76667C4.46507 0 0.166666 4.2984 0.166666 9.6V76.8C0.166666 82.1016 4.46507 86.4 9.76667 86.4H62.5667C67.8683 86.4 72.1667 82.1016 72.1667 76.8V26.4H50.5667C48.1667 26.4 45.7667 24 45.7667 21.6V0Z"
        fill="#CCD6DD"
      />
      <path
        d="M45.7667 0H40.9667V21.6C40.9667 26.9016 45.2651 31.2 50.5667 31.2H72.1667V26.4H50.5667C48.1667 26.4 45.7667 24 45.7667 21.6V0ZM33.7667 19.2C33.7667 20.5248 32.6915 21.6 31.3667 21.6H12.1667C10.8419 21.6 9.76667 20.5248 9.76667 19.2C9.76667 17.8752 10.8419 16.8 12.1667 16.8H31.3667C32.6915 16.8 33.7667 17.8752 33.7667 19.2ZM33.7667 28.8C33.7667 30.1248 32.6915 31.2 31.3667 31.2H12.1667C10.8419 31.2 9.76667 30.1248 9.76667 28.8C9.76667 27.4752 10.8419 26.4 12.1667 26.4H31.3667C32.6915 26.4 33.7667 27.4752 33.7667 28.8ZM62.5667 38.4C62.5667 39.7248 61.4939 40.8 60.1667 40.8H12.1667C10.8419 40.8 9.76667 39.7248 9.76667 38.4C9.76667 37.0752 10.8419 36 12.1667 36H60.1667C61.4939 36 62.5667 37.0752 62.5667 38.4ZM62.5667 48C62.5667 49.3272 61.4939 50.4 60.1667 50.4H12.1667C10.8419 50.4 9.76667 49.3272 9.76667 48C9.76667 46.6728 10.8419 45.6 12.1667 45.6H60.1667C61.4939 45.6 62.5667 46.6728 62.5667 48ZM62.5667 57.6C62.5667 58.9272 61.4939 60 60.1667 60H12.1667C10.8419 60 9.76667 58.9272 9.76667 57.6C9.76667 56.2728 10.8419 55.2 12.1667 55.2H60.1667C61.4939 55.2 62.5667 56.2728 62.5667 57.6ZM62.5667 67.2C62.5667 68.5272 61.4939 69.6 60.1667 69.6H12.1667C10.8419 69.6 9.76667 68.5272 9.76667 67.2C9.76667 65.8728 10.8419 64.8 12.1667 64.8H60.1667C61.4939 64.8 62.5667 65.8728 62.5667 67.2Z"
        fill="#99AAB5"
      />
    </svg>
  )
}

export const FileUpload = memo(FileUploadSvg)

function FileOutlinedSvg({
  stroke = 'currentcolor',
  ...props
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 13 17" fill="none" {...props}>
      <path
        d="M11.175 3.706L9.03 1.308A1.441 1.441 0 007.958.81h-5.44C1.677.811 1 1.57 1 2.507v11.87c0 .936.679 1.695 1.517 1.695h7.586c.838 0 1.517-.758 1.517-1.695V4.904c0-.449-.16-.88-.444-1.198v0z"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.62 5.05H8.586c-.419 0-.759-.38-.759-.848V.811M3.276 10.137h4.551M3.276 12.68H6.56"
        stroke={stroke}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const FileOutlined = memo(FileOutlinedSvg)
