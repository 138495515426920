import {
  TemplateConfig,
  TemplateConfigFieldInput
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { TemplateFields } from '../types'

export function toggleTemplateLockedField({
  existingTemplateConfig,
  fieldToUpdate
}: {
  existingTemplateConfig?: TemplateConfig | null
  fieldToUpdate: TemplateFields
}): TemplateConfigFieldInput[] {
  let newFields
  const hasExistingFieldConfig = existingTemplateConfig?.fields?.some(
    ({ name }) => name === fieldToUpdate
  )
  if (hasExistingFieldConfig) {
    newFields = existingTemplateConfig?.fields?.map(config => {
      if (config.name === fieldToUpdate) return { ...config, locked: !config.locked }
      return config
    })
  } else {
    newFields = [
      ...(existingTemplateConfig?.fields || []),
      { name: fieldToUpdate, locked: true, required: false }
    ]
  }
  return newFields || []
}
