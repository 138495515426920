import { LinkInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { OnCreateLinkMutationCacheEntryAddedApi } from '../onCreateLinkMutationCacheEntryAdded'
import { pageQueries } from '../../pageQueries'

/**
 * Patches the page object in RTKQ cache to include the newly server-saved link.
 * It does so by modifing the page (draft) reference passed by searching the matching link
 * and overriding the matching link with the newlly added link
 */
export const patchPageQueryDataWithNewLink = (
  dispatch: OnCreateLinkMutationCacheEntryAddedApi['dispatch'],
  pageId: string,
  newLink: LinkInfoFragment,
  isTemplateEditor: boolean | undefined
): void => {
  dispatch(
    pageQueries.util.updateQueryData(
      'pageDetail',
      { id: pageId, isPageTemplate: isTemplateEditor },
      draft => {
        const updatedLinks = draft.Page?.links?.map(link => {
          if (link.id === newLink.id) return newLink
          return link
        })
        draft.Page.links = updatedLinks ?? []
      }
    )
  )
}
