import { getColor, hexToRgb } from '../../../../utils/theme'

/**
 * Creates the css linear gradient with the background color if provided, otherwise
 * it uses the primaryColor for the gradient
 * @param backgroundColor hex string color
 * @param primaryColor hex string color
 * @returns css linear-gradient string
 */
export const getLinearGradientBg = (backgroundColor?: string, primaryColor?: string): string => {
  const hex = backgroundColor ? backgroundColor : getColor(primaryColor)
  const { r, g, b } = hexToRgb(hex)
  return `
    linear-gradient(
      358.57deg,
      rgb(${r}, ${g}, ${b})
      6.45%, 
      rgb(255, 255, 255) 98.84%
    ) border-box
  `
}
