import { SelectOption } from '@dtx-company/shared-components/src'
// Sources for labels
// https://greenwichmeantime.com/time-zone/abbreviations/
// https://publib.boulder.ibm.com/tividd/td/TWS/SC32-1274-02/en_US/HTML/SRF_mst273.htm
const tzOptions = [
  { label: 'MIT-11:00', value: 'Etc/GMT+11' },
  { label: 'HST-10:00', value: 'Etc/GMT+10' },
  { label: 'AST-9:00', value: 'Etc/GMT+9' },
  { label: 'PST-8:00', value: 'America/Los_Angeles' },
  { label: 'MST-7:00', value: 'America/Denver' },
  { label: 'CST-6:00', value: 'America/Chicago' },
  { label: 'EST-5:00', value: 'America/New_York' },
  { label: 'IET-4:00', value: 'Etc/GMT+4' },
  { label: 'AGT-3:00', value: 'Etc/GMT+3' },
  { label: 'BET-3:00', value: 'Etc/GMT+3' },
  { label: 'GST-2:00', value: 'Etc/GMT+2' },
  { label: 'CAT-1:00', value: 'Etc/GMT+1' },
  { label: 'GMT-0:00', value: 'Europe/London' },
  { label: 'UTC-0:00', value: 'Europe/London' },
  { label: 'ANAT', value: 'Etc/GMT+12' },
  { label: 'CET+1:00', value: 'Europe/Berlin' },
  { label: 'EET+2:00', value: 'Etc/GMT-2' },
  { label: 'ART+2:00', value: 'Etc/GMT-2' },
  { label: 'EAT+3:00', value: 'Etc/GMT-3' },
  { label: 'MET+3:00', value: 'Etc/GMT-3' },
  { label: 'NET+4:00', value: 'Etc/GMT-4' },
  { label: 'PLT+5:00', value: 'Etc/GMT-5' },
  { label: 'IST+5:30', value: 'Asia/Colombo' },
  { label: 'GMT+05:45', value: 'Asia/Kathmandu' },
  { label: 'BST+6:00', value: 'Etc/GMT-6' },
  { label: 'CCT+6:30', value: 'Indian/Cocos' },
  { label: 'VST+7:00', value: 'Etc/GMT-7' },
  { label: 'CTT+8:00', value: 'Etc/GMT-8' },
  { label: 'JST+9:00', value: 'Etc/GMT-9' },
  { label: 'ACT+9:30', value: 'Pacific/Marquesas' },
  { label: 'AET+10:00', value: 'Etc/GMT-10' },
  { label: 'SST+11:00', value: 'Etc/GMT-11' },
  { label: 'NST+12:00', value: 'Etc/GMT-12' },
  { label: 'NZDT+13:00', value: 'Etc/GMT-13' }
]

export const SCANNER_TZ = 'Scanner Time'

export const tzOptionsWithoutOffset = tzOptions.map(({ label, value }) => {
  return { label: label.split('-')[0], value }
})

// The redirect server can not handle offsets which are not whole hours i.e. Etc/GMT-6:30
const isRedirectCompatible = (s: string): boolean => {
  const arr = s.split(/[-+]/)
  return !(arr.length > 1 && arr[1].length > 2)
}

export const RedirectCompatibleTimeZoneOptions: SelectOption[] = [
  { label: SCANNER_TZ, value: SCANNER_TZ }
].concat(tzOptions.filter(option => isRedirectCompatible(option.value)))
