import { LinkThemeState } from '../types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { useEffect, useState } from 'react'

/**
 * Manages the linkTheme state and loads a linkTheme if the link already has one
 */
export const useLinkThemeState = ({
  defaultLinkTheme,
  updatePreview
}: {
  defaultLinkTheme: LinkType['linkTheme']
  updatePreview?: () => void
}): LinkThemeState => {
  const [linkTheme, _setLinkTheme] = useState<Partial<LinkType['linkTheme']>>(null)

  const setLinkTheme = (partialLinkTheme: Partial<LinkType['linkTheme']>): void => {
    _setLinkTheme(currLinkTheme => {
      return {
        ...currLinkTheme,
        ...partialLinkTheme
      }
    })
    updatePreview?.()
  }

  useEffect(() => {
    _setLinkTheme(defaultLinkTheme)
  }, [defaultLinkTheme])

  return {
    linkTheme: linkTheme ? { ...linkTheme, active: Boolean(linkTheme.active) } : null,
    setLinkTheme
  }
}
