import { SVGProps, memo } from 'react'

function IconsOnlyStyleSvg({ stroke = '5B5E63', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="0.75"
        y="11.75"
        width="7.5"
        height="8.5"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <rect
        x="11.75"
        y="11.75"
        width="7.5"
        height="8.5"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <rect
        x="22.75"
        y="11.75"
        width="7.5"
        height="8.5"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
    </svg>
  )
}

export const IconsOnlyStyle = memo(IconsOnlyStyleSvg)
