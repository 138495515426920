import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ColorCard } from '../../../../../../components/profile/Customize/ColorCard'
import { EditTheme } from '../../../../../../components/profile/Customize/types'
import { FC } from 'react'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { ShadePicker } from '../../../../../../components/profile/Customize/ShadePicker'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { defaultUserTheme } from '@dtx-company/true-common/src/constants/page'
import { getLockedFieldFromTemplateConfig } from '../../../../../FlowpageTemplates/utils/getLockedFieldFromTemplateConfig'
import { useEditThemeWithAutosave } from '../../../../../../components/profile/Customize/hooks'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'

export const ColorMenu: FC<{ pageTheme: PageType['theme'] }> = ({ pageTheme }) => {
  const { __typename, ...curTheme } = pageTheme || defaultUserTheme
  const editTheme = useEditThemeWithAutosave()

  const handleSelect = (newTheme: EditTheme): void => {
    editTheme({
      themeInput: {
        ...curTheme,
        ...newTheme
      },
      themeId: curTheme.id
    })
  }
  const { isTemplateEditor } = usePageTemplateEditorState()

  const colorDisabled =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: pageTheme?.templateConfig,
      field: 'primaryColor'
    })
  const styleDisabled =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: pageTheme?.templateConfig,
      field: 'style'
    })
  return (
    <Box padding="16px 3px" flexDirection="column">
      <ColorCard
        disabled={colorDisabled}
        mobile
        curr={curTheme?.primaryColor || ''}
        handleSelect={handleSelect}
      />
      <Spacer mb="16px" />
      <ShadePicker
        curr={curTheme?.style ?? ''}
        color={curTheme?.primaryColor ?? ''}
        handleSelect={handleSelect}
        mobile
        disabled={styleDisabled}
      />
    </Box>
  )
}
