import { AddLinkType, LinkEditDrawerContent } from '../LinkEditDrawer/LinkEditDrawer.constants'
import {
  setDrawerContent,
  setIsNewPreviewLink
} from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { useDispatch } from 'react-redux'
import { widgetTypes } from '../../../../../components/widgets/manifest'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
interface AddSectionCallbacks {
  handleContactLinksOpen: () => void
  handleImageSectionLinkOpen: () => void
  handleTextBoxSectionLinkOpen: () => void
  handleContactCollectionFormOpen: () => void
}
export function useAddSectionCallbacks(): AddSectionCallbacks {
  const dispatch = useDispatch()
  const handleContactLinksOpen = (): void => {
    dispatch(setDrawerContent({ drawerContentState: LinkEditDrawerContent.CONTACT_INFO }))
    dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
    events.userClickedAddFlowpageSectionType({ pageSectionType: AddLinkType.CONTACT_INFO })
    events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.CONTACT_INFO })
  }

  const handleImageSectionLinkOpen = (): void => {
    const imageWidgetObj = widgetTypes.find(widget => widget.id === 'bigImage')
    if (imageWidgetObj) {
      dispatch(
        setDrawerContent({
          drawerContentState: LinkEditDrawerContent.WIDGET_FORM,
          drawerContentMeta: { widgetObj: imageWidgetObj }
        })
      )
      dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
      events.userClickedAddFlowpageSectionType({ pageSectionType: AddLinkType.IMAGE })
      events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.IMAGE })
    }
  }

  const handleTextBoxSectionLinkOpen = (): void => {
    const textBoxWidgetObj = widgetTypes.find(widget => widget.id === 'textBoxSection')
    if (textBoxWidgetObj) {
      dispatch(
        setDrawerContent({
          drawerContentState: LinkEditDrawerContent.WIDGET_FORM,
          drawerContentMeta: { widgetObj: textBoxWidgetObj }
        })
      )
      dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
      events.userClickedAddFlowpageSectionType({ pageSectionType: AddLinkType.TEXT_BOX })
      events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.TEXT_BOX })
    }
  }

  const handleContactCollectionFormOpen = (): void => {
    const contactCollection = widgetTypes.find(widget => widget.id === 'contactCollection')
    if (contactCollection) {
      dispatch(
        setDrawerContent({
          drawerContentState: LinkEditDrawerContent.WIDGET_FORM,
          drawerContentMeta: { widgetObj: contactCollection }
        })
      )
      dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
      events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.CONTACT_COLLECTION_FORM })
    }
  }

  return {
    handleContactLinksOpen,
    handleImageSectionLinkOpen,
    handleTextBoxSectionLinkOpen,
    handleContactCollectionFormOpen
  }
}
