import {
  ADD_MAILCHIMP,
  DELETE_MAILCHIMP,
  EDIT_MAILCHIMP,
  HAS_MAILCHIMP
} from '@dtx-company/flow-codegen/src/page/mutations'
import { pageGqlFetcher } from '../../../services/gqlFetcher'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'

export async function hasMailchimp(pageId: string): Promise<string | boolean> {
  try {
    if (pageId.length > 0) {
      const resp = await pageGqlFetcher<MailchimpResponse>(HAS_MAILCHIMP, {
        pageId: pageId
      })
      return resp?.hasMailchimp ?? false
    } else {
      //no page id
      return false
    }
  } catch (err) {
    console.error('Error getting Mailchimp,', err)
    return false
  }
}

export async function addMailchimp(apiKey: string, pageId: string): Promise<boolean | undefined> {
  try {
    const resp = await pageGqlFetcher<MailchimpResponse>(ADD_MAILCHIMP, {
      apiKey: apiKey,
      pageId: pageId
    })
    if (!resp?.addMailchimp) {
      console.error('Error adding mailchimp')
      sendErrorNotification(
        'Unable to add Mailchimp integration. Please verify your API key is correct and try again.'
      )
    } else {
      sendSuccessNotification('Mailchimp integration added succesfully')
    }
    return !!resp?.addMailchimp
  } catch (err) {
    console.error('Error adding mailchimp', err)
    sendErrorNotification(
      'Unable to add Mailchimp integration. Please verify your API key is correct and try again.'
    )
  }
}

export async function editMailchimp(apiKey: string, pageId: string): Promise<boolean | undefined> {
  try {
    const resp = await pageGqlFetcher<MailchimpResponse>(EDIT_MAILCHIMP, {
      apiKey: apiKey,
      pageId: pageId
    })
    if (!resp?.editMailchimp) {
      console.error('Error editing Mailchimp')
      sendErrorNotification(
        'Unable to update your Mailchimp integration. Please verify your API key is correct and try again.'
      )
    } else {
      sendSuccessNotification('Mailchimp updated succesfully')
    }
    return !!resp?.editMailchimp
  } catch (err) {
    console.error('Error editing Mailchimp', err)
    sendErrorNotification(
      'Unable to update your Mailchimp integration. Please verify your API key is correct and try again.'
    )
  }
}

export async function deleteMailchimp(pageId: string): Promise<boolean | undefined> {
  try {
    const resp = await pageGqlFetcher<MailchimpResponse>(DELETE_MAILCHIMP, {
      pageId: pageId
    })
    if (!resp?.deleteMailchimp) {
      console.error('Unable to remove Mailchimp integration. Please try again.')
      sendErrorNotification('Unable to remove your Mailchimp integration. Please try again.')
    } else {
      sendSuccessNotification('Mailchimp integration removed succesfully')
    }
    return !!resp?.deleteMailchimp
  } catch (err) {
    console.error('Unable to remove Mailchimp integration. Please try again.', err)
    sendErrorNotification('Unable to remove your Mailchimp integration. Please try again.')
  }
}

interface MailchimpResponse {
  addMailchimp?: boolean
  hasMailchimp?: string | boolean
  editMailchimp?: boolean
  deleteMailchimp?: boolean
}
