import { BoxProps, theme } from '@dtx-company/shared-components/src'
import { DEFAULT_LINK_TITLE_FONT_SIZE } from '@dtx-company/true-common/src/constants/page'
import { LinkStyle } from '../../../../constants/customizeTheme'
import { LinkType, PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { getContrastText, getRGBFromPrimaryColor } from '../../../../utils/theme'
import { getLinearGradientBg } from './getLinearGradientBg'
import { getLinkStyleValuesFromTheme } from './getLinkStyleValuesFromTheme'

const { colors, shadows } = theme

interface GetLinkLevelStylesArgs {
  style?: LinkStyle
  pageTheme?: PageType['theme'] | null
  linkTheme?: LinkType['linkTheme'] | null
  primaryColor?: string
  isBlackTextOnBlackBg?: boolean
}

export const getLinkLevelStyles = ({
  style,
  pageTheme,
  linkTheme,
  primaryColor,
  isBlackTextOnBlackBg
}: GetLinkLevelStylesArgs): Omit<BoxProps, 'children' | 'onClick'> => {
  const { borderColor, backgroundColor, fontColor, shadowColorRGB, titleFontSize } =
    getLinkStyleValuesFromTheme(pageTheme, linkTheme)
  const fontSize = titleFontSize ? `${titleFontSize}pt` : DEFAULT_LINK_TITLE_FONT_SIZE

  const primaryColorRGB = getRGBFromPrimaryColor(primaryColor)
  switch (style) {
    // LinkStyle.OUTLINED_BW and LinkStyle.OUTLINED are very similar, and given the customization
    // enabled by link-level styling we should look into unifying them.
    case LinkStyle.OUTLINED_BW:
      return {
        background: 'transparent',
        border: '2px solid',
        borderColor: borderColor || 'flowpage.contrastText',
        boxShadow: 'none',
        color: fontColor || 'flowpage.contrastText',
        fontSize
      }
    case LinkStyle.OUTLINED:
      const c = isBlackTextOnBlackBg ? 'flowpage.contrastText' : 'flowpage.primaryColor'
      return {
        backgroundColor: 'transparent',
        border: `2px solid`,
        borderColor: borderColor || c,
        boxShadow: 'none',
        color: fontColor || c,
        fontSize
      }
    case LinkStyle.GRADIENT:
      return {
        background: getLinearGradientBg(backgroundColor, primaryColor),
        color: fontColor || colors.primary.black,
        border: '2px solid',
        borderColor: borderColor || 'transparent',
        fontSize
      }
    case LinkStyle.INSET:
      return {
        background: backgroundColor || colors.primary.white,
        color: fontColor || colors.primary.black,
        boxShadow: `rgb(${shadowColorRGB.r} ${shadowColorRGB.g} ${shadowColorRGB.b} / 35%) 7px 7px 0px 0px`,
        border: '2px solid',
        borderColor: 'transparent',
        fontSize
      }
    case LinkStyle.COLORED:
      return {
        backgroundColor: backgroundColor || 'flowpage.primaryColor',
        color: fontColor || getContrastText(primaryColorRGB),
        boxShadow: shadows.float,
        border: '2px solid',
        borderColor: borderColor || 'transparent',
        fontSize
      }
    case LinkStyle.DEFAULT:
    default:
      return {
        background: backgroundColor || colors.primary.white,
        color: fontColor || colors.primary.black,
        boxShadow: shadows.float,
        border: '2px solid',
        borderColor: borderColor || 'transparent',
        fontSize
      }
  }
}
