import { AddWidgetType } from '../../widgets'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { FC, SVGProps } from 'react'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import styled from 'styled-components'

const StyledWidgetTitle = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
`
interface AddWidgetTypes {
  link: SocialLinkType | AddWidgetType

  linkInfo: {
    title?: string
    description?: string
    icon?: ({ ref, ...props }: SVGProps<SVGSVGElement>) => JSX.Element
  }
  onClick: (link: SocialLinkType | AddWidgetType) => void
  collapsed: boolean
}
export type AddNewLinkType = SocialLinkType | AddWidgetType

export const AddWidget: FC<AddWidgetTypes> = ({ link, linkInfo, onClick, collapsed }) => {
  const Icon = linkInfo.icon
  if (collapsed) {
    return (
      <Box
        width="100%"
        height="40px"
        backgroundColor="primary.white"
        borderRadius="8px"
        boxShadow="float"
        m="3px"
        overflow="hidden"
      >
        <Button
          colorVariant="primaryOnDark"
          onClick={() => onClick(link)}
          minWidth="154px"
          height="min-content"
          justifyContent="left"
          alignItems="ceneter"
          p="0 10px"
        >
          {Icon && <Icon height="100%" width="48px" />}
          <Box flexDirection="column" ml="6px" flex="1 1" justifyContent="start" alignItems="start">
            <StyledWidgetTitle
              variant={'body/small'}
              letterSpacing="normal"
              overflow="hidden"
              maxWidth="110px"
            >
              {linkInfo?.title}
            </StyledWidgetTitle>
          </Box>
        </Button>
      </Box>
    )
  } else {
    return (
      <Box
        width="100%"
        height="auto"
        backgroundColor="primary.white"
        boxShadow="float"
        borderRadius="8px"
        m="6px"
      >
        <Button
          colorVariant="primaryOnDark"
          onClick={() => onClick(link)}
          width="100%"
          height="min-content"
          justifyContent="space-between"
          p="13px 6px 13px 16px"
          alignItems="flex-start"
          borderRadius="8px"
        >
          {Icon && <Icon height="100%" width="48px" />}
          <Box
            flexDirection="column"
            ml="14px"
            mt="-2px"
            flex="1 1"
            justifyContent="start"
            alignItems="start"
            height="100%"
          >
            <Text
              textAlign="left"
              variant={'body/small'}
              fontWeight={collapsed ? 'inherit' : 'bold'}
            >
              {linkInfo?.title}
            </Text>
            {!collapsed && (
              <Text variant="body/small" letterSpacing="normal" textAlign="left" lineHeight="1.4">
                {linkInfo?.description}
              </Text>
            )}
          </Box>
        </Button>
      </Box>
    )
  }
}
