import { Box } from '../Box'
import { ChipProps, ChipSelector } from '../../../types/atoms/chip.types'
import { FC } from 'react'
import { theme } from '../../../../src/theme'
import styled from 'styled-components'

const Selector = styled(Box)`
  content: '';
  position: absolute;
  height: calc(100% + 20px);
  top: -10px;
  left: -10px;
  border: 6px solid ${theme.colors.primary.flowBlue};
  border-radius: inherit;
  width: calc(100% + 20px);
`

export const Chip: FC<ChipProps> = ({
  children,
  selected,
  selectorType,
  selectedBorderWidth = '3px',
  cursor = 'pointer',
  ...rest
}) => {
  return (
    <Box
      cursor={cursor}
      {...(selectorType === ChipSelector.BORDER && {
        borderColor: selected ? theme.colors.primary.flowBlue : theme.colors.secondary.border,
        borderWidth: selected ? selectedBorderWidth : '1px',
        borderStyle: 'solid'
      })}
      position="relative"
      {...rest}
    >
      {children}
      {selected && selectorType === ChipSelector.FLOAT && <Selector cursor={cursor} />}
    </Box>
  )
}
