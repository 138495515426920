import {
  BorderProps,
  ColorProps,
  FlexboxProps,
  LayoutProps,
  MarginProps,
  SpaceProps,
  border,
  color,
  flexbox,
  layout,
  margin,
  space
} from 'styled-system'
import styled, { css } from 'styled-components'

export type SelectContainerProps = LayoutProps &
  FlexboxProps &
  BorderProps &
  SpaceProps &
  ColorProps &
  MarginProps &
  LayoutProps &
  React.HTMLProps<HTMLDivElement>

export const SelectContainer: React.ComponentType<SelectContainerProps> = styled.div<SelectContainerProps>`
  display: flex;
  position: relative;
  width: 200px;
  * {
    box-sizing: border-box;
  }
  ${layout}
  ${color}
  ${margin}
  ${border}
  ${space}
  ${flexbox}
`

export type SelectButtonProps = { disabled?: boolean; 'data-testid'?: string } & ColorProps &
  BorderProps &
  React.HTMLProps<HTMLDivElement>

export const SelectButton: React.ComponentType<SelectButtonProps> = styled.div<SelectButtonProps>`
  display: flex;
  outline: none;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0 17px;
  color: ${({ theme }) => theme.colors.primary.black};
  border: 1px solid ${({ theme }) => theme.colors.secondary.border};
  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.grey};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary.black};
  }
  ${color}
  ${flexbox}
  ${border}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.2;
      background-color: #dadcdd;
      color: ${({ theme }) => theme.colors.primary.grey};
      :focus,
      :hover {
        border: 1px transparent solid;
        cursor: initial;
      }
    `}
`

export const Option = styled.li<{ active?: boolean }>`
  height: 44px !important;
  padding: 0 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  :focus,
  :hover {
    background-color: ${({ theme }) => theme.colors.secondary.backgroundDark};
  }
  ${({ active }) =>
    active &&
    css`
      background-color: ${({ theme }) => theme.colors.secondary.backgroundDark};
    `}
`

export const OptionsContainer = styled.ul`
  border: 1px solid ${({ theme }) => theme.colors.primary.grey};
  border-top: none;
  box-shadow: ${({ theme }) => theme.shadows.float};
  position: absolute;
  background-color: white;
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  width: 100%;
  top: 100%;
  min-height: 50px;
  max-height: 300px;
  overflow: scroll;
  padding: 0;
  margin: 0;
`
