import { Button } from '../Button'
import { border, color, layout, space } from 'styled-system'
import styled, { css } from 'styled-components'

export const ToggleButtonBase = styled(Button)<{ selected?: boolean } & { value?: string }>`
  height: auto;
  width: auto;
  max-width: max-content;
  padding: 12px 20px;
  ${({ selected }) =>
    selected &&
    css`
      border-radius: 4px;
    `}
  ${color}
   ${space}
   ${layout}
   ${border}
`
