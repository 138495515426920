import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DrawerComponent } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer'
import { FC } from 'react'
import { ManagerCancelButton } from '../../../components/widgets/components/Manager/ManagerCancelButton'
import { useLinkEditDrawerState } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.hooks'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'

export const LinkEditSection: FC<{ handleClose?: () => void }> = ({ handleClose }) => {
  const { onDrawerClose, drawerContentState, previewLink } = useLinkEditDrawerState()

  const highlightStyles = drawerContentState
    ? {
        backgroundColor: 'primary.white',
        borderRadius: '12px',
        border: '4px solid rgba(3, 61, 221, 0.5)'
      }
    : {}

  const onCloseDrawer = (): void => {
    onDrawerClose()
    handleClose?.()
  }

  const cancelEdits = (): void => {
    onCloseDrawer()
    events.Flowpage_PageProfile_Clicked_CancelLinkEdits()
  }

  return (
    <Box position="relative" width="100%" cursor="default" {...highlightStyles}>
      <DrawerComponent
        type={drawerContentState}
        handleClose={onCloseDrawer}
        currentEditLink={previewLink}
      />
      <LinkEditorScrollAnchor id="fp-link-edit-scroll-anchor" />
      {drawerContentState && <ManagerCancelButton onClick={cancelEdits} />}
    </Box>
  )
}

const LinkEditorScrollAnchor = styled.div`
  width: 1px;
  height: 1px;
  color: red;
  visiblity: hidden;
  position: absolute;
  z-index: 100;
  top: -80px;
`
