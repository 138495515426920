import { MaskType, theme } from '@dtx-company/shared-components/src'
const { colors, shadows } = theme
/**
 * @deprecated
 */
export const COLORS_DEPRECATED: Record<string, string> = {
  pink1: '#ff6767',
  orange1: '#ff7a00',
  orange2: '#ffb673',
  yellowGreen: '#dbff00',
  lime: '#00ff19',
  seafoam: '#7eff8b',
  turquiose: '#00ffd1',
  blueGrey: '#01a5c8',
  darkGrey: '#282828',
  pink2: '#FD94A6',
  pink3: '#FF6A85',
  fuscia: '#FF00C7',
  darkPink: '#BD00FF',
  lavendar: '#AB68FF',
  purple: '#7000FF',
  royalBlue: '#001AFF',
  white: '#FFF'
}

const {
  redLight,
  black,
  roseLight,
  pinkLight,
  lilacLight,
  purpleLight,
  navyLight,
  blueLight,
  fuchsiaLight,
  skyLight,
  turquoiseLight,
  greenLight,
  limeLight,
  lemonLight,
  orangeLight,
  rustLight,
  brownLight,
  grapeLight,
  tanLight,
  starfishLight,
  greyLight,
  coralLight,
  watermelonLight,
  slateLight,
  magentaLight,
  jadeLight,
  yellowLight,
  pumpkinLight,
  mintLight,
  kiwiLight,
  peachLight,
  raspberryLight,
  violetLight,
  indigoLight,
  flowblueLight,
  goldLight
} = colors.builder

export const COLORS: Record<string, string> = {
  black,
  purpleLight,
  lilacLight,
  grapeLight,
  navyLight,
  blueLight,
  fuchsiaLight,
  skyLight,
  turquoiseLight,
  greenLight,
  limeLight,
  lemonLight,
  tanLight,
  orangeLight,
  rustLight,
  starfishLight,
  brownLight,
  coralLight,
  roseLight,
  watermelonLight,
  slateLight,
  redLight,
  magentaLight,
  jadeLight,
  yellowLight,
  pumpkinLight,
  mintLight,
  kiwiLight,
  peachLight,
  raspberryLight,
  violetLight,
  indigoLight,
  flowblueLight,
  goldLight,
  greyLight,
  pinkLight
}

export interface FlowpageThemeColor {
  color: Record<string, string>
  index: number
}

export const ALL_USER_COLORS: FlowpageThemeColor[] = [
  { color: { black }, index: 0 },
  { color: { greyLight }, index: 2 },
  { color: { redLight }, index: 3 },
  { color: { magentaLight }, index: 7 },
  { color: { pinkLight }, index: 9 },
  { color: { purpleLight }, index: 12 },
  { color: { navyLight }, index: 14 },
  { color: { blueLight }, index: 16 },
  { color: { turquoiseLight }, index: 19 },
  { color: { jadeLight }, index: 20 },
  // { color: { lemonLight }, index: 24 },
  // { color: { yellowLight }, index: 26 },
  { color: { pumpkinLight }, index: 30 },
  { color: { brownLight }, index: 31 }
]

export const FREE_LOGGED_IN_USER_COLORS: FlowpageThemeColor[] = [
  { color: { skyLight }, index: 17 },
  // { color: { mintLight }, index: 18 },
  // { color: { kiwiLight }, index: 23 },
  { color: { peachLight }, index: 29 }
]

export const PRO_ENTERPRISE_USER_COLORS: FlowpageThemeColor[] = [
  { color: { slateLight }, index: 1 },
  { color: { raspberryLight }, index: 4 },
  { color: { coralLight }, index: 5 },
  { color: { roseLight }, index: 6 },
  { color: { fuchsiaLight }, index: 8 },
  { color: { lilacLight }, index: 10 },
  { color: { violetLight }, index: 11 },
  { color: { indigoLight }, index: 13 },
  { color: { flowblueLight }, index: 15 },
  { color: { greenLight }, index: 21 },
  { color: { limeLight }, index: 22 },
  // { color: { tanLight }, index: 25 },
  // { color: { goldLight }, index: 27 },
  { color: { orangeLight }, index: 28 }
]

export enum LinkStyle {
  DEFAULT = 'default',
  GRADIENT = 'gradient',
  INSET = 'inset',
  OUTLINED = 'outlined',
  COLORED = 'colored',
  OUTLINED_BW = 'outlined_bw'
}

export const LINK_STYLE_LABELS: Record<LinkStyle, string> = {
  [LinkStyle.DEFAULT]: 'Default',
  [LinkStyle.GRADIENT]: 'Gradient',
  [LinkStyle.INSET]: 'Drop Shadow',
  [LinkStyle.OUTLINED]: 'Outline',
  [LinkStyle.COLORED]: 'Filled',
  [LinkStyle.OUTLINED_BW]: 'B/W Outline'
}

export enum LinkShape {
  DEFAULT = 'default',
  ROUND = 'round',
  SQUARE = 'square'
}
export const LINK_SHAPE_LABELS: Record<LinkShape, string> = {
  [LinkShape.DEFAULT]: 'Rounded Rectangle',
  [LinkShape.SQUARE]: 'Rectangle',
  [LinkShape.ROUND]: 'Oval'
}

export const HEX_COLOR_TO_NAME = Object.fromEntries(
  Object.entries({ ...COLORS, ...COLORS_DEPRECATED }).map(([key, val]) => {
    return [val, key]
  })
)

export enum ThemeCalcs {
  TINT = 'tint',
  SHADE = 'shade'
}

export interface StyleKind {
  type: ThemeCalcs
  factor: number
}

export enum Theme {
  minimal = 'minimal',
  light = 'light',
  color = 'color',
  dark = 'dark',
  none = 'none'
}

export const STYLE: { [key: string]: StyleKind } = {
  [Theme.none]: { type: ThemeCalcs.TINT, factor: 1 },
  [Theme.minimal]: { type: ThemeCalcs.TINT, factor: 0.95 },
  [Theme.light]: { type: ThemeCalcs.TINT, factor: 0.75 },
  [Theme.color]: { type: ThemeCalcs.SHADE, factor: 0 },
  [Theme.dark]: { type: ThemeCalcs.SHADE, factor: 0.6 }
}

export const PROFILE_IMAGE_MASK_RADIUS: Partial<Record<MaskType, string>> = {
  [MaskType.CIRCLE]: '50%',
  [MaskType.SQUARE]: '8px'
}
