import { SVGProps, memo } from 'react'

function DefaultLinkStyleSvg({ stroke = '5B5E63', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="0.75"
        y="8.75"
        width="30.5"
        height="14.5"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <rect
        x="3.95"
        y="11.95"
        width="8.1"
        height="8.1"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M15.2 13.65C14.7858 13.65 14.45 13.9858 14.45 14.4C14.45 14.8142 14.7858 15.15 15.2 15.15V13.65ZM28 15.15C28.4142 15.15 28.75 14.8142 28.75 14.4C28.75 13.9858 28.4142 13.65 28 13.65V15.15ZM15.2 15.15H28V13.65H15.2V15.15Z"
        fill={stroke}
      />
      <path
        d="M15.2 16.8501C14.7858 16.8501 14.45 17.1859 14.45 17.6001C14.45 18.0143 14.7858 18.3501 15.2 18.3501V16.8501ZM23.2 18.3501C23.6143 18.3501 23.95 18.0143 23.95 17.6001C23.95 17.1859 23.6143 16.8501 23.2 16.8501V18.3501ZM15.2 18.3501H23.2V16.8501H15.2V18.3501Z"
        fill={stroke}
      />
    </svg>
  )
}

export const DefaultLinkStyle = memo(DefaultLinkStyleSvg)
