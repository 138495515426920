import { BoxComponentProps } from './box.types'
import { BoxProps } from '.'
import { CSSProperties, FC } from 'react'
import { TextProps, TextStyleProps } from './text.types'

export enum ChipSelector {
  FLOAT = 'float',
  BORDER = 'border'
}
export interface ChipProps extends BoxComponentProps {
  selected: boolean
  selectorType: ChipSelector
  selectedBorderWidth?: BoxProps['borderWidth']
  alt?: string
}
export interface ColorChipProps extends Omit<ChipProps, 'selectorType'> {
  chipColor: CSSProperties['backgroundColor']
}

export interface IconChipProps extends Omit<ChipProps, 'selectorType'> {
  src: string
}

export interface DestinationChipProps extends Omit<ChipProps, 'selectorType'> {
  IconComponent: FC<{ color: string }>
  label: string
  styles?: {
    label?: Omit<TextProps, 'children'> & TextStyleProps
  }
}
