import { SVGProps, memo } from 'react'

function TopFriendsSvg({ stroke = '#111', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" {...rest}>
      <path
        d="M22.0933 9.60877C23.041 10.5565 23.041 12.0931 22.0933 13.0409C21.1455 13.9887 19.6089 13.9887 18.6611 13.0409C17.7134 12.0931 17.7134 10.5565 18.6611 9.60877C19.6089 8.66101 21.1455 8.66101 22.0933 9.60877Z"
        fill="#7625F4"
      />
      <path
        d="M15.3873 5.27688C16.8467 6.73623 16.8467 9.10231 15.3873 10.5617C13.928 12.021 11.5619 12.021 10.1026 10.5617C8.64322 9.10232 8.64322 6.73624 10.1026 5.27688C11.5619 3.81754 13.928 3.81754 15.3873 5.27688Z"
        fill="#4A7AFC"
      />
      <path
        d="M6.82899 9.60877C7.77674 10.5565 7.77674 12.0931 6.82899 13.0409C5.88123 13.9887 4.34461 13.9887 3.39685 13.0409C2.4491 12.0931 2.4491 10.5565 3.39685 9.60877C4.34461 8.66101 5.88123 8.66101 6.82899 9.60877Z"
        fill="#F4134F"
      />
      <path
        d="M19.1499 19.7778V17.8572C19.1499 15.5383 17.2701 13.6584 14.9511 13.6584H10.5376C8.21871 13.6584 6.33887 15.5383 6.33887 17.8572V19.7778"
        fill="#4A7AFC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.7415 18.463V19.7778L24.7291 19.7642L24.7486 19.7906H20.7872V15.4707L20.7881 15.4711L20.7815 15.4639H21.7424C23.3991 15.4639 24.7415 16.8063 24.7415 18.463Z"
        fill="#7625F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.755539 18.4503V19.7651L0.767998 19.7515L0.7485 19.7779H4.70985V15.458L4.70892 15.4584L4.71558 15.4512H3.75466C2.09794 15.4512 0.755539 16.7936 0.755539 18.4503Z"
        fill="#F4134F"
      />
    </svg>
  )
}

export const TopFriends = memo(TopFriendsSvg)
