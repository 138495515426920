import { LinkCardManager } from './types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { MaskType } from '@app/common/src/components/ImageEditor/ImageEditor.types'
import { useIsMobile } from '../../../utils/main'
import { useMemo } from 'react'

export function useLinkCardManager(link: LinkType, preview: boolean): LinkCardManager {
  const isMobile = useIsMobile()
  const isCRMWithImageRectangle =
    link.type === 'contactCollection' &&
    link.actionData.widgetDisplayType === MaskType.RECTANGLE &&
    Boolean(link.thumbNailImgUrl)
  const isFeatured = link.displayType === 'featured' || isCRMWithImageRectangle

  const cardHeight = useMemo(() => {
    return isFeatured ? 210 : 86
  }, [isFeatured])

  const isDefaultSocialImage = useMemo(() => {
    const logos = JSON.parse(process.env.RESERVED_LOGOS ?? '{}')
    return !!logos?.[link.provider + '-color'] && !link.thumbNailImgUrl
  }, [link.provider, link.thumbNailImgUrl])

  const iconProps = useMemo(() => {
    if (isFeatured) {
      return {
        minHeight: isMobile || preview ? 'min(100%, 121px)' : '140px',
        width: '100%',
        maxHeight: isDefaultSocialImage ? 130 : undefined,
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px',
        overflow: 'hidden',
        marginRight: '-1px',
        marginLeft: '-1px'
      }
    }

    if (preview) {
      return {
        flexShrink: 0, // prevent items from shinking because we want to respect height & width
        hight: '50px',
        width: '50px',
        borderRadius: link.actionData.widgetDisplayType === MaskType.CIRCLE ? '50%' : '4px',
        overflow: 'hidden',
        marginRight: '12px'
      }
    }

    return {
      flexShrink: 0, // prevent items from shinking because we want to respect height & width
      height: '70px',
      width: '70px',
      borderRadius: link.actionData.widgetDisplayType === MaskType.CIRCLE ? '50%' : '4px',
      overflow: 'hidden',
      marginRight: '18px'
    }
  }, [isFeatured, preview, link.actionData.widgetDisplayType, isMobile, isDefaultSocialImage])

  return {
    cardHeight,
    iconProps,
    isFeatured
  }
}
