import {
  CREATE_LINK,
  CREATE_OR_EDIT_LINK_THEME,
  DELETE_LINK,
  EDIT_LINK,
  MOVE_LINK_MUTATION
} from '@dtx-company/flow-codegen/src/page/mutations'
import {
  CreateLinkMutation,
  CreateOrEditLinkThemeMutation,
  DeleteLinkMutation,
  EditLinkMutation,
  MoveLinkMutation,
  MoveLinkMutationVariables,
  MutationCreateLinkArgs,
  MutationCreateOrEditLinkThemeArgs,
  MutationDeleteLinkArgs,
  MutationEditLinkArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { RootState } from '../../../redux/types'
import { deleteLinkThumbnail } from '../../../utils/rest'
import { flowpageApi } from './empty'
import { flowpageAutosaveMessaging } from '@dtx-company/true-common/src/constants/messages'
import { getCurrentJwtFromStore } from '../../../utils/jwt'
import { logger } from '@dtx-company/logger'
import { onCreateLinkMutationCacheEntryAdded } from './linkMutationsHelpers/onCreateLinkMutationCacheEntryAdded'
import { onCreateLinkMutationQueryStarted } from './linkMutationsHelpers/onCreateLinkMutationQueryStarted'
import { onEditLinkMutationCacheEntryAdded } from './linkMutationsHelpers/onEditLinkMutationCacheEntryAdded'
import { pageQueries } from './pageQueries'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'

export const linkMutations = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    createLink: builder.mutation<
      CreateLinkMutation,
      MutationCreateLinkArgs & { addImage?: string; isTemplateEditor?: boolean }
    >({
      query: ({ pageId, linkInput }) => ({
        document: CREATE_LINK,
        variables: { pageId, linkInput },
        authOnly: true
      }),
      onQueryStarted: onCreateLinkMutationQueryStarted,
      onCacheEntryAdded: onCreateLinkMutationCacheEntryAdded
    }),
    editLink: builder.mutation<
      EditLinkMutation,
      MutationEditLinkArgs & {
        editImage?: string
        deleteImage: boolean
        currentImage?: string
        isTemplateEditor?: boolean
        childLinkImageIdsToDelete?: string[]
      }
    >({
      query: ({ linkId, linkInput }) => ({
        document: EDIT_LINK,
        variables: { linkId, linkInput },
        authOnly: true
      }),
      onCacheEntryAdded: onEditLinkMutationCacheEntryAdded,
      async onQueryStarted(
        { linkId, linkInput, editImage, deleteImage, isTemplateEditor },
        { dispatch, queryFulfilled, getState }
      ) {
        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            {
              id: isTemplateEditor
                ? (getState() as RootState).pageReducer.pageEditorReducer.templateId
                : (getState() as RootState).pageReducer.pageEditorReducer.pageId,
              isPageTemplate: Boolean(isTemplateEditor)
            },
            draft => {
              const updatedLinks = draft.Page?.links?.map(link => {
                const childLinks = (linkInput?.childLinks ?? []).map(child => {
                  return {
                    ...child,
                    thumbNailImgUrl: linkInput.actionData[child.provider]?.thumbNailImgUrl ?? null
                  }
                })
                return link?.id === linkId
                  ? {
                      ...link,
                      ...linkInput,
                      thumbNailImgUrl: deleteImage ? null : editImage || link?.thumbNailImgUrl,
                      childLinks
                    }
                  : link
              })
              Object.assign(draft, {
                Page: { ...draft.Page, links: updatedLinks || [] }
              })
            }
          )
        )

        try {
          const resp = await queryFulfilled
          // after the query completes, if a new link was created, ensure that the new link id
          // replaces the old id on the link in state
          dispatch(
            pageQueries.util.updateQueryData(
              'pageDetail',
              {
                id: isTemplateEditor
                  ? (getState() as RootState).pageReducer.pageEditorReducer.templateId
                  : (getState() as RootState).pageReducer.pageEditorReducer.pageId,
                isPageTemplate: Boolean(isTemplateEditor)
              },
              draft => {
                const updatedLinks = draft.Page?.links?.map(link => {
                  return link?.id === linkId
                    ? {
                        ...link,
                        id: resp.data.editLink.id
                      }
                    : link
                })
                Object.assign(draft, {
                  Page: { ...draft.Page, links: updatedLinks || [] }
                })
              }
            )
          )
          sendSuccessNotification(flowpageAutosaveMessaging.editLink.success)
        } catch (err) {
          patchResult.undo()
          sendErrorNotification(flowpageAutosaveMessaging.editLink.error)
          logger.logError(err, { team: 'templates', technicalArea: 'flowpage' })
        }
      }
    }),
    deleteLink: builder.mutation<
      DeleteLinkMutation,
      MutationDeleteLinkArgs & { deleteImage?: boolean; isTemplateEditor?: boolean }
    >({
      query: ({ linkId, pageId }) => ({
        document: DELETE_LINK,
        variables: { linkId, pageId },
        authOnly: true
      }),
      invalidatesTags: ['PageDetail', 'formList'],
      async onCacheEntryAdded({ linkId, deleteImage = false }, { getState }) {
        const jwt = getCurrentJwtFromStore(getState() as RootState)
        if (deleteImage) {
          deleteLinkThumbnail(linkId, jwt?.token)
        }
      },
      async onQueryStarted({ linkId, isTemplateEditor }, { dispatch, queryFulfilled, getState }) {
        const pageId = (getState() as RootState).pageReducer.pageEditorReducer.pageId
        const templateId = (getState() as RootState).pageReducer.pageEditorReducer.templateId
        const id = isTemplateEditor ? templateId : pageId
        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            { id, isPageTemplate: isTemplateEditor },
            draft => {
              const updatedLinks = draft.Page?.links?.filter(link => link?.id !== linkId)
              if (draft?.Page?.links) {
                draft.Page.links = updatedLinks
              }
            }
          )
        )
        try {
          await queryFulfilled
          sendSuccessNotification(flowpageAutosaveMessaging.deleteLink.success)
        } catch (err) {
          patchResult.undo()
          logger.logError(err, { team: 'templates', technicalArea: 'flowpage' })
          if (err.error.message?.includes('User does not have access for Template with id')) {
            sendErrorNotification(flowpageAutosaveMessaging.deleteLink.error.templatePermission)
          } else {
            sendErrorNotification(flowpageAutosaveMessaging.deleteLink.error.general)
          }
        }
      }
    }),
    createOrEditLinkTheme: builder.mutation<
      CreateOrEditLinkThemeMutation,
      MutationCreateOrEditLinkThemeArgs & { isTemplateEditor?: boolean }
    >({
      query: ({ linkIds, linkThemeInput }) => ({
        document: CREATE_OR_EDIT_LINK_THEME,
        variables: { linkIds, linkThemeInput },
        authOnly: true
      }),
      async onQueryStarted(
        { linkThemeInput, linkIds, isTemplateEditor },
        { dispatch, queryFulfilled, getState }
      ) {
        const [linkId] = linkIds
        const pageId = (getState() as RootState).pageReducer.pageEditorReducer.pageId
        const templateId = (getState() as RootState).pageReducer.pageEditorReducer.templateId
        const patch = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            { id: isTemplateEditor ? templateId : pageId, isPageTemplate: isTemplateEditor },
            draft => {
              const updatedLinks = draft?.Page?.links?.map(link =>
                link?.id === linkId ? { ...link, linkTheme: linkThemeInput } : link
              )

              Object.assign(draft, {
                Page: {
                  ...draft.Page,
                  links: updatedLinks || []
                }
              })
            }
          )
        )

        try {
          await queryFulfilled
          sendSuccessNotification(flowpageAutosaveMessaging.createOrEditLinkTheme.success)
        } catch (err) {
          sendErrorNotification(flowpageAutosaveMessaging.createOrEditLinkTheme.error)
          console.error(err)
          patch.undo()
        }
      }
    }),
    moveLink: builder.mutation<
      MoveLinkMutation,
      MoveLinkMutationVariables & { isTemplateEditor?: boolean }
    >({
      query: variables => ({
        document: MOVE_LINK_MUTATION,
        variables,
        authOnly: true
      }),
      async onQueryStarted(
        { input, isTemplateEditor = false },
        { dispatch, queryFulfilled, getState }
      ) {
        const { pageId, templateId } = (getState() as RootState).pageReducer.pageEditorReducer
        const id = isTemplateEditor ? templateId : pageId
        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            { id, isPageTemplate: isTemplateEditor },
            draft => {
              const updatedLinks = draft.Page?.links?.map(link => {
                if (link.id === input.id) {
                  return {
                    ...link,
                    position: input.position
                  }
                }
                return link
              })
              draft.Page.links = updatedLinks ?? []
            }
          )
        )
        try {
          const response = await queryFulfilled
          if (!response?.data?.moveLink?.position) {
            throw new Error(JSON.stringify(response))
          }
          sendSuccessNotification(flowpageAutosaveMessaging.reorderLinks.success)
        } catch (e) {
          logger.logError(e, {
            name: 'Error moving link',
            team: 'templates'
          })
          patchResult.undo()
          sendErrorNotification(flowpageAutosaveMessaging.reorderLinks.error)
        }
      }
    })
  }),
  overrideExisting: true
})

export const {
  useEditLinkMutation,
  useCreateLinkMutation,
  useDeleteLinkMutation,
  useMoveLinkMutation,
  useCreateOrEditLinkThemeMutation
} = linkMutations
