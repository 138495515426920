import { SVGProps, memo } from 'react'

function StarSvg({ color = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...rest}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 0.916016L13.4306 6.89578L19.9792 7.85949L15.2396 12.5122L16.3613 19.0844L10.5 15.98L4.63872 19.0844L5.76042 12.5122L1.02085 7.85949L7.56936 6.89578L10.5 0.916016Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Star = memo(StarSvg)
