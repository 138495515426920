import { ActionDataTypes } from '../types'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { SplitCountryCode } from './types'
import { sentenceCase } from '@dtx-company/true-common/src/utils/strings'
import { splitCountryCode } from '../../../utils/main'

const defaultDigits: SplitCountryCode = {
  codes: {
    text: '1',
    call: '1'
  },
  digits: {
    text: '',
    call: ''
  }
}
export function splitCountryCodes(curr: Partial<LinkType> | undefined | null): SplitCountryCode {
  if (!curr) {
    return defaultDigits
  }
  const actionData = curr?.actionData as ActionDataTypes['contactInfo'] | undefined
  const [callCC, call] = splitCountryCode(actionData?.call)
  const [textCC, text] = splitCountryCode(actionData?.text)
  return {
    codes: {
      call: callCC,
      text: textCC
    },
    digits: {
      call,
      text
    }
  }
}
export enum ContactInfoFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  CALL = 'call',
  TEXT = 'text',
  EMAIL = 'email',
  WEBSITE = 'website',
  CARD = 'card',
  STREET1 = 'street1',
  STREET2 = 'street2',
  LOCATION = 'location',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zip_code',
  TITLE = 'title',
  COMPANY = 'company',
  DISPLAY_TYPE = 'displayType',
  ICON_FILL = 'iconFill',
  ICON_SIZE = 'iconSize'
}

export const EXCLUDED_CONTACT_INFO_FIELD_KEYS = [
  'FIRST_NAME',
  'LAST_NAME',
  'LOCATION',
  'CARD',
  'COMPANY',
  'TITLE',
  'DISPLAY_TYPE',
  'ICON_SIZE',
  'ICON_FILL',
  'ADDRESS',
  'STREET1',
  'STREET2',
  'CITY',
  'ZIPCODE',
  'STATE'
]

export const contactInfoFormTransforms: Record<string, (_: string) => string> = {
  [ContactInfoFields.EMAIL]: email => email.toLowerCase().trim(),
  [ContactInfoFields.CALL]: call => call.split('-').join(''),
  [ContactInfoFields.TEXT]: text => text.split('-').join(''),
  [ContactInfoFields.WEBSITE]: website => {
    if (!website || website.length === 0) return website
    return website.startsWith('http') ? website : `https://${website}`
  }
}

export function parseLocationObj(
  location:
    | string
    | {
        street1?: string
        street2?: string
        city?: string
        state?: string
        zipCode?: string
      }
    | undefined
): string | undefined {
  if (!location || typeof location === 'string') {
    return location
  } else {
    return `${location.street1}, ${location.street2 ? location.street2 + ', ' : ''}${
      location.city
    }, ${location.state} ${location.zipCode}`
  }
}

const ADDRESS_FIELDS = [
  'route',
  'street_number',
  'administrative_area_level_1',
  'country',
  'postal_code',
  'administrative_area_level_3',
  'locality',
  'neighborhood',
  'sublocality'
]

export const getAddrFieldName = (type: string | undefined): string | undefined => {
  switch (type) {
    case 'route':
      return 'street2'
    case 'street_number':
      return 'street1'
    case 'administrative_area_level_1':
      return 'state'
    case 'country':
      return 'country'
    case 'administrative_area_level_3':
    case 'locality':
    case 'neighborhood':
    case 'sublocality':
      return 'city'
    case 'postal_code':
      return 'zip_code'
    default:
      return undefined
  }
}

export const parseTypes = (types: string[]): string | undefined => {
  const type = types.find(type => ADDRESS_FIELDS.includes(type))
  return getAddrFieldName(type)
}

export const getLabelForContactInput = (type: string): string | undefined => {
  switch (type) {
    case 'CALL':
      return 'Work'
    case 'TEXT':
      return 'Mobile'
    case 'LOCATION':
      return 'Address'
    default:
      return sentenceCase(type)
  }
}
