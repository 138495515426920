import { ContactInfoFields } from './utils'
import { FormProps } from '../components/Layout'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { UseFormMethods } from 'react-hook-form-deprecated'

export interface ContactInfoProps {
  [ContactInfoFields.FIRST_NAME]?: string
  [ContactInfoFields.LAST_NAME]?: string
  [ContactInfoFields.CALL]?: string
  [ContactInfoFields.EMAIL]?: string
  [ContactInfoFields.TEXT]?: string
  [ContactInfoFields.COMPANY]?: string
  [ContactInfoFields.WEBSITE]?: string
  [ContactInfoFields.STREET1]?: string
  [ContactInfoFields.STREET2]?: string
  [ContactInfoFields.CITY]?: string
  [ContactInfoFields.STATE]?: string
  [ContactInfoFields.ZIPCODE]?: string
  [ContactInfoFields.TITLE]?: string
  [ContactInfoFields.CARD]?: boolean
  [ContactInfoFields.LOCATION]?: string
  [ContactInfoFields.DISPLAY_TYPE]?: string
  [ContactInfoFields.ICON_FILL]?: string
  [ContactInfoFields.ICON_SIZE]?: number
}

export interface DigitFields {
  text: string
  call: string
}
export interface SplitCountryCode {
  codes: DigitFields
  digits: DigitFields
}

export interface InputProps {
  type: ContactInfoFields
  codes?: DigitFields
  handleCountryCodeChange: (type: keyof DigitFields, code: string) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
  errors: UseFormMethods['errors']
  inputId?: string
}

export interface LocationProps {
  setValue: (_1: string, _2: string) => void
  value: string
}
export interface ToggleProps {
  setValueAndPreview: UseFormMethods['setValue']
  resetDisplayTypeValueWhenUnchecked?: () => void
  value: boolean
  disabled: boolean
}
export interface ContactFormState {
  mobile: boolean
  linkTheme: LinkType['linkTheme']
  setLinkTheme: (linkThemeInput: Partial<LinkType['linkTheme']>) => void
  disableCard: boolean
  watchAll: {
    firstName: string
    lastName: string
    call: string
    email: string
    text: string
    company?: string
    website?: string
    title?: string
    location?: string
    street1?: string
    street2?: string
    city?: string
    state?: string
    zip_code?: string
    card: boolean
    displayType: string
    iconSize: number
    iconFill: string
  }
  clearContactInfo: () => void
  contactInputs: string[]
  handleCountryCodeChange: (type: 'call' | 'text', code: string) => void
  setValue: UseFormMethods['setValue']
  clearErrors: UseFormMethods['clearErrors']
  setValueAndPreview: UseFormMethods['setValue']
  handleSubmit: UseFormMethods['handleSubmit']
  errors: UseFormMethods['errors']
  reset: UseFormMethods['reset']
  codes: SplitCountryCode['codes']
  initialEmpty: boolean
  onSubmit: ({
    call,
    email,
    text,
    location,
    card,
    company,
    title,
    displayType
  }: ContactInfoProps) => Promise<void>
}

export interface UseContactFormProps
  extends Pick<FormProps, 'widgetObj' | 'order' | 'curr' | 'handleClose'> {
  defaultValues?: { email?: string; displayType?: string }
}

export enum ContactInfoDisplayTypes {
  DEFAULT = 'default',
  WIDGET = 'widget',
  LINK_CARD = 'link-card' // Introduced in https://app.shortcut.com/flowcode/story/57631/additional-vcard-style-option
}
