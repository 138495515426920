import { AddLinkType, LinkEditDrawerContent } from '../LinkEditDrawer/LinkEditDrawer.constants'
import {
  clearPreviewLink,
  setDrawerContent,
  setIsNewPreviewLink
} from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { useDispatch } from 'react-redux'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface AddAllLinksCallbacks {
  handleAdvancedLinkOpen: () => void
  handleSocialLinkOpen: () => void
  handleStandardLinkOpen: () => void
}
export function useAddAllLinksCallbacks(): AddAllLinksCallbacks {
  const dispatch = useDispatch()

  const handleSocialLinkOpen = (): void => {
    dispatch(clearPreviewLink({}))
    dispatch(setDrawerContent({ drawerContentState: LinkEditDrawerContent.SOCIALS }))
    dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
    events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.SOCIAL_LINK })
  }
  const handleAdvancedLinkOpen = (): void => {
    dispatch(clearPreviewLink({}))
    dispatch(setDrawerContent({ drawerContentState: LinkEditDrawerContent.ADD_WIDGET }))
    events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.ADVANCED_LINK })
  }
  const handleStandardLinkOpen = (): void => {
    dispatch(clearPreviewLink({}))
    dispatch(setIsNewPreviewLink({ isNewPreviewLink: true }))
    dispatch(setDrawerContent({ drawerContentState: LinkEditDrawerContent.DESTINATION }))
    events.userClickedAddFlowpageLinkType({ pageLinkType: AddLinkType.STANDARD_LINK })
  }

  return {
    handleAdvancedLinkOpen,
    handleSocialLinkOpen,
    handleStandardLinkOpen
  }
}
