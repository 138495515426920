import { Spinner as CircularProgress } from '@dtx-company/shared-components/src/components/atoms/Spinner'
import styled from 'styled-components'

export const addProtocol = (link: string): string =>
  link.toLowerCase().startsWith('http') ? link : `https://${link}`

export const PdfUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  label {
    display: flex;
    align-items: flex-start;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    border: none;
  }
  width: 100%;
`

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  right: 50%;
  z-index: 1500;
`
