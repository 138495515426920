import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/Spacer'

import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { FC, ReactNode } from 'react'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { stopPropagationEvents } from '../LinkEditDrawer/LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src'

export const MenuItem: FC<{
  label: string
  Icon: ReactNode
  onClick: () => void
  id?: string
  selected?: boolean
}> = ({ Icon, label, onClick, selected = false, id }) => {
  const color = selected ? theme.colors.primary.flowBlue : theme.colors.primary.black
  return (
    <Button
      colorVariant="invisible"
      id={id}
      type="button"
      alignItems="center"
      justifyContent="flex-end"
      onClick={onClick}
      width="fit-content"
      height="100%"
      flexDirection="column"
      {...stopPropagationEvents}
    >
      {Icon}
      <Spacer mb="8px" />
      <Text color={color} fontSize="12px" fontWeight={700}>
        {label}
      </Text>
    </Button>
  )
}
