import {
  TemplateConfig,
  TemplateConfigField
} from '@dtx-company/flow-codegen/src/page/generated.types'

export function parseTemplateFields(
  templateConfig?: TemplateConfig | null
): Record<string, TemplateConfigField> | undefined {
  return templateConfig?.fields?.reduce(
    (allConfigs, config) => ({
      ...allConfigs,
      [config.name]: config
    }),
    {}
  )
}
