import {
  CreateLinkMutation,
  MutationCreateLinkArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { GraphqlRequestBaseQuery } from '../utils'
import { MutationCacheLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { RootState } from '../../../../redux/types'
import { getCurrentJwtFromStore } from '../../../../utils/jwt'
import { getLink } from './utils.ts/getLink'
import { getValidatedPDFActionData } from '../../../../utils/flowpage'
import { isFileString } from '@dtx-company/true-common/src/utils/blob'
import { patchPageQueryDataWithNewLink } from './utils.ts/patchPageQueryDataWithNewLink'
import {
  uploadChildlinkThumbnails,
  uploadLinkThumbnail,
  uploadMediaFile,
  uploadPdf
} from '../../../../utils/rest'

export type OnCreateLinkMutationCacheEntryAddedArgs = MutationCreateLinkArgs & {
  addImage?: string | undefined
  isTemplateEditor?: boolean | undefined
}

export type OnCreateLinkMutationCacheEntryAddedApi = MutationCacheLifecycleApi<
  OnCreateLinkMutationCacheEntryAddedArgs,
  GraphqlRequestBaseQuery,
  CreateLinkMutation,
  'flowpageApi'
>

/**
 * Handle side effects of a succesful createLink mutation when the success response data
 * is added to the RTK cache.
 *
 * Uploads files for the link like the thumbnail image, a pdf file for the pdf link or the CRM link
 * or thumbnail images for child links of the socialLink. Lastly it updates the page object data
 * to append the newlly added link
 *
 * Todo: Optimize this function so the file uploads happen in parallel instead of serially as is
 * done currently
 * */
export async function onCreateLinkMutationCacheEntryAdded(
  { linkInput, addImage, pageId, isTemplateEditor }: OnCreateLinkMutationCacheEntryAddedArgs,
  { cacheDataLoaded, getState, dispatch }: OnCreateLinkMutationCacheEntryAddedApi
): Promise<void> {
  const jwt = getCurrentJwtFromStore(getState() as RootState)
  const token = jwt?.token
  const createLinkResponse = (await cacheDataLoaded).data.createLink
  if (addImage && linkInput.id) {
    await uploadLinkThumbnail(linkInput.id, addImage, token)
  }
  if (linkInput.type === 'pdf' && linkInput.id) {
    const url = getValidatedPDFActionData(linkInput.actionData).link
    if (isFileString(url)) {
      await uploadMediaFile(linkInput.id, url, token)
    }
  }
  if (linkInput.type === 'contactCollection' && linkInput.id) {
    const url = linkInput.actionData.link
    if (isFileString(url)) {
      await uploadPdf(linkInput.id, url, token)
    }
  }
  if (linkInput.type === 'socialLink' && linkInput.id) {
    await uploadChildlinkThumbnails(token, createLinkResponse)
  }

  // Fetch the newly inserted link from the database
  const newLink = await getLink(linkInput.id || '')

  patchPageQueryDataWithNewLink(dispatch, pageId, newLink, isTemplateEditor)
}
