import { SVGProps, memo } from 'react'

function FontStyleSvg({ fill = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.55318 18.9658H15.1961"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3829 10.1006L6.72217 20.905"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.0276 20.905L11.3831 10.1006"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6481 30.6668H8.35185C4.75199 30.6668 1.83333 27.7482 1.83333 24.1483V7.85201C1.83333 4.25216 4.75199 1.3335 8.35185 1.3335H24.6481C28.248 1.3335 31.1667 4.25216 31.1667 7.85201V24.1483C31.1667 27.7482 28.248 30.6668 24.6481 30.6668Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8705 14.3701V20.8886"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.9104 15.3139C26.1902 16.5937 26.1902 18.6687 24.9104 19.9485C23.6306 21.2283 21.5556 21.2283 20.2757 19.9485C18.9959 18.6687 18.9959 16.5937 20.2757 15.3139C21.5556 14.034 23.6306 14.034 24.9104 15.3139Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const FontStyle = memo(FontStyleSvg)
