import * as React from 'react'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { ToggleButton } from '@dtx-company/shared-components/src/components/atoms/ToggleButton/index'
import { ToggleButtonList, theme } from '@dtx-company/shared-components/src'
import { stopPropagationEvents } from '../../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import styled from 'styled-components'

interface DisplayToggleV2Props {
  onChange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) => void
  selectedValue: string
  options: { title: string; value: string; image?: JSX.Element }[]
  name?: string
  title?: string
}

export const DisplayToggleV2: React.FC<DisplayToggleV2Props> = ({
  onChange,
  selectedValue,
  options
}) => {
  const selectedIndex = options.findIndex(option => option.value === selectedValue)
  return (
    <ToggleButtonList
      selectedValue={selectedValue}
      onChange={onChange}
      justifyContent="left"
      borderRadius="5px"
      overflow="hidden"
      p="0"
      border={`1px solid ${theme.colors.secondary.border}`}
      background="transparent !important"
      width="calc(100% - 96px)"
      {...stopPropagationEvents}
    >
      {options.map((option, idx) => {
        const selected = option.value === selectedValue
        return (
          <StyledToggleButton
            value={option.value}
            selected={selected}
            key={`${idx}-selectable-option`}
            borderLeft={getLeftBorderStyles(idx, selectedIndex)}
            width="100%"
          >
            {option.image && (
              <>
                {option.image}
                <Spacer mb="4px" />
              </>
            )}
            <Text
              fontSize="14px"
              color={selected ? 'primary.flowBlue' : 'primary.grey'}
              fontWeight={selected ? 600 : 500}
            >
              {option.title}
            </Text>
          </StyledToggleButton>
        )
      })}
    </ToggleButtonList>
  )
}

const getLeftBorderStyles = (idx: number, selectedIndex: number): string | undefined => {
  if (idx === 0 || idx === selectedIndex) return
  if (idx > selectedIndex + 1 || idx < selectedIndex)
    return `1px solid ${theme.colors.secondary.border}!important`
}

const StyledToggleButton = styled(ToggleButton)<{ selected: boolean }>`
  flex-direction: column;
  max-width: none;
  padding: 12px 0;
  justify-content: space-around;
  border-radius: ${({ selected }) => (selected ? '5px' : '0')};
  background-color: ${({ selected }) => (selected ? theme.colors.secondary.highlight : 'white')};
  border: ${({ selected }) =>
    selected ? `2px solid ${theme.colors.primary.flowBlue}` : '1px solid rgba(0,0,0,0)'};
  overflow: hidden;
  :hover {
    background-color: ${theme.colors.secondary.highlight};
  }
`
