import { PageDisplayState, context } from './context'
import { useContext } from 'react'

export const PageDisplayConsumer = context.Consumer

export const usePageDisplayContext = (): PageDisplayState => {
  const result = useContext(context)
  if (!result) {
    throw new Error(
      'usePageDisplayConext must be called within the provider of a PageDisplayProvider'
    )
  }
  return result
}
