import * as Yup from 'yup'
import { DESCRIPTION_MAX, DESCRIPTION_MAX_ERROR, TITLE_MAX, TITLE_MAX_ERROR } from '../validation'
import { FormFields } from '../types'

export const { TITLE, DESCRIPTION, LINK, IMAGE, DISPLAY_TYPE } = FormFields
export const EMBED = 'embed'
export const schema = Yup.object().shape({
  title: Yup.string().required().max(TITLE_MAX, TITLE_MAX_ERROR),
  link: Yup.string().required(),
  description: Yup.string().max(DESCRIPTION_MAX, DESCRIPTION_MAX_ERROR)
})

export const imageDisplayOptions = [
  {
    title: 'No Image',
    value: 'noImage'
  },
  {
    title: 'Icon',
    value: 'default'
  },
  {
    title: 'Featured',
    value: 'featured'
  }
]
