import { CREATE_SLOT, DELETE_SLOT, UPDATE_SLOT } from '@dtx-company/flow-codegen/src/page/mutations'
import {
  CreateSlotMutation,
  CreateSlotMutationVariables,
  DeleteSlotMutation,
  DeleteSlotMutationVariables,
  UpdateSlotMutation,
  UpdateSlotMutationVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'

import { flowpageApi } from './empty'

export const slotsMutationsQueries = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    createSlot: builder.mutation<CreateSlotMutation, CreateSlotMutationVariables>({
      query: ({ slotMetaType, pageId, value, templateConfigInput }) => ({
        document: CREATE_SLOT,
        variables: { slotMetaType, pageId, value, templateConfigInput },
        authOnly: true
      }),
      invalidatesTags: ['PageDetail']
    }),
    updateSlot: builder.mutation<UpdateSlotMutation, UpdateSlotMutationVariables>({
      query: ({ slotId, input, templateConfigInput, pageId }) => ({
        document: UPDATE_SLOT,
        variables: { slotId, input, templateConfigInput, pageId },
        authOnly: true
      }),
      invalidatesTags: ['PageDetail']
    }),
    deleteSlot: builder.mutation<DeleteSlotMutation, DeleteSlotMutationVariables>({
      query: ({ slotId, pageId }) => ({
        document: DELETE_SLOT,
        variables: { slotId, pageId },
        authOnly: true
      }),
      invalidatesTags: ['PageDetail']
    })
  }),
  overrideExisting: true
})

export const { useCreateSlotMutation, useDeleteSlotMutation, useUpdateSlotMutation } =
  slotsMutationsQueries
