import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { ChangeEvent, FC, useEffect } from 'react'
import { FileUploadInput } from '../../util/SettingsUtil'
import { FormFields } from '../types'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { MaskObj } from '@dtx-company/shared-components/src/components/organisms/ImageEdit/constants'
import { MaskType, Yield } from '@dtx-company/shared-components/src'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UseFormMethods } from 'react-hook-form-deprecated'
import { exceedsMaxFileSize } from '@dtx-company/shared-components/src/components/organisms/ImageEdit/utils'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { useLinkImageEditModalState } from './LinkImageEditModal/hooks'
import { usePreviousValue } from '@dtx-company/true-common/src/hooks/usePreviousValue/usePreviousValue'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface ImageEditProps {
  id: string
  imageUrl?: string
  replaceButtonCTA?: string
  deleteButtonCTA?: string
  defaultImage?: string
  setValue: UseFormMethods['setValue']
  active: boolean
  showLabel?: boolean
  setImageSizeWarning?: (val: boolean) => void
  maskType?: MaskType.CIRCLE | MaskType.SQUARE | MaskType.IPHONEX | MaskType.FEATURED
  /** Crop the image according to maskType without user input or triggering the image edit modal.
    Specially useful when we are automatically fetching the og image for a link */
  performAutomaticDefaultCrop?: boolean
}

export const ImageEdit: FC<ImageEditProps> = ({
  id,
  imageUrl,
  replaceButtonCTA = 'Replace',
  deleteButtonCTA = 'Delete',
  setValue,
  showLabel,
  defaultImage,
  maskType = MaskType.SQUARE,
  performAutomaticDefaultCrop = false
}: ImageEditProps) => {
  const {
    resultImageUrl,
    openImageEditModal,
    setOriginalImageUrl,
    AutomaticDefaultCropper,
    originalImageUrl,
    defaultCroppedImageUrl
  } = useLinkImageEditModalState()
  const handleStagingImage = (e: ChangeEvent<HTMLInputElement>): void => {
    hasImage
      ? events.Flowpage_PageProfile_Clicked_Replace_Icon_Image()
      : events.Flowpage_PageProfile_Clicked_Add_Icon_Image()

    const stagedImage = e.target.files?.[0]
    if (stagedImage) {
      if (exceedsMaxFileSize(stagedImage.size)) {
        sendErrorNotification('Image is too large to upload (size limit 10MB)')
      } else {
        openImageEditModal({
          sourceImageUrl: URL.createObjectURL(stagedImage),
          maskType: maskType === MaskType.FEATURED ? MaskType.RECTANGLE : maskType,
          hideMask: true,
          initialAspectRatio:
            maskType === MaskType.FEATURED ? MaskObj.featured.width / MaskObj.featured.height : 1
        })
      }
    }
  }

  useEffect(() => {
    if (resultImageUrl) {
      setValue(FormFields.IMAGE, resultImageUrl)
    } else if (performAutomaticDefaultCrop && defaultCroppedImageUrl) {
      setValue(FormFields.IMAGE, defaultCroppedImageUrl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultImageUrl, defaultCroppedImageUrl])

  const prevMaskType = usePreviousValue(maskType)
  const prevImageUrl = usePreviousValue(imageUrl)

  useEffect(() => {
    // User changed maskType, then trigger to modal for re-cropping/re-centering
    if (prevMaskType && prevMaskType !== maskType) {
      openImageEditModal({
        sourceImageUrl: originalImageUrl,
        maskType: maskType === MaskType.FEATURED ? MaskType.RECTANGLE : maskType,
        hideMask: true,
        initialAspectRatio:
          maskType === MaskType.FEATURED ? MaskObj.featured.width / MaskObj.featured.height : 1
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUrl, prevMaskType, maskType, openImageEditModal])

  useEffect(() => {
    // If this is the first time we are seeing imageUrl, save it as the original
    // image url
    if (!prevImageUrl && imageUrl) {
      setOriginalImageUrl(imageUrl)
    }
  }, [prevImageUrl, imageUrl, setOriginalImageUrl])

  const handleDeleteImage = (): void => {
    setValue(FormFields.IMAGE, undefined)
    events.Flowpage_PageProfile_Clicked_Delete_Icon_Image()
  }

  const hasImage = imageUrl || defaultImage
  return (
    <>
      {showLabel && <Text mb="8px">Image Thumbnail (size limit 10MB)</Text>}
      <Box>
        <Box position="relative" maxWidth="270px">
          <FileUploadInput handleUpload={handleStagingImage} id={id}>
            <Box>
              {hasImage ? (
                <Image
                  src={imageUrl || defaultImage || ''}
                  width={maskType === 'featured' ? 150 : 60}
                  height={60}
                  mr="8px"
                />
              ) : (
                <Box
                  alignItems="center"
                  width={maskType === 'featured' ? 150 : 60}
                  height={60}
                  bg="#f0f0f0"
                  justifyContent="center"
                  color="primary.black"
                  borderRadius="8px"
                  mr="8px"
                >
                  <Image
                    width={maskType === 'featured' ? 150 : 30}
                    height={30}
                    src={icons.photoGrey}
                  />
                </Box>
              )}
              <Box
                height="36px"
                alignItems="center"
                color="tertiary"
                padding="0px 4px"
                border="1px solid rgba(0, 0, 0, 0.23);"
                borderRadius={1}
              >
                <Text textAlign="center" width="100%" fontWeight={600}>
                  {hasImage ? replaceButtonCTA : 'Add Image'}
                </Text>
              </Box>
            </Box>
          </FileUploadInput>
        </Box>
        {performAutomaticDefaultCrop && !defaultCroppedImageUrl && <AutomaticDefaultCropper />}
        {imageUrl && !imageUrl.startsWith('/') && (
          <Box ml="12px" flexShrink={0}>
            <Button
              width="91px"
              height="36px"
              colorVariant="tertiary"
              backgroundColor="status.errorLight"
              onClick={handleDeleteImage}
              type="button"
            >
              <Text
                lineHeight="30px"
                textAlign="center"
                width="100%"
                cursor="pointer"
                fontWeight={600}
              >
                {deleteButtonCTA}
              </Text>
            </Button>
          </Box>
        )}
      </Box>
    </>
  )
}

export const ImageSizeWarning: FC = () => {
  return (
    <Box alignItems="center">
      <Yield height="60px" width="60px" color="#e1cb00" />
      <Text ml="12px" fontSize="13px" color="status.warningDark">
        Warning: This photo is low resolution and may come out pixelated on larger devices
      </Text>
    </Box>
  )
}
