import { SVGProps, memo } from 'react'

function InstagramFilledSvg(props: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" viewBox="0 0 33 33" fill="none" {...props}>
      <g clipPath="url(#instagram_svg)">
        <path
          d="M2.063 2.245C-.531 4.94 0 7.8 0 16.493c0 7.219-1.26 14.456 5.332 16.16 2.059.529 20.297.529 22.352-.004 2.745-.708 4.978-2.934 5.283-6.816.043-.541.043-18.129-.001-18.682-.325-4.134-2.87-6.517-6.224-7C25.974.04 25.82.007 21.877 0 7.888.007 4.822-.616 2.063 2.245z"
          fill="url(#instagram_svg_path)"
        />
        <path
          d="M16.497 4.316c-4.993 0-9.734-.444-11.545 4.203-.748 1.92-.639 4.413-.639 7.982 0 3.133-.1 6.076.64 7.98 1.806 4.651 6.586 4.206 11.541 4.206 4.781 0 9.71.497 11.543-4.205.75-1.939.64-4.395.64-7.98 0-4.76.262-7.834-2.046-10.141-2.338-2.338-5.499-2.045-10.14-2.045h.006zm-1.092 2.196c10.415-.017 11.74-1.174 11.008 14.91-.26 5.688-4.59 5.063-9.915 5.063-9.707 0-9.986-.277-9.986-9.99 0-9.824.77-9.978 8.893-9.986v.003zm7.596 2.023a1.462 1.462 0 100 2.923 1.462 1.462 0 000-2.923zm-6.504 1.709a6.257 6.257 0 000 12.514c3.455 0 6.255-2.801 6.255-6.257 0-3.455-2.8-6.257-6.255-6.257zm0 2.196c5.37 0 5.376 8.123 0 8.123-5.368 0-5.376-8.123 0-8.123z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="instagram_svg_path"
          x1={2.126}
          y1={30.892}
          x2={32.796}
          y2={4.348}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FD5" />
          <stop offset={0.5} stopColor="#FF543E" />
          <stop offset={1} stopColor="#C837AB" />
        </linearGradient>
        <clipPath id="instagram_svg">
          <path fill="#fff" d="M0 0h33v33H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const InstagramFilled = memo(InstagramFilledSvg)
