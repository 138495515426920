import { DestinationRestrictiveClients } from './landing-page'
import { MaterializedConfig } from '@dtx-company/connect-codegen/src/gen/plans/v1/plans_pb'
import {
  RESTRICTED_LIBERTYMUTUAL_SOCIALS,
  RESTRICTED_VOYA_SOCIALS
} from '@app/page/src/components/widgets/SocialLink/types'
import { useAtlas } from '@dtx-company/inter-app/src/hooks/atlas/useAtlas'
import { useAtlasFeatureFlag } from '@dtx-company/inter-app/src/hooks/atlas/useAtlasFeatureFlag'
import { useShouldRestrictLinksByClient } from './useShouldRestrictLinksByClient'

export function useRestrictedFlowpageLinkTypes(): string[] {
  const atlasData = useAtlas()
  const atlasEnabled = useAtlasFeatureFlag()
  const clientFilter = useShouldRestrictLinksByClient()
  if (atlasEnabled) {
    //return a name of all disabled configs - link providers that are prohibited will be included
    return (
      atlasData?.usage?.['pages']?.configs?.reduce(
        (acc: string[], curr: MaterializedConfig): string[] => {
          if (curr.calculationType === 'toggle' && curr.configValue === 'false') {
            return acc.concat(curr.configName)
          }
          return acc
        },
        [] as string[]
      ) || []
    )
  }
  if (clientFilter === DestinationRestrictiveClients.LIBERTY_MUTUAL) {
    return RESTRICTED_LIBERTYMUTUAL_SOCIALS
  } else if (clientFilter === DestinationRestrictiveClients.VOYA) {
    return RESTRICTED_VOYA_SOCIALS
  }
  return []
}
