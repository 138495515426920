import { TemplateConfig } from '@dtx-company/flow-codegen/src/page/generated.types'
import { TemplateFields } from '../types'

export function getLockedFieldFromTemplateConfig({
  templateConfig,
  field
}: {
  templateConfig?: TemplateConfig | null
  field: TemplateFields
}): boolean {
  const parsedField = templateConfig?.fields?.find(({ name }) => name === field)
  return parsedField?.locked || false
}
