import { Dot } from 'pure-react-carousel'
import { theme } from '@dtx-company/design-system/src'
import styled from '@emotion/styled'

export const StyledDot = styled(Dot)`
  background-color: transparent;
  border: none;
  padding: 3px;
  border: 1px solid transparent;
  border-radius: 4px;
  color: ${theme.palette.common.black};

  &[disabled] {
    color: ${theme.palette.info.dark};
    p {
      color: ${theme.palette.info.dark};
    }
  }
`
