import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Flowpage as EmbedFlowpage } from '../EmbeddableMedia'
import { FC } from 'react'
import { FlowpageLinkCard } from '../../flowpage/FlowpageLinkCard'
import { FlowpageProps } from '../types/FlowpageProps'
import { logoUrlForType } from '../../../utils/main'

export const DestinationFlowpage: FC<FlowpageProps> = ({
  link,
  preview = false,
  editLinkMode = false,
  isEditLinkPreview = false,
  isLockedTemplateLink
}: FlowpageProps) => {
  if (link.embed)
    return (
      <EmbedFlowpage isLockedTemplateLink={isLockedTemplateLink} link={link} preview={preview} />
    )
  return isEditLinkPreview ? (
    <Box
      backgroundColor="#0000002b"
      border="1px dashed #888888"
      borderRadius="4px"
      padding="8px"
      width="100%"
    >
      <FlowpageLinkCard
        preview={preview}
        link={link}
        isLockedTemplateLink={isLockedTemplateLink}
        iconSrc={link.thumbNailImgUrl || logoUrlForType(link.provider, link?.type)}
        editLinkMode={editLinkMode}
      />
    </Box>
  ) : (
    <FlowpageLinkCard
      preview={preview}
      isLockedTemplateLink={isLockedTemplateLink}
      link={link}
      iconSrc={link.thumbNailImgUrl || logoUrlForType(link.provider, link?.type)}
      editLinkMode={editLinkMode}
    />
  )
}
