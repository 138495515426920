import { SVGProps, memo } from 'react'

function LinkSvg({ fill = 'currentcolor', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="1em" height="1em" fill="none" viewBox="0 0 20 20" {...rest}>
      <g clipPath="url(#prefix__prefix__clip_link)">
        <path
          fill={color || fill}
          fillRule="evenodd"
          d="M14.52.25a5.267 5.267 0 00-3.711 1.484l-.008.007-1.557 1.551a.75.75 0 001.058 1.063l1.553-1.547a3.77 3.77 0 015.288.05 3.788 3.788 0 01.05 5.301l-2.71 2.716a3.763 3.763 0 01-5.692-.409.75.75 0 10-1.202.898 5.277 5.277 0 003.847 2.106 5.268 5.268 0 004.108-1.535l2.716-2.721.009-.01a5.288 5.288 0 00-.064-7.406A5.275 5.275 0 0014.52.25zM8.563 6.546a5.268 5.268 0 00-4.108 1.535l-2.716 2.72-.009.01a5.292 5.292 0 00.065 7.406 5.269 5.269 0 007.396.065l.009-.01 1.547-1.55a.749.749 0 10-1.061-1.06l-1.543 1.546a3.771 3.771 0 01-6.393-2.694 3.787 3.787 0 011.056-2.657l2.71-2.716a3.763 3.763 0 014.48-.646 3.8 3.8 0 011.213 1.054.75.75 0 001.202-.897 5.279 5.279 0 00-3.848-2.106z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="prefix__prefix__clip_link">
          <path fill="#fff" d="M.25.25h19.499v19.516H.25V.25z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Link = memo(LinkSvg)

function Link2Svg({ stroke = '', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g
        clipPath="url(#prefix__prefix__clip_link)"
        stroke={stroke || '#000'}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6.756 9.536a3.974 3.974 0 01.038-5.597L8.544 2.2a4.422 4.422 0 016.261.044v0a4.467 4.467 0 01-.044 6.318l-.347.348" />
        <path d="M10.244 7.464a3.974 3.974 0 01-.038 5.597L8.456 14.8a4.423 4.423 0 01-6.261-.045v0a4.465 4.465 0 01.044-6.317l.348-.348" />
      </g>
      <defs>
        <clipPath id="#prefix__prefix__clip_link">
          <path fill="#fff" transform="translate(.5 .5)" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Link2 = memo(Link2Svg)
