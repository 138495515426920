import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps, MaskType } from '@dtx-company/shared-components/src'
import { Card } from '@dtx-company/shared-components/src/components/atoms/Card/index'
import { EditLinkCardEndAdornment } from '../../widgets/EditLinkUtils'
import { FC, useMemo } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkShape } from '../../../constants/customizeTheme'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { getLinkCardStyles } from './utils/getLinkCardStyles'
import { getValidatedActionData } from '../../../components/widgets/typeUtils'
import { useFlowpageAnalyticsCollector } from '../../../hooks/useFlowpageAnalyticsCollector'
import { useFlowpageTheme } from '../../profile/Customize/hooks'
import { useGetLinkUrlWithParams } from '../../../utils/hooks'
import { useLinkCardManager } from './hooks'
import { useNewTabSlots } from '../../../hooks/useNewTabSlots'
import Image from 'next/legacy/image'
import styled from 'styled-components'

export interface LinkCardBaseProps {
  link: LinkType
  preview?: boolean
  newTab?: boolean
  onlyTitle?: boolean
  iconSrc?: string
  color?: string
  bg?: string
  widgetId?: string | null
  hidden?: boolean
  editLinkMode?: boolean
  parentLinkId?: string
  isLockedTemplateLink?: boolean
}

export const CardWrapper = styled(Card)`
  overflow: hidden;
  :not(:last-child) {
    margin-bottom: 25px;
  }
`
interface LinkCardTextProps {
  link: LinkType
  onlyTitle: boolean
  isFeatured: boolean
  showIcon: boolean
  editLinkMode: boolean
  hidden?: boolean
  padding?: string
  titleFontSize?: BoxProps['fontSize']
  isLockedTemplateLink?: boolean
}

const LinkCardText: FC<LinkCardTextProps> = ({
  onlyTitle,
  isFeatured,
  link,
  hidden,
  editLinkMode,
  padding,
  titleFontSize,
  isLockedTemplateLink
}: LinkCardTextProps) => (
  <>
    <Box flexDirection="column" flexGrow={1} justifyContent="center">
      <Text
        textAlign={onlyTitle || isFeatured ? 'center' : 'left'}
        color="inherit"
        variant={'body/medium'}
        fontWeight={600}
        padding={padding}
        lineHeight={titleFontSize}
        fontSize={titleFontSize}
      >
        {link.title}
      </Text>
      {link.description && (
        <Text
          textAlign={onlyTitle || isFeatured ? 'center' : 'left'}
          color="inherit"
          variant="body/small"
          mt="4px"
          wordBreak="break-word"
          padding={padding}
        >
          {link.description}
        </Text>
      )}
    </Box>
    {hidden && (
      <Box marginLeft="auto" marginRight="10px" flexDirection="column">
        <Icon flexShrink={0} src={icons.eye} width="20px" margin="auto" />
        <Text variant="body/small" color="primary.grey">
          Hidden
        </Text>
      </Box>
    )}
    {!link.active && (
      <Box marginLeft="auto" marginRight="10px" flexDirection="column">
        <Icon flexShrink={0} src={icons.circleX} width="20px" margin="auto" />
        <Text variant="body/small" color="primary.grey">
          Inactive
        </Text>
      </Box>
    )}
    {editLinkMode && (
      <EditLinkCardEndAdornment
        isLockedTemplateLink={isLockedTemplateLink}
        margin={'0 5px 0 auto'}
        iconWidth="10px"
        iconMargin="auto"
        padding="0 0 0 5px"
      />
    )}
  </>
)

interface LinkCardProps extends LinkCardTextProps {
  iconProps: BoxProps
  iconSrc: string
}

export const FeaturedLinkCard: FC<LinkCardProps> = props => {
  const { theme } = useFlowpageTheme()
  const textPadding = theme?.linkShape === LinkShape.ROUND ? '0px 6px' : ''
  return (
    <>
      <Image
        quality={100}
        layout="responsive"
        width={400}
        height={160}
        objectPosition="top"
        src={props.iconSrc}
        alt={`${capitalize(props.link?.provider || '')} ${capitalize(props.link?.type || '')}`}
        data-testid="featured-linkcard-next-img"
        objectFit="cover"
      />
      <Spacer mb="12px" />
      <LinkCardText {...props} padding={textPadding} />
      <Spacer mb="12px" />
    </>
  )
}

export const LinkCard: FC<LinkCardProps> = props => (
  <Box alignItems="center" justifyContent={props.onlyTitle ? 'center' : 'start'} flexGrow={1}>
    {!props.onlyTitle && (
      <Box {...props.iconProps}>
        <Image
          width={70}
          height={70}
          src={props.iconSrc}
          alt={`${capitalize(props.link?.provider || '')} ${capitalize(props.link?.type || '')}`}
          data-testid="linkcard-next-img"
        />
      </Box>
    )}
    <LinkCardText {...props} />
  </Box>
)

export const StyledLink = styled.a`
  width: 100%;
`

export const FlowpageLinkCard: FC<LinkCardBaseProps> = (props: LinkCardBaseProps) => {
  const { link, preview, editLinkMode = false, widgetId = null, parentLinkId } = props
  const actionData = getValidatedActionData<'destination'>(link?.actionData, 'link')
  const newTabSlot = useNewTabSlots()
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: editLinkMode
  })

  const linkUrlWithParams = useGetLinkUrlWithParams(actionData?.link)

  const openLinkInSameTab = newTabSlot?.data?.sameTab
  const isSmsLink = link.type === 'sendMessage'

  return (
    <StyledLink
      id={`hp-flowpage-link_card-${link.id}`}
      href={isSmsLink ? actionData?.link : linkUrlWithParams}
      {...(!openLinkInSameTab && { target: '_blank' })}
      rel="noopener"
      onClick={() =>
        trackLinkClick({ link_id: link.id, parent_link_id: parentLinkId }, { useHeap: true })
      }
      data-link_id={link.id}
      data-testid="flowpage-link"
      {...(widgetId && { 'data-widget': widgetId })}
    >
      <InnerLinkCard {...props} />
    </StyledLink>
  )
}

export const InnerLinkCard: FC<LinkCardBaseProps> = ({
  link,
  color,
  bg,
  preview,
  editLinkMode = false,
  hidden,
  iconSrc = '',
  isLockedTemplateLink
}) => {
  const { cardHeight, iconProps, isFeatured } = useLinkCardManager(link, preview ?? false)

  const { theme } = useFlowpageTheme()

  const onlyTitle =
    (link.provider === LinkProvider.LINK && !link.thumbNailImgUrl) ||
    link.displayType === MaskType.NO_IMAGE ||
    !iconSrc

  // BLM or AAPI campaign links. Not to be confused with link.displayType = 'featured'
  const isFeaturedLink = link.provider === LinkProvider.FEATURE
  const linkStyles = useMemo(
    () =>
      getLinkCardStyles({
        theme,
        linkTheme: link.linkTheme,
        isFeaturedLink
      }),
    [theme, link.linkTheme, isFeaturedLink]
  )

  const showIcon = hidden || editLinkMode || !link.active
  return (
    <CardWrapper
      id={link.id}
      {...(color && { color })}
      {...(bg && { background: bg })}
      {...linkStyles}
      minHeight={cardHeight}
      cursor={preview ? 'auto' : 'pointer'}
      padding={isFeatured ? '0' : '8px'}
      display="flex"
      flexDirection="column"
      width="auto"
    >
      {isFeatured && !onlyTitle && iconSrc ? (
        <FeaturedLinkCard
          iconSrc={iconSrc}
          link={link}
          iconProps={iconProps}
          showIcon={showIcon}
          hidden={hidden}
          editLinkMode={editLinkMode}
          onlyTitle={onlyTitle}
          isFeatured={isFeatured}
          titleFontSize={linkStyles.fontSize}
          isLockedTemplateLink={isLockedTemplateLink}
        />
      ) : (
        <LinkCard
          iconSrc={iconSrc}
          link={link}
          iconProps={iconProps}
          onlyTitle={onlyTitle}
          showIcon={showIcon}
          hidden={hidden}
          editLinkMode={editLinkMode}
          isFeatured={isFeatured}
          titleFontSize={linkStyles.fontSize}
          isLockedTemplateLink={isLockedTemplateLink}
        />
      )}
    </CardWrapper>
  )
}
