import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactIconComponent } from './ContactIcon'
import { ContactInfoFields } from './utils'
import { ContactLinkItemType } from '../ContactInfo/types'
import { CountrySelect, CountryType } from '../../../components/util/CountryCodes'
import { FC } from 'react'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { InputMode, Option, theme } from '@dtx-company/shared-components/src'
import { InputProps } from './types'
import { indexInterfaceWithVar } from '@dtx-company/true-common/src/utils/objects'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'

export const getInputType = (type: ContactInfoFields): InputMode => {
  switch (type) {
    case ContactInfoFields.EMAIL:
      return InputMode.EMAIL
    case ContactInfoFields.TEXT:
      return InputMode.TEL
    case ContactInfoFields.CALL:
      return InputMode.TEL
    case ContactInfoFields.WEBSITE:
      return InputMode.URL
    default:
      return InputMode.TEXT
  }
}

export interface ContactInfoInputProps extends InputProps {
  boxShadow?: string
  mobile: boolean
  hideEmailIcon?: boolean
  label?: string
}

export const ContactInfoInput: FC<ContactInfoInputProps> = ({
  inputId,
  type,
  onChange,
  value,
  errors,
  handleCountryCodeChange,
  codes,
  boxShadow = 'float',
  mobile,
  hideEmailIcon = false,
  label
}) => {
  const contactType = type.toLowerCase()
  const isNumeric = contactType === 'call' || contactType === 'text'
  const isError = Boolean(indexInterfaceWithVar(errors, contactType))

  return (
    <Box
      boxShadow={boxShadow}
      margin="4px 0px"
      border={`1px solid ${theme.colors.secondary.border}`}
      alignItems="end"
      borderRadius="6px"
      justifyContent="center"
      padding="8px"
      bg="secondary.backgroundDark"
      width="100%"
      {...stopPropagationEvents}
    >
      {hideEmailIcon && type === ContactInfoFields.EMAIL ? undefined : (
        <Box margin={`0 8px ${isError ? 31 : 10}px`}>
          <ContactIconComponent type={contactType as ContactLinkItemType} isOutline={true} />
        </Box>
      )}
      <Input
        id={inputId}
        inputMode={isNumeric && mobile ? InputMode.NUMERIC : undefined}
        type={getInputType(type)}
        helperText={indexInterfaceWithVar(errors, contactType)?.message}
        value={value}
        {...(label && { label: label, labelProps: { mb: '4px' } })}
        startAdornment={
          isNumeric ? (
            <CountrySelect
              defaultValue={codes ? codes[type.toLowerCase() as 'text' | 'call'] : '1'}
              onChange={(_, option) => {
                const countryCodeTyped = (option as Option).value as CountryType
                handleCountryCodeChange(
                  type.toLowerCase() as 'text' | 'call',
                  countryCodeTyped?.phone || ''
                )
              }}
            />
          ) : undefined
        }
        maxWidth="100%"
        error={isError}
        onChange={onChange}
      />
    </Box>
  )
}
