import { SVGProps, memo } from 'react'

function LinkShapesSvg({ fill = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9259 22.5187C6.07556 22.5187 1.33333 17.7765 1.33333 11.9261C1.33333 6.07572 6.07556 1.3335 11.9259 1.3335C17.7763 1.3335 22.5185 6.07572 22.5185 11.9261C22.5185 17.7765 17.7763 22.5187 11.9259 22.5187Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0889 12.0889H30.6667V30.6667H12.0889V12.0889Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LinkShapes = memo(LinkShapesSvg)
