import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'

import * as React from 'react'
import { FEATURED_DISPLAY_TYPE, ICONS_ONLY } from './types'
import { FlowpageLinkCard } from '../../../components/flowpage/FlowpageLinkCard'
import { FlowpageProps } from '../types/FlowpageProps'
import { FormProps } from '../components/Layout'
import { SocialLinkFormV2 } from './SocialLinkFormV2'
import { SocialLinkIconCard } from '../../../components/flowpage/SocialLinkCard'
import { circleLogoUrlForProvider, featuredLogoUrlForProvider } from './SocialLinkFormV2/utils'
import { logoUrlForType } from '../../../utils/main'
import { processSocialUrl } from './helperFunctions/helperFunctions'
import { useManagerSettings } from '../components/Manager/useManagerSettings'
import { useMemo } from 'react'
import { useNewTabSlots } from '../../../hooks/useNewTabSlots'

export const Form: React.FC<FormProps> = ({ order, curr, handleClose, widgetObj }: FormProps) => {
  return (
    <SocialLinkFormV2 order={order} curr={curr} handleClose={handleClose} widgetObj={widgetObj} />
  )
}

export const Flowpage: React.FC<FlowpageProps> = ({
  link,
  preview = false,
  editLinkMode = false
}: FlowpageProps) => {
  const resolvedActionData = link.actionData
  const resolvedChildLinks = useMemo(() => link.childLinks ?? [], [link.childLinks])
  const newTabSlot = useNewTabSlots()
  // childlinks do not exist on the link object until after they are first submitted
  const addingLink = (link.childLinks ?? []).length === 0
  const openLinksInSameTab = newTabSlot?.data?.sameTab
  //sort by order 0-10
  const sortedSocialProviderArray = React.useMemo(
    () =>
      !addingLink
        ? [...resolvedChildLinks]
            .sort((a, b) => a.order - b.order)
            .map(child => child?.provider ?? '')
        : Object.keys(resolvedActionData)
            .filter((adKey: string) => (resolvedActionData[adKey].order ?? -1) > -1)
            .sort(function (a, b) {
              return resolvedActionData[a].order - resolvedActionData[b].order
            }),
    [resolvedActionData, resolvedChildLinks, addingLink]
  )

  if (link.displayType === ICONS_ONLY) {
    return (
      <SocialLinkIconCard
        link={{
          ...link,
          actionData: link.actionData
        }}
        widgetId="socialLink"
        preview={preview}
        editLinkMode={editLinkMode}
        newTab={!openLinksInSameTab}
      />
    )
  } else {
    return (
      <Box flexDirection="column" width="100%">
        {sortedSocialProviderArray.map((socialLink: string, index) => {
          const targetChild = !addingLink
            ? resolvedChildLinks.find(child => child?.provider === socialLink)
            : resolvedActionData[socialLink]
          const childLinkId = !addingLink ? targetChild.id : link.id
          const featuredOrCardImageUrl =
            link.displayType === FEATURED_DISPLAY_TYPE
              ? featuredLogoUrlForProvider(socialLink)
              : logoUrlForType(socialLink)
          const iconSrc =
            targetChild.thumbNailImgUrl && targetChild.thumbNailImgUrl.length > 0
              ? targetChild.thumbNailImgUrl
              : featuredOrCardImageUrl
          return (
            <Box
              cursor="pointer"
              pb={index === sortedSocialProviderArray.length - 1 ? '0' : '20px'}
              width="100%"
              key={`${socialLink}-flowpage`}
            >
              <FlowpageLinkCard
                link={{
                  ...link,
                  id: childLinkId,
                  title: targetChild.title,
                  description: targetChild.description ?? '',
                  actionData: {
                    ...targetChild,
                    link: processSocialUrl(
                      !addingLink ? targetChild.actionData.link : targetChild.link,
                      socialLink
                    )
                  }
                }}
                widgetId="socialLink"
                preview={preview}
                editLinkMode={editLinkMode}
                iconSrc={iconSrc}
                parentLinkId={!addingLink ? link.id : undefined}
              />
            </Box>
          )
        })}
      </Box>
    )
  }
}

export const Manager: React.FC<ManagerProps> = ({ link, editWidget, handle }: ManagerProps) => {
  const ad = link.actionData
  const adArray = React.useMemo(
    () =>
      Object.keys(ad)
        .filter((adKey: string) => (ad[adKey].order ?? -1) > -1)
        .sort(function (a, b) {
          return ad[a].order - ad[b].order
        }),
    [ad]
  )
  const { managerContainerStyles } = useManagerSettings(link, editWidget)
  return (
    <DefaultManager
      handle={handle}
      editWidget={editWidget}
      link={link}
      iconUrl="/icons/social-media/social-link-smile.svg"
      customTitleSection={
        <Box justifyItems="flex-start" flexDirection="column">
          <Spacer mt="4px" />
          <Text
            variant="body/medium"
            opacity={managerContainerStyles.textOpacity}
            fontWeight="600"
            color={managerContainerStyles.titleColor}
          >
            Socials
          </Text>
          <Spacer mb="8px" />
          <Box flexWrap="wrap">
            {adArray.map((provider: string) => {
              return (
                <Box
                  mr="8px"
                  mb="8px"
                  display="inline-block"
                  width="24px"
                  key={`${provider}-manager-image`}
                  opacity={managerContainerStyles.iconOpacity}
                >
                  <Image
                    width="24px"
                    height="24px"
                    src={circleLogoUrlForProvider(provider)}
                    alt={`${provider} icon`}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
      }
    ></DefaultManager>
  )
}
