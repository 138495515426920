import { DELETE_THEME, EDIT_THEME } from '@dtx-company/flow-codegen/src/page/mutations'
import {
  DeleteThemeMutation,
  DeleteThemeMutationVariables,
  EditThemeMutation,
  MutationEditThemeArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { RootState } from '../../../redux/types'
import { deleteBackgroundImage, uploadBackgroundImage } from '../../../utils/rest'
import { flowpageApi } from './empty'
import { flowpageAutosaveMessaging } from '@dtx-company/true-common/src/constants/messages'
import { getCurrentJwtFromStore } from '../../../utils/jwt'
import { logger } from '@dtx-company/logger'
import { pageQueries } from './pageQueries'
import { sendErrorNotification, sendSuccessNotification } from '../../../utils/notifications'

export const themeMutations = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    deleteTheme: builder.mutation<
      DeleteThemeMutation,
      DeleteThemeMutationVariables & {
        deleteImage?: boolean
        isTemplateEditor?: boolean
      }
    >({
      query: ({ themeId }) => ({
        document: DELETE_THEME,
        variables: { themeId },
        authOnly: true
      }),
      invalidatesTags: ['PageDetail'],
      async onCacheEntryAdded({ deleteImage = false }, { getState }) {
        const jwt = getCurrentJwtFromStore(getState() as RootState)
        try {
          const pageId = (getState() as RootState).pageReducer.pageEditorReducer.pageId
          if (deleteImage) {
            deleteBackgroundImage(pageId ?? '', jwt?.token)
          }
        } catch (e) {
          console.error(e)
          sendErrorNotification('Error uploading background image')
        }
      }
    }),
    editTheme: builder.mutation<
      EditThemeMutation,
      MutationEditThemeArgs & {
        backgroundImage?: string
        deleteImage?: boolean
        isTemplateEditor?: boolean
      }
    >({
      query: ({ themeId, themeInput }) => ({
        document: EDIT_THEME,
        variables: { themeId, themeInput },
        authOnly: true
      }),
      async onQueryStarted(
        { backgroundImage, themeInput, deleteImage, isTemplateEditor },
        { dispatch, queryFulfilled, getState }
      ) {
        const id = isTemplateEditor
          ? (getState() as RootState).pageReducer.pageEditorReducer.templateId
          : (getState() as RootState).pageReducer.pageEditorReducer.pageId
        const patchResult = dispatch(
          pageQueries.util.updateQueryData(
            'pageDetail',
            { id, isPageTemplate: Boolean(isTemplateEditor) },
            draft => {
              Object.assign(draft, {
                Page: {
                  ...draft.Page,
                  theme: {
                    ...(draft.Page?.theme || {}),
                    ...themeInput,
                    backgroundImgUrl: deleteImage
                      ? null
                      : backgroundImage || draft?.Page?.theme?.backgroundImgUrl
                  }
                }
              })
            }
          )
        )
        try {
          await queryFulfilled
          sendSuccessNotification(flowpageAutosaveMessaging.editTheme.success)
        } catch {
          patchResult.undo()
          sendErrorNotification(flowpageAutosaveMessaging.editTheme.error)
        }
      },
      async onCacheEntryAdded(
        { backgroundImage, deleteImage = false },
        { getState, cacheDataLoaded }
      ) {
        try {
          await cacheDataLoaded
          const pageId = (getState() as RootState).pageReducer.pageEditorReducer.pageId
          const jwt = getCurrentJwtFromStore(getState() as RootState)
          const token = jwt?.token

          if (backgroundImage) {
            uploadBackgroundImage(pageId ?? '', backgroundImage, token)
          } else if (deleteImage) {
            deleteBackgroundImage(pageId ?? '', token)
          }
        } catch (e) {
          logger.logError(e, { team: 'templates', technicalArea: 'flowpage' })
          sendErrorNotification('Error uploading background image')
        }
      }
    })
  }),
  overrideExisting: true
})

export const { useEditThemeMutation, useDeleteThemeMutation } = themeMutations
