import { Image } from '@dtx-company/shared-components/src/components/atoms/Image'

export const Verified = ({ verified = false }: { verified?: boolean | null }): JSX.Element => {
  return (
    <Image
      src="/icons/verified-checkmark.svg"
      width="20px"
      height="20px"
      ml="4px"
      data-testid="verifiedCheckmark"
      display={verified ? 'flex' : 'none'}
    />
  )
}
