import { SVGProps, memo } from 'react'

function MessageSvg({ stroke = '#111', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M7.40135 12.5961C7.01575 11.7238 6.81573 10.7808 6.81396 9.82699C6.81396 5.63137 10.59 2.23291 15.2052 2.23291C19.8204 2.23291 23.5965 5.61039 23.5965 9.82699C23.5793 11.0358 23.264 12.2216 22.6785 13.2792C22.0929 14.3368 21.2553 15.2335 20.24 15.8897V18.879L16.9464 17.2532C16.3724 17.3625 15.7895 17.4187 15.2052 17.4211C14.481 17.4233 13.7591 17.3388 13.055 17.1693"
        fill="#25D086"
      />
      <path
        d="M12.5865 17.7123L12.5865 17.7129C12.584 18.3222 12.4615 18.925 12.2261 19.487C11.9906 20.0489 11.6468 20.559 11.2142 20.9881C10.7816 21.4171 10.2687 21.7568 9.70488 21.9877C9.14102 22.2185 8.53721 22.3361 7.92792 22.3336L7.92543 22.3336C7.2518 22.3342 6.58605 22.1888 5.97403 21.9073C5.8761 21.8623 5.76623 21.8501 5.66081 21.8726L3.26567 22.3835L3.76855 19.9889C3.79094 19.8823 3.77788 19.7712 3.73136 19.6727C3.43332 19.0414 3.28125 18.3511 3.28643 17.653C3.29161 16.9549 3.45391 16.2669 3.76129 15.6401C4.06866 15.0133 4.51323 14.4637 5.062 14.0322C5.61077 13.6006 6.24966 13.2982 6.93128 13.1473C7.6129 12.9964 8.31975 13.0009 8.99938 13.1605C9.67902 13.3201 10.314 13.6306 10.8572 14.0691C11.4005 14.5076 11.838 15.0628 12.1373 15.6935C12.4367 16.3242 12.5902 17.0141 12.5865 17.7123Z"
        fill="#33B4FF"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle r="1.46051" transform="matrix(-1 0 0 1 19.6587 10.1295)" fill="white" />
      <circle r="1.46051" transform="matrix(-1 0 0 1 15.2056 10.1295)" fill="white" />
      <circle r="1.46051" transform="matrix(-1 0 0 1 10.7519 10.1295)" fill="white" />
    </svg>
  )
}

export const Message = memo(MessageSvg)
