import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ColorChip } from '@dtx-company/shared-components/src/components/atoms/Chip/ColorChip'
import { ColorInputHex } from '@dtx-company/shared-components/src'
import { ColorSelectionMethods } from '@app/common/src/types/ColorSelectionMethods'
import { EditTheme } from './types'
import { FC, useCallback } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon'
import { LockedFeatureWrapper } from '@app/common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { PlanType } from '@dtx-company/true-common/src/types/planType'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { TooltipContent } from '@dtx-company/design-system/src'
import { UPDATED_FLOWCODE_COLOR_OPTIONS } from '@app/code/src/constants/flowcode'
import { getPlanTypeName } from '@app/common/src/utils/getPlanTypeName'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import { useDebouncedCallback } from 'use-debounce'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import useTranslation from 'next-translate/useTranslation'

interface CardProps {
  page?: PageType
  handleSelect: (values: EditTheme) => void
  curr: string
  color?: string
  contrastText?: string
  mobile?: boolean
  disabled?: boolean
}

export const ColorCard: FC<CardProps> = ({ handleSelect, curr, mobile, disabled }) => {
  const { isViewer, hasOperation } = useAuthState() // org-level permission.
  const { hasViewOnlyAccess } = useCurrentPagePermissions() // page-level permission
  const userCanEditColor = !(isViewer || hasViewOnlyAccess)
  const { t } = useTranslation('codes-pages')
  const setThemeColorDebounced = useDebouncedCallback(
    (color: string) => handleSelect({ primaryColor: color }),
    500
  )

  const setColor = useCallback(
    (color: string, colorName: string, colorSelectedVia: ColorSelectionMethods) => {
      setThemeColorDebounced(color.toUpperCase())
      const eprops = {
        colorName,
        colorHex: color.toUpperCase(),
        colorSelectedVia: colorSelectedVia as string
      }

      events.Flowpage_PageProfile_Selected_Color(eprops)
    },
    [setThemeColorDebounced]
  )

  const userHasHexColors = hasOperation('view:hex_color_page')
  const disabledOptions = !userCanEditColor || disabled
  return (
    <Box flexDirection="column">
      <Box>
        <Icon display={['none', 'none', 'flex']} src={icons.paintPalette} />
        <Spacer mr={[0, 0, '8px']} />
        <Text
          data-testid="color-card-label"
          color="primary.black"
          variant={mobile ? 'body/small' : 'body/medium'}
          fontWeight="bold"
          opacity={disabled ? 0.35 : 1}
        >
          {t('CodesPagesDesign.SelectColor')}
        </Text>
      </Box>
      <Spacer mt={['8px', '8px', '16px']} />
      <Box pl="12px" flexDirection="column" {...stopPropagationEvents}>
        {userHasHexColors ? (
          <ColorInputHex
            color={curr}
            onColorChange={selectedColor =>
              setColor(selectedColor, 'hexColor', ColorSelectionMethods.HEX_PICKER)
            }
            disabled={disabledOptions}
            width={{ _: '100%', md: '144px' }}
          />
        ) : (
          <LockedFeatureWrapper featureName="view:hex_color_page">
            <ColorInputHex
              color={curr}
              onColorChange={() => undefined}
              disabled={true}
              width={{ _: '100%', md: '144px' }}
            />
          </LockedFeatureWrapper>
        )}
        <Spacer mb="16px" />
      </Box>
      <Box pl="12px" flexDirection="column">
        <Text variant="body/small" color="primary.grey">
          Default Colors
        </Text>
        <Spacer mb="16px" />
      </Box>
      <Box flexWrap="wrap" maxWidth="600px" justifyContent="flex-start">
        {UPDATED_FLOWCODE_COLOR_OPTIONS.map(colorObj => {
          const selected = curr === colorObj.color
          return (
            <ColorChip
              data-testid={`color-${colorObj.color}`}
              key={colorObj.color}
              margin={['8px', '8px', '8px 12px']}
              onClick={() =>
                !disabledOptions &&
                setColor(colorObj.color, colorObj.id, ColorSelectionMethods.COLOR_CHIP)
              }
              {...stopPropagationEvents}
              chipColor={colorObj.color}
              width={[40, 40, 48]}
              height={[40, 40, 48]}
              cursor={disabledOptions ? 'default' : 'pointer'}
              borderRadius={'50%'}
              selected={selected}
              opacity={disabledOptions ? 0.35 : 1}
            />
          )
        })}
      </Box>
    </Box>
  )
}

const HexColorsPFWTooltip = (
  <TooltipContent
    title={`${getPlanTypeName(PlanType.PRO)} Feature: Hex colors`}
    content={`Upgrade to ${getPlanTypeName(
      PlanType.PRO
    )} to select colors for your Flowpage that match your brand.`}
  />
)
