import {
  AllFontFamilyOptionNames,
  FONT_STYLESHEETS,
  FontFamilies
} from '@dtx-company/true-common/src/constants/page'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'

type UseGatedFlowpageFontOptionsReturnType = {
  availableFonts: FontFamilies[]
  gatedFonts: FontFamilies[]
  userIsViewer: boolean
}

export const FreeFontFamilyOptionNames: FontFamilies[] = AllFontFamilyOptionNames.filter(
  fontOption => FONT_STYLESHEETS[fontOption].free
)

export const NonFreeFontFamilyOptionNames: FontFamilies[] = AllFontFamilyOptionNames.filter(
  fontOption => !FONT_STYLESHEETS[fontOption].free
)

export const useGatedFlowpageFontOptions = (): UseGatedFlowpageFontOptionsReturnType => {
  const { isViewer, hasOperation } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const canUsePaidFonts = hasOperation('view:page_fonts')
  const availableFonts = canUsePaidFonts ? AllFontFamilyOptionNames : FreeFontFamilyOptionNames
  const gatedFonts = canUsePaidFonts ? [] : NonFreeFontFamilyOptionNames
  const viewOnly = isViewer || hasViewOnlyAccess

  return { availableFonts, gatedFonts, userIsViewer: viewOnly }
}
