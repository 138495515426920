import { SVGProps, memo } from 'react'

function PaintPaletteSvg({ fill = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5172 10.2979C28.3683 7.57669 26.4244 5.26564 23.94 3.66717C21.4555 2.06871 18.5462 1.2573 15.5929 1.33913C11.8579 1.48073 8.31418 3.02744 5.67124 5.66962C3.0283 8.3118 1.48114 11.8545 1.3395 15.5884C1.28259 17.5481 1.61995 19.4992 2.33155 21.3261C3.04315 23.153 4.11451 24.8184 5.48208 26.2235C6.84849 27.6311 8.48395 28.7498 10.2913 29.5131C12.0987 30.2764 14.0411 30.6687 16.0031 30.6668H18.2028C18.7072 30.6661 19.2045 30.5486 19.6558 30.3234C20.1071 30.0982 20.5 29.7715 20.8038 29.369C21.1085 28.9669 21.3153 28.4993 21.4076 28.0033C21.4999 27.5074 21.4753 26.9967 21.3357 26.5119L20.9165 25.0428C20.7769 24.558 20.7522 24.0474 20.8446 23.5514C20.9369 23.0555 21.1436 22.5879 21.4484 22.1858C21.7533 21.7848 22.1466 21.4596 22.5977 21.2352C23.0488 21.0109 23.5455 20.8936 24.0493 20.8924H27.4076C27.8363 20.8936 28.261 20.81 28.6572 20.6464C29.0535 20.4828 29.4134 20.2425 29.7163 19.9392C30.0193 19.6359 30.2592 19.2758 30.4223 18.8794C30.5854 18.4831 30.6684 18.0584 30.6666 17.6298V16.003C30.6685 14.0436 30.2776 12.1037 29.5172 10.2979V10.2979Z"
        stroke={fill}
        strokeMiterlimit="10"
      />
      <path
        d="M8.06817 17.3511L8.06817 17.3511C8.18717 17.3016 8.29452 17.2279 8.38336 17.1346L8.38627 17.1316L8.38629 17.1316C8.47576 17.0392 8.54566 16.9297 8.59181 16.8097C8.63763 16.6882 8.65913 16.5588 8.65507 16.429L8.65484 16.4217L8.65483 16.4145C8.65426 16.16 8.55317 15.9159 8.37352 15.7355C8.19477 15.556 7.95263 15.4539 7.69933 15.4512C7.44255 15.4544 7.19706 15.5574 7.01484 15.7384C6.8317 15.9202 6.72697 16.1664 6.72297 16.4244L6.72283 16.4335L6.72275 16.4335C6.71845 16.5613 6.74041 16.6887 6.7873 16.8077L6.79031 16.8154L6.79025 16.8154C6.83471 16.934 6.90356 17.0419 6.99236 17.1323L6.99675 17.1368L6.99673 17.1368C7.08515 17.2291 7.19174 17.3021 7.30978 17.3511L7.31272 17.3524L7.31271 17.3524C7.43144 17.4027 7.5591 17.4285 7.68804 17.4283L8.06817 17.3511ZM8.06817 17.3511L8.06661 17.3518M8.06817 17.3511L8.06661 17.3518M8.06661 17.3518C7.94679 17.402 7.81818 17.4281 7.68823 17.4283L8.06661 17.3518Z"
        fill={fill}
        stroke={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5942 21.9894C11.6696 22.1673 11.7083 22.3586 11.708 22.5519C11.7092 22.7452 11.6704 22.9366 11.5942 23.1143C11.5187 23.2927 11.4081 23.4541 11.269 23.5889C11.1308 23.7247 10.9664 23.8307 10.7858 23.9006C10.6051 23.9705 10.4121 24.0027 10.2186 23.9953C9.83619 23.9953 9.46943 23.8437 9.19874 23.5737C8.92805 23.3037 8.77555 22.9374 8.77468 22.5551C8.77917 22.3177 8.84063 22.0848 8.9539 21.8761C9.06718 21.6674 9.22895 21.4889 9.4256 21.3557C9.62225 21.2225 9.84803 21.1385 10.0839 21.1108C10.3198 21.083 10.559 21.1123 10.7812 21.1962C10.9609 21.2673 11.1253 21.3722 11.2657 21.505C11.4067 21.6427 11.5184 21.8075 11.5942 21.9894Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.89261 10.8232C8.78293 10.5606 8.7541 10.2712 8.80982 9.99212C8.85486 9.76774 8.95179 9.55702 9.09286 9.37679C9.23394 9.19657 9.41524 9.05184 9.62227 8.9542C9.8293 8.85655 10.0563 8.80868 10.2851 8.81443C10.514 8.82017 10.7383 8.87937 10.9402 8.98729C11.142 9.09521 11.3158 9.24885 11.4477 9.43593C11.5795 9.62301 11.6658 9.83833 11.6995 10.0647C11.7332 10.291 11.7135 10.5221 11.6419 10.7395C11.5703 10.9569 11.4488 11.1545 11.2871 11.3165C11.1507 11.4562 10.9888 11.5686 10.8102 11.6476C10.6307 11.7219 10.4381 11.7591 10.2439 11.7569C9.95368 11.7566 9.67009 11.6702 9.42914 11.5085C9.18388 11.3409 8.99648 11.1015 8.89261 10.8232Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7698 7.13356C17.8421 7.31189 17.8791 7.50253 17.8788 7.69493C17.8757 8.05713 17.7385 8.40535 17.4938 8.67246C17.2491 8.93957 16.9141 9.10666 16.5534 9.14153C16.1928 9.17639 15.832 9.07656 15.5406 8.86128C15.2492 8.646 15.0479 8.3305 14.9754 7.97561C14.9164 7.69089 14.9451 7.39497 15.058 7.12696C15.1686 6.85896 15.3564 6.62986 15.5975 6.46877C15.8386 6.30767 16.1222 6.22184 16.4122 6.22217C16.6047 6.22189 16.7954 6.2589 16.9738 6.33114C17.1529 6.40804 17.3147 6.52036 17.4494 6.66136C17.5871 6.79546 17.6961 6.95611 17.7698 7.13356Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9402 10.8132C23.8296 11.0822 23.6412 11.312 23.3992 11.4734C23.1572 11.6347 22.8724 11.7201 22.5815 11.7187C22.2917 11.7193 22.0082 11.6339 21.7669 11.4732C21.5256 11.3126 21.3374 11.0841 21.2262 10.8165C21.1198 10.5524 21.0912 10.2635 21.1436 9.98367C21.1996 9.6995 21.3388 9.43838 21.5436 9.23349C21.6782 9.09375 21.8388 8.98151 22.0163 8.90302C22.1933 8.82936 22.3831 8.79118 22.5749 8.79066C22.7695 8.78487 22.9632 8.81976 23.1435 8.89311C23.3238 8.9683 23.4861 9.08094 23.6195 9.22358C23.8252 9.42828 23.9655 9.68934 24.0228 9.97376C24.0799 10.2555 24.0511 10.5479 23.9402 10.8132Z"
        fill={fill}
      />
    </svg>
  )
}

export const PaintPalette = memo(PaintPaletteSvg)
