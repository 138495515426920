import { Chip } from '.'
import { ChipSelector, ColorChipProps } from '../../../types/atoms/chip.types'
import { FC } from 'react'
import { theme } from '../../../../src/theme'
import styled from 'styled-components'

const ColorChipContent = styled.div<{ chipColor: React.CSSProperties['backgroundColor'] }>`
  content: '';
  height: 100%;
  width: 100%;
  display: flex;
  aligh-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ chipColor }) => chipColor};
`
export const ColorChip: FC<ColorChipProps> = ({ selected, children, chipColor, ...rest }) => {
  return (
    <Chip
      selected={selected}
      width="48px"
      height="48px"
      borderRadius="50%"
      selectorType={ChipSelector.FLOAT}
      border={selected ? undefined : `1px solid ${theme.colors.secondary.border}`}
      {...rest}
    >
      <ColorChipContent chipColor={chipColor}>{children}</ColorChipContent>
    </Chip>
  )
}
