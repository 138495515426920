import { PermissionsValueTypes } from '@dtx-company/inter-app/src/types/permissions'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useGetPermissions } from '@app/code/src/hooks/useGetPermissions'
import { useIsTempPage } from '../hooks/useIsTempPage'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'

interface CurrentPagePermissionsState {
  hasViewOnlyAccess: boolean
  hasEditorOnlyAccess: boolean
  isOwner: boolean
}

export const useCurrentPagePermissions = (): CurrentPagePermissionsState => {
  const currentpage = useCurrentPage()
  const { jwt } = useAuthState()
  const isTempPage = useIsTempPage()
  const { isTemplateEditor, templateId } = usePageTemplateEditorState()

  const { loading, viewOnly, entityPermissions, isOwner } = useGetPermissions({
    pageId: isTemplateEditor ? undefined : currentpage?.id,
    isBatch: true,
    pageTemplateId: isTemplateEditor ? templateId : undefined
  })
  let permissionName = entityPermissions?.find(
    entity => entity.entityId === jwt?.ithacaId
  )?.permissionName
  if (!permissionName) permissionName = entityPermissions?.[0]?.permissionName
  return {
    isOwner,
    hasViewOnlyAccess: !loading && viewOnly && !isTempPage,
    hasEditorOnlyAccess:
      !loading && !isOwner && permissionName === PermissionsValueTypes.Editor && !isTempPage
  }
}
