import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Chip } from '@dtx-company/shared-components/src/components/atoms/Chip/index'
import { ChipSelector } from '@dtx-company/shared-components/src/types/atoms/chip.types'
import { FC, useState } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { SocialLinkUrlFields } from '../../../Destination/types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { circleLogoUrlForProvider } from '../utils'
import { stopPropagationEvents } from '../../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import styled from 'styled-components'

interface SocialChipProps {
  provider: SocialLinkUrlFields
  displayText: string
  selected: boolean
  updateSelectedLinks: (linkType: SocialLinkUrlFields) => void
}

export const SocialChip: FC<SocialChipProps> = ({
  provider,
  displayText,
  selected,
  updateSelectedLinks
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  return (
    <Box
      flex="0 0 14.28%"
      onMouseEnter={() => setShowTooltip(true)}
      justifyContent="center"
      onMouseLeave={() => setShowTooltip(false)}
    >
      <Chip
        selected={selected}
        data-testid={`${provider}-add-chip`}
        width="44px"
        height="44px"
        cursor="pointer"
        borderRadius="50%"
        mr="8px"
        mb="8px"
        selectorType={ChipSelector.BORDER}
        backgroundColor={selected ? 'secondary.highlight' : 'primary.white'}
        border={selected ? `1px solid primary.white` : `1px solid primary.flowBlue`}
        onClick={() => updateSelectedLinks(provider)}
        key={`${provider}-add-chip`}
        justifyContent="center"
        alignItems="center"
        {...stopPropagationEvents}
      >
        <Icon
          src={circleLogoUrlForProvider(provider)}
          p={selected ? '4px' : '6px'}
          alt={`${provider}-select-icon`}
          flexShrink={0}
          width="100%"
          height="100%"
        />
      </Chip>
      {showTooltip && (
        <ChipTooltip>
          <Text opacity={1} color="primary.white" fontWeight="bold">
            {displayText}
          </Text>
        </ChipTooltip>
      )}
    </Box>
  )
}

const ChipTooltip = styled(Box)`
  padding: 5px;
  border-radius: 4px;
  background-color: black;
  position: absolute;
  margin-top: 45px;
  z-index: 5;
  -webkit-animation: show-tooltip 1s forwards;
  -moz-animation: show-tooltip 1s forwards;
  -o-animation: show-tooltip 1s forwards;
  animation: show-tooltip 1s forwards;
  opacity: 0;
  @keyframes show-tooltip {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 0.8;
    }
  }
`
