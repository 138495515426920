import { SVGProps, memo } from 'react'

function BackgroundImageSvg({
  fill = 'currentcolor',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="33"
      height="26"
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27.6763 14.4828L22.2281 9.0474C21.9896 8.81032 21.667 8.67725 21.3307 8.67725C20.9944 8.67725 20.6718 8.81032 20.4333 9.0474L14.947 14.4828"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3117 20.8475L13.3177 12.8663C13.0792 12.6292 12.7566 12.4961 12.4203 12.4961C12.084 12.4961 11.7614 12.6292 11.5229 12.8663L5.32373 19.0399"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.56967 9.41645C9.27269 9.41645 9.84259 8.84655 9.84259 8.14353C9.84259 7.44051 9.27269 6.87061 8.56967 6.87061C7.86665 6.87061 7.29675 7.44051 7.29675 8.14353C7.29675 8.84655 7.86665 9.41645 8.56967 9.41645Z"
        fill={fill}
      />
      <path
        d="M28.2236 1.11719H4.77637C2.81496 1.11719 1.22491 2.70723 1.22491 4.66864V21.3312C1.22491 23.2926 2.81496 24.8827 4.77637 24.8827H28.2236C30.185 24.8827 31.7751 23.2926 31.7751 21.3312V4.66864C31.7751 2.70723 30.185 1.11719 28.2236 1.11719Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const BackgroundImage = memo(BackgroundImageSvg)
