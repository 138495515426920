import { AddAllLinks } from '../AddAllLinks/AddAllLinks'
import { AddSection } from '../AddSection/AddSection'
import { AddWidgetType } from '@app/page/src/components/widgets'
import { AddWidgets } from '../../../Links/AddNew'
import { AnimatePresence, m as motion } from 'framer-motion'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactInfoFormV2 } from '../../../../../components/widgets/Contact/ContactForm'
import { DesignMenu } from '../DesignMenu/DesignMenu'
import { FC, MouseEvent, PropsWithChildren, useState } from 'react'
import { LinkEditDrawerContent } from './LinkEditDrawer.constants'
import {
  LinkProvider,
  SocialLinkType,
  socialLinks
} from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Form as SocialLinkForm } from '../../../../widgets/SocialLink'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { StandardLinkForm } from '../../../../../components/widgets/Destination/DestinationForm'
import { SwipeableDrawer, useMediaQuery } from '@mui/material'
import { WidgetDrawerForm } from '../AddAllLinks/WidgetDrawerForm/WidgetDrawerForm'
import { animationProps, desktopAnimationProps } from './LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src'
import { useLinkEditDrawerState } from './LinkEditDrawer.hooks'
import { useNextLinkOrder } from '../../../../../hooks/useGetLinkOrder'
import noop from 'lodash/noop'
import styled from 'styled-components'

const Puller = styled(Box)`
  width: 30px;
  height: 6px;
  background-color: black;
  border-radius: 3px;
  position: absolute;
  top: 8px;
  z-index: 1000;
  left: calc(50% - 15px);
`

const SwipeableBox = styled(Box)`
  transition: height 0.5s;
`
const StyledMotionDiv = styled(motion.div)`
  width: 100%;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ResponsiveStyledMotionDiv: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const isDesktop = useMediaQuery(`(min-width:${theme.breakpoints.md})`)
  return (
    <StyledMotionDiv {...(isDesktop ? desktopAnimationProps : animationProps)}>
      {children}
    </StyledMotionDiv>
  )
}

export const DrawerComponent: FC<{
  type: LinkEditDrawerContent | null
  currentEditLink: LinkType | null
  handleClose: () => void
  onClickAddWidget?: (link: AddWidgetType | SocialLinkType) => void
  height?: string
}> = ({ type, currentEditLink, handleClose, onClickAddWidget, height }) => {
  const order = useNextLinkOrder()
  const linkOrder = currentEditLink?.order ?? order

  switch (type) {
    case LinkEditDrawerContent.ADD_LINK:
      return (
        <AnimatePresence initial={false}>
          <ResponsiveStyledMotionDiv>
            <AddAllLinks />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    case LinkEditDrawerContent.CONTACT_INFO:
      return (
        <AnimatePresence initial={false}>
          <ResponsiveStyledMotionDiv>
            <ContactInfoFormV2
              handleClose={handleClose}
              order={linkOrder}
              curr={currentEditLink || undefined}
              widgetObj={{
                provider: LinkProvider.WIDGET,
                type: 'contactInfo',
                id: 'contactInfo'
              }}
            />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    case LinkEditDrawerContent.ADD_SECTION:
      return (
        <AnimatePresence initial={false}>
          <ResponsiveStyledMotionDiv>
            <AddSection />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    case LinkEditDrawerContent.DESIGN:
      return <DesignMenu height={height} />
    case LinkEditDrawerContent.DESTINATION:
      return (
        <AnimatePresence initial={false}>
          <ResponsiveStyledMotionDiv>
            <Spacer mb="16px" />
            <StandardLinkForm
              curr={currentEditLink || undefined}
              widgetObj={socialLinks[0]}
              order={linkOrder}
              handleClose={handleClose}
            />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    case LinkEditDrawerContent.ADD_WIDGET:
      return (
        <AnimatePresence>
          <ResponsiveStyledMotionDiv>
            <Spacer mb="16px" />
            <AddWidgets onClick={onClickAddWidget || noop} handleClose={handleClose} />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    case LinkEditDrawerContent.SOCIALS:
      return (
        <AnimatePresence>
          <ResponsiveStyledMotionDiv>
            <Spacer mb="24px" />
            <SocialLinkForm
              handleClose={handleClose}
              curr={currentEditLink || undefined}
              order={linkOrder}
              widgetObj={{
                provider: LinkProvider.WIDGET,
                type: 'socialLink',
                id: 'socialLink'
              }}
            />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    case LinkEditDrawerContent.WIDGET_FORM:
      return (
        <AnimatePresence>
          <ResponsiveStyledMotionDiv>
            <WidgetDrawerForm handleClose={handleClose} order={linkOrder} />
          </ResponsiveStyledMotionDiv>
        </AnimatePresence>
      )
    default:
      return <></>
  }
}

export const LinkEditDrawer: FC = () => {
  const [touchStart, setTouchStart] = useState<number | undefined>(undefined)
  const [touchEnd, setTouchEnd] = useState<number | undefined>(undefined)
  const [height, setHeight] = useState('366px')
  const handleTouchStart = (clientY: number): void => {
    setTouchStart(clientY)
  }

  const handleTouchMove = (clientY: number): void => {
    setTouchEnd(clientY)
  }

  const handleMouseDown = (e: MouseEvent): void => {
    e.preventDefault()
    handleTouchStart(e.clientY)
  }
  const handleMouseEnd = (e: MouseEvent): void => {
    e.preventDefault()
    handleTouchMove(e.clientY)
  }

  const handleTouchEnd = (): void => {
    if (!touchStart || !touchEnd) return
    if (touchStart - touchEnd > 50) {
      setHeight('100vh')
    }
    if (touchStart - touchEnd < -50) {
      setHeight('366px')
    }
  }
  const {
    onDrawerClose,
    onDrawerOpen,
    drawerOpen,
    drawerContentState,
    previewLink,
    onClickAddWidget
  } = useLinkEditDrawerState()

  return (
    <SwipeableDrawer
      anchor="bottom"
      onClose={onDrawerClose}
      onOpen={onDrawerOpen}
      hysteresis={0.75}
      minFlingVelocity={800}
      open={drawerOpen}
      hideBackdrop
    >
      <SwipeableBox
        onTouchStart={e => handleTouchStart(e.touches[0].clientY)}
        onTouchEnd={handleTouchEnd}
        onTouchMove={e => handleTouchMove(e.touches[0].clientY)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseEnd}
        height={height}
        position="relative"
        data-testid="link-edit-swipeable-drawer"
      >
        <Puller />
        <Spacer mb="16px" />
        <DrawerComponent
          handleClose={() => {
            setHeight('366px')
            onDrawerClose()
          }}
          type={drawerContentState}
          height={height}
          currentEditLink={previewLink}
          onClickAddWidget={onClickAddWidget}
        />
      </SwipeableBox>
    </SwipeableDrawer>
  )
}
