import { DropResult } from 'react-beautiful-dnd'
import {
  SocialAdvLinkType,
  SocialLinkFormFields,
  SocialLinkUrlFields
} from '../../Destination/types'
import { SocialLinkFields } from '../../Destination/SocialLinkFields.types'
import { moveListItem } from '../../../../utils/object'
import { musicLinks } from '../types'

export const circleLogoUrlForProvider = (provider: string): string =>
  `/icons/social-media/${provider}-color-circle.svg`

export const bwLogoUrlForProvider = (provider: string): string =>
  provider === 'twitter'
    ? `/icons/social-media/${provider}.svg`
    : `/icons/social-media/${provider}-filled.svg`

export const featuredLogoUrlForProvider = (provider: string): string =>
  `/icons/social-media/featured/${provider}-featured.svg`

export const reorderLinks = (
  result: DropResult,
  selectedLinks: string[],
  updateFormFieldValue: (
    value: string | number,
    social: SocialLinkUrlFields | undefined,
    nestedItem?: keyof SocialLinkFields | undefined
  ) => Promise<void>,
  watchAll: SocialLinkFormFields,
  setSelectedLinks: (links: string[]) => void
): void => {
  if (!result.destination || result.destination.index === result.source.index) {
    return
  }
  const updatedList: string[] = moveListItem(
    selectedLinks,
    result.source.index,
    result.destination.index
  )
  updatedList.forEach(function (val, i) {
    const formField = val as SocialLinkUrlFields
    updateFormFieldValue(i, formField, 'order')
    const titleInput: HTMLInputElement | null = document.getElementById(
      `inline-title-input-${i}`
    ) as HTMLInputElement
    if (titleInput) {
      titleInput.value = watchAll[formField]?.title ?? ''
    }
    const descriptionInput: HTMLInputElement | null = document.getElementById(
      `inline-description-input-${i}`
    ) as HTMLInputElement
    if (descriptionInput) {
      descriptionInput.value = watchAll[formField]?.description ?? ''
    }
  })
  setSelectedLinks(updatedList)
}

export const getErrorHeaderForChildlinkManager = (message: string | undefined): string => {
  switch (message) {
    case "Didn't recognize that link":
      return 'Incorrect link'
    case 'This field is required':
    case 'URL is required':
    case 'required':
      return 'Missing info'
    case 'maxLength':
    default:
      return 'Invalid value'
  }
}

export const getSocialInputPlaceholderText = (
  socialLinkType: SocialAdvLinkType | undefined
): string => {
  if (socialLinkType?.isUsernameLink) return `Enter ${socialLinkType?.displayText || ''} username`
  if (socialLinkType?.provider && musicLinks.includes(socialLinkType?.provider))
    return `Enter ${socialLinkType?.displayText || ''} URL for a song, album or artist profile`
  return `Enter ${socialLinkType?.displayText || ''} URL`
}
