import { DESCRIPTION, DISPLAY_TYPE, EMBED, IMAGE, TITLE } from './constants'
import { DESCRIPTION_MAX, TITLE_MAX, TITLE_MAX_ERROR } from '../validation'
import { DestinationLinkFormFields, DestinationLinkFormState, LinkFormFields } from './types'
import { FEATURED_LINKS, FeatureLinkVariants } from '../../../constants'
import { FormProps } from '../components/Layout'
import {
  LinkMetaType,
  LinkProvider,
  genericLinkMeta
} from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { addProtocol } from '../../profile/LinkForm/utils'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import {
  clearImageEditModalState,
  setPreviewLink
} from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { fetchOgInfoFromUrl } from '../../../utils/fetchUrlOgInfo'
import { getValidatedActionData } from '../typeUtils'
import { linkTypes } from '@dtx-company/flowcode-utils/src'
import { logoUrlForType } from '../../../utils/main'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { useShouldRestrictLinksByClient } from '@app/code/src/components/utils/useShouldRestrictLinksByClient'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'
import { validateLink } from '../utils'

export function useDestinationFormState({
  widgetObj: linkObj,
  order,
  curr,
  handleClose,
  defaultFields
}: FormProps): DestinationLinkFormState {
  const clientFilter = useShouldRestrictLinksByClient()

  const dispatch = useDispatch()
  const initialProvider = curr ? curr.provider : linkObj.provider
  const [imageSizeWarning, setImageSizeWarning] = useState(false)
  const [isFetchingOgInfo, setIsFetchingOgInfo] = useState(false)

  // Ref to keep track of whether fetching og info has been cancelled and
  // avoid closure issues in fetchOgInfoFromLink
  const isCancelledFetchingOgInfoRef = useRef(false)
  const setIsCancelledFetchingOgInfo = (val: boolean): void => {
    isCancelledFetchingOgInfoRef.current = val
  }

  const defaultTitle: string = defaultFields?.title || ''
  const defaultLink: string = defaultFields?.link || ''
  let initialMeta = defaultFields?.meta || linkTypes.find(item => item.provider === initialProvider)

  if (curr?.provider === LinkProvider.FEATURE && curr.type) {
    initialMeta = FEATURED_LINKS[curr.type as FeatureLinkVariants]?.link?.defaultFields?.meta
  }
  const [meta, setMeta] = useState<LinkMetaType>(initialMeta || genericLinkMeta)
  const linkTitle = capitalize(meta.provider) || linkObj?.displayText || linkObj?.provider
  const id = curr ? curr.id : uuid()
  const actionData = getValidatedActionData<'destination'>(curr?.actionData, 'link')
  const defaultValues: DestinationLinkFormFields = {
    title: (curr ? curr.title : defaultTitle) as string,
    description: curr?.description || '',
    link: (curr ? actionData?.link?.replace(/http(s)?:\/\//, '') : defaultLink) as string,
    displayType: curr?.displayType || (curr?.thumbNailImgUrl ? 'default' : 'noImage'),
    image: curr ? curr?.thumbNailImgUrl : defaultFields?.imageUrl,
    embed: curr ? curr.embed : false
  }

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
    watch,
    trigger,
    clearErrors
  } = useForm({
    defaultValues,
    mode: 'onBlur'
  })

  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })

  const setLinkThemeAndUpdatePreview = (linkThemeInput: Partial<LinkType['linkTheme']>): void => {
    setLinkTheme(linkThemeInput)
    updatePreview()
  }

  const submitLink = useSubmitWidget()
  const onSubmit = async ({
    title,
    description,
    link,
    displayType,
    image,
    embed
  }: LinkFormFields): Promise<void> => {
    const actionData = {
      link: addProtocol(link),
      ...(embed ? { provider: curr ? curr.provider : linkObj.provider } : {})
    }
    const { pattern: _, embeddable: __, ...metaToAdd } = meta
    await submitLink({
      image,
      curr,
      actionData,
      widgetType: 'destination',
      handleClose,
      fields: {
        id,
        description,
        order,
        embed: Boolean(embed),
        ...metaToAdd,
        title,
        displayType: displayType || 'default'
      },
      linkTheme
    })
  }

  const watchAll = watch()

  const validateTitle = useCallback(
    (value: string | null): boolean | string => {
      const values = getValues()
      if (values.embed) {
        return true
      }
      if (!value || value?.length <= 0) {
        return 'Title is Required'
      }
      if (value?.length > TITLE_MAX) {
        return TITLE_MAX_ERROR
      }
      return true
    },
    [getValues]
  )

  useEffect(() => {
    register(IMAGE)
    register(DISPLAY_TYPE)
    register(EMBED)
  }, [register, setMeta, clientFilter, getValues])

  const disabled = watchAll.embed
    ? watchAll.link?.length === 0
    : watchAll.title?.length === 0 || watchAll.link?.length === 0

  const updatePreview = useDebouncedCallback(() => {
    const { image: _0, ...rest } = watchAll
    dispatch(
      setPreviewLink({
        previewLink: {
          ...rest,
          title: watchAll.title || 'Enter a title',
          description: watchAll.description || 'Enter a description',
          id,
          active: true,
          thumbNailImgUrl: watchAll.image || logoUrlForType(meta.provider),
          displayType: watchAll.displayType,
          provider: meta.provider,
          type: meta?.type || null,
          actionData: {
            link: watchAll.link,
            provider: meta.provider
          },
          order,
          ...(linkTheme && {
            linkTheme: {
              ...linkTheme,
              active: linkTheme.active
            }
          }),
          __typename: 'Link'
        }
      })
    )
  }, 500)

  const setPreviewAndFormValue: DestinationLinkFormState['setPreviewAndFormValue'] = (
    name,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    options
  ): void => {
    setValue(name, value, options)
    updatePreview()
  }

  const fetchOgInfoFromLink = async (url: string): Promise<void> => {
    setIsCancelledFetchingOgInfo(false)
    clearErrors()
    const userEntered = {
      title: Boolean(watchAll.title),
      description: Boolean(watchAll.description),
      image: Boolean(watchAll.image)
    }

    if (validateLink(url) === true) {
      try {
        setIsFetchingOgInfo(true)
        const { title, description, imageUrl } = await fetchOgInfoFromUrl(url)
        // Currently graphql-request doesn't support proper cancelling of requests
        // Might get introduced soon, see: https://github.com/prisma-labs/graphql-request/pull/303
        // For now we just ignore the response if the user cancelled it (possibly
        // because it was taking too long)
        if (isCancelledFetchingOgInfoRef.current === true) return
        if (!userEntered.title) {
          setPreviewAndFormValue(
            TITLE,
            title.length > TITLE_MAX ? title.slice(0, TITLE_MAX - 1) + '…' : title
          )
        }
        if (!userEntered.description) {
          setPreviewAndFormValue(
            DESCRIPTION,
            description.length > DESCRIPTION_MAX
              ? description.slice(0, DESCRIPTION_MAX - 1) + '…'
              : description
          )
        }
        if (!userEntered.image && imageUrl) {
          setPreviewAndFormValue(IMAGE, imageUrl)
          setPreviewAndFormValue(DISPLAY_TYPE, 'default')
        }
      } finally {
        setIsFetchingOgInfo(false)
      }
    }
  }

  const cancelFetchOgInfoFromLink = (): void => {
    setIsFetchingOgInfo(false)
    setIsCancelledFetchingOgInfo(true)
  }

  const clearOGInfo = (): void => {
    setPreviewAndFormValue(TITLE, '', { shouldValidate: false })
    setPreviewAndFormValue(DESCRIPTION, '')
    setPreviewAndFormValue(IMAGE, undefined)
    dispatch(clearImageEditModalState())
  }

  return {
    disabled,
    watchAll,
    setPreviewAndFormValue,
    setValue,
    embed: null,
    errors,
    onSubmit: handleSubmit(onSubmit),
    clearOGInfo,
    id,
    trigger,
    imageSizeWarning,
    setImageSizeWarning,
    fetchOgInfoFromLink,
    cancelFetchOgInfoFromLink,
    isFetchingOgInfo,
    meta,
    validateTitle,
    register,
    setMeta,
    linkTitle,
    linkTheme,
    setLinkTheme: setLinkThemeAndUpdatePreview
  }
}
