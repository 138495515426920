import { MaskType } from '../../../../src/types/organisms/imageedit.types'

export const MaskObj = {
  circle: {
    name: MaskType.CIRCLE,
    borderRadius: 300,
    width: undefined,
    height: undefined
  },
  square: {
    name: MaskType.SQUARE,
    borderRadius: 20,
    width: undefined,
    height: undefined
  },
  featured: {
    name: MaskType.FEATURED,
    borderRadius: 0,
    width: 400,
    height: 160
  },
  rectangle: {
    name: MaskType.RECTANGLE,
    borderRadius: 0,
    width: 400,
    height: 225
  },
  iphonex: {
    name: MaskType.IPHONEX,
    borderRadius: 0,
    width: 200,
    height: 400
  },
  noImage: {
    name: MaskType.NO_IMAGE,
    borderRadius: 0,
    width: 0,
    height: 0
  }
}
