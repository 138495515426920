import { BoxProps } from '@dtx-company/shared-components/src'
import { LinkShape, LinkStyle, Theme as PageStyle } from '../../../../constants/customizeTheme'
import {
  LinkType,
  PageType,
  UnauthPageQueryTheme
} from '@dtx-company/flow-codegen/src/page/typeUtils'
import { defaultUserTheme } from '@dtx-company/true-common/src/constants/page'
import { getLinkShape } from './getLinkShape'
import { getLinkStyle } from './getLinkStyle'
/**
 * Consider text having poor contrast if there is no background image,
 * the primaryColor is black, the link style is "outlined" and the style of
 * the page is "color" or "dark"
 */
export const isBlackTextOnBlackBg = (
  backgroundImgUrl?: string | null,
  primaryColor?: string,
  linkStyle?: string | null,
  style?: string | null
): boolean => {
  return (
    !backgroundImgUrl &&
    primaryColor === '#000000' &&
    linkStyle === LinkStyle.OUTLINED &&
    (style === PageStyle.color || style === PageStyle.dark)
  )
}

interface GetLinkCardStylesArgs {
  theme?: PageType['theme'] | UnauthPageQueryTheme | null
  linkTheme: LinkType['linkTheme'] | null
  /* Featured links are links that always have a Black background and White text. Used for BLM and AAPI specific links */
  isFeaturedLink?: boolean
}

export type LinkCardStyles = Omit<BoxProps, 'children' | 'onClick'>

export function getLinkCardStyles({
  theme,
  linkTheme,
  isFeaturedLink
}: GetLinkCardStylesArgs): LinkCardStyles {
  const { linkStyle, linkShape, backgroundImgUrl, style } = theme ?? defaultUserTheme
  const primaryColor = theme?.primaryColor ?? undefined
  const blackTextOnBlackBg = isBlackTextOnBlackBg(backgroundImgUrl, primaryColor, linkStyle, style)
  return {
    ...getLinkStyle({
      style: linkStyle as LinkStyle,
      primaryColor,
      isBlackTextOnBlackBg: blackTextOnBlackBg,
      linkTheme,
      pageTheme: theme,
      isFeaturedLink
    }),
    ...getLinkShape(linkShape as LinkShape)
  }
}
