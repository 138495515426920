import { BioEditorState, TemplateConfigFields } from './BioEditor.types'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { editUserInfo } from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { parseTemplateFields } from '../../../../../../../FlowpageTemplates/utils/parseTemplateFields'
import { theme } from '@dtx-company/shared-components/src'
import { toggleTemplateLockedField } from '../../../../../../../FlowpageTemplates/utils/toggleTemplateLockedField'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch } from 'react-redux'
import { useEditPageInfoMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/pageMutations'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'
import flowpageEvents from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import flowpageTemplateEvents from '@dtx-company/inter-app/src/event-tracking/events/flowpageTemplates'

export function useBioEditorState({ page }: { page?: PageType | null }): BioEditorState {
  const dispatch = useDispatch()
  const [displayName, setDisplayName] = useState('')
  const [caption, setCaption] = useState('')
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const [editPageInfoMutation] = useEditPageInfoMutation()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const templateFields = parseTemplateFields(page?.templateConfig)
  const toggleFieldLock = async (field: TemplateConfigFields): Promise<void> => {
    const newFields = toggleTemplateLockedField({
      existingTemplateConfig: page?.templateConfig,
      fieldToUpdate: field
    })
    flowpageTemplateEvents.Flowpage_TemplateEditor_Clicked_ToggleLockedField(field)
    await editPageInfoMutation({
      isTemplateEditor: true,
      editPage: {
        id: page?.id || '',
        templateConfig: {
          ...page?.templateConfig,
          order: page?.templateConfig?.order ?? 0,
          fields: newFields
        }
      }
    })
  }
  const onDisplayNameBlur = useCallback(async (): Promise<void> => {
    shouldEditPageInRedux
      ? dispatch(
          editUserInfo({
            title: displayName ?? '',
            description: caption ?? ''
          })
        )
      : await editPageInfoMutation({
          isTemplateEditor,
          editPage: {
            id: page?.id ?? '',
            displayName,
            caption
          }
        })
  }, [
    caption,
    dispatch,
    displayName,
    editPageInfoMutation,
    isTemplateEditor,
    page?.id,
    shouldEditPageInRedux
  ])

  const onCaptionChange = useCallback(async (): Promise<void> => {
    shouldEditPageInRedux
      ? dispatch(
          editUserInfo({
            title: displayName ?? '',
            description: caption ?? ''
          })
        )
      : await editPageInfoMutation({
          isTemplateEditor,
          editPage: {
            id: page?.id ?? '',
            displayName,
            caption
          }
        })
  }, [
    caption,
    dispatch,
    displayName,
    editPageInfoMutation,
    isTemplateEditor,
    page?.id,
    shouldEditPageInRedux
  ])

  const fireTypedNameEvent = useDebouncedCallback(() => {
    flowpageEvents.Flowpage_PageProfile_Typed_Name()
  }, 500)

  const fireTypedBioEvent = useDebouncedCallback(() => {
    flowpageEvents.Flowpage_PageProfile_Typed_Bio()
  }, 500)

  const displayNameBorder = page?.displayName
    ? `1px dotted ${theme.colors.secondary.disabled}`
    : `1px dotted ${theme.colors.primary.flowBlue}`

  const captionBorder = page?.caption
    ? `1px dotted ${theme.colors.secondary.disabled}`
    : `1px dotted ${theme.colors.primary.flowBlue}`

  useEffect(() => {
    setCaption(page?.caption ?? '')
    setDisplayName(page?.displayName ?? '')
  }, [page?.caption, page?.displayName])

  return {
    onCaptionChange,
    onDisplayNameBlur,
    setDisplayName: name => {
      setDisplayName(name)
      fireTypedNameEvent()
    },
    displayName,
    setCaption: caption => {
      setCaption(caption)
      fireTypedBioEvent()
    },
    caption,
    displayNameBorder,
    captionBorder,
    toggleFieldLock,
    titleLocked: Boolean(templateFields?.title?.locked),
    descriptionLocked: Boolean(templateFields?.description?.locked),
    profileImageLocked: Boolean(templateFields?.profileImage?.locked)
  }
}
