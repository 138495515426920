import { AddWidgetType } from '../../../../../../components/widgets/types'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC, useEffect, useMemo, useRef } from 'react'
import { RootPageState } from '@dtx-company/inter-app/src/redux/types'
import { WIDGETS } from '../../../../../../components/widgets/manifest'
import { usePageSelector } from '@dtx-company/inter-app/src/hooks/usePageSelector'

export const WidgetDrawerForm: FC<{
  order: number
  handleClose: () => void
}> = ({ order, handleClose }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { drawerContentMeta, previewLink } = usePageSelector(
    (state: RootPageState) => state.linkEditReducer
  )
  // TODO: resolve this rule of hooks error - issue tracked in https://app.shortcut.com/flowcode/story/41455/resolve-rule-of-hooks-error-that-were-commented-out
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const curr = useMemo(() => previewLink, [])
  const widgetType = (drawerContentMeta?.widgetObj as AddWidgetType)?.type
  useEffect(() => {
    ref?.current?.scrollIntoView()
  }, [])
  if (!widgetType) return <></>
  const Form = WIDGETS[widgetType]?.Form
  return (
    <Box width="100%" height="100%" borderRadius="12px" maxWidth={[null, null, '600px']}>
      <Box ref={ref} width="100%" padding="16px">
        <Form
          widgetObj={drawerContentMeta?.widgetObj as AddWidgetType}
          order={curr ? curr.order : order}
          handleClose={handleClose}
          curr={curr || undefined}
        />
      </Box>
    </Box>
  )
}
