import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'

export function useShowResetCustomizationOptions({ page }: { page?: PageType | null }): boolean {
  const { isAuthenticated } = useAuthState()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const showResetCustomizeOptions =
    isAuthenticated && !isTemplateEditor && Boolean(page?.baseTemplateId)

  return showResetCustomizeOptions
}
