import { FC } from 'react'
import { FormFields } from '../types'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { StyledToggleButton } from './EmbedToggle'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { ToggleButtonList } from '@dtx-company/shared-components/src'
import { UseFormMethods } from 'react-hook-form-deprecated'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export const ImageDisplayToggle: FC<{
  setValue: UseFormMethods['setValue']
  displayType: string
  fetching: boolean
}> = ({ setValue, displayType, fetching }) => {
  return (
    <>
      <Text>Image Display Type</Text>
      <ToggleButtonList
        selectedValue={displayType}
        onChange={(_e, type) => {
          setValue(FormFields.DISPLAY_TYPE, type)
          events.Flowpage_PageProfile_Clicked_Image_Display_Type()
        }}
        justifyContent="flex-start"
        background="transparent !important"
      >
        <StyledToggleButton
          value="noImage"
          disabled={fetching}
          border="1px solid #dadcdd"
          flexDirection="column"
          justifyContent="space-around"
          height="100px"
        >
          <Image src="/static/icons/LinkOption-NoImage.svg" />
          <Text variant="body/small">No image</Text>
        </StyledToggleButton>
        <StyledToggleButton
          value="default"
          border="1px solid  #dadcdd"
          flexDirection="column"
          justifyContent="space-around"
          mr="16px"
          disabled={fetching}
          ml="16px"
        >
          <Image src="/static/icons/LinkOption-Thumbnail.svg" />
          <Text variant="body/small">Icon</Text>
        </StyledToggleButton>
        <StyledToggleButton
          value="featured"
          border="1px solid  #dadcdd"
          flexDirection="column"
          justifyContent="space-around"
          disabled={fetching}
        >
          <Image src="/static/icons/LinkOption-Featured.svg" />
          <Text variant="body/small">Featured</Text>
        </StyledToggleButton>
      </ToggleButtonList>
    </>
  )
}
