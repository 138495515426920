import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'

export const LinkStyleToggle: FC<{
  checked: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
}> = ({ checked, onChange, disabled }) => {
  return (
    <Box justifyContent="space-between" width="100%">
      <Box flexDirection="column">
        <Text
          as="h3"
          variant="body/medium"
          id="advanced-customization"
          color={disabled ? 'secondary.disabled' : 'primary.black'}
        >
          Advanced customization
        </Text>
        <Text
          variant="general/captionAndLabel"
          color={disabled ? 'secondary.disabled' : 'primary.grey'}
          id="customization-helper-text"
        >
          Personalize your link color and font style
        </Text>
      </Box>
      <Toggle
        checked={checked}
        onChange={e => onChange(e.target.checked)}
        backgroundColor="secondary.border"
        ariaLabelledBy="advanced-customization"
        toggleInputProps={{ 'aria-describedby': 'customization-helper-text' }}
        disabled={disabled}
      />
    </Box>
  )
}
