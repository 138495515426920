import { SVGProps, memo } from 'react'

function FeaturedLinkStyleSvg({
  stroke = '5B5E63',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="0.75"
        y="5.75"
        width="30.5"
        height="20.5"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <rect
        x="0.75"
        y="5.75"
        width="30.5"
        height="11.5"
        rx="0.85"
        stroke={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M6 21.25C5.58579 21.25 5.25 21.5858 5.25 22C5.25 22.4142 5.58579 22.75 6 22.75V21.25ZM25.8 22.75C26.2143 22.75 26.55 22.4142 26.55 22C26.55 21.5858 26.2143 21.25 25.8 21.25V22.75ZM6 22.75H25.8V21.25H6V22.75Z"
        fill={stroke}
      />
    </svg>
  )
}

export const FeaturedLinkStyle = memo(FeaturedLinkStyleSvg)
