import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Dispatch } from '@reduxjs/toolkit'
import { FC, useState } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { LinkType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import {
  PrimaryButton,
  SecondaryButton
} from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { clearPreviewLink } from '@dtx-company/inter-app/src/redux/slices/linkEditor/linkEditorSlice'
import { deleteMailchimp } from '@dtx-company/inter-app/src/redux/slices/pageEditor/mailchimpEditing'
import {
  setClientHasMailchimpKey,
  toggleLinkActive
} from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { trashCanRed } from '@app/code/src/assets'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useDeleteLinkWithAutosave } from '../deleteUtils'
import { useDispatch } from 'react-redux'
import { useEditLinkMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/linkMutations'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled, { css } from 'styled-components'

interface SettingsProps {
  curr?: LinkType
  singleCol?: boolean
  handleClose(): void
  disabled?: boolean
  deleteAction?: () => void
  linkTypeText?: string
  initialSaveActionText?: string
}

export const SettingsWrapper = styled(Box)`
  @media (max-width: 1024px) {
    position: fixed;
    bottom: 10px;
    width: 90%;
    z-index: 1000;
  }

  ${({ singleCol }: { singleCol: boolean }) =>
    !singleCol &&
    css`
      @media (min-width: 1024px) {
        position: absolute;
        bottom: 20px;
        left: calc(50% + 12px);
        width: calc(50% - 50px);
      }
    `}
`
const CONTACT_COLLECTION_FORM_TYPE = 'contactCollection'

export const deleteMailchimpIfEnabled = async (
  curr: LinkType | undefined,
  dispatch: Dispatch<any>,
  pageId: string | null
): Promise<void> => {
  if (
    curr?.type === CONTACT_COLLECTION_FORM_TYPE &&
    (curr?.actionData?.mailChimp?.url ?? '').length > 0
  ) {
    await deleteMailchimp(pageId ?? '')
    dispatch(setClientHasMailchimpKey({ apiKey: '' }))
  }
}

export const SettingsAndSaveLink: FC<SettingsProps> = ({
  curr,
  handleClose,
  disabled = false,
  linkTypeText = 'Link',
  initialSaveActionText = 'Save'
}: SettingsProps) => {
  const [isActive, setIsActive] = useState<boolean | undefined>(curr?.active)
  const dispatch = useDispatch()
  const page = useCurrentPage()
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const deleteLink = useDeleteLinkWithAutosave()
  const [editLinkMutation] = useEditLinkMutation()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const saveButtonLabel = curr ? `Save ${linkTypeText}` : `${initialSaveActionText} ${linkTypeText}`
  const isExistingLink = curr && page?.links?.some(link => link.id === curr.id)
  return (
    <Box flexDirection="column" width="100%">
      {isExistingLink && (
        <>
          <Box width="100%" justifyContent="space-between">
            <SecondaryButton
              type="button"
              width="calc(50% - 4px)"
              onClick={() => {
                const {
                  __typename,
                  thumbNailImgUrl: _image,
                  childLinks: _childLinks,
                  position: _position,
                  ...rest
                } = curr
                try {
                  shouldEditPageInRedux
                    ? dispatch(
                        toggleLinkActive({
                          linkId: curr.id
                        })
                      )
                    : editLinkMutation({
                        linkId: curr.id,
                        linkInput: {
                          ...rest,
                          active: !curr.active
                        },
                        deleteImage: false,
                        isTemplateEditor
                      })

                  if (isActive) {
                    curr.provider === LinkProvider.WIDGET
                      ? events.userDeactivatedWidget({ link_id: curr.id })
                      : events.userDeactivatedLink({ link_id: curr.id })
                  }
                  setIsActive(!isActive)

                  handleClose()
                } catch (e) {
                  console.error('error toggling active link', e)
                }
              }}
              label={isActive ? 'Deactivate' : 'Activate'}
            />

            <SecondaryButton
              type="button"
              borderColor={'status.errorDark'}
              onClick={async () => {
                try {
                  deleteLink({
                    linkId: curr.id,
                    linkOrder: curr.order
                  })
                  if (!shouldEditPageInRedux) {
                    await deleteMailchimpIfEnabled(curr, dispatch, page?.id ?? '')
                  }
                  curr.provider === LinkProvider.WIDGET
                    ? events.userDeletedWidget({ link_id: curr.id })
                    : events.userDeletedLink({ link_id: curr.id })

                  handleClose()
                } catch (err) {
                  console.error(err)
                }
              }}
              width="calc(50% - 4px)"
            >
              <Image src={trashCanRed} />
              <Spacer mr="8px" />
              <Text variant="button/general" color="status.errorDark" data-testid="delete-link-btn">
                {`Delete ${linkTypeText}`}
              </Text>
            </SecondaryButton>
          </Box>
          <Spacer mb="8px" />
        </>
      )}
      <Box width="100%" justifyContent="space-between">
        {!isExistingLink && (
          <SecondaryButton
            borderColor={'status.errorDark'}
            type="button"
            onClick={async () => {
              try {
                dispatch(clearPreviewLink({}))
                handleClose()
              } catch (err) {
                console.error(err)
              }
            }}
            width="calc(50% - 4px)"
          >
            <Image src={trashCanRed} />
            <Spacer mr="8px" />
            <Text variant="button/general" color="status.errorDark" data-testid="delete-link-btn">
              {`Delete ${linkTypeText}`}
            </Text>
          </SecondaryButton>
        )}
        <PrimaryButton
          disabled={disabled}
          data-test="link-form-submit"
          type="submit"
          label={saveButtonLabel}
          width={isExistingLink ? '100%' : 'calc(50% - 4px)'}
        />
      </Box>
    </Box>
  )
}
