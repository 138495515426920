import { LinkType } from '../../types'
import { SocialLinkFields } from '../../Destination/SocialLinkFields.types'
import { emptySocialLinkFields } from '../types'

//returns initial form state, either from actionData or childLinks
//moved out of helper functions for testing purposes
export const getInitialFormValueForProvider = (
  provider: string,
  curr: LinkType | undefined
): SocialLinkFields => {
  const formattedChildlink = (curr?.childLinks ?? []).find(child => child.provider === provider)
  if (formattedChildlink) {
    const { actionData, ...resolvedChildlink } = {
      ...formattedChildlink,
      link: formattedChildlink?.actionData?.link
    }
    return resolvedChildlink as SocialLinkFields
  }
  if (curr) {
    const currLink = curr.actionData?.[provider]
    if (currLink?.link) {
      return {
        title: currLink.title,
        description: currLink.description,
        thumbNailImgUrl: currLink.thumbNailImgUrl,
        order: currLink.order,
        link: currLink.link
      }
    }
  }
  return emptySocialLinkFields
}
