import { useIsMobile } from '@app/page/src/utils/main'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { usePageTemplateQuery } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/templateQueries'

export function useShowTemplateLocks(): { showTemplateLocks: boolean } {
  const { isTemplateEditor, templateId } = usePageTemplateEditorState()
  const { data } = usePageTemplateQuery(
    { id: templateId ?? '' },
    { skip: !isTemplateEditor || !templateId }
  )
  const isMobile = useIsMobile()
  return {
    showTemplateLocks: !isMobile && isTemplateEditor && Boolean(data?.template?.isInheritable)
  }
}
