import { GoogleFontCategory } from './types'

const BASE_FONT_REQUEST_URL = 'https://fonts.googleapis.com/css2?family='
const FALLBACK_SUFFIX = '&display=swap'
const TARGETED_CHAR_DOWNLOAD = '&text='

export function getGoogleFontStyleUrlFor({
  fontFamily,
  withFallback,
  onlyDownloadTitle
}: {
  fontFamily: string
  withFallback?: boolean
  onlyDownloadTitle?: boolean
}): string {
  return `${BASE_FONT_REQUEST_URL}${fontFamily.replace(' ', '+')}${
    onlyDownloadTitle ? `${TARGETED_CHAR_DOWNLOAD}${fontFamily}` : ''
  }${withFallback ? FALLBACK_SUFFIX : ''}`
}

export const imageUrlForFontCategory = (category: GoogleFontCategory): string =>
  `/icons/fonts/${category}.svg`
