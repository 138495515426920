import { TemplateLockProps, UseTemplateLinkLockState } from './types'
import { useCurrentPageId } from '@dtx-company/inter-app/src/hooks/useCurrentPageId'
import { useEditLinkMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/linkMutations'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpageTemplates'

export function useTemplateLinkLock({ link }: TemplateLockProps): UseTemplateLinkLockState {
  const locked = Boolean(link.templateConfig?.lockAll)
  const [editLink] = useEditLinkMutation()
  const pageId = useCurrentPageId()
  const toggleLocked = async (): Promise<void> => {
    const { thumbNailImgUrl: ____, __typename: _, linkTheme: __, ...rest } = link
    events.Flowpage_TemplateEditor_Clicked_ToggleLockedField(link.provider + link.type)
    await editLink({
      isTemplateEditor: true,
      deleteImage: false,
      linkId: link.id,
      linkInput: {
        ...rest,
        templateConfig: {
          ...rest.templateConfig,
          order: rest.templateConfig?.order ?? 0,
          lockAll: !locked
        }
      }
    })
  }

  return {
    locked,
    toggleLocked
  }
}
