import { BoxProps } from '@dtx-company/shared-components/src'
import { DEFAULT_COLOR } from '@dtx-company/true-common/src/constants/page'
import { LinkStyle } from '../../../../constants/customizeTheme'
import { LinkType, PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { getLinkLevelStyles } from './getLinkLevelStyles'
import { getLinkStylesForFeaturedLink } from './getLinkStylesForFeaturedLink'

interface GetLinkStyleArgs {
  style: LinkStyle
  primaryColor?: string
  isBlackTextOnBlackBg: boolean
  linkTheme?: LinkType['linkTheme'] | null
  pageTheme?: PageType['theme'] | null
  /* Featured links are links that always have a Black background and White text. Used for BLM and AAPI specific links */
  isFeaturedLink?: boolean
}

export function getLinkStyle({
  style,
  primaryColor = DEFAULT_COLOR,
  isBlackTextOnBlackBg,
  linkTheme,
  pageTheme,
  isFeaturedLink
}: GetLinkStyleArgs): Omit<BoxProps, 'children' | 'onClick'> {
  if (isFeaturedLink) {
    // Featured links always have a Black background and White text regardless of
    // the link "style". Used for BLM and AAPI specific links. These links are not
    // customizable and as such don't have a linkTheme atm.
    return getLinkStylesForFeaturedLink({ style })
  }

  return getLinkLevelStyles({ style, pageTheme, linkTheme, primaryColor, isBlackTextOnBlackBg })
}
