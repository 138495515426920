import { GET_LINK } from '@dtx-company/flow-codegen/src/page/link/GET_LINK'
import {
  GetLinkQuery,
  GetLinkQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'

/** Get a link by linkId */
export const getLink = async (linkId: string): Promise<GetLinkQuery['getLink']> => {
  const { getLink: link } = await pageGqlFetcher<GetLinkQuery, GetLinkQueryVariables>(GET_LINK, {
    linkId
  })
  return link
}
