import { SVGProps, memo } from 'react'

function AgeGateSvg({ stroke = '#111', color, ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M4.26001 8.90821C4.26001 7.52749 5.3793 6.4082 6.76001 6.4082H19.2863C20.6671 6.4082 21.7863 7.52749 21.7863 8.9082V22.2766C21.7863 22.5528 21.5625 22.7766 21.2863 22.7766H4.76001C4.48387 22.7766 4.26001 22.5528 4.26001 22.2766V8.90821Z"
        fill="#ADADAD"
        stroke="#ADADAD"
      />
      <path
        d="M9.5495 6.48714V4.27661C9.5495 2.61976 10.8926 1.27661 12.5495 1.27661H13.4969C15.1537 1.27661 16.4969 2.61976 16.4969 4.27661V6.48714"
        stroke="#5B5E63"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="path-3-outside-1"
        maskUnits="userSpaceOnUse"
        x="10.2864"
        y="10.6978"
        width="5"
        height="8"
        fill="black"
      >
        <rect fill="white" x="10.2864" y="10.6978" width="5" height="8" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8917 13.8586C14.4108 13.6584 14.7601 13.2842 14.7601 12.8556C14.7601 12.2162 13.9825 11.6978 13.0232 11.6978C12.064 11.6978 11.2864 12.2162 11.2864 12.8556C11.2864 13.2842 11.6357 13.6585 12.1548 13.8587V16.9871C12.1548 17.2632 12.3787 17.4871 12.6548 17.4871H13.3917C13.6678 17.4871 13.8917 17.2632 13.8917 16.9871V13.8586Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8917 13.8586C14.4108 13.6584 14.7601 13.2842 14.7601 12.8556C14.7601 12.2162 13.9825 11.6978 13.0232 11.6978C12.064 11.6978 11.2864 12.2162 11.2864 12.8556C11.2864 13.2842 11.6357 13.6585 12.1548 13.8587V16.9871C12.1548 17.2632 12.3787 17.4871 12.6548 17.4871H13.3917C13.6678 17.4871 13.8917 17.2632 13.8917 16.9871V13.8586Z"
        fill="#111111"
      />
      <path
        d="M13.8917 13.8586L13.7118 13.3921L13.3917 13.5156V13.8586H13.8917ZM12.1548 13.8587H12.6548V13.5156L12.3347 13.3921L12.1548 13.8587ZM14.2601 12.8556C14.2601 12.9874 14.1386 13.2275 13.7118 13.3921L14.0716 14.3251C14.683 14.0893 15.2601 13.581 15.2601 12.8556H14.2601ZM13.0232 12.1978C13.4179 12.1978 13.7526 12.3053 13.974 12.4529C14.1989 12.6029 14.2601 12.7551 14.2601 12.8556H15.2601C15.2601 12.3167 14.9324 11.89 14.5287 11.6209C14.1215 11.3494 13.5878 11.1978 13.0232 11.1978V12.1978ZM11.7864 12.8556C11.7864 12.7551 11.8475 12.6029 12.0724 12.4529C12.2939 12.3053 12.6285 12.1978 13.0232 12.1978V11.1978C12.4587 11.1978 11.9249 11.3494 11.5177 11.6209C11.114 11.89 10.7864 12.3167 10.7864 12.8556H11.7864ZM12.3347 13.3921C11.9079 13.2275 11.7864 12.9875 11.7864 12.8556H10.7864C10.7864 13.581 11.3634 14.0894 11.9749 14.3252L12.3347 13.3921ZM12.6548 16.9871V13.8587H11.6548V16.9871H12.6548ZM12.6548 16.9871H12.6548H11.6548C11.6548 17.5394 12.1025 17.9871 12.6548 17.9871V16.9871ZM13.3917 16.9871H12.6548V17.9871H13.3917V16.9871ZM13.3917 16.9871V16.9871V17.9871C13.944 17.9871 14.3917 17.5394 14.3917 16.9871H13.3917ZM13.3917 13.8586V16.9871H14.3917V13.8586H13.3917Z"
        fill="black"
        mask="url(#path-3-outside-1)"
      />
    </svg>
  )
}

export const AgeGate = memo(AgeGateSvg)
