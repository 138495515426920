import { SVGProps, memo } from 'react'

function MarketplaceSvg({ stroke = '#111', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" {...rest}>
      <path
        d="M3.08679 9.72473V21.1887"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9133 9.72473V21.1887"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44934 21.1887V16.0872C9.48907 15.439 9.7746 14.8303 10.2477 14.3853C10.7208 13.9403 11.3458 13.6925 11.9953 13.6925C12.6448 13.6925 13.2698 13.9403 13.7429 14.3853C14.216 14.8303 14.5015 15.439 14.5412 16.0872V21.1887"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9133 21.1887H14.5413"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.44932 21.1887H3.07727"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1728 9.72474C2.73897 9.74307 2.3078 9.64866 1.92133 9.45071C1.53486 9.25277 1.20628 8.95804 0.96765 8.59528C0.729016 8.23252 0.588469 7.8141 0.559715 7.38084C0.530961 6.94758 0.614982 6.51426 0.803584 6.12314L2.77156 2.24451C3.00442 1.80708 3.35302 1.44207 3.77927 1.18935C4.20552 0.936632 4.69304 0.805926 5.18855 0.811512H18.8115C19.3071 0.805926 19.7946 0.936632 20.2208 1.18935C20.6471 1.44207 20.9957 1.80708 21.2285 2.24451L23.1965 6.11359C23.3851 6.50471 23.4691 6.93802 23.4404 7.37129C23.4116 7.80455 23.2711 8.22296 23.0324 8.58572C22.7938 8.94849 22.4652 9.24321 22.0788 9.44116C21.6923 9.63911 21.2611 9.73352 20.8273 9.71518C20.1103 9.77102 19.4002 9.54296 18.8498 9.08013C18.2994 8.61731 17.9529 7.95678 17.8849 7.24088V7.19311C17.8263 7.91739 17.4845 8.58936 16.9337 9.06329C16.3828 9.53721 15.6674 9.77488 14.9425 9.72474C14.2175 9.77488 13.5021 9.53721 12.9513 9.06329C12.4004 8.58936 12.0587 7.91739 12 7.19311C11.9414 7.91739 11.5996 8.58936 11.0488 9.06329C10.498 9.53721 9.78254 9.77488 9.05763 9.72474C8.33272 9.77488 7.61726 9.53721 7.06644 9.06329C6.51561 8.58936 6.17383 7.91739 6.11522 7.19311V7.25043C6.04723 7.96633 5.70071 8.62686 5.15032 9.08968C4.59993 9.55251 3.88975 9.78057 3.1728 9.72474V9.72474Z"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Marketplace = memo(MarketplaceSvg)
