import { SVGProps, memo } from 'react'

function RefreshSvg({ fill = 'currentcolor', ...props }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5 0C10.1131 0 7.82387 0.948211 6.13604 2.63604C4.44821 4.32387 3.5 6.61305 3.5 9H0.5L4.39 12.89L4.46 13.03L8.5 9H5.5C5.5 7.14348 6.2375 5.36301 7.55025 4.05025C8.86301 2.7375 10.6435 2 12.5 2C14.3565 2 16.137 2.7375 17.4497 4.05025C18.7625 5.36301 19.5 7.14348 19.5 9C19.5 10.8565 18.7625 12.637 17.4497 13.9497C16.137 15.2625 14.3565 16 12.5 16C10.57 16 8.82 15.21 7.56 13.94L6.14 15.36C7.77 17 10 18 12.5 18C14.8869 18 17.1761 17.0518 18.864 15.364C20.5518 13.6761 21.5 11.3869 21.5 9C21.5 6.61305 20.5518 4.32387 18.864 2.63604C17.1761 0.948211 14.8869 0 12.5 0Z"
        fill={fill}
      />
    </svg>
  )
}

export const Refresh = memo(RefreshSvg)
