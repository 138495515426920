import { capitalize } from '@dtx-company/true-common/src/utils/strings'

export function capitalizeAll(s: string): string {
  const strArr = s.split(' ')
  return strArr.map(word => capitalize(word)).join(' ')
}

export function capitalizeDestination(s: string): string {
  if (typeof s !== 'string') return ''
  const lowerS = s?.toLowerCase()
  switch (lowerS) {
    case 'gofundme':
      return 'GoFundMe'
    case 'ebay':
      return 'eBay'
    case 'youtube':
      return 'YouTube'
    case 'linkedin':
      return 'LinkedIn'
    default:
      return capitalizeAll(s.replace('-', ' '))
  }
}
