import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import {
  CategoryFilterProps,
  GoogleFont,
  GoogleFontCategory,
  GoogleFontCategoryList,
  GoogleFontsSortBy
} from './types'
import { FC, useMemo, useState } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { arrowDown, arrowUp, search } from '@app/code/src/assets'
import { fireHeapEvent } from '@dtx-company/inter-app/src/event-tracking/helpers'
import { getGoogleFontStyleUrlFor, imageUrlForFontCategory } from './utils'
import { theme } from '@dtx-company/shared-components/src'
import { useDebouncedCallback } from 'use-debounce'
import { useGoogleFontsList } from './useGoogleFontsList'
import styled from 'styled-components'

export const GoogleFontsPicker: FC<{
  currFont: string
  handleSelect: (fontOption: string) => void
  defaultOpen?: boolean
  disabled?: boolean
}> = ({ currFont, handleSelect, defaultOpen, disabled }) => {
  const FONTS_PAGINATION_NUMBER = 50
  const { fonts } = useGoogleFontsList(GoogleFontsSortBy.ALPHA)
  const [dropdownOpen, setDropdownOpen] = useState(defaultOpen ?? false)
  const [searchQuery, setSearchQuery] = useState('')
  const [maxNumFonts, setMaxNumFonts] = useState(FONTS_PAGINATION_NUMBER)
  const [categoryFilter, setCategoryFilter] = useState<GoogleFontCategory | undefined>(undefined)

  const filteredFontList: GoogleFont[] = useMemo(() => {
    return fonts?.filter(option => {
      return (
        (categoryFilter ? option.category === categoryFilter : true) &&
        (searchQuery.length > 0
          ? option.family.toLowerCase().includes(searchQuery.toLowerCase())
          : true)
      )
    })
  }, [fonts, categoryFilter, searchQuery])

  const handleSelection = (selection: GoogleFont): void => {
    handleSelect(selection.family)
  }

  const handleSearch = useDebouncedCallback(async (value: string): Promise<void> => {
    setMaxNumFonts(FONTS_PAGINATION_NUMBER)
    setSearchQuery(value)
    fireHeapEvent('Flowpage_PageProfile_Typed_FontInputDropdown', { text: value })
  }, 300)

  return (
    <Box flexDirection="column" width="100%">
      <Text variant="input/label">Customize fonts to your brand style</Text>
      <Spacer mb="8px" />
      <DropdownMenuHeader
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            setDropdownOpen(!dropdownOpen)
            setCategoryFilter(undefined)
            setSearchQuery('')
            fireHeapEvent('Flowpage_PageProfile_Clicked_FontInputDropdown')
          }
        }}
      >
        <Box
          justifyContent="space-between"
          width="100%"
          flexShrink={0}
          px="15px"
          data-testid="font-dropdown-menu-header"
        >
          <link
            rel="stylesheet"
            href={getGoogleFontStyleUrlFor({ fontFamily: currFont, onlyDownloadTitle: true })}
          />
          <Text
            fontFamily={currFont}
            variant="input/field"
            color={disabled ? theme.colors.secondary.disabled : theme.colors.primary.black}
          >
            {currFont === 'Inter' ? 'Inter (default)' : currFont}
          </Text>
          <Icon width="10px" alt="dropdown arrow" src={dropdownOpen ? arrowUp : arrowDown} />
        </Box>
      </DropdownMenuHeader>
      <Spacer mb="8px" />
      {dropdownOpen && (
        <Box flexDirection="column">
          <SearchInput
            type="text"
            placeholder="Search fonts..."
            onChange={e => handleSearch(e.target.value)}
            startAdornment={<Icon src={search} zIndex={5} />}
          />
          <Box
            maxHeight="220px"
            width="100%"
            flexDirection="column"
            overflowY="scroll"
            border={`1px solid ${theme.colors.secondary.border}`}
            borderRadius="8px"
          >
            {filteredFontList.slice(0, maxNumFonts).map(option => (
              <MenuItemComponent
                key={`${option.family}-option`}
                option={option}
                isSelected={option.family === currFont}
                handleSelect={handleSelection}
              />
            ))}
            {filteredFontList.length > maxNumFonts && (
              <DisplayMoreFontsButton
                onClick={() => setMaxNumFonts(maxNumFonts + FONTS_PAGINATION_NUMBER)}
              >
                Display more fonts...
              </DisplayMoreFontsButton>
            )}
            {filteredFontList.length === 0 && (
              <Text flexShrink={0} textAlign="center" lineHeight="40px">
                {categoryFilter ? `No fonts found in category` : 'No fonts found'}
              </Text>
            )}
          </Box>
        </Box>
      )}
      {dropdownOpen && (
        <>
          <Spacer mb="24px" />
          <CategoryFilter
            filteredFontCount={filteredFontList.length}
            totalFontCount={fonts.length}
            selectedFilter={categoryFilter}
            setFilter={setCategoryFilter}
          />
        </>
      )}
    </Box>
  )
}

const MenuItemComponent: FC<{
  option: GoogleFont
  isSelected: boolean
  handleSelect: (option: GoogleFont) => void
}> = ({ option, isSelected, handleSelect }) => {
  return (
    <Box
      pl="8px"
      flexShrink={0}
      borderRadius="4px"
      fontFamily={option.family}
      alignItems="center"
      height="44px"
      borderStyle="solid"
      cursor="pointer"
      onClick={() => {
        handleSelect(option)
      }}
      backgroundColor={theme.colors.primary.white}
      borderWidth={`1px 1px 1px ${isSelected ? '8px' : '1px'}`}
      borderColor={`${theme.colors.secondary.border} ${theme.colors.secondary.border} ${
        theme.colors.secondary.border
      } ${isSelected ? '#62AAFF' : theme.colors.secondary.border}`}
    >
      {/* Only downloads the characters neccesary to render Font-family name */}
      <link
        rel="stylesheet"
        href={getGoogleFontStyleUrlFor({ fontFamily: option.family, onlyDownloadTitle: true })}
      />
      {option.family}
    </Box>
  )
}

const CategoryFilter: FC<CategoryFilterProps> = ({
  filteredFontCount,
  totalFontCount,
  selectedFilter,
  setFilter
}) => {
  return (
    <Box flexDirection="column" width="100%" px="1px">
      <Box justifyContent="space-between" width="100%" alignItems="center">
        <Text fontSize="16px">Font categories:</Text>
        <Text
          color="primary.grey"
          variant="general/captionAndLabel"
        >{`${filteredFontCount} of ${totalFontCount} fonts`}</Text>
      </Box>
      <Spacer mb="8px" />
      <Box>
        {GoogleFontCategoryList.map(category => (
          <CategoryButton
            selected={selectedFilter === category}
            key={`${category}-category-button`}
            onClick={() => {
              setFilter(selectedFilter === category ? undefined : category)
            }}
          >
            <Image
              alt={`${category} filter`}
              height="14px"
              src={imageUrlForFontCategory(category)}
            />
          </CategoryButton>
        ))}
      </Box>
    </Box>
  )
}

const DropdownMenuHeader = styled.button`
  width: 100%;
  border: ${() => `1px solid${theme.colors.secondary.border}`};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  border-radius: 4px;
  height: 44px;
  background-color: ${theme.colors.primary.white};
  padding-x: 0;
`

const CategoryButton = styled.button<{ selected: boolean }>`
  padding: 12px;
  background-color: ${theme.colors.primary.white};
  border-radius: 8px;
  border: ${props =>
    props.selected ? `1px solid #62AAFF` : `1px solid ${theme.colors.secondary.border}`};
  cursor: pointer;
  outline: none;
  margin: 4px 8px 4px 0;
`

const SearchInput = styled(Input)`
  margin: 0;
  max-width: none;
  width: 100%;
  border: ${`1px solid ${theme.colors.secondary.border}`}!important;
  input {
    padding-left: 40px;
    border: none !important;
  }
`

const DisplayMoreFontsButton = styled.button`
height 40px;
color:${theme.colors.primary.flowBlue};
text-align:left;
flex-shrink:0;
cursor:pointer;
border:none;
outline:none;
background-color: ${theme.colors.primary.white};
`
