import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC, useMemo } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { LockToggle } from '../profile/ToggleTemplateLock/ToggleTemplateLock'
import { LockedFeatureWrapper } from '@app/common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { LockedTemplateField } from '../FlowpageTemplates/LockedTemplateField/LockedTemplateField'
import { RemoveBranding } from './RemoveBranding'
import { SlotType } from '../../containers/settings/settings.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useCurrentPagePermissions } from '../../utils/useGetCurrentPagePermissions'
import { useIsMobile } from '../../utils/main'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useScrollToProfileBottom } from '../profile/PageEditor/hooks'
import { useShowTemplateLocks } from '@app/common/src/hooks/useShowTemplateLocks'
import { useToggleRemoveBrandingSlot } from './hooks/useToggleRemoveBrandingSlot'

export const RemoveBrandingCard: FC = () => {
  const scrollToPreview = useScrollToProfileBottom('end', 'scrolltarget-powered-by-footer')
  const page = useCurrentPage()
  const isMobile = useIsMobile()
  const { isAuthenticated, hasOperation } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()

  const handleScroll = (): void => {
    scrollToPreview()
  }
  const existingRemoveBrandingSlot = useMemo(
    () => page?.slots?.find(slot => slot?.slotMeta?.type === SlotType.SETTINGS_REMOVE_FOOTER),
    [page?.slots]
  )
  const templateFieldIsLocked = Boolean(existingRemoveBrandingSlot?.templateConfig?.lockAll)
  const { showTemplateLocks } = useShowTemplateLocks()
  const { toggleLocked, trackingCall } = useToggleRemoveBrandingSlot()
  const isBrandingToggleEnabled =
    isAuthenticated && hasOperation('view:remove_footer_branding_slot')
  const { isTemplateEditor } = usePageTemplateEditorState()
  // Render nothing if user is creating a page while logged out because lock
  // modal has issue. See https://flowcode.slack.com/archives/C02LMN3NYQ0/p1660755993165079
  // Todo: Address issue with rev-team
  if (!isAuthenticated) return null

  return (
    <>
      {isMobile && <Spacer mb={'24px'} />}
      <LockedFeatureWrapper
        featureName="view:remove_footer_branding_slot"
        sx={{ alignItems: 'flex-start' }}
      >
        <Box alignItems="flex-start">
          {showTemplateLocks ? (
            <LockToggle
              disabled={hasViewOnlyAccess}
              locked={Boolean(existingRemoveBrandingSlot?.templateConfig?.lockAll)}
              toggleLocked={async () => {
                trackingCall()
                await toggleLocked({
                  slotId: existingRemoveBrandingSlot?.id,
                  existingTemplateConfig: existingRemoveBrandingSlot?.templateConfig || undefined,
                  newSlot: {
                    pageId: page?.id || '',
                    slotMetaType: SlotType.SETTINGS_REMOVE_FOOTER,
                    value: {
                      isEnabled: false //if the slot does not exist then it is 'off'
                    },
                    templateConfigInput: {
                      templateConfig: {
                        lockAll: true, //if the slot does not exist then user will be 'locking'
                        order: 0
                      }
                    }
                  }
                })
              }}
            />
          ) : (
            templateFieldIsLocked && <LockedTemplateField />
          )}
          <Box flexDirection={'column'} justifyContent={'flex-start'} onMouseEnter={handleScroll}>
            <Box pt="8px" opacity={templateFieldIsLocked ? 0.35 : 1}>
              <Image height={'16px'} width={'16px'} src={'/icons/flowpage-outline.svg'} />
              <Spacer mr="8px" />
              <Text as={'h2'} fontSize={'16px'} fontWeight={'700'}>
                Flowcode Branding
              </Text>
            </Box>
            <Spacer mb="16px" />
            <RemoveBranding
              existingRemoveBrandingSlot={existingRemoveBrandingSlot}
              disabled={
                !isBrandingToggleEnabled ||
                hasViewOnlyAccess ||
                (templateFieldIsLocked && !isTemplateEditor)
              }
              pageId={page?.id ?? ''}
            />
            <Spacer mb="16px" />
          </Box>
        </Box>
      </LockedFeatureWrapper>
      <div id="scrolltarget-branding-toggle" />
    </>
  )
}
