import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { CardBase, LabelText } from './subcomponents'
import { ColorChip } from '@dtx-company/shared-components/src/components/atoms/Chip/ColorChip'
import { EditTheme } from './types'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { STYLE } from '../../../constants/customizeTheme'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { calcContrastText } from './utils'
import { getBackgroundColor, getColor } from '../../../utils/theme'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface CardProps {
  handleSelect: (values: EditTheme) => void
  curr: string
  color?: string
  mobile?: boolean
  disabled?: boolean
}

export const ShadePicker: FC<CardProps> = ({ handleSelect, curr, color, mobile, disabled }) => {
  const { isViewer } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const canUserEditShade = !(isViewer || hasViewOnlyAccess) && !disabled
  return (
    <Box
      flexDirection="column"
      width={'100%'}
      {...stopPropagationEvents}
      px={mobile ? '8px' : 'unset'}
    >
      <Box>
        {!mobile && (
          <>
            <Icon src={icons.vennDiagram} />
            <Spacer mr="8px" />
          </>
        )}
        <Text
          color="primary.black"
          variant={mobile ? 'body/small' : 'body/medium'}
          fontWeight="bold"
          opacity={canUserEditShade ? 1 : 0.35}
        >
          Choose a shade
        </Text>
      </Box>
      <CardBase padding={'0px'} justify="space-between" backgroundColor="transparent">
        {Object.entries(STYLE).map(([shade, _], idx) => {
          const selected = shade === curr
          return (
            <Box
              key={idx}
              flexDirection="column"
              alignItems="center"
              onClick={() => {
                if (canUserEditShade) {
                  handleSelect({ style: shade })
                  events.userChangedStyle()
                }
              }}
              opacity={canUserEditShade ? 1 : 0.35}
              cursor={canUserEditShade ? 'pointer' : 'not-allowed'}
            >
              <ColorChip
                selected={selected}
                chipColor={getBackgroundColor(getColor(color), shade)}
                width={mobile ? '40px' : '48px'}
                height={mobile ? '40px' : '48px'}
              >
                <Text
                  alignSelf="center"
                  variant="body/medium"
                  color={calcContrastText(color, shade)}
                >
                  Aa
                </Text>
              </ColorChip>
              <Spacer mb="8px" />
              <LabelText selected={selected} label={shade} />
            </Box>
          )
        })}
      </CardBase>
    </Box>
  )
}
