import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactInfoFields } from '../utils'
import { FC } from 'react'
import { FirstNameLastNameInputProps } from './types'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { stopPropagationEvents } from '../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src'

export const FirstNameLastNameInput: FC<FirstNameLastNameInputProps> = ({
  values: { firstName, lastName },
  setValueAndPreview
}) => {
  return (
    <Box
      boxShadow="float"
      margin="4px 0px"
      border={`1px solid ${theme.colors.secondary.border}`}
      alignItems="center"
      borderRadius="6px"
      justifyContent="center"
      padding="8px"
      bg="secondary.backgroundDark"
      {...stopPropagationEvents}
    >
      <Input
        type="text"
        labelProps={{ marginBottom: '6px' }}
        placeholder="John"
        value={firstName || ''}
        label="First Name"
        onChange={e => {
          setValueAndPreview(ContactInfoFields.FIRST_NAME, e.target.value)
        }}
      />
      <Spacer mr="8px" />
      <Input
        type="text"
        labelProps={{ marginBottom: '6px' }}
        placeholder="Doe"
        value={lastName || ''}
        label="Last Name"
        onChange={e => {
          setValueAndPreview(ContactInfoFields.LAST_NAME, e.target.value)
        }}
      />
    </Box>
  )
}
