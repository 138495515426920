import { DefaultTheme } from 'styled-components'
import { EditorDesignManager, EditorDraftManager, EditorModalManager } from './types'
import { FontFamilies } from '@dtx-company/true-common/src/constants/page'
import {
  HEX_COLOR_TO_NAME,
  LinkShape,
  LinkStyle,
  STYLE,
  Theme
} from '../../../constants/customizeTheme'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { ModalTypes, setModalOpen } from '@dtx-company/inter-app/src/redux/slices/modal'
import {
  calculateColor,
  getBackgroundColor,
  getColor,
  getContrastText,
  getCustomThemeValues
} from '../../../utils/theme'
import { theme as defaultTheme } from '@dtx-company/shared-components/src'
import { defaultUserTheme } from '../../../constants'
import { loadImageWithEXIF } from '../../../utils/imageEditor'
import { parseLinks } from '../../../utils/main'
import { removeNulls } from '@dtx-company/true-common/src/utils/objects'
import { useCallback, useMemo } from 'react'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { useDispatch } from 'react-redux'
import { useEditPageNav } from './components/Sidebar/hooks'
import { useEditThemeWithAutosave } from '../Customize/hooks'
import { useGetOpenModals } from '@dtx-company/inter-app/src/redux/slices/utils/modal'
import { useIsTempPage } from '../../../hooks/useIsTempPage'
import { useSavePage } from '@dtx-company/inter-app/src/redux/slices/utils/savePage'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export function useEditorDesignManager(): EditorDesignManager {
  const page = useCurrentPage()
  const baseBackgroundColor = getColor(page?.theme?.primaryColor)
  const { previewMode } = useEditPageNav()
  const themeId = page?.theme?.id ?? ''
  const editTheme = useEditThemeWithAutosave()
  const updateBaseBackgroundColor = useCallback(
    (newBaseBackgroundColor: string): void => {
      editTheme({
        themeInput: { primaryColor: HEX_COLOR_TO_NAME[newBaseBackgroundColor] },
        themeId
      })
    },
    [editTheme, themeId]
  )

  const updateLinkStyle = useCallback(
    (linkStyle: LinkStyle): void => {
      editTheme({ themeId, themeInput: { linkStyle } })
    },
    [editTheme, themeId]
  )
  const updateLinkShape = useCallback(
    (linkShape: LinkShape): void => {
      editTheme({ themeId, themeInput: { linkShape } })
    },
    [editTheme, themeId]
  )

  const updateFont = useCallback(
    (fontOption: FontFamilies): void => {
      editTheme({ themeId, themeInput: { fontFamily: fontOption } })
    },
    [editTheme, themeId]
  )

  const themeObj = page?.theme

  const theme = themeObj?.style || Theme.light
  const backgroundImageUrl = themeObj?.backgroundImgUrl ?? null
  const backdropStyleIsImage = !!backgroundImageUrl
  const backdropStyleIsColor = !backdropStyleIsImage
  const pageLinkStyle = (themeObj?.linkStyle ?? defaultUserTheme.linkStyle) as LinkStyle
  const pageLinkShape = (themeObj?.linkShape ?? defaultUserTheme.linkShape) as LinkShape
  const currFont = (themeObj?.fontFamily ?? defaultUserTheme.fontFamily) as FontFamilies
  const updateTheme = useCallback(
    (newTheme: Theme): void => {
      editTheme({ themeId, themeInput: { style: newTheme } })
    },
    [editTheme, themeId]
  )

  const pageBackgroundColor = useMemo(() => {
    return getBackgroundColor(baseBackgroundColor, theme)
  }, [baseBackgroundColor, theme])

  const pageTextColor = useMemo(() => {
    const themeType = STYLE[theme].type
    const themeFactor = STYLE[theme].factor
    const textBaseColor = calculateColor(themeType, themeFactor, baseBackgroundColor)
    return getContrastText(textBaseColor)
  }, [baseBackgroundColor, theme])

  const setPageBackgroundImageUrl = useCallback(
    (imageUrl: string | undefined): void => {
      editTheme({ backgroundImage: imageUrl ?? undefined, themeId, themeInput: {} })
    },
    [editTheme, themeId]
  )

  const setBackdropStyleToSolid = useCallback(() => {
    setPageBackgroundImageUrl(undefined)
  }, [setPageBackgroundImageUrl])

  const handleUploadBackgroundImage = async (newFile: File): Promise<void> => {
    const backgroundImgUrl = await loadImageWithEXIF(newFile)

    if (backgroundImgUrl) {
      setPageBackgroundImageUrl(backgroundImgUrl)
      events.userUploadedBackgroundImage()
    }
  }
  const fpTheme = getCustomThemeValues(themeObj)
  const fontFamily = (page?.theme?.fontFamily ?? FontFamilies.Inter) as FontFamilies
  const userTheme = {
    ...defaultTheme,
    fontFamily: `${fontFamily}, ${defaultTheme.fontFamily}`,
    colors: {
      ...defaultTheme.colors,
      flowpage: fpTheme
    }
  } as DefaultTheme

  const { contact } = page?.links ? parseLinks(removeNulls(page.links)) : { contact: [] }

  return {
    baseBackgroundColor,
    pageBackgroundColor,
    pageTextColor,
    backdropStyleIsImage,
    backdropStyleIsColor,
    setBackdropStyleToSolid,
    theme,
    userTheme,
    updateBaseBackgroundColor,
    updateTheme,
    updateLinkStyle,
    currFont,
    updateFont,
    previewMode,
    pageLinkStyle,
    updateLinkShape,
    pageLinkShape,
    handleUploadBackgroundImage,
    profileImageSrc: page?.profileImage,
    pageContactInfo: contact?.[0]
  }
}

export function useEditorModalManager(): EditorModalManager {
  const dispatch = useDispatch()
  const openModals = useGetOpenModals()

  const openContactModal = useCallback(() => {
    dispatch(setModalOpen({ type: ModalTypes.CONTACT }))
  }, [dispatch])

  const openAddLinkModal = useCallback(() => {
    dispatch(setModalOpen({ type: ModalTypes.ADD_LINK }))
  }, [dispatch])

  const openSettingsModal = useCallback(() => {
    dispatch(setModalOpen({ type: ModalTypes.PAGE_SETTINGS }))
  }, [dispatch])

  const openAnalyticsModal = useCallback(() => {
    dispatch(setModalOpen({ type: ModalTypes.ANALYTICS }))
  }, [dispatch])

  const openBioModal = useCallback(() => {
    dispatch(
      setModalOpen({
        type: ModalTypes.LINK_FORM,
        props: {
          widgetObj: {
            id: 'info',
            provider: LinkProvider.WIDGET,
            type: 'userInfo'
          }
        }
      })
    )
  }, [dispatch])

  return {
    contactModalOpen: openModals.contact ?? false,
    addLinkModalOpen: openModals.addLink ?? false,
    settingsModalOpen: openModals.pageSettings ?? false,
    bioModalOpen: false,
    openSettingsModal,
    openContactModal,
    openAnalyticsModal,
    openAddLinkModal,
    openBioModal
  }
}

export function useEditorDraftManager(): EditorDraftManager {
  const savePage = useSavePage()
  const isTempPage = useIsTempPage()
  return {
    draftHasUnsavedChanges: isTempPage,
    saveUnsavedChanges: savePage
  }
}

export function useScrollToProfileBottom(
  blockStyle?: ScrollLogicalPosition,
  id?: string
): () => void {
  const scrollTo = useCallback(
    () => {
      setTimeout(function () {
        const el = document.getElementById(id ?? 'scrolltarget-profile-bottom')
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: blockStyle })
        }
      }, 250)
    }, // TODO: resolve this rule of hooks error - issue tracked in https://app.shortcut.com/flowcode/story/41455/resolve-rule-of-hooks-error-that-were-commented-out
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return scrollTo
}
