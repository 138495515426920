import { LinkInfoFragment } from '../fragments'
import { gql } from 'graphql-request'

export const GET_LINK = gql`
  query getLink($linkId: ID!) {
    getLink(linkId: $linkId) {
      ...LinkInfo
    }
  }
  ${LinkInfoFragment}
`
