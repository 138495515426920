import { FC } from 'react'
import { LinkStyle } from './LinkStyle'
import { LinkThemeState } from './types'
import { LockedFeatureWrapper } from '../../../../../../common/src/components/LockedFeatureWrapper/LockedFeatureWrapper'
import { TooltipContent } from '@dtx-company/design-system/src'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useIsMobile } from '@dtx-company/true-common/src/hooks/useIsMobile'
import useTranslation from 'next-translate/useTranslation'

export const LinkLevelStylingTooltipContent: FC = () => {
  const { t } = useTranslation('page')
  return (
    <TooltipContent
      title={t('Widgets.linkStyleGated.tooltip.title')}
      content={t('Widgets.linkStyleGated.tooltip.content')}
    />
  )
}

export const LinkStyleGated = (props: LinkThemeState): JSX.Element => {
  const { hasOperation } = useAuthState()
  const canViewLinkStyleOptions = hasOperation('view:link_style_options')
  const isMobile = useIsMobile()

  return (
    <LockedFeatureWrapper featureName="view:link_style_options" showTooltip={!isMobile}>
      <LinkStyle {...props} disabled={!canViewLinkStyleOptions} />
    </LockedFeatureWrapper>
  )
}
