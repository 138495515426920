import { GET_OG_INFO_FROM_URL } from '@dtx-company/flow-codegen/src/page/queries'
import {
  GetOgInfoFromUrlQuery,
  GetOgInfoFromUrlQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'

type OgInfo = {
  title: string
  description: string
  imageUrl: string
}

export const fetchOgInfoFromUrl = async (url: string): Promise<OgInfo> => {
  try {
    const { ogInfo } = await pageGqlFetcher<GetOgInfoFromUrlQuery, GetOgInfoFromUrlQueryVariables>(
      GET_OG_INFO_FROM_URL,
      { url }
    )

    let imageUrl = ''
    if (ogInfo?.ogImage?.url) {
      try {
        // Fetch image and store it as a blob url. This is necessary because
        // when saving the page if the imageUrl is not a blob url it gets skipped
        // from the upload (it gets skipped because its assumed it's already uploaded)
        const imageBlob = await fetch(ogInfo.ogImage.url).then(res => res.blob())
        imageUrl = URL.createObjectURL(imageBlob)
      } catch (err) {
        console.error(err)
      }
    }

    return {
      title: ogInfo?.ogTitle || '',
      description: ogInfo?.ogDescription || '',
      imageUrl: imageUrl
    }
  } catch (err) {
    // Might throw if url returns a 404
    console.error(err)
    return {
      title: '',
      description: '',
      imageUrl: ''
    }
  }
}
