import { SVGProps, memo } from 'react'

function PollingSvg({ stroke = '#111', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="27" height="25" viewBox="0 0 27 25" fill="none" {...rest}>
      <path d="M7.89265 24.6813H1.16003V14.0146H7.89265" fill="#25D086" />
      <path d="M16.5201 0.681396H9.78748V24.6814H16.5201V0.681396Z" fill="#7625F4" />
      <path d="M18.4274 7.34814H25.1601V24.6815H18.4274" fill="#25D086" />
    </svg>
  )
}

export const Polling = memo(PollingSvg)
