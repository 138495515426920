import { Autocomplete, Option } from '@dtx-company/shared-components/src'
import { ChangeEvent, FC, RefObject } from 'react'
import { LayoutProps } from 'styled-system'
import COUNTRY_CODES from '../../constants/countryCodes'

export interface CountryType {
  code: string
  label: string
  phone: string
  suggested?: boolean
}
interface CountryCodeProps {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: (event: ChangeEvent<{}>, countryCode: Option | null | unknown) => void
  width?: LayoutProps['width']
  height?: LayoutProps['height']
  defaultValue?: string
  autocompleteRef?: RefObject<HTMLInputElement>
  disabled?: boolean
}
export const CountrySelect: FC<CountryCodeProps> = ({
  onChange,
  width = '140px',
  height = '44px',
  defaultValue = '1',
  autocompleteRef,
  disabled
}: CountryCodeProps) => {
  const initialValueObj = COUNTRY_CODES.find(obj => obj.phone === defaultValue.replace('+', ''))
  const option = initialValueObj
    ? {
        label: initialValueObj.phone
          ? `+${initialValueObj.phone} (${initialValueObj.code})`
          : initialValueObj.label,
        value: {
          code: initialValueObj?.code,
          phone: initialValueObj?.phone,
          suggested: initialValueObj?.suggested
        }
      }
    : null
  const formatOptions: Option[] = COUNTRY_CODES.map(code => {
    const { label, ...rest } = code
    return {
      label: code.phone ? `+${code.phone} (${code.code})` : label,
      value: rest
    } as Option
  })

  return (
    <Autocomplete
      disabled={disabled}
      height={height}
      ml={-1}
      width={width}
      optionsWidth={240}
      options={formatOptions}
      option={option as Option}
      onSelect={onChange}
      autocompleteRef={autocompleteRef}
      autoComplete="tel-country-code"
    />
  )
}
