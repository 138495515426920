import * as React from 'react'
import { BaseSyntheticEvent } from 'react'
import { Box } from '../../atoms/Box/index'
import { Button } from '../../atoms/Button/index'
import { Text } from '../../atoms/Text/index'
import { TooltipBase, TooltipIcon } from './styles'
import { TooltipProps } from '../../../types'
import { autoscroll } from '../../../utils'
import OutsideClickHandler from 'react-outside-click-handler'
import questionMarkBlue from '../../../static/question-mark-blue.svg'
import questionMarkGray from '../../../static/question-mark-gray.svg'

type Click = BaseSyntheticEvent & React.MouseEvent<HTMLElement, MouseEvent>
export const Tooltip = ({
  id,
  forceOpen,
  onChange,
  buttonImage,
  buttonImageClosed,
  buttonText,
  buttonProps,
  layout = 'up',
  autoScroll = true,
  children,
  offset = 23,
  additionalOffset = 0,
  distance = 23,
  label,
  content,
  tooltipFor,
  margin,
  marginTop,
  marginLeft,
  marginBottom,
  marginRight,
  hideButton,
  ignoreClickOutside,
  onHover,
  ...rest
}: TooltipProps): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(false)

  const ref = React.useRef<HTMLDivElement | null>(null)

  const toggle = (): void => {
    setIsOpen(prevState => !prevState)
  }

  const handleTooltipClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    if (buttonProps?.onClick) buttonProps.onClick(e)
    if (onChange) onChange(id, !isOpen)
    if (forceOpen === undefined) {
      toggle()
    }
  }

  const handleOutsideClick = (e: Click): void => {
    if (e.target?.id !== tooltipFor && isOpen) {
      if (onChange) onChange(id, !isOpen)
      if (forceOpen === undefined) {
        setIsOpen(false)
      }
    }
  }

  React.useEffect(() => {
    if (forceOpen !== undefined) setIsOpen(forceOpen)
  }, [forceOpen])

  React.useEffect(() => {
    autoscroll(ref)
  }, [autoScroll, isOpen, ref])

  return (
    <OutsideClickHandler onOutsideClick={e => !ignoreClickOutside && handleOutsideClick(e)}>
      <Box
        display="inline-block"
        position="relative"
        key={id}
        margin={margin}
        marginTop={marginTop}
        marginLeft={marginLeft}
        marginBottom={marginBottom}
        marginRight={marginRight}
      >
        {children && (
          <span
            onMouseEnter={() => {
              setIsOpen(true)
              onHover?.()
            }}
            onMouseLeave={() => setIsOpen(false)}
            onClick={handleTooltipClick}
          >
            {children}
          </span>
        )}
        {!hideButton &&
          (buttonText ? (
            <Button
              {...buttonProps}
              aria-haspopup
              onClick={handleTooltipClick}
              data-testid="button-tooltip"
            >
              {buttonText}
            </Button>
          ) : (
            <TooltipIcon
              onMouseEnter={() => {
                setIsOpen(true)
                onHover?.()
              }}
              onMouseLeave={() => setIsOpen(false)}
              onClick={handleTooltipClick}
              aria-haspopup
              src={
                isOpen
                  ? buttonImage || questionMarkBlue
                  : buttonImageClosed || buttonImage || questionMarkGray
              }
              alt="tooltip"
            />
          ))}
        {isOpen && (
          <TooltipBase
            onClick={handleOutsideClick}
            ref={ref}
            layout={layout}
            offset={offset}
            additionalOffset={additionalOffset}
            distance={distance}
            {...rest}
          >
            {label && (
              <Text variant="body/small" color="primary.white" marginBottom="5px" fontWeight="bold">
                {label}
              </Text>
            )}
            {content && (
              <Text variant="body/small" color="primary.white">
                {content}
              </Text>
            )}
          </TooltipBase>
        )}
      </Box>
    </OutsideClickHandler>
  )
}
