import { BackgroundImage } from '../../../../../../components/profile/Customize/BackgroundImage'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { getLockedFieldFromTemplateConfig } from '../../../../../FlowpageTemplates/utils/getLockedFieldFromTemplateConfig'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'

export const BackgroundMenu: FC<{ pageTheme: PageType['theme'] }> = ({ pageTheme }) => {
  const page = useCurrentPage()
  const { isTemplateEditor } = usePageTemplateEditorState()

  const disabled =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: pageTheme?.templateConfig,
      field: 'backgroundImgUrl'
    })
  return (
    <Box p="16px 3px" flexDirection="column">
      <BackgroundImage disabled={disabled} page={page || undefined} />
    </Box>
  )
}
