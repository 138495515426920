import { FC } from 'react'
import { Hidden, Visible, theme } from '@dtx-company/shared-components/src'
import { stopPropagationEvents } from '../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import styled from 'styled-components'

interface ActivateButtonProps {
  activated: boolean
  viewOnly: boolean
  toggleActivated: () => void
}

export const ActivateButton: FC<ActivateButtonProps> = ({
  activated,
  viewOnly,
  toggleActivated
}) => {
  const iconStyles = {
    fill: viewOnly ? theme.colors.secondary.disabled : theme.colors.primary.grey,
    width: '24px',
    height: '24px'
  }
  return (
    <StyledActivateButton
      onClick={toggleActivated}
      disabled={viewOnly}
      {...stopPropagationEvents}
      data-testid="manager-activate-button"
      aria-label={`${activated ? 'deactivate' : 'activate'} widget`}
    >
      {activated ? (
        <Visible {...iconStyles} data-testid="activate-button-activated" />
      ) : (
        <Hidden {...iconStyles} data-testid="activate-button-deactivated" />
      )}
    </StyledActivateButton>
  )
}

const StyledActivateButton = styled.button<{ disabled: boolean }>`
  border: 1px solid ${theme.colors.secondary.border};
  height: 40px;
  width: 40px;
  background-color: ${({ disabled }) => (disabled ? theme.colors.secondary.border : 'transparent')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  :focus {
    outline: 2px solid rgba(3, 61, 221, 0.5);
  }
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  :hover {
    background-color: ${theme.colors.secondary.border};
  }
`
