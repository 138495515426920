import * as icons from '../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { EditLinkCardEndAdornment } from '../widgets/EditLinkUtils'
import { FACEBOOK_APP_ID } from '../../../../../constants/app'
import { FC, useEffect, useState } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { ReactPlayer } from '../../../../../components/ReactPlayer/ReactPlayer'
import { Section } from '@dtx-company/shared-components/src/components/atoms/Section/index'
import { embedPlaceholder } from '../../assets/placeholders'
import { getShouldAutoPlayVideo } from './video/getShouldAutoPlayVideo'
import { logoUrlForType } from '../../utils/main'
import { useFlowpageAnalyticsCollector } from '../../hooks/useFlowpageAnalyticsCollector'
import { useIsMounted } from '@dtx-company/true-common/src/hooks/useIsMounted'
import { useIsPresetFpPageLayout } from '../../hooks/useIsPresetFpPageLayout'
import { useIsProfilePageLayout } from '../../hooks/useIsProfilePageLayout'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import styled, { DefaultTheme } from 'styled-components'

export interface LinkCardProps {
  link: string
  pageId?: string
  provider: string
  preview?: boolean
  id: string
  editLinkMode?: boolean
  isLockedTemplateLink?: boolean
}
interface PlayersProps {
  url?: string
  preview?: boolean
  editLinkMode?: boolean
  trackingCallback?: () => void
  isLockedTemplateLink?: boolean
}
interface PreviewProps {
  $preview?: boolean
  $provider?: string
}

const StyledSpotifyPlayer = styled.section<{
  editLinkMode?: boolean
  height: string
  theme: DefaultTheme
}>`
  border-radius: 20px;
  width: 100%;
  height: ${({ height }) => height};
  margin: 0px auto 32px;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.flowStyle.aggressiveBoxShadow};
  iframe {
    width: ${({ editLinkMode }) => (editLinkMode ? '90%' : '100%')};
  }
  display: ${({ editLinkMode }) => (editLinkMode ? 'flex' : 'block')};
`
const SPOTIFY_DIVIDER = 'open.spotify.com'
const SpotifyPlayer: FC<PlayersProps & { disablePointerEvents?: boolean }> = ({
  url,
  editLinkMode,
  disablePointerEvents,
  trackingCallback,
  isLockedTemplateLink
}) => {
  const linkParts = url?.split(SPOTIFY_DIVIDER)

  if (!linkParts || linkParts.length !== 2) {
    return <span>Invalid spotify embed link</span>
  }

  //podcast episode previews are larger than song prviews
  const isSpotifyPodcast = linkParts[1].includes('episode') || linkParts[1].includes('show')
  return (
    <StyledSpotifyPlayer
      editLinkMode={editLinkMode}
      height={isSpotifyPodcast ? '152px' : '80px'}
      data-testid="spotify-player"
    >
      <iframe
        title="spotify"
        loading="lazy"
        src={'https://' + SPOTIFY_DIVIDER + '/embed' + linkParts[1]}
        height="100%"
        frameBorder="0"
        allow="encrypted-media"
        style={{
          borderRadius: editLinkMode ? '20px 0 0 20px' : '20px',
          pointerEvents: disablePointerEvents ? 'none' : 'auto'
        }}
        onClick={trackingCallback}
      />
      {editLinkMode && (
        <EditLinkCardEndAdornment
          wrapperWidth={'10%'}
          isLockedTemplateLink={isLockedTemplateLink}
          margin={'0 auto 0 0'}
          iconSrc={icons.arrowNextWhite}
          iconWidth={'18px'}
          backgroundImage="linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.8))"
        />
      )}
    </StyledSpotifyPlayer>
  )
}

const StyledWrapper = styled(Section)`
  box-shadow: ${({ theme }) => theme.flowStyle.aggressiveBoxShadow};
  .media-player {
    height: ${({ $preview, $provider }: PreviewProps) => {
      if ($preview) {
        return '120px !important'
      } else if ($provider === 'youtube') {
        return '101% !important'
      } else if ($provider === 'soundcloud') {
        return '110% !important'
      } else {
        return '100% !important'
      }
    }};
  }
`

export const LinkEmbeddedCard: FC<LinkCardProps> = ({
  link,
  provider,
  preview,
  id,
  editLinkMode,
  isLockedTemplateLink
}) => {
  const isMounted = useIsMounted()
  const { trackLinkClick } = useFlowpageAnalyticsCollector({
    isPreview: Boolean(preview),
    isEditMode: Boolean(editLinkMode)
  })
  const isProfilePageLayout = useIsProfilePageLayout()
  const isPresetFpPageLayout = useIsPresetFpPageLayout()

  const [progress, setProgress] = useState({
    playedFrac: 0,
    playedSeconds: 0
  })
  const [duration, setDuration] = useState(-1)
  const [started, setStarted] = useState(false)
  useEffect(() => {
    const isValidDuration = duration && duration > 0
    if (started && isValidDuration && !(preview || editLinkMode)) {
      trackLinkClick({ link_id: id }, { useHeap: true })
    }
  }, [started, duration, link, provider, id, preview, editLinkMode, trackLinkClick])

  const disablePointerEvents = isProfilePageLayout || isPresetFpPageLayout

  if (!link) {
    return (
      <Box
        data-testid="embed-placeholder"
        position="relative"
        width="100%"
        borderRadius="12px"
        overflow="hidden"
      >
        <Image src={embedPlaceholder} width="100%" />
        <Image
          zIndex={1}
          position="absolute"
          width="60px"
          left="calc(50% - 30px)"
          top="calc(50% - 30px)"
          src={logoUrlForType(provider) || icons.embed}
        />
      </Box>
    )
  }
  const shouldAutoPlayVideo = getShouldAutoPlayVideo(link)

  if (provider === 'spotify')
    return (
      <SpotifyPlayer
        disablePointerEvents={disablePointerEvents}
        url={link}
        editLinkMode={editLinkMode}
        trackingCallback={() => trackLinkClick({ link_id: id }, { useHeap: true })}
      />
    )
  return (
    <StyledWrapper
      id={id}
      $preview={preview}
      $provider={provider}
      borderRadius="20px"
      width="100%"
      height={preview ? '120px' : '260px'}
      m="0px auto 32px"
      overflow="hidden"
      backgroundColor="primary.black"
      display={editLinkMode ? 'flex' : 'block'}
      py={editLinkMode ? '20px' : 'initial'}
      data-testid="react-player-wrapper"
    >
      <>
        <ReactPlayer
          className="media-player"
          url={link}
          {...(provider === 'vimeo' || shouldAutoPlayVideo
            ? {
                onStart: () => setStarted(true)
              }
            : {
                light: true,
                playing: started,
                onClick: () => setStarted(true),
                onKeyDown: () => setStarted(!started)
              })}
          width={editLinkMode ? '90%' : '100%'}
          controls={preview ? false : true}
          onDuration={d => setDuration(d)}
          onProgress={({ played, playedSeconds }) => {
            setProgress({ playedFrac: played, playedSeconds })
          }}
          config={{
            // to override player specific settings
            youtube: {
              playerVars: {
                ...(shouldAutoPlayVideo && {
                  autoplay: 1,
                  mute: true,
                  playsinline: true
                })
              } // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
            },
            vimeo: {
              playerOptions: {
                ...(shouldAutoPlayVideo && {
                  autoplay: 1,
                  muted: true,
                  playsinline: true
                })
              } // https://developer.vimeo.com/player/sdk/embed
            },
            facebook: {
              appId: FACEBOOK_APP_ID,
              version: 'v2.8',
              playerId: ''
            },
            soundcloud: {
              options: {
                buying: false,
                sharing: false,
                download: false,
                show_artwork: false,
                show_playcount: false,
                show_user: false,
                auto_play: false
              }
            }
          }}
          style={{
            overflow: 'hidden',
            background: 'transparent',
            pointerEvents: disablePointerEvents ? 'none' : 'auto'
          }}
        />
        {editLinkMode && (
          <EditLinkCardEndAdornment
            isLockedTemplateLink={isLockedTemplateLink}
            iconSrc={icons.arrowNextWhite}
            iconWidth="20px"
            wrapperWidth="10%"
            backgroundColor="primary.black"
            iconMargin="auto"
          />
        )}
        {isLockedTemplateLink && <LockOutlinedIcon />}
      </>
    </StyledWrapper>
  )
}
