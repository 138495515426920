import { SVGProps, memo } from 'react'

function EmbedOtherSvg({
  stroke = '#111',
  color = '#FC440F',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M12.759 24.3682C19.3864 24.3682 24.759 18.9956 24.759 12.3682C24.759 5.74075 19.3864 0.368164 12.759 0.368164C6.13162 0.368164 0.759033 5.74075 0.759033 12.3682C0.759033 18.9956 6.13162 24.3682 12.759 24.3682Z"
        fill="#FD805D"
      />
      <path
        d="M12.7591 22.0834C18.1245 22.0834 22.4741 17.7338 22.4741 12.3683C22.4741 7.00288 18.1245 2.65332 12.7591 2.65332C7.39363 2.65332 3.04407 7.00288 3.04407 12.3683C3.04407 17.7338 7.39363 22.0834 12.7591 22.0834Z"
        fill={color}
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7169 10.4526L11.8013 14.2838"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.5903 10.4526L18.5059 12.3682L16.5903 14.2838"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.92781 14.2838L7.01221 12.3682L8.92781 10.4526"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const EmbedOther = memo(EmbedOtherSvg)
