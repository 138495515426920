import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import { DEFAULT_PAGE_ID } from '@dtx-company/true-common/src/constants/page'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { Toggle } from '@dtx-company/shared-components/src/components/atoms/Toggle/index'
import { UnauthSlot } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { theme } from '@dtx-company/shared-components/src'
import { useCreateOrUpdateRemoveFCBrandingSlot } from './hooks/useCreateOrUpdateRemoveFCBranding'
import CircularProgress from '@mui/material/CircularProgress'
import InfoIcon from '@mui/icons-material/Info'

const { errorDark } = theme.colors.status

const UnpublishedPageWarning = (): JSX.Element => (
  <Box alignItems="center">
    <InfoIcon sx={{ color: errorDark }} />
    <Spacer mr="8px" />
    <Text color={errorDark}> Publish your page before changing this setting</Text>
    <Spacer mr="8px" />
  </Box>
)

export interface RemoveBrandingProps {
  pageId: string
  disabled: boolean
  existingRemoveBrandingSlot?: UnauthSlot
}

export const RemoveBranding: FC<RemoveBrandingProps> = ({
  pageId,
  disabled = false,
  existingRemoveBrandingSlot
}) => {
  const [isBrandingOn, setIsBrandingOn] = useState(false)
  const isUnpublishedPage = pageId === DEFAULT_PAGE_ID
  const existingRemoveBrandingSlotIsEnabled = existingRemoveBrandingSlot?.data?.isEnabled

  const { isLoading, createOrUpdateRemoveFCBrandingSlot } = useCreateOrUpdateRemoveFCBrandingSlot({
    pageId
  })

  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const checked = e.target.checked
    // Don't do anything for an unpublished page
    if (isUnpublishedPage) return

    createOrUpdateRemoveFCBrandingSlot({
      pageId,
      slotId: existingRemoveBrandingSlot?.id,
      enabled: checked
    })
  }

  useEffect(() => {
    // When value changed in the API set the local state as well
    if (existingRemoveBrandingSlotIsEnabled !== undefined)
      setIsBrandingOn(existingRemoveBrandingSlotIsEnabled)
  }, [existingRemoveBrandingSlotIsEnabled])

  return (
    <>
      <Text opacity={disabled ? 0.35 : 1} id="remove-branding">
        Remove Flowcode and Flowpage branding from the footer
      </Text>
      <Spacer mb="8px" />
      <Box alignItems="center" opacity={disabled ? 0.35 : 1}>
        <Text>No</Text>
        <Spacer mr="8px" />
        <Toggle
          disabled={disabled || isLoading || isUnpublishedPage}
          checked={isBrandingOn}
          ariaLabelledBy="remove-branding"
          onChange={onChange}
        />
        <Spacer mr="8px" />
        <Text>Yes</Text>
        <Spacer mr="8px" />
        {isLoading && <CircularProgress size={30} />}
        {isUnpublishedPage && <UnpublishedPageWarning />}
      </Box>
    </>
  )
}
