import * as React from 'react'
import { AllPageInfoFragment } from '@dtx-company/flow-codegen/src/page/generated.types'
import { AnimatePresence, m as motion } from 'framer-motion'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { DEFAULT_AVATAR_DARK } from '../../constants/profile'
import { FileUploadInput, MaskType, theme } from '@dtx-company/shared-components/src'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { ImageEditor } from '@app/common/src/components/ImageEditor/ImageEditor'
import { LockedTemplateField } from '../FlowpageTemplates/LockedTemplateField/LockedTemplateField'
import { ModalProps } from '@dtx-company/inter-app/src/redux/slices/modal'
import { PageNavButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import {
  editProfileImage,
  editTheme
} from '@dtx-company/inter-app/src/redux/slices/pageEditor/pageEditorSlice'
import { getLockedFieldFromTemplateConfig } from '../FlowpageTemplates/utils/getLockedFieldFromTemplateConfig'
import { useCurrentPagePermissions } from '../../utils/useGetCurrentPagePermissions'
import { useDispatch } from 'react-redux'
import { useEditPageInfoMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/pageMutations'
import { useEditThemeMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/theme'
import { useIsMobile } from '@dtx-company/true-common/src/hooks/useIsMobile'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'
import { useRouter } from 'next/router'
import { useShouldEditPageInRedux } from '@app/common/src/hooks/useShouldEditPageInRedux'
import { useState } from 'react'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'
import styled from 'styled-components'

const StyledAvatar = styled(Image)`
  height: 140px;
  width: 140px;
`

const StyledEditIcon = styled.button`
  position: absolute;
  top: 12px;
  right: -8px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0);
  border: none;
`

const FlowtagStyledEditIcon = styled.div`
  position: absolute;
  top: 12px;
  right: -8px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background: rgba(0, 0, 0, 0);
  border: none;
`

const StyledBox = styled(Box)`
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.primary.flowBlue};
  background-color: ${({ theme }) => theme.colors.primary.white};
  border: 1px solid ${({ theme }) => theme.colors.secondary.border};
  :hover {
    color: ${({ theme }) => theme.colors.masterWeb.blue2};
  }
  padding: 8px 12px;
  cursor: pointer;
`
const StyledAvatarSection = styled.div`
  position: relative;
`

export interface ChangeAvatarProps {
  page: PageType
  label?: string
  mt?: string
  onClickUpload?: () => void
}

interface FileUploadProps {
  setFile: (_file?: File | string) => void
  setOpenEditImageModal: (props?: ModalProps) => void
  page: AllPageInfoFragment
  children: React.ReactNode
}

const FileUpload: React.FC<FileUploadProps> = ({
  children,
  setFile,
  setOpenEditImageModal,
  page
}) => {
  return (
    <FileUploadInput
      handleUpload={(e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e?.target?.files
        if (files) {
          setFile(files[0] || undefined)
          setOpenEditImageModal()
          events.userChangedBioPicture({ page_id: page?.id })
          return
        }
        setFile(undefined)
      }}
      id="change-avatar"
    >
      {children}
    </FileUploadInput>
  )
}

const AvatarEditMenu: React.FC<
  {
    setUsePlaceholderImage: (_val: boolean) => void
    maskType: MaskType.CIRCLE | MaskType.SQUARE | MaskType.NO_IMAGE | MaskType.RECTANGLE
  } & Omit<FileUploadProps, 'children'>
> = ({ setFile, setOpenEditImageModal, page, maskType, setUsePlaceholderImage }) => {
  const mobile = useIsMobile()
  const motionProps = mobile
    ? {
        left: '-40px',
        top: '15px',
        right: '40px'
      }
    : {
        right: '-200px'
      }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        position: 'absolute',
        top: '0px',
        ...motionProps,
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <BioPageNavButtonsContainer>
        <FileUpload setFile={setFile} setOpenEditImageModal={setOpenEditImageModal} page={page}>
          <StyledBox onClick={events.Flowpage_PageProfile_Clicked_EditBioImageButton}>
            <Text color="inherit" fontWeight={600}>
              Add image
            </Text>
          </StyledBox>
        </FileUpload>
      </BioPageNavButtonsContainer>
      <Spacer mb="8px" />
      <BioPageNavButtonsContainer>
        {page?.theme?.profileImageMaskType !== MaskType.NO_IMAGE && (
          <PageNavButton
            onClick={e => {
              e.stopPropagation()
              setUsePlaceholderImage(false)
              events.Flowpage_PageProfile_Clicked_RemoveBioImageButton()
            }}
            bg="primary.white"
            label="Remove image placeholder"
          />
        )}
      </BioPageNavButtonsContainer>
    </motion.div>
  )
}

const BioPageNavButtonsContainer = styled(Box)`
  p {
    font-family: ${theme.fontFamily};
  }
`

//used in Flowtag onboarding - grab file first & then open modal approach
const uploadAvatarWithoutMenu: React.FC<Omit<FileUploadProps, 'children'>> = ({
  setFile,
  setOpenEditImageModal,
  page
}) => (
  <FileUpload setFile={setFile} setOpenEditImageModal={setOpenEditImageModal} page={page}>
    <FlowtagStyledEditIcon>
      <Icon
        height={'40px'}
        width={'40px'}
        src="/static/icons/AddCircleLight.svg"
        alt="upload photo"
      />
    </FlowtagStyledEditIcon>
  </FileUpload>
)

const ChangeAvatar: React.FC<ChangeAvatarProps> = ({ page, label, mt = '16px', onClickUpload }) => {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = React.useState<boolean>(false)
  const [file, setFile] = React.useState<File | string | undefined>(undefined)
  const [editMenuOpen, setEditMenuOpen] = React.useState(false)
  const [maskType, setMaskType] = React.useState<
    MaskType.SQUARE | MaskType.CIRCLE | MaskType.RECTANGLE
  >(
    (page?.theme?.profileImageMaskType as MaskType.SQUARE | MaskType.CIRCLE | MaskType.RECTANGLE) ||
      MaskType.CIRCLE
  )
  const { isTemplateEditor } = usePageTemplateEditorState()
  const router = useRouter()
  const { shouldEditPageInRedux } = useShouldEditPageInRedux()
  const [editPageInfoMutation] = useEditPageInfoMutation()
  const [editThemeMutation] = useEditThemeMutation()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const isTemplateLocked =
    !isTemplateEditor &&
    getLockedFieldFromTemplateConfig({
      templateConfig: page?.templateConfig,
      field: 'profileImage'
    })
  const disabled = hasViewOnlyAccess || isTemplateLocked

  React.useEffect(() => {
    if (page?.theme?.profileImageMaskType && page?.theme?.profileImageMaskType !== maskType) {
      setMaskType(
        page?.theme.profileImageMaskType as MaskType.SQUARE | MaskType.CIRCLE | MaskType.RECTANGLE
      )
      events.Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape()
    }
  }, [page?.theme, maskType])

  const uploadImage = async (imageUrl: string, profileImageMaskType?: MaskType): Promise<void> => {
    setSubmitting(true)
    if (shouldEditPageInRedux) {
      dispatch(
        editProfileImage({
          profileImage: imageUrl
        })
      )

      dispatch(editTheme({ theme: { profileImageMaskType } }))
    } else {
      await editPageInfoMutation({
        uploadImage: imageUrl,
        isTemplateEditor,
        editPage: {
          id: page.id,
          caption: page.caption,
          displayName: page.displayName
        }
      })
      await editThemeMutation({
        isTemplateEditor,
        themeId: page.theme?.id ?? '',
        themeInput: {
          profileImageMaskType,
          pageId: page.id
        }
      })
    }
    setSubmitting(false)
    closeImageEditModal()
  }
  const setUsePlaceholderImage = (usePlaceholder: boolean): void => {
    shouldEditPageInRedux
      ? dispatch(
          editTheme({
            theme: { profileImageMaskType: usePlaceholder ? MaskType.CIRCLE : MaskType.NO_IMAGE }
          })
        )
      : editThemeMutation({
          isTemplateEditor,
          themeId: page.theme?.id ?? '',
          themeInput: {
            profileImageMaskType: usePlaceholder ? MaskType.CIRCLE : MaskType.NO_IMAGE
          }
        })
  }
  const placeholder =
    page?.theme?.profileImageMaskType === MaskType.NO_IMAGE
      ? '/placeholders/noAvatar.svg'
      : DEFAULT_AVATAR_DARK

  const isFlowtagOnboarding = router.route === Routes.CONTACT2_FLOWTAG

  const [editImageDialogIsOpen, setEditImageDialogIsOpen] = useState(false)
  const closeImageEditModal = (): void => setEditImageDialogIsOpen(false)
  const setOpenEditImageModal = (): void => setEditImageDialogIsOpen(true)

  return (
    <>
      <Box position="absolute" left={{ _: '-54px', md: 0 }}>
        {isTemplateLocked && <LockedTemplateField />}
      </Box>
      <Box
        position="relative"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        mt={mt}
      >
        {label && (
          <>
            <Text variant="body/small">{label}</Text>
            <Spacer mt="5px" />
          </>
        )}
        <StyledAvatarSection>
          <StyledAvatar
            borderRadius={maskType === MaskType.CIRCLE ? '50%' : 'none'}
            src={page.profileImage || placeholder}
            alt="avatar"
          />
          {page?.profileImage ? (
            <StyledEditIcon
              disabled={disabled}
              onClick={e => {
                e.preventDefault()
                onClickUpload?.()
                setFile(page.profileImage || undefined)
                setOpenEditImageModal()
              }}
            >
              <Icon
                height="40px"
                width="40px"
                src="/static/icons/edit-circle.svg"
                alt="edit photo"
              />
            </StyledEditIcon>
          ) : (
            <>
              {isFlowtagOnboarding &&
                uploadAvatarWithoutMenu({ setFile, setOpenEditImageModal, page })}
              {!isFlowtagOnboarding && (
                <>
                  <StyledEditIcon
                    onClick={e => {
                      e.preventDefault()
                      if (!disabled) {
                        setEditMenuOpen(prevState => !prevState)
                        events.Flowpage_PageProfile_Clicked_EditPageProfileIcon()
                      }
                    }}
                    data-testid="edit-avatar-menu"
                  >
                    <Icon
                      height={'40px'}
                      width={'40px'}
                      padding={editMenuOpen ? '12px' : null}
                      opacity={disabled ? 0.5 : 1}
                      src={
                        editMenuOpen
                          ? '/static/icons/closeBlack.svg'
                          : '/static/icons/edit-circle.svg'
                      }
                      alt="edit photo"
                    />
                  </StyledEditIcon>
                  <AnimatePresence>
                    {editMenuOpen && (
                      <AvatarEditMenu
                        setFile={setFile}
                        setOpenEditImageModal={setOpenEditImageModal}
                        page={page}
                        maskType={maskType}
                        setUsePlaceholderImage={setUsePlaceholderImage}
                      />
                    )}
                  </AnimatePresence>
                </>
              )}
            </>
          )}
        </StyledAvatarSection>

        <ImageEditor
          title="Edit profile photo"
          open={editImageDialogIsOpen}
          onClose={() => {
            closeImageEditModal()
            events.Flowpage_PageProfile_Clicked_Exit_Profile_Image_Window()
          }}
          file={file}
          useObjectURL
          handleUploadImage={uploadImage}
          onPositionChange={events.Flowpage_PageProfile_Clicked_Change_Profile_Image_Shape}
          onSlide={events.Flowpage_PageProfile_Clicked_Profile_Image_Zoom}
          onSave={events.Flowpage_PageProfile_Clicked_Save_Profile_Image}
          onUpload={events.Flowpage_PageProfile_Clicked_Change_Profile_Image}
          handleDeleteImage={async () => {
            if (shouldEditPageInRedux) {
              dispatch(editProfileImage({ profileImage: undefined }))
            } else {
              await editPageInfoMutation({
                deleteImage: true,
                isTemplateEditor,
                editPage: {
                  id: page.id,
                  caption: page.caption,
                  displayName: page.displayName
                }
              })
            }
            closeImageEditModal()
            events.Flowpage_PageProfile_Clicked_Delete_Profile_Image()
          }}
          maskType={maskType}
        />
      </Box>
    </>
  )
}

export default ChangeAvatar
