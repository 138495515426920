import { GoogleFont, GoogleFontsSortBy, UseGoogleFontsType } from './types'
import { useEffect, useState } from 'react'

const FONT_REQUEST_URL = 'https://www.googleapis.com/webfonts/v1/webfonts'

export const useGoogleFontsList = (sortBy?: GoogleFontsSortBy): UseGoogleFontsType => {
  const [fonts, setFonts] = useState<GoogleFont[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getFontsList = async (sort?: GoogleFontsSortBy): Promise<void> => {
      setLoading(true)
      try {
        const response = await fetch(
          `${FONT_REQUEST_URL}?sort=${sort ?? 'alpha'}&key=${process.env.GOOGLE_CLOUD_API_KEY}`
        )
        const json = await response.json()
        setFonts(json.items)
        setLoading(false)
        return
      } catch (e) {
        console.error('Error getting Google fonts', e)
      }
    }

    getFontsList(sortBy)
  }, [sortBy])

  return { fonts, loading }
}
