import { LinkType, PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { hexToRgb } from '../../../../utils/theme'

/**
 * Returns colors from the applying theme. Use colors from linkTheme if active
 * and available. When linkTheme is not active
 * use colors from the pageTheme if available, otherwise return empty strings.
 * We return empty strings and not specific default values because the colors
 * what would apply are dependant on the the value of LinkStyle. See {@link getLinkLevelStyles}
 */
export const getLinkStyleValuesFromTheme = (
  pageTheme?: PageType['theme'] | null,
  linkTheme?: LinkType['linkTheme'] | null
): {
  borderColor: string
  backgroundColor: string
  fontColor: string
  shadowColorRGB: { r: number; g: number; b: number }
  titleFontSize: string
} => {
  let fontColor = ''
  if (pageTheme?.fontColor) fontColor = pageTheme.fontColor
  if (linkTheme?.active && linkTheme?.fontColor) fontColor = linkTheme.fontColor

  let borderColor = ''
  if (pageTheme?.borderColor) borderColor = pageTheme.borderColor
  if (linkTheme?.active && linkTheme?.borderColor) borderColor = linkTheme.borderColor

  let backgroundColor = ''
  if (pageTheme?.linkColor) backgroundColor = pageTheme.linkColor
  if (linkTheme?.active && linkTheme?.linkColor) backgroundColor = linkTheme.linkColor

  let shadowColor = ''
  if (pageTheme?.shadowColor) shadowColor = pageTheme.shadowColor
  if (linkTheme?.active && linkTheme?.shadowColor) shadowColor = linkTheme.shadowColor

  const shadowColorRGB = shadowColor ? hexToRgb(shadowColor) : { r: 0, g: 0, b: 0 }

  let titleFontSize = ''
  if (pageTheme?.textSize) titleFontSize = pageTheme.textSize.toString()
  if (linkTheme?.active && linkTheme?.textSize) titleFontSize = linkTheme.textSize.toString()

  return {
    borderColor,
    backgroundColor,
    fontColor,
    shadowColorRGB,
    titleFontSize
  }
}
