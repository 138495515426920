import * as icons from '../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { BoxProps } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import LockOutlined from '@mui/icons-material/LockOutlined'

interface EditLinkArrowProps extends BoxProps {
  iconWidth?: string
  wrapperWidth?: string
  iconSrc?: string
  margin?: string
  backgroundImage?: string
  iconMargin?: string
}

const _EditLinkArrow: FC<EditLinkArrowProps> = ({
  backgroundColor,
  iconWidth,
  wrapperWidth,
  iconSrc = icons.arrowNextBlack,
  margin,
  backgroundImage,
  iconMargin,
  padding,
  ...rest
}: EditLinkArrowProps) => (
  <Box
    margin={margin}
    width={wrapperWidth}
    backgroundColor={backgroundColor}
    backgroundImage={backgroundImage}
    padding={padding}
    {...rest}
  >
    <Icon src={iconSrc} width={iconWidth} margin={iconMargin} />
  </Box>
)
export interface EditLinkCardEndAdornmentProps extends EditLinkArrowProps {
  isLockedTemplateLink?: boolean
}

export const EditLinkCardEndAdornment: FC<EditLinkCardEndAdornmentProps> = ({
  isLockedTemplateLink,
  ...rest
}) => {
  if (isLockedTemplateLink) return <LockOutlined />
  return <_EditLinkArrow {...rest} />
}
