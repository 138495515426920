import * as icons from '../../../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { FC } from 'react'
import { FEATURED_DISPLAY_TYPE, ICONS_ONLY } from '../../types'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { IconEditor } from './IconEditor'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { LinkHandle } from '../../../../../components/util/LinkItemUtils'
import {
  SocialAdvLinkType,
  SocialLinkFormFields,
  SocialLinkUrlFields
} from '../../../Destination/types'
import { SocialLinkFields } from '../../../Destination/SocialLinkFields.types'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { UseFormMethods } from 'react-hook-form-deprecated'
import {
  bwLogoUrlForProvider,
  getErrorHeaderForChildlinkManager,
  getSocialInputPlaceholderText
} from '../utils'
import { stopPropagationEvents } from '../../../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src'
import { trashCanBold } from '@app/code/src/assets'
import styled from 'styled-components'

interface ChildlinkManagerProps {
  idx: number
  renderDraggable: (
    render: (provided: DraggableProvided) => JSX.Element
  ) => (provided: DraggableProvided) => JSX.Element
  socialLinkType?: SocialAdvLinkType
  watchAll: SocialLinkFormFields
  updateFormFieldValue: (
    value: string | number,
    social: SocialLinkUrlFields | undefined,
    nestedItem?: keyof SocialLinkFields | undefined
  ) => Promise<void>
  errors: UseFormMethods['errors']
  trigger: (name?: string | string[] | undefined) => Promise<boolean>
  expanded: boolean
  handleExpand: (provider: string) => void
  handleDeleteChildlink: (provider: SocialLinkUrlFields) => void
}

export const ChildlinkManager: FC<ChildlinkManagerProps> = ({
  idx,
  renderDraggable,
  socialLinkType,
  watchAll,
  updateFormFieldValue,
  errors,
  trigger,
  expanded,
  handleExpand,
  handleDeleteChildlink
}) => {
  const provider = socialLinkType?.provider ?? ('' as SocialLinkUrlFields)
  const showCardInputs = watchAll.displayType !== ICONS_ONLY
  const errorValue =
    errors[provider]?.link?.message ??
    errors[provider]?.title?.type ??
    errors[provider]?.description?.type
  return (
    <Draggable
      draggableId={`${idx}-draggable`}
      index={idx}
      key={`${idx}-draggable`}
      {...stopPropagationEvents}
    >
      {renderDraggable((provided: DraggableProvided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <Box
            width="100%"
            key={`${provider}-url-entry`}
            alignItems="center"
            backgroundColor="primary.white"
            border={`1px solid ${theme.colors.secondary.border}`}
            borderRadius="6px"
            p="16px 14px"
            mb="12px"
            cursor="default"
            {...stopPropagationEvents}
            flexDirection="column"
            boxShadow="3px 3px 30px rgba(17, 17, 17, 0.1)"
          >
            <Box justifyContent="space-between" width="100%">
              <Box pr="8px" alignItems="center">
                <LinkHandle {...provided.dragHandleProps} />
                <Spacer mr="6px" />
                <Icon
                  color="black"
                  src={bwLogoUrlForProvider(socialLinkType?.provider ?? '')}
                  alt={`${socialLinkType?.provider || 'social'} icon`}
                  width="24px"
                  height="24px"
                />
                <Spacer mr="10px" />
                <Text variant="header/subheader">{socialLinkType?.displayText}</Text>
                <Spacer mr="12px" />
                {errors[provider] && !expanded && (
                  <Text color="status.errorDark">
                    {getErrorHeaderForChildlinkManager(errorValue)}
                  </Text>
                )}
              </Box>
              <Box justifyItems="right" alignItems="center">
                {!expanded && (
                  <Icon
                    src={icons.pencilOutlineBlue}
                    width="18px"
                    height="18px"
                    alt={`Edit ${socialLinkType?.provider} link`}
                    mb="6px"
                    cursor="pointer"
                    onClick={() => handleExpand(socialLinkType?.provider ?? '')}
                  />
                )}
                <Spacer mr="15px" />
                <Icon
                  src={trashCanBold}
                  alt={`Delete ${socialLinkType?.provider} link`}
                  cursor="pointer"
                  onClick={() =>
                    expanded
                      ? handleDeleteChildlink(socialLinkType?.provider as SocialLinkUrlFields)
                      : handleExpand(socialLinkType?.provider ?? '')
                  }
                />
              </Box>
            </Box>
            {expanded && (
              <InputSectionGrid>
                <Text variant="input/label">Link</Text>
                <Input
                  type="text"
                  value={watchAll[provider]?.link || ''}
                  error={!!errors[provider]?.link}
                  helperText={errors[provider]?.link?.message}
                  helperTextProps={{ fontSize: '12px', color: 'status.errorDark' }}
                  backgroundColor="primary.white"
                  placeholder={getSocialInputPlaceholderText(socialLinkType)}
                  labelProps={{ marginBottom: '6px' }}
                  onBlur={() => trigger(`${provider}.link`)}
                  onChange={e => {
                    updateFormFieldValue(e.target.value, provider, 'link')
                  }}
                  startAdornment={
                    socialLinkType?.isUsernameLink || undefined ? (
                      <Box pl="8px" pr="4px" pb="4px" justifyContent="center" alignContent="center">
                        <Text>{socialLinkType?.adornment || '@'}</Text>
                      </Box>
                    ) : (
                      <></>
                    )
                  }
                  maxWidth="100%"
                  controlled
                />
                <Text variant="input/label">Icon</Text>
                <IconEditor
                  provider={provider}
                  imageUrl={watchAll[provider]?.thumbNailImgUrl ?? ''}
                  setImage={(imageUrl: string) =>
                    updateFormFieldValue(imageUrl, provider, 'thumbNailImgUrl')
                  }
                  resetImage={() => updateFormFieldValue('', provider, 'thumbNailImgUrl')}
                  featured={watchAll.displayType === FEATURED_DISPLAY_TYPE}
                />
                {showCardInputs && <Text variant="input/label">Title</Text>}
                {showCardInputs && (
                  <Input
                    type="text"
                    id={`inline-title-input-${provider}`}
                    value={watchAll[provider]?.title ?? ''}
                    error={!!errors[provider]?.title}
                    helperText={errors[provider]?.title?.message}
                    helperTextProps={{ fontSize: '12px', color: 'status.errorDark' }}
                    placeholder="Enter a title"
                    onBlur={() => trigger(`${provider}.title`)}
                    labelProps={{ marginBottom: '6px' }}
                    onChange={e => {
                      updateFormFieldValue(e.target.value, provider ?? '', 'title')
                    }}
                    maxWidth="100%"
                    mb="8px"
                  />
                )}
                {showCardInputs && <Text variant="input/label">Description (optional)</Text>}
                {showCardInputs && (
                  <Input
                    type="text"
                    id={`inline-description-input-${provider}`}
                    value={watchAll[provider]?.description ?? ''}
                    error={!!errors[provider]?.description}
                    helperText={errors[provider]?.description?.message}
                    helperTextProps={{ fontSize: '12px', color: 'status.errorDark' }}
                    placeholder="Enter a description"
                    onBlur={() => trigger(`${provider}.description`)}
                    labelProps={{ marginBottom: '6px' }}
                    onChange={e => {
                      updateFormFieldValue(e.target.value, provider ?? '', 'description')
                    }}
                    maxWidth="100%"
                    mb="8px"
                  />
                )}
              </InputSectionGrid>
            )}
          </Box>
        </div>
      ))}
    </Draggable>
  )
}

const InputSectionGrid = styled.div`
  display: grid;
  grid-template-columns: 76px auto;
  align-items: center;
  width: 100%;
  row-gap: 16px;
  margin-top: 16px;
`
