import { SVGProps, memo } from 'react'

function NextSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width=".57em"
      height="1em"
      fill="none"
      viewBox="0 0 8 14"
      {...rest}
    >
      <path
        stroke={color || stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.0"
        d="M1 1l6 6-6 6"
      />
    </svg>
  )
}

function NextFlippedSvg({
  stroke = 'currentcolor',
  color,
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width=".57em"
      height="1em"
      fill="none"
      viewBox="0 0 8 14"
      {...rest}
    >
      <path
        stroke={color || stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.0"
        d="M7 1l-6 6 6 6"
      />
    </svg>
  )
}

export const Next = memo(NextSvg)
export const NextFlipped = memo(NextFlippedSvg)
