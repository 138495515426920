import { GetPageDetailsQuery } from '@dtx-company/flow-codegen/src/page/generated.types'

/** Sets the linkTheme property of all links to null */
export const nullifyLinksThemes = (
  links?: GetPageDetailsQuery['Page']['links']
): GetPageDetailsQuery['Page']['links'] => {
  if (!links) return []

  const resetLinks = links.map(link => {
    return { ...link, linkTheme: null }
  })
  return resetLinks
}
