import { ContactInfoFields } from './utils'
import { DisplayToggle } from '../components/DisplayToggle'
import { FC } from 'react'
import { UseFormMethods } from 'react-hook-form-deprecated'

export const ContactInfoDisplayToggle: FC<{
  setValue: UseFormMethods['setValue']
  displayType: string
}> = ({ setValue, displayType }) => {
  return (
    <DisplayToggle
      onChange={(_e, type) => {
        setValue(ContactInfoFields.DISPLAY_TYPE, type)
      }}
      displayOptions={[
        { title: 'Circle', value: 'widget' },
        { title: 'Floating', value: 'default' }
      ]}
      displayType={displayType}
      name="icon-style-toggle"
      title="Icon Style"
    />
  )
}
