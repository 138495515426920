import { Box } from '../../atoms/Box/index'
import { border, color, layout, space } from 'styled-system'
import styled from 'styled-components'

export const ToggleButtonListBase = styled(Box)`
  border-radius: 4px;
  padding: 7px;
  background-color: ${({ theme }) => theme.colors.secondary.backgroundDark};
  ${border}
  ${layout}
  ${space}
  ${color}
`
