import * as React from 'react'
import { ToggleButtonListBase } from './styles'
import { ToggleButtonListProps, ToggleButtonProps } from '../../..'

export const ToggleButtonList = ({
  children,
  selectedValue,
  onChange,
  ...rest
}: ToggleButtonListProps): JSX.Element => {
  const handleToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    childValue: string
  ): void => {
    if (onChange) onChange(event, childValue)
  }
  return (
    <ToggleButtonListBase aria-label="toggle-button-list" {...rest}>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) {
          return null
        }
        return React.cloneElement<ToggleButtonProps, React.Component<ToggleButtonProps>>(
          child as React.CElement<ToggleButtonProps, React.Component<ToggleButtonProps>>,
          {
            onClick: handleToggle,
            selected:
              child.props.selected === undefined
                ? child.props.value === selectedValue
                : child.props.selected
          }
        )
      })}
    </ToggleButtonListBase>
  )
}
