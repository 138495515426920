import { ActionDataTypes, ActionDataWidgets } from '../types'
import { ContactFormState, ContactInfoProps, DigitFields, UseContactFormProps } from './types'
import { ContactInfoFields, EXCLUDED_CONTACT_INFO_FIELD_KEYS, splitCountryCodes } from './utils'
import { defaultWidgetLink } from '../../../constants'
import { isValidUrl } from '@dtx-company/inter-app/src/utils/isValidUrl/isValidUrl'
import { useDeleteLinkWithAutosave } from '../deleteUtils'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form-deprecated'
import { useIsMobile } from '../../../utils/main'
import { useLinkThemeState } from '../components/LinkStyle/hooks/useLinkThemeState'
import { useSetValueWithPreview } from '../utils'
import { useSubmitWidget } from '../submitUtils'
import { v4 as uuid } from 'uuid'
import { validateInternationalPhoneNumbers } from '@app/code/src/components/myflowcodes/v2/CodeComplete/subcomponents/ScanDestination/ValidateInternationalPhoneNumbers'
import isEmail from 'validator/lib/isEmail'
import useTranslation from 'next-translate/useTranslation'

export function useContactForm({
  widgetObj,
  order,
  curr,
  handleClose,
  defaultValues
}: UseContactFormProps): ContactFormState {
  const id = curr ? curr.id : uuid()
  const { codes, digits } = splitCountryCodes(curr)
  const [countryCodes, setCountryCodes] = useState<DigitFields>({
    text: digits.text.length > 0 ? codes?.text : '1',
    call: digits.call.length > 0 ? codes?.call : '1'
  })
  const { linkTheme, setLinkTheme } = useLinkThemeState({
    defaultLinkTheme: curr?.linkTheme ?? null
  })
  const deleteLink = useDeleteLinkWithAutosave()
  // All fields in contactInfo are optional, so we can assert here
  const actionData = curr?.actionData as ActionDataTypes['contactInfo']
  const { register, setValue, handleSubmit, errors, watch, reset, clearErrors } = useForm({
    defaultValues: {
      firstName: actionData?.firstName || '',
      lastName: actionData?.lastName || '',
      call: digits?.call || '',
      email: defaultValues?.email || actionData?.email || '',
      website: actionData?.website || '',
      text: digits?.text || '',
      street1: actionData?.street1,
      location: actionData?.location || '' /** formatted address used for form*/,
      street2: actionData?.street2,
      zip_code: actionData?.zip_code,
      country: actionData?.country,
      city: actionData?.city,
      state: actionData?.state,
      card: actionData?.card || false,
      company: actionData?.company || '',
      title: actionData?.title || '',
      displayType: defaultValues?.displayType || curr?.displayType || 'default',
      iconSize: actionData?.iconSize || 24,
      iconFill: actionData?.iconFill || 'fill'
    }
  })
  const clearContactInfo = (): void => {
    deleteLink({ linkId: id, linkOrder: order })
    handleClose()
  }

  const submitWidget = useSubmitWidget()
  const initialEmpty = Boolean(
    !digits?.call && !actionData?.email && !actionData?.location && !digits?.text
  )
  const onSubmit = async ({
    firstName,
    lastName,
    call,
    email,
    website,
    text,
    street1,
    location,
    street2,
    city,
    state,
    zip_code,
    company,
    title,
    iconFill,
    iconSize,
    card,
    displayType
  }: ContactInfoProps): Promise<void> => {
    let fullText = text
    let fullCall = call
    if (text && countryCodes.text !== '') {
      fullText = '+' + countryCodes.text + '-' + text
    }
    if (call && countryCodes.call !== '') {
      fullCall = '+' + countryCodes.call + '-' + call
    }
    const actionData = {
      firstName,
      lastName,
      call: fullCall,
      email: email || defaultValues?.email,
      website,
      text: fullText,
      card,
      street1,
      location,
      street2,
      city,
      state,
      zip_code,
      company,
      title,
      iconFill: iconFill as 'fill' | 'outline' | undefined,
      iconSize
    }
    const formIsEmpty =
      !call &&
      !(email || defaultValues?.email) &&
      !text &&
      !location &&
      !company &&
      !title &&
      !website
    if (formIsEmpty) {
      if (curr) {
        clearContactInfo()
      }
      handleClose()
      return
    }
    await submitWidget({
      curr,
      actionData,
      handleClose,
      linkTheme,
      widgetType: (curr ? curr.type : widgetObj.type) as ActionDataWidgets,
      fields: {
        title: 'Contact Info',
        description: '',
        id,
        order: order,
        displayType: displayType || 'default'
      }
    })
  }
  const contactInputs = Object.keys(ContactInfoFields).filter(
    item => !EXCLUDED_CONTACT_INFO_FIELD_KEYS.includes(item)
  )
  const handleCountryCodeChange = (type: 'call' | 'text', code: string): void => {
    setCountryCodes({ ...countryCodes, [type]: code })
  }
  const watchAll = watch()

  const { t } = useTranslation('pages')
  useEffect(() => {
    register(ContactInfoFields.FIRST_NAME)
    register(ContactInfoFields.LAST_NAME)
    register(ContactInfoFields.CALL, {
      validate: async value => {
        if (!value) return true
        if (value.startsWith('+')) return t('Forms.contact.errors.phoneNumber')
        return (await validateInternationalPhoneNumbers(countryCodes.call, value))
          ? true
          : t('Forms.contact.errors.phoneNumber')
      }
    })
    register(ContactInfoFields.EMAIL, {
      validate: value => (isEmail(value) ? true : !value ?? 'Please enter a valid email')
    })
    register(ContactInfoFields.TEXT, {
      validate: async value => {
        if (!value) return true
        if (value.startsWith('+')) return t('Forms.contact.errors.phoneNumber')
        return (await validateInternationalPhoneNumbers(countryCodes.text, value))
          ? true
          : t('Forms.contact.errors.phoneNumber')
      }
    })
    register(ContactInfoFields.STATE)
    register(ContactInfoFields.STREET1)
    register(ContactInfoFields.LOCATION)
    register(ContactInfoFields.STREET2)
    register(ContactInfoFields.CITY)
    register(ContactInfoFields.ZIPCODE)
    register(ContactInfoFields.COMPANY)
    register(ContactInfoFields.WEBSITE, {
      validate: value => (!value || isValidUrl(value) ? true : 'Please enter a valid URL')
    })
    register(ContactInfoFields.TITLE)
    register(ContactInfoFields.DISPLAY_TYPE)
    register(ContactInfoFields.ICON_FILL)
    register(ContactInfoFields.ICON_SIZE)
    register(ContactInfoFields.CARD)
  }, [countryCodes.call, countryCodes.text, register, t])

  const disableCard = Boolean(
    !watchAll?.call &&
      !watchAll?.email &&
      !watchAll?.location &&
      !watchAll?.text &&
      !watchAll.website
  )
  const mobile = useIsMobile()
  const previewLink = useMemo(() => {
    const { displayType, ...actionData } = watchAll
    return {
      ...defaultWidgetLink,
      id,
      linkTheme,
      type: 'contactInfo',
      displayType,
      actionData
    }
  }, [watchAll, id, linkTheme])

  const { setValue: setValueAndPreview, setLinkThemeValue } = useSetValueWithPreview(
    previewLink,
    setValue,
    setLinkTheme
  )
  return {
    mobile,
    disableCard,
    watchAll,
    contactInputs,
    handleCountryCodeChange,
    setValue,
    linkTheme,
    setLinkTheme: setLinkThemeValue,
    setValueAndPreview,
    handleSubmit,
    errors,
    clearErrors,
    initialEmpty,
    clearContactInfo,
    codes: countryCodes,
    onSubmit,
    reset
  }
}
