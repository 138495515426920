import { Maybe, Slot, SlotMeta } from '@dtx-company/flow-codegen/src/page/generated.types'
import { SlotType } from '../containers/settings/settings.types'
import { useCurrentPage } from '@dtx-company/inter-app/src/redux/slices/utils/currentPage'
import { usePageDisplayContext } from '../context/page-display'

export function useNewTabSlots():
  | Maybe<
      { __typename?: 'Slot' | undefined } & Pick<Slot, 'id' | 'data'> & {
          slotMeta?:
            | Maybe<{ __typename?: 'SlotMeta' | undefined } & Pick<SlotMeta, 'id' | 'type'>>
            | undefined
        }
    >
  | undefined {
  const clientSlots = useCurrentPage()?.slots

  const {
    page: { slots: serverSlots }
  } = usePageDisplayContext()

  if (!serverSlots && !clientSlots) return undefined
  const newTabSlots = (serverSlots || clientSlots)?.filter(
    slot => slot?.slotMeta?.type === SlotType.SETTINGS_TAB_NAVIGATION
  )

  const slot = newTabSlots && newTabSlots.length > 0 ? newTabSlots[0] : undefined
  return slot
}
