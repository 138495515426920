import { SVGProps, memo } from 'react'

function DocAndPenSvg({ fill = 'currentcolor', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="33" height="32" viewBox="0 0 33 32" fill="none">
      <path
        d="M25.8333 14.6667V10.4373C25.8333 9.73067 25.552 9.052 25.052 8.552L21.2813 4.78133C20.7813 4.28133 20.1027 4 19.396 4H7.16667C5.69333 4 4.5 5.19333 4.5 6.66667V25.3333C4.5 26.8067 5.69333 28 7.16667 28H13.8333"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.8334 10.6667H20.5001C19.7641 10.6667 19.1667 10.0693 19.1667 9.33333V4"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3614 27.6093L28.108 22.8627C28.6294 22.3413 28.6294 21.4973 28.108 20.9773L25.9934 18.8627C25.472 18.3413 24.628 18.3413 24.108 18.8627L19.3614 23.6093C19.1107 23.86 18.9707 24.1987 18.9707 24.552V28H22.4187C22.772 28 23.1107 27.86 23.3614 27.6093Z"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const DocAndPen = memo(DocAndPenSvg)
