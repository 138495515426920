import { SVGProps, memo } from 'react'

function StoreHoursSvg({
  stroke = '#111',
  color = '#111',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <circle cx="12.7595" cy="12.0767" r="12" fill={color} />
      <circle cx="12.7596" cy="12.0767" r="9.40385" fill={color} stroke="white" strokeWidth="1.5" />
      <path
        d="M3.52881 12.0767H5.37496"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1441 12.0767H21.9903"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7595 19.4614L12.7595 21.3076"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7595 8.38428L12.7595 12.0766"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7595 2.84595L12.7595 4.6921"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7595 12.0767H16.4518"
        stroke="#F4134F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const StoreHours = memo(StoreHoursSvg)
