import { HTMLMotionProps } from 'framer-motion'

export const stopPropagationEvents = {
  onTouchStart: (e: React.TouchEvent): void => e.stopPropagation(),
  onTouchEnd: (e: React.TouchEvent): void => e.stopPropagation(),
  onTouchMove: (e: React.TouchEvent): void => e.stopPropagation(),
  onMouseDown: (e: React.MouseEvent): void => e.stopPropagation(),
  onMouseUp: (e: React.MouseEvent): void => e.stopPropagation()
}

export const animationProps: HTMLMotionProps<'div'> = {
  initial: { x: 300, opacity: 0 },
  animate: { x: 0, opacity: 1 },
  exit: { x: -300, opacity: 0 },
  transition: {
    ease: [0.48, 0, 0.45, 0.64]
  }
}
export const desktopAnimationProps: HTMLMotionProps<'div'> = {
  initial: { opacity: 0, position: 'absolute' },
  animate: { opacity: 1, position: 'relative' },
  exit: { opacity: 0, position: 'absolute' }
}
