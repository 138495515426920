import * as React from 'react'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ProTag } from '../../../../../code/src/components/ProTag/ProTag'
import { Stack } from '@dtx-company/design-system/src'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { ToggleButton } from '@dtx-company/shared-components/src/components/atoms/ToggleButton/index'
import { ToggleButtonList } from '@dtx-company/shared-components/src/components/molecules/ToggleButtonList'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { stopPropagationEvents } from '../../../components/profile/PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src/theme'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useLockedFeatureAction } from '../../../../../common/src/components/LockedFeatureModal/hooks/useLockedFeatureAction'
import styled from 'styled-components'

const StyledToggleButton = styled(ToggleButton)`
  border-radius: 5px;
  border: solid 2px ${({ theme }) => theme.colors.secondary.border};
  color: ${({ theme }) => theme.colors.primary.flowBlue};
  &[aria-pressed='true'] {
    background-color: #e6eeef;
    border: solid 2px ${({ theme }) => theme.colors.primary.flowBlue};
    color: ${({ theme }) => theme.colors.primary.flowBlue};
  }
`

const MobileStyledToggleButton = styled(ToggleButton)`
  :hover {
    background-color: white;
  }
`
interface DisplayToggleProps {
  onChange: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type: string) => void
  displayType: string
  displayOptions: { title: string; value: string; isLockedFeature?: boolean; disabled?: boolean }[]
  name?: string
  title?: string
  width?: Array<string>
}
export const DisplayToggle: React.FC<DisplayToggleProps> = ({
  onChange,
  displayType,
  displayOptions,
  title,
  width = ['100%', '60%', '60%']
}) => {
  const { hasOperation } = useAuthState()
  const showLock = !hasOperation('view:flowpage_paid_crm')
  const openLockedFeatureModal = useLockedFeatureAction({
    lockedFeatureName: 'view:flowpage_paid_crm'
  })
  const hasLockedFeature = displayOptions.some(option => option.isLockedFeature === true)

  return (
    <Box width={width} flexDirection="column">
      <Text>{title || 'Display Type'}</Text>
      <Stack direction="row" alignItems="center">
        <ToggleButtonList
          selectedValue={displayType}
          onChange={onChange}
          justifyContent={displayOptions.length === 2 ? 'flex-start' : 'space-between'}
          margin="0px -4px"
          padding="7px 0"
          {...stopPropagationEvents}
          background="transparent !important"
          alignItems="center"
        >
          {displayOptions.map(option => (
            <StyledToggleButton
              key={option.value}
              value={option.value}
              flexDirection="column"
              justifyContent="space-around"
              m="0px 4px"
              {...((option.isLockedFeature || option.disabled) && {
                backgroundColor: theme.colors.secondary.border
              })}
              disabled={option?.disabled}
              aria-label={`${option.title} toggle on`}
            >
              <Text
                color={option.isLockedFeature || option.disabled ? 'secondary.disabled' : 'inherit'}
                variant="button/general"
                fontWeight={600}
              >
                {capitalize(option.title)}
              </Text>
            </StyledToggleButton>
          ))}
        </ToggleButtonList>
        {hasLockedFeature && showLock && (
          <ProTag
            onClick={() => {
              openLockedFeatureModal({})
            }}
          />
        )}
      </Stack>
    </Box>
  )
}

export const MinimalDisplayToggle: React.FC<DisplayToggleProps> = ({
  onChange,
  displayType,
  displayOptions
}) => {
  return (
    <ToggleButtonList
      selectedValue={displayType}
      onChange={onChange}
      padding="4px"
      width="100%"
      backgroundColor={'rgba(118, 118, 128, 0.24)'}
      background="secondary.border"
      justifyContent="space-between"
      {...stopPropagationEvents}
    >
      {displayOptions.map(displayOption => (
        <MobileStyledToggleButton
          key={displayOption.value}
          border="none"
          inverse
          height="24px"
          value={displayOption.value}
        >
          <Text color="inherit" variant="body/medium" fontFamily={theme.fontFamily}>
            {displayOption.title}
          </Text>
        </MobileStyledToggleButton>
      ))}
    </ToggleButtonList>
  )
}
