import * as Yup from 'yup'
import { CSSProperties, ChangeEvent, FC, ReactNode, useCallback } from 'react'
import { FLOWPAGE_SLUG_REGEX } from '@app/common/src/constants/regex'
import { FormikFieldProps } from '../Formik/FormikTextInput.types'
import { Input } from '@dtx-company/shared-components/src/components/atoms/Input/index'
import { SLUG_RESERVED } from '@dtx-company/flow-codegen/src/page/queries'
import {
  SlugReservedQuery,
  SlugReservedQueryVariables
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { flowpageRoot } from '../../constants'
import { pageGqlFetcher } from '@dtx-company/inter-app/src/services/gqlFetcher'
import styled from 'styled-components'

export const SlugInputFormik: FC<FormikFieldProps> = props => {
  const {
    form: { setFieldValue },
    field: { name, value },
    label
  } = props
  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      setFieldValue(name, value ? value.toLowerCase().replace(' ', '') : '')
    },
    [setFieldValue, name]
  )

  return (
    <Input
      margin="8px 0px"
      type="text"
      maxWidth="100%"
      startAdornment={<Text>{flowpageRoot}</Text>}
      value={value}
      placeholder={label?.toString()}
      onChange={onChange}
    />
  )
}

interface FileUploadProps {
  handleUpload: (arg0: ChangeEvent<HTMLInputElement>) => void | Promise<void>
  id?: string
  accept?: string
  multiple?: boolean
  position?: CSSProperties['position']
  width?: CSSProperties['width']
  children?: ReactNode
}

const StyledFileUploadInput = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  cursor: pointer;
  height: 100%;
  opacity: 0;
`

export const FileUploadInput: FC<FileUploadProps> = ({
  // id, TODO: Revisit usage of htmlFor in linking <label> with <input>; This was causing errors at uploads
  handleUpload,
  accept,
  multiple,
  children,
  position = 'initial',
  width
}) => (
  <label style={{ cursor: 'pointer', position, width }}>
    {children}
    <StyledFileUploadInput
      type="file"
      accept={accept || 'image/png,image/jpeg,image/svg'}
      multiple={multiple || false}
      onChange={e => {
        handleUpload(e)
        /** Workaround for a bug when uploading the same file twice */
        e.target.value = ''
      }}
      data-testid="FileUploadInput"
    />
  </label>
)

export function useSlugValidation({
  submitEvent,
  invalidEvent
}: {
  invalidEvent?: () => void
  submitEvent?: () => void
}): Yup.Schema<{ slugName: string }> {
  return Yup.object({
    slugName: Yup.string()
      .required('Name is required')
      .matches(FLOWPAGE_SLUG_REGEX, 'Must not have special characters')
      .notOneOf(process.env.RESERVED_PAGES?.split(',') ?? [], 'This link is reserved!')
      .test('slug', 'Flowpage exists', async function (this, value) {
        const { createError, path } = this
        submitEvent?.()
        if (
          (
            await pageGqlFetcher<SlugReservedQuery, SlugReservedQueryVariables>(SLUG_RESERVED, {
              slug: value
            })
          ).slugReserved
        ) {
          invalidEvent?.()
          return createError({ path, message: 'This name is reserved' })
        }
        return true
      })
  })
}
