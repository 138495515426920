import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { FC } from 'react'
import { HoveredTooltip } from '@dtx-company/shared-components/src/components/atoms/HoveredTooltip/index'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image/index'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { capitalizeDestination } from '../../../utils/capitalize'
import { logoUrlForType } from '../../../utils/main'
import styled from 'styled-components'

export const StyledSocialImage = styled(Image)`
  img {
    object-fit: cover;
  }
`
export const DestinationAdd: FC<{
  links: SocialLinkType[]
  onClick: (link: SocialLinkType) => void
  mobile: boolean
}> = ({
  links,
  onClick,
  mobile
}: {
  links: SocialLinkType[]
  onClick: (link: SocialLinkType) => void
  mobile: boolean
}) => {
  return (
    <>
      <Text variant={'body/small'} ml="4px" mt="8px" mb={mobile ? '6px' : '4px'}>
        Standard Links
      </Text>
      <Box flexWrap="wrap">
        {links.map(link => (
          <HoveredTooltip
            key={link.provider}
            text={link.displayText || capitalizeDestination(link.provider)}
            disable={mobile}
            tooltipProps={{ width: 'max-content' }}
            textProps={{ color: 'white' }}
          >
            <Button
              colorVariant="primaryOnDark"
              onClick={() => onClick(link)}
              m="4px"
              p="0"
              width="40px"
              id={`add-${link.provider}`}
              height="40px"
              border="1px solid"
              borderColor="secondary.border"
            >
              {link.provider === 'link' ? (
                <StyledSocialImage
                  alt="link icon"
                  src={'/icons/social-media/link-small.svg'}
                  width="37.5px"
                  height="37.5px"
                />
              ) : (
                <StyledSocialImage
                  alt="social icon"
                  src={logoUrlForType(link.provider)}
                  width="26px"
                  height="26px"
                />
              )}
            </Button>
          </HoveredTooltip>
        ))}
      </Box>
    </>
  )
}
