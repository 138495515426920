import * as React from 'react'
import { Icon } from '../Icon'
import { IconBaseProps, IconProps, Option, SelectOption, TextStyleProps } from '../../../types'
import { Options } from '../Options'
import { SelectButton, SelectButtonProps, SelectContainer, SelectContainerProps } from './styles'
import { Text } from '../Text'
import { useRef, useState } from 'react'
import arrowDown from '../../../static/icons/arrow-down.svg'
import arrowUp from '../../../static/icons/arrow-up.svg'

export interface SelectProps {
  label?: string
  labelProps?: TextStyleProps
  name: string
  value?: SelectOption
  options: SelectOption[]
  placeholder?: string
  defaultValue?: SelectOption
  onDisabledClick?: (option: SelectOption) => void
  onOptionClick?: (option: SelectOption) => void
  onChange: (
    option: SelectOption,
    e?: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent
  ) => void
  disabled?: boolean
  error?: string
  selectContainerProps?: SelectContainerProps
  selectButtonProps?: SelectButtonProps
  iconProps?: IconProps & IconBaseProps
  optionsMaxHeight?: string
  disabledOptions?: SelectOption[]
  onDropdownClick?: () => void
  iconUpSrc?: string
  iconDownSrc?: string
}

export const Select = ({
  label,
  labelProps,
  options,
  placeholder = 'Select',
  defaultValue,
  onOptionClick,
  value,
  name,
  disabled,
  error,
  onChange,
  selectContainerProps,
  selectButtonProps,
  iconProps,
  onDisabledClick,
  disabledOptions,
  optionsMaxHeight,
  onDropdownClick,
  iconUpSrc = arrowUp,
  iconDownSrc = arrowDown
}: SelectProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<SelectOption>({
    label: defaultValue?.label || '',
    value: defaultValue?.value || '',
    icon: defaultValue?.icon
  })
  const [focused, setFocused] = useState<number>(() => {
    const index =
      (Array.isArray(options) &&
        options.findIndex(option => option?.value === defaultValue?.value)) ||
      -1
    return index >= 0 ? index : 0
  })

  React.useEffect(() => {
    if (value) {
      setSelected(value)
    }
  }, [value])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    // up
    if (e.keyCode === 38) {
      const index = focused - 1 >= 0 ? focused - 1 : options?.length - 1
      setFocused(index)
      e.preventDefault()
    }

    // down
    if (e.keyCode === 40) {
      const index = focused + 1 <= options?.length - 1 ? focused + 1 : 0
      setFocused(index)
      e.preventDefault()
    }

    // enter
    if (e.keyCode === 13) {
      setOpen(!open)
      setSelected(options[focused])
    }

    // esc
    if (e.keyCode === 27 && open) {
      setOpen(false)
    }
  }

  const handleEnter = (
    e: React.KeyboardEvent,
    option: Option | SelectOption,
    idx: number
  ): void => {
    setOpen(!open)
    setSelected(options[idx])
  }
  const handleOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent,
    option: Option | SelectOption,
    index: number
  ): void => {
    const selectOption = option as SelectOption
    setSelected(selectOption)
    setFocused(index)
    onChange?.(selectOption, e)
    onOptionClick?.(selectOption)
    setOpen(false)
  }
  const handleDisabledOptionClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option | SelectOption,
    index: number
  ): void => {
    const selectOption = option as SelectOption
    setSelected(selectOption)
    setFocused(index)
    onDisabledClick?.(selectOption)
    setOpen(false)
  }

  return (
    <div>
      {label && (
        <Text variant="body/small" {...labelProps}>
          {label}
        </Text>
      )}
      <SelectContainer height="44px" {...selectContainerProps}>
        <SelectButton
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-disabled={disabled}
          // move disabled color here
          bg={(!open && selected?.value && 'secondary.backgroundDark') || ''}
          disabled={disabled}
          onBlur={() => setOpen(false)}
          onClick={() => {
            !disabled && setOpen(!open)
            onDropdownClick && onDropdownClick()
          }}
          onKeyDown={handleKeyDown}
          ref={containerRef}
          role="button"
          tabIndex={0}
          borderRadius={1}
          {...selectButtonProps}
          {...(error && { backgroundColor: 'status.errorLight', borderColor: 'status.errorDark' })}
        >
          {selected?.icon}
          {selected?.label || placeholder}
          <Icon
            width="12px"
            height="12px"
            display="flex"
            // add render function
            src={open ? iconUpSrc : iconDownSrc}
            alt={open ? 'Close dropdown' : 'Open dropdown'}
            {...iconProps}
          />
        </SelectButton>

        <Options
          options={options}
          onOptionSelect={handleOptionClick}
          disabled={disabled}
          open={open}
          onOptionEnter={handleEnter}
          focused={focused}
          onDisabledOptionSelect={handleDisabledOptionClick}
          disabledOptions={disabledOptions}
          optionsMaxHeight={optionsMaxHeight}
        />

        <input type="hidden" aria-label="hidden-input" name={name} value={selected?.value} />
      </SelectContainer>
      {error && (
        <Text height="0" variant="body/small" color="status.errorDark">
          {error}
        </Text>
      )}
    </div>
  )
}
