import {
  AnalyticsCollectorActionType,
  AnalyticsCollectorDisplayTypeMap,
  FlowpageAnalyticsCollectorEventTypes as EventType
} from '@dtx-company/inter-app/src/event-tracking/types'
import { CookieClassification } from '@dtx-company/true-common/src/types/cookies'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { fireFlowpageAnalyticsCollectorEvent } from '@dtx-company/inter-app/src/event-tracking/helpers'
import { useCallback } from 'react'
import { useCookieState } from '@dtx-company/inter-app/src/redux/selectors/useCookieState'
import { useIsEU } from '@dtx-company/inter-app/src/redux/selectors/currentSessionSelectors'
import { usePageDisplayContext } from '../context/page-display'

interface TrackLinkClickProps {
  link_id?: string
  parent_link_id?: string
}
interface TrackCRMWidgetActionProps {
  link_id?: string
  actionType: AnalyticsCollectorActionType
  displayType?: Link['displayType']
}

export interface TrackerOptions {
  useHeap?: boolean
  headers?: Record<string, string | null | undefined>
  latitude?: number
  longitude?: number
  accuracy?: number
  eventId?: string
  shouldCollectEventId?: boolean
  isInterstitial?: boolean
}

const noLinkId = (link_id?: string): boolean => {
  if (!link_id) {
    console.error('No link id provided')
    return true
  }
  return false
}

const noDisplayType = (displayType?: Link['displayType']): boolean => {
  if (!displayType) {
    console.error('No link display type provided')
    return true
  }
  return false
}

/*
  Stores the time at which the hook loaded. Used to report when a page view took place, 
  in case some additional steps prevent the page view from firing right away, such as 
  the case with geo-enabled pages.
*/
const initialTimeAtLoad = Date.now()
export interface FlowpageAnalyticsCollector {
  trackLinkClick: (properties: TrackLinkClickProps, options?: TrackerOptions) => void
  trackPageView: (options?: TrackerOptions) => Promise<string | undefined>
  trackCRMWidgetAction: (properties: TrackCRMWidgetActionProps) => void
}

export function useFlowpageAnalyticsCollector({
  isPreview,
  isEditMode
}: {
  isPreview: boolean
  isEditMode: boolean
}): FlowpageAnalyticsCollector {
  const {
    isOwnPage,
    page: { id: pageId },
    geoData
  } = usePageDisplayContext()
  const isEU = useIsEU()
  const { cookiePreferences } = useCookieState()
  const hasAcceptedFunctionalCookies = cookiePreferences[CookieClassification.functional]
  const hasValidPageId = pageId && pageId !== 'default-page'
  const excludeGeoInformation = (isEU ?? true) && !hasAcceptedFunctionalCookies //default to true if isEU is undefined
  const shouldTrack = hasValidPageId && !isOwnPage && !isPreview && !isEditMode
  const trackLinkClick = useCallback(
    ({ link_id, parent_link_id }: TrackLinkClickProps) => {
      if (!shouldTrack || noLinkId(link_id)) return
      const clientEventTime = Date.now()
      fireFlowpageAnalyticsCollectorEvent(EventType.LINK_CLICK, {
        page_id: pageId,
        link_id,
        parent_link_id,
        ...(!excludeGeoInformation && {
          latitude: geoData?.latitude,
          longitude: geoData?.longitude,
          isInterstitial: geoData?.isInterstitial
        }),
        accuracyMeters: geoData?.accuracyMeters,
        clientEventTime
      })
    },
    [pageId, shouldTrack, geoData, excludeGeoInformation]
  )

  const trackPageView = useCallback(
    async (options?: TrackerOptions): Promise<string | undefined> => {
      if (!shouldTrack) return
      const eventId = await fireFlowpageAnalyticsCollectorEvent(EventType.PAGE_VIEW, {
        page_id: pageId,
        headers: options?.headers,
        ...(!excludeGeoInformation && {
          latitude: options?.latitude,
          longitude: options?.longitude,
          isInterstitial: options?.isInterstitial
        }),
        accuracyMeters: options?.accuracy,
        clientEventTime: initialTimeAtLoad,
        eventId: options?.eventId,
        shouldCollectEventId: options?.shouldCollectEventId
      })

      if (typeof eventId === 'string') return eventId
      return undefined
    },
    [pageId, shouldTrack, excludeGeoInformation]
  )

  const trackCRMWidgetAction = useCallback(
    ({ link_id, actionType, displayType }: TrackCRMWidgetActionProps & { link_id?: string }) => {
      if (!shouldTrack || noLinkId(link_id) || noDisplayType(displayType)) return
      const clientEventTime = Date.now()
      fireFlowpageAnalyticsCollectorEvent(EventType.CRM_WIDGET_ACTION, {
        page_id: pageId,
        link_id,
        actionType,
        displayType: AnalyticsCollectorDisplayTypeMap(displayType),
        clientEventTime,
        ...(!excludeGeoInformation && {
          latitude: geoData?.latitude,
          longitude: geoData?.longitude,
          isInterstitial: geoData?.isInterstitial
        })
      })
    },
    [pageId, shouldTrack, geoData, excludeGeoInformation]
  )

  return {
    trackLinkClick,
    trackPageView,
    trackCRMWidgetAction
  }
}
