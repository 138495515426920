import { Box } from '../../../../src/components/atoms/Box/index'
import { MaskObj } from './constants'
import { MaskType } from '../../../../src'
import { canvasToBlob } from '@dtx-company/true-common/src/utils/blob'
import { useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'

interface AutomaticDefaultCropperComponentProps {
  imageUrl: string
  maskType: MaskType
  onDefaultCropDone: (imgSrc: string) => void
}

/**
 * Component to perform image cropping without user input according to the
 * passed maskType. This component does not visually display to the user.
 */
export const AutomaticDefaultCropperComponent = ({
  imageUrl,
  maskType,
  onDefaultCropDone
}: AutomaticDefaultCropperComponentProps): JSX.Element => {
  const avatarEditorRef = useRef<AvatarEditor>(null)

  const save = async (): Promise<void> => {
    if (avatarEditorRef.current) {
      const canvas = avatarEditorRef.current.getImage()
      const blob = await canvasToBlob(canvas, 'image/png')
      const imageUrl = URL.createObjectURL(blob)
      onDefaultCropDone(imageUrl)
    }
  }

  return (
    <Box display="none">
      <AvatarEditor
        ref={avatarEditorRef}
        image={imageUrl}
        border={50}
        borderRadius={MaskObj[maskType]?.borderRadius || 0}
        color={[25, 25, 25, 0.5]}
        scale={1}
        rotate={0}
        width={MaskObj[maskType]?.width || undefined}
        height={MaskObj[maskType]?.height || undefined}
        onImageReady={save}
      />
    </Box>
  )
}
