import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ContactFormState, ContactInfoDisplayTypes } from '../types'
import { ContactInfoDisplayToggle } from '../ContactInfoDisplayToggle'
import { ContactInfoFields } from '../utils'
import { DisplayToggle } from '../../components/DisplayToggle'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'

export interface ContactInfoLinkStyleFormProps {
  setValueAndPreview: ContactFormState['setValueAndPreview']
  watchAll: ContactFormState['watchAll']
}

/**
 * Toggles that control the look and feel of contactInfo link on the page
 */
export const ContactInfoLinkStyleForm = ({
  watchAll,
  setValueAndPreview
}: ContactInfoLinkStyleFormProps): JSX.Element => {
  const isLinkCardDisplayType = watchAll.displayType === ContactInfoDisplayTypes.LINK_CARD
  const isAllowDownloadVCardOn = watchAll.card

  return (
    <Box justifyContent="space-between" width="100%" flexDirection={'column'}>
      {
        /* Only display the Icon/Card toggle if the user has toggled on the "Allow
          your visitors to download your virtual contact card." checkbox */
        isAllowDownloadVCardOn && (
          <DisplayToggle
            onChange={(_e, value) => setValueAndPreview(ContactInfoFields.DISPLAY_TYPE, value)}
            displayType={
              isLinkCardDisplayType
                ? ContactInfoDisplayTypes.LINK_CARD
                : ContactInfoDisplayTypes.DEFAULT
            }
            displayOptions={[
              { title: 'Icon', value: 'default' },
              { title: 'Card', value: 'link-card' }
            ]}
            name="display-type"
            title="Display Type"
          />
        )
      }
      {
        /* With addition of ContactInfoDisplayTypes.LINK_CARD as a displayType for the contact-info link
        we want to hide the icon customization options like Icon Size, Icon Fill and Icon style
        because they don't apply when the link is displaying as a link card */
        !isLinkCardDisplayType && (
          <>
            <DisplayToggle
              onChange={(_e, value) =>
                setValueAndPreview(ContactInfoFields.ICON_SIZE, parseInt(value))
              }
              displayType={watchAll.iconSize?.toString()}
              displayOptions={[
                { title: 'Small', value: '24' },
                { title: 'Medium', value: '32' },
                { title: 'Large', value: '44' }
              ]}
              name="icon-size-toggle"
              title="Icon Size"
            />
            <DisplayToggle
              onChange={(_e, value) => setValueAndPreview(ContactInfoFields.ICON_FILL, value)}
              displayType={watchAll.iconFill}
              displayOptions={[
                { title: 'Outline', value: 'outline' },
                { title: 'Fill', value: 'fill' }
              ]}
              name="icon-fill-toggle"
              title="Icon Fill"
            />
            <ContactInfoDisplayToggle
              setValue={setValueAndPreview}
              displayType={watchAll.displayType}
            />
            <Spacer mb="16px" />
          </>
        )
      }
    </Box>
  )
}
