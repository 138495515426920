import { BoxProps } from '@dtx-company/shared-components/src/types'
import { LinkShape } from '../../../../constants/customizeTheme'

export function getLinkShape(shape: LinkShape): Omit<BoxProps, 'children' | 'onClick'> {
  switch (shape) {
    case LinkShape.ROUND:
      return {
        borderRadius: '40px'
      }
    case LinkShape.SQUARE:
      return {
        borderRadius: 0
      }
    case LinkShape.DEFAULT:
    default:
      return {
        borderRadius: 3
      }
  }
}
