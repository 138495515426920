import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { RemoveBrandingCard } from '../../../../../../components/RemoveBranding/RemoveBrandingCard'
import { stopPropagationEvents } from '../../LinkEditDrawer/LinkEditDrawer.utils'

/**
 * Wrapper for RemoveBrandingCard with styles for mobile
 */
export const RemoveBrandingMenu: FC = () => {
  return (
    <Box p="16px 3px" flexDirection="column" {...stopPropagationEvents}>
      <RemoveBrandingCard />
    </Box>
  )
}
