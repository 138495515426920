import * as icons from '../../../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { Image } from '@dtx-company/shared-components/src/components/atoms/Image'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { useAddSectionCallbacks } from './AddSection.hooks'

export const AddSection: FC = () => {
  const {
    handleContactLinksOpen,
    handleImageSectionLinkOpen,
    handleTextBoxSectionLinkOpen,
    handleContactCollectionFormOpen
  } = useAddSectionCallbacks()
  return (
    <Box padding="16px" flexDirection="column" width="100%" alignItems="center">
      <Spacer mb="16px" />
      <Text variant="button/general" fontWeight={700}>
        Select a section to add to your page
      </Text>
      <Spacer mb="16px" />

      <SecondaryButton onClick={handleTextBoxSectionLinkOpen} width="100%">
        <Image src={icons.textOutlineBlue} alt="" height="20px" width="20px" />
        <Spacer mr="8px" />
        <Text color="primary.flowBlue" variant="button/general">
          Text Box
        </Text>
      </SecondaryButton>
      <Spacer mb="16px" />
      <SecondaryButton onClick={handleContactLinksOpen} width="100%">
        <Image src={'/icons/contact-info/call-blue.svg'} alt="" height="20px" width="20px" />
        <Spacer mr="8px" />
        <Text color={'primary.flowBlue'} variant="button/general">
          Contact Information
        </Text>
      </SecondaryButton>

      <Spacer mb="16px" />
      <SecondaryButton onClick={handleContactCollectionFormOpen} width="100%">
        <Image src={icons.saveContactBlue} alt="" height="20px" width="20px" />
        <Spacer mr="8px" />
        <Text color={'primary.flowBlue'} variant="button/general">
          Contact Collection Form
        </Text>
      </SecondaryButton>
      <Spacer mb="16px" />
      <SecondaryButton onClick={handleImageSectionLinkOpen} width="100%">
        <Image src={icons.photoOutlinedBlue} alt="" height="20px" width="20px" />
        <Spacer mr="8px" />
        <Text color="primary.flowBlue" variant="button/general">
          Image
        </Text>
      </SecondaryButton>
    </Box>
  )
}
