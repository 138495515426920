import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { FC } from 'react'
import { Link, SocialLinkSmileMinimal } from '@dtx-company/shared-components/src/foundation/Icons'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer'
import { Star } from '@dtx-company/shared-components/src/foundation/Icons/Product/Star'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { stopPropagationEvents } from '../LinkEditDrawer/LinkEditDrawer.utils'
import { theme } from '@dtx-company/shared-components/src'
import { useAddAllLinksCallbacks } from './AddAllLinks.hooks'

export const AddAllLinks: FC = () => {
  const { handleAdvancedLinkOpen, handleSocialLinkOpen, handleStandardLinkOpen } =
    useAddAllLinksCallbacks()
  return (
    <Box padding="16px" flexDirection="column" width="100%" alignItems="center">
      <Spacer mb="16px" />
      <Text variant="button/general" fontWeight={700}>
        Select a link type to add to your page
      </Text>
      <Spacer mb="16px" />
      <SecondaryButton
        id="add-link"
        onClick={handleStandardLinkOpen}
        {...stopPropagationEvents}
        width="100%"
      >
        <Link height="20px" width="20px" />
        <Spacer mr="8px" />
        <Text color="primary.flowBlue" variant="button/general">
          Standard Link
        </Text>
      </SecondaryButton>
      <Spacer mb="16px" />
      <SecondaryButton onClick={handleSocialLinkOpen} {...stopPropagationEvents} width="100%">
        <SocialLinkSmileMinimal height="20px" width="20px" color={theme.colors.primary.flowBlue} />
        <Spacer mr="8px" />
        <Text color="primary.flowBlue" variant="button/general">
          Social Link
        </Text>
      </SecondaryButton>
      <Spacer mb="16px" />
      <SecondaryButton onClick={handleAdvancedLinkOpen} {...stopPropagationEvents} width="100%">
        <Star height="20px" width="20px" color={theme.colors.primary.flowBlue} />
        <Spacer mr="8px" />
        <Text color="primary.flowBlue" variant="button/general">
          Advanced Link
        </Text>
      </SecondaryButton>
    </Box>
  )
}
