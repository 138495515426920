import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { EditTheme } from '../../../../../../components/profile/Customize/types'
import { FC } from 'react'
import { LinkColorPicker } from '../../../../Customize/LinkColorPicker'
import { LinkStyleCard } from '../../../../../../components/profile/Customize/LinkStyle'
import { PageType } from '@dtx-company/flow-codegen/src/page/typeUtils'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { defaultUserTheme } from '@dtx-company/true-common/src/constants/page'
import { getLockedFieldFromTemplateConfig } from '../../../../../FlowpageTemplates/utils/getLockedFieldFromTemplateConfig'
import { useEditThemeWithAutosave } from '../../../../../../components/profile/Customize/hooks'
import { usePageTemplateEditorState } from '@dtx-company/inter-app/src/hooks/usePageTemplateEditorState'

export const LinksMenu: FC<{ pageTheme: PageType['theme'] }> = ({ pageTheme }) => {
  const { __typename, ...curTheme } = pageTheme || defaultUserTheme
  const editTheme = useEditThemeWithAutosave()
  const { isTemplateEditor } = usePageTemplateEditorState()
  const handleSelect = (newTheme: EditTheme): void => {
    editTheme({
      themeInput: {
        ...curTheme,
        ...newTheme
      },
      themeId: curTheme.id
    })
  }
  const styleDisabled = getLockedFieldFromTemplateConfig({
    templateConfig: pageTheme?.templateConfig,
    field: 'linkStyle'
  })
  const colorDisabled = getLockedFieldFromTemplateConfig({
    templateConfig: pageTheme?.templateConfig,
    field: 'linkColor'
  })

  return (
    <Box padding="16px 3px" flexDirection="column">
      <LinkStyleCard
        currShape={curTheme.linkShape ?? ''}
        currStyle={curTheme.linkStyle ?? ''}
        handleSelect={handleSelect}
        disabled={styleDisabled && !isTemplateEditor}
      />
      <Spacer mt="8px" />
      <LinkColorPicker
        handleChange={handleSelect}
        linkColor={curTheme.linkColor}
        shadowColor={curTheme.shadowColor}
        borderColor={curTheme.borderColor}
        disabled={colorDisabled && !isTemplateEditor}
      />
    </Box>
  )
}
