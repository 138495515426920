import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import styled, { css } from 'styled-components'

export const Wrapper = styled(Box)`
  box-sizing: unset;
`
export const StyledFieldSet = styled.fieldset`
  border: none;
  margin: 0;
  padding: 0;
`

export const CheckBoxWrapper = styled(Box)<{ error?: boolean }>`
  ${props =>
    props.error &&
    css`
      .checkbox-custom {
        border-color: red;
      }
      p {
        color: red;
      }
    `}
`

export const SubLinkWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
`

export const FormFields = styled(Box)`
  display: block;
  > div {
    margin: 16px 0;
  }
`
export const StyledLink = styled.a`
  display: inline;
  text-decoration: none;
  cursor: pointer;
`

export const SocialLinkChip = styled.a`
  text-decoration: none;
  cursor: pointer;
`

export const StyledSSO = styled(Box)`
  ul + div {
    z-index: 0;
  }
`

export const StyledDivider = styled.div`
  width: 2px;
  height: 28px;
  background-color: #8e9091;
  margin: 0 20px;
  @media (min-width: 1024px) {
    margin: 0 50px;
  }
`

export const LinkButton = styled(Button)`
  background-color: transparent;
  width: auto;
  border: none;
  outline: none;
  padding-top: 8px;
  &:hover {
    background-color: transparent;
  }
`
