import { SVGProps, memo } from 'react'

function PromotedItemSvg({ stroke = '#111', ...rest }: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" {...rest}>
      <path
        d="M4.95623 6.5673L3.98194 2.07056H1.40381"
        stroke="#888888"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6574 21.4314C19.5471 21.4314 19.4393 21.4644 19.3478 21.526C19.2563 21.5876 19.1853 21.6752 19.1438 21.7774C19.1023 21.8796 19.0922 21.9919 19.1148 22.0998C19.1375 22.2078 19.1918 22.3066 19.2709 22.3835C19.3499 22.4604 19.4502 22.512 19.5587 22.5317C19.6673 22.5514 19.7792 22.5382 19.8803 22.4939C19.9813 22.4497 20.0668 22.3763 20.1259 22.2831C20.185 22.1899 20.215 22.0813 20.212 21.971C20.2081 21.8265 20.148 21.6893 20.0444 21.5885C19.9408 21.4877 19.802 21.4313 19.6574 21.4314"
        stroke="#888888"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.12863 21.4314C9.05652 21.4294 8.98474 21.4418 8.91753 21.4681C8.85032 21.4943 8.78905 21.5337 8.73735 21.584C8.68564 21.6343 8.64455 21.6945 8.6165 21.7609C8.58845 21.8274 8.57401 21.8988 8.57404 21.971C8.57404 22.1181 8.63247 22.2591 8.73647 22.3631C8.84048 22.4671 8.98155 22.5256 9.12863 22.5256C9.25819 22.504 9.37591 22.4373 9.46084 22.3371C9.54576 22.2369 9.59236 22.1098 9.59236 21.9785C9.59236 21.8471 9.54576 21.7201 9.46084 21.6199C9.37591 21.5197 9.25819 21.4529 9.12863 21.4314"
        stroke="#888888"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.09697 16.7313L5.04539 7.07291H22.742C22.7517 7.07291 22.7615 7.07277 22.7713 7.07248C22.8482 7.07022 22.9246 7.08579 22.9945 7.11797C23.0645 7.15016 23.126 7.19808 23.1743 7.25798C23.2226 7.3179 23.2564 7.38817 23.273 7.46331C23.2896 7.53812 23.2887 7.61573 23.2704 7.69013C23.2704 7.69046 23.2703 7.6908 23.2702 7.69113L21.2473 15.7678L21.2465 15.7708C21.1477 16.1708 20.9276 16.5304 20.6165 16.8004C20.3054 17.0703 19.9186 17.2375 19.5088 17.2791C19.5087 17.2791 19.5086 17.2792 19.5084 17.2792L9.28742 18.2983L9.28741 18.2982L9.27461 18.2996C8.78171 18.3552 8.28577 18.2254 7.88327 17.9355C7.48085 17.6457 7.20061 17.2166 7.09704 16.7316C7.09702 16.7315 7.097 16.7314 7.09697 16.7313Z"
        fill="#EC6FDB"
        stroke="#E326CA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const PromotedItem = memo(PromotedItemSvg)
