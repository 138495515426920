import * as icons from '../../../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ChangeEvent, FC } from 'react'
import { Edit, FileUploadInput, Refresh, Upload, theme } from '@dtx-company/shared-components/src'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { SecondaryButton } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { exceedsMaxFileSize } from '@dtx-company/shared-components/src/components/organisms/ImageEdit/utils'
import { featuredLogoUrlForProvider } from '../utils'
import { logoUrlForType } from '../../../../../utils/main'
import { sendErrorNotification } from '@dtx-company/inter-app/src/utils/notifications'
import { uploadBold } from '@app/code/src/assets'
import styled from 'styled-components'

interface IconEditorProps {
  provider: string
  imageUrl?: string
  setImage: (imageUrl: string) => void
  resetImage: () => void
  featured: boolean
}

export const IconEditor: FC<IconEditorProps> = ({
  provider,
  imageUrl,
  setImage,
  resetImage,
  featured
}) => {
  const hasImageUrl = imageUrl && imageUrl.length > 0
  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>): void => {
    const stagedImage = e.target.files?.[0]
    if (stagedImage) {
      if (exceedsMaxFileSize(stagedImage.size)) {
        sendErrorNotification('Image is too large to upload (size limit 10MB)')
      } else {
        setImage(URL.createObjectURL(stagedImage))
      }
    }
  }
  if (featured) {
    return (
      <Box
        backgroundSize="100% 100%"
        backgroundImage={`url(${hasImageUrl ? imageUrl : featuredLogoUrlForProvider(provider)})`}
        backgroundRepeat="no-repeat"
        backgroundPosition="center"
        height="72px"
        width="260px"
        borderRadius="4px"
        p="8px"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <FileUploadInput
          handleUpload={handleImageUpload}
          id={`${provider}-edit-link`}
          accept="image/png, image/jpeg"
        >
          <FeaturedEditButton>
            {hasImageUrl ? (
              <Edit fill={theme.colors.primary.white} data-testid="featured-edit-icon" />
            ) : (
              <Upload fill={theme.colors.primary.white} data-testid="featured-upload-icon" />
            )}
          </FeaturedEditButton>
        </FileUploadInput>
        {hasImageUrl && (
          <FeaturedEditButton onClick={resetImage}>
            <Refresh fill={theme.colors.primary.white} data-testid="featured-reset-icon" />
          </FeaturedEditButton>
        )}
      </Box>
    )
  } else {
    return (
      <Box alignItems="center" position="relative" width="100%">
        <Icon
          src={hasImageUrl ? imageUrl : logoUrlForType(provider)}
          width="55px"
          height="55px"
          alt={`${provider}-icon-display`}
          borderRadius="4px"
          objectFit="cover"
        />
        <Spacer mr="14px" />
        <Box>
          <FileUploadInput
            handleUpload={handleImageUpload}
            id={`${provider}-edit-link`}
            accept="image/png, image/jpeg"
          >
            <>
              {!hasImageUrl ? (
                <SecondaryButton as="span" px="10px">
                  <Icon src={uploadBold} alt="upload icon" width="20px" height="17px" />
                  <Spacer mr="10px" />
                  Upload an icon
                </SecondaryButton>
              ) : (
                <SecondaryButton as="span" px="12px">
                  <Icon src={icons.pencilOutlineBlue} alt="edit icon" width="20px" height="17px" />
                  <Spacer mr="8px" />
                  Edit
                </SecondaryButton>
              )}
            </>
          </FileUploadInput>
          {hasImageUrl && (
            <SecondaryButton px="12px" ml="8px" onClick={resetImage}>
              <Icon src={icons.refreshBlue} alt="reset icon" width="20px" height="17px" />
              <Spacer mr="8px" />
              Reset
            </SecondaryButton>
          )}
        </Box>
      </Box>
    )
  }
}

const FeaturedEditButton = styled(Box)`
  border: none;
  outline: none;
  background-color: rgba(17, 17, 17, 0.5);
  cursor: pointer;
  border-radius: 18px;
  height: 32px;
  width: 32px;
  margin-left: 8px;
  align-items: center;
  justify-content: center;
  padding: 6px;
  :hover {
    background-color: rgba(17, 17, 17, 0.4);
  }
`
