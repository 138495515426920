import { getBackgroundColorAsRgb, getColor, getContrastText } from '../../../utils/theme'
import { useCurrentPageId } from '@dtx-company/inter-app/src/hooks/useCurrentPageId'
import { useDeleteThemeMutation } from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/theme'
import { useResetLinkThemes } from '../../../containers/settings/ResetLinkThemes/useResetLinkThemes'

export const calcContrastText = (color: string | undefined, style: string): string => {
  const bgColorAsRgb = getBackgroundColorAsRgb(getColor(color), style)
  return getContrastText(bgColorAsRgb)
}

export const useHandleResetLinkThemes = (): { handleResetLinkThemes: () => Promise<void> } => {
  const pageId = useCurrentPageId()
  const resetLinkThemes = useResetLinkThemes()
  const handleResetLinkThemes = async (): Promise<void> => {
    try {
      await resetLinkThemes({ pageId: pageId })
      return
    } catch (err) {
      console.error('Could not reset link styles', err)
    }
  }
  return { handleResetLinkThemes }
}

export const useResetToBaseTemplateTheme = (): {
  resetToBaseTemplateTheme: (themeId?: string) => Promise<void>
} => {
  const [deleteThemeMutation] = useDeleteThemeMutation()
  const resetToBaseTemplateTheme = async (themeId?: string): Promise<void> => {
    try {
      if (themeId) {
        await deleteThemeMutation({
          isTemplateEditor: false,
          themeId
        })
      }
      return
    } catch (err) {
      console.error('Could not reset theme', err)
    }
  }
  return { resetToBaseTemplateTheme }
}
