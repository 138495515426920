import {
  GET_ASSET_DETAILS,
  GET_BASE_TEMPLATE,
  GET_FLOWPAGE_TEMPLATES,
  GET_FLOWPAGE_TEMPLATE_CHILD_PAGES,
  GET_PAGE_TEMPLATE,
  GET_PAGE_TEMPLATES_COUNT
} from '@dtx-company/flow-codegen/src/page/queries'
import {
  GetBaseTemplateQuery,
  GetBaseTemplateQueryVariables,
  GetFlowpageTemplatesQuery,
  GetFlowpageTemplatesQueryVariables,
  GetPageTemplateQuery,
  GetPageTemplateQueryVariables,
  GetPageTemplatesCountQuery,
  GetPageTemplatesCountQueryVariables,
  GetTemplateAssetDetailsQuery,
  GetTemplateChildPagesQuery,
  GetTemplateChildPagesQueryVariables,
  QueryGetAssetDetailsArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { flowpageApi } from './empty'

export const templateQueries = flowpageApi.injectEndpoints({
  endpoints: builder => ({
    templateAssetDetails: builder.query<GetTemplateAssetDetailsQuery, QueryGetAssetDetailsArgs>({
      query: variables => ({
        document: GET_ASSET_DETAILS,
        authOnly: true,
        variables
      }),
      providesTags: ['templateAssetDetails']
    }),
    pageTemplate: builder.query<GetPageTemplateQuery, GetPageTemplateQueryVariables>({
      query: variables => ({
        document: GET_PAGE_TEMPLATE,
        authOnly: true,
        variables
      }),
      providesTags: ['pageTemplate']
    }),
    pageTemplates: builder.query<GetFlowpageTemplatesQuery, GetFlowpageTemplatesQueryVariables>({
      query: variables => ({
        document: GET_FLOWPAGE_TEMPLATES,
        authOnly: true,
        variables
      }),
      providesTags: ['pageTemplates']
    }),
    baseTemplate: builder.query<GetBaseTemplateQuery, GetBaseTemplateQueryVariables>({
      query: variables => ({
        document: GET_BASE_TEMPLATE,
        authOnly: true,
        variables
      }),
      providesTags: ['pageBaseTemplate']
    }),
    pageTemplateCount: builder.query<
      GetPageTemplatesCountQuery,
      GetPageTemplatesCountQueryVariables
    >({
      query: variables => ({
        document: GET_PAGE_TEMPLATES_COUNT,
        authOnly: true,
        variables
      }),
      providesTags: ['pageTemplateCount']
    }),
    pageTemplateChildPages: builder.query<
      GetTemplateChildPagesQuery,
      GetTemplateChildPagesQueryVariables
    >({
      query: variables => ({
        document: GET_FLOWPAGE_TEMPLATE_CHILD_PAGES,
        authOnly: true,
        variables
      }),
      providesTags: ['pageTemplateChildPages']
    })
  }),
  overrideExisting: true
})

export const {
  useTemplateAssetDetailsQuery,
  usePageTemplateQuery,
  useLazyPageTemplatesQuery,
  usePageTemplatesQuery,
  useLazyTemplateAssetDetailsQuery,
  usePageTemplateCountQuery,
  usePageTemplateChildPagesQuery,
  useBaseTemplateQuery
} = templateQueries
