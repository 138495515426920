import { ContactLinkItemType } from '../ContactInfo/types'
import { FC } from 'react'
import dynamic from 'next/dynamic'

const iconMap = {
  email: {
    filled: dynamic(() => import('@mui/icons-material/Mail')),
    outlined: dynamic(() => import('@mui/icons-material/MailOutlined'))
  },
  location: {
    filled: dynamic(() => import('@mui/icons-material/LocationOn')),
    outlined: dynamic(() => import('@mui/icons-material/LocationOnOutlined'))
  },
  text: {
    filled: dynamic(() => import('@mui/icons-material/Message')),
    outlined: dynamic(() => import('@mui/icons-material/MessageOutlined'))
  },
  call: {
    filled: dynamic(() => import('@mui/icons-material/Call')),
    outlined: dynamic(() => import('@mui/icons-material/CallOutlined'))
  },
  website: {
    filled: dynamic(() => import('@mui/icons-material/Language')),
    outlined: dynamic(() => import('@mui/icons-material/LanguageOutlined'))
  }
}

export const ContactIconComponent: FC<{
  isOutline: boolean
  type?: ContactLinkItemType
  color?: string
  size?: number
}> = ({ type, color = '#000', size, isOutline }) => {
  if (!type) return null

  const Component = iconMap[type]?.[isOutline ? 'outlined' : 'filled']

  return Component ? <Component sx={{ color, fontSize: size }} /> : null
}
