import { DefaultManager, ManagerProps } from '../components/Manager/Manager'
import { Manager as EmbedManager } from '../EmbeddableMedia'
import { FC } from 'react'

export const DestinationManager: FC<ManagerProps> = ({
  hideIcon,
  link,
  editWidget,
  iconProps,
  empty = false,
  handle
}: ManagerProps) => {
  return link.embed ? (
    <EmbedManager link={link} editWidget={editWidget} handle={handle} />
  ) : (
    <DefaultManager
      hideIcon={hideIcon}
      link={link}
      editWidget={editWidget}
      empty={empty}
      handle={handle}
      iconProps={iconProps}
    />
  )
}
