import {
  InputMaybe,
  TemplateConfig,
  TemplateInput
} from '@dtx-company/flow-codegen/src/page/generated.types'
import {
  useCreateSlotMutation,
  useUpdateSlotMutation
} from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/slots'

export function useToggleLockedSlot(): (props: {
  slotId?: string
  existingTemplateConfig?: TemplateConfig
  newSlot?: {
    pageId: string
    slotMetaType: string
    value: Record<string, string | boolean>
    templateConfigInput?: InputMaybe<TemplateInput> | undefined
  }
}) => Promise<void> {
  const [updateSlotMutation] = useUpdateSlotMutation()
  const [createSlot] = useCreateSlotMutation()
  return async ({
    slotId,
    existingTemplateConfig,
    newSlot
  }: {
    slotId?: string
    existingTemplateConfig?: TemplateConfig
    newSlot?: {
      pageId: string
      slotMetaType: string
      value: Record<string, string | boolean>
      templateConfigInput?: InputMaybe<TemplateInput> | undefined
    }
  }) => {
    if (slotId) {
      await updateSlotMutation({
        slotId,
        templateConfigInput: {
          templateConfig: {
            ...existingTemplateConfig,
            order: existingTemplateConfig?.order ?? 0,
            lockAll: !existingTemplateConfig?.lockAll
          }
        }
      })
    } else if (newSlot) {
      await createSlot(newSlot)
    }
  }
}
