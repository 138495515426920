import { ActionDataTypes, ActionDataWidgets } from './types'
import { Link } from '@dtx-company/flow-codegen/src/page/generated.types'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { cleanLink } from '../../utils/cleanLink'

export function validateActionData<T extends ActionDataWidgets>(
  aData: Record<string, unknown> | null | undefined,
  expectedField: string
): aData is ActionDataTypes[T] {
  return Boolean(aData && expectedField in aData)
}

export function getValidatedActionData<T extends ActionDataWidgets>(
  aData: Record<string, unknown> | null | undefined,
  expectedField: string
): ActionDataTypes[T] | null {
  if (validateActionData<T>(aData, expectedField)) {
    return aData
  }
  return null
}
export function getValidatedActionDataOrThrow<T extends ActionDataWidgets>(
  aData: Record<string, unknown> | null | undefined,
  expectedField: string
): ActionDataTypes[T] {
  if (validateActionData<T>(aData, expectedField)) {
    return aData
  }
  throw Error(
    `Malformed action data, field ${expectedField} doesn't exist on ${JSON.stringify(aData)}`
  )
}

export function getLinkText(link: Link): string | null {
  if (link.provider === LinkProvider.WIDGET) {
    return capitalize(link.type || '')
  }
  const ad = getValidatedActionData<'destination'>(link?.actionData, 'link')
  if (!ad?.link) return null
  return link.type === LinkProvider.LINK ? ad.link : cleanLink(ad.link)
}
