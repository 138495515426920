import {
  CreateLinkMutation,
  MutationCreateLinkArgs
} from '@dtx-company/flow-codegen/src/page/generated.types'
import { GraphqlRequestBaseQuery } from '../utils'
import { MutationLifecycleApi } from '@reduxjs/toolkit/dist/query/endpointDefinitions'
import { RootState } from '../../../../redux/types'
import { flowpageAutosaveMessaging } from '@dtx-company/true-common/src/constants/messages'
import { logger } from '@dtx-company/logger'
import { pageQueries } from '../pageQueries'
import { sendErrorNotification, sendSuccessNotification } from '../../../../utils/notifications'
export type OnCreateLinkMutationQueryStartedArgs = MutationCreateLinkArgs & {
  addImage?: string | undefined
  isTemplateEditor?: boolean | undefined
}

export type OnCreateLinkMutationQueryStartedApi = MutationLifecycleApi<
  OnCreateLinkMutationQueryStartedArgs,
  GraphqlRequestBaseQuery,
  CreateLinkMutation,
  'flowpageApi'
>

/**
 * Optimistically update the page data in the store when the createLink mutation has started
 * Undo optimistic updates if there's any errors
 **/
export async function onCreateLinkMutationQueryStarted(
  { linkInput, pageId, addImage, isTemplateEditor }: OnCreateLinkMutationQueryStartedArgs,
  { dispatch, queryFulfilled, getState }: OnCreateLinkMutationQueryStartedApi
): Promise<void> {
  const linkId = linkInput.id
  const templateId = (getState() as RootState).pageReducer.pageEditorReducer.templateId
  if (!linkId) throw new Error('Error creating link - no id')
  const childLinks = (linkInput?.childLinks ?? []).map(child => {
    return {
      ...child,
      thumbNailImgUrl: linkInput.actionData[child.provider]?.thumbNailImgUrl ?? null
    }
  })
  const id = isTemplateEditor ? templateId : pageId
  const patchResult = dispatch(
    pageQueries.util.updateQueryData(
      'pageDetail',
      { id, isPageTemplate: isTemplateEditor },
      draft => {
        const newDraftLink = {
          ...linkInput,
          active: Boolean(linkInput.active),
          id: linkId,
          actionData: linkInput.actionData || {},
          thumbNailImgUrl: addImage,
          childLinks
        }

        const links = [...(draft?.Page?.links || []), newDraftLink]

        Object.assign(draft, {
          Page: {
            ...draft.Page,
            links
          }
        })
      }
    )
  )
  try {
    await queryFulfilled
    sendSuccessNotification(flowpageAutosaveMessaging.createLink.success)
  } catch (err) {
    patchResult.undo()
    logger.logError(err, { team: 'templates', technicalArea: 'flowpage' })
    sendErrorNotification(flowpageAutosaveMessaging.createLink.error)
  }
}
