export default {
  Flowpage_TemplateEditor_Clicked_ToggleLockedField:
    'Flowpage_TemplateEditor_Clicked_ToggleLockedField',
  Flowpage_Management_Clicked_TemplatesTab: 'Flowpage_Management_Clicked_TemplatesTab',
  Flowpage_Management_Clicked_CreateTemplateUpsellButton:
    'Flowpage_Management_Clicked_CreateTemplateUpsellButton',
  Flowpage_Management_Clicked_CreateTemplateButton:
    'Flowpage_Management_Clicked_CreateTemplateButton',
  Flowpage_Management_Clicked_WatchTemplatesDemoButton:
    'Flowpage_Management_Clicked_WatchTemplatesDemoButton',
  Flowpage_Management_Clicked_DeleteTemplateButton:
    'Flowpage_Management_Clicked_DeleteTemplateButton',
  Flowpage_Management_Clicked_CancelDeleteTemplateButton:
    'Flowpage_Management_Clicked_CancelDeleteTemplateButton',
  Flowpage_Management_Clicked_LearnMoreTemplateButton:
    'Flowpage_Management_Clicked_LearnMoreTemplateButton',
  Flowpage_Management_Clicked_BrowseTemplateButton:
    'Flowpage_Management_Clicked_BrowseTemplateButton',
  Flowpage_Management_Clicked_DismissTemplateBannerTooltipButton:
    'Flowpage_Management_Clicked_DismissTemplateBannerTooltipButton',
  Flowpage_Management_Clicked_DismissTemplateBannerButton:
    'Flowpage_Management_Clicked_DismissTemplateBannerButton',
  Flowpage_Management_Clicked_EditTemplateButton: 'Flowpage_Management_Clicked_EditTemplateButton',
  Flowpage_Management_Clicked_CancelEditTemplateButton:
    'Flowpage_Management_Clicked_CancelEditTemplateButton',
  Flowpage_Management_Clicked_PreviewTemplateButton:
    'Flowpage_Management_Clicked_PreviewTemplateButton',
  Flowpage_Management_Clicked_UseTemplateButton: 'Flowpage_Management_Clicked_UseTemplateButton',
  Flowpage_Management_Closed_PreviewTemplateModal:
    'Flowpage_Management_Closed_PreviewTemplateModal',
  Flowpage_Management_Opened_PreviewTemplateModalSheet:
    'Flowpage_Management_Opened_PreviewTemplateModalSheet',
  Flowpage_Management_Clicked_OpenPreviewTemplateModalContextMenuButton:
    'Flowpage_Management_Clicked_OpenPreviewTemplateModalContextMenuButton',
  Flowpage_Management_Clicked_ClosePreviewTemplateModalContextMenuButton:
    'Flowpage_Management_Clicked_ClosePreviewTemplateModalContextMenuButton',
  Flowpage_Management_Clicked_PreviewTemplateModalPreviewPageButton:
    'Flowpage_Management_Clicked_PreviewTemplateModalPreviewPageButton',
  Flowpage_Management_Clicked_TemplateCardContextMenuItem:
    'Flowpage_Management_Clicked_TemplateCardContextMenuItem',
  Flowpage_Management_Clicked_PreviewTemplateContextMenuItem:
    'Flowpage_Management_Clicked_PreviewTemplateContextMenuItem',
  Flowpage_Management_Clicked_CreateBlankPageButton:
    'Flowpage_Management_Clicked_CreateBlankPageButton',
  Flowpage_Management_Clicked_CreatePageModalFilter:
    'Flowpage_Management_Clicked_CreatePageModalFilter',
  Flowpage_Management_Focused_CreatePageModalSearchInput:
    'Flowpage_Management_Focused_CreatePageModalSearchInput',
  Flowpage_Management_Cleared_CreatePageModalSearchInput:
    'Flowpage_Management_Cleared_CreatePageModalSearchInput',
  Flowpage_Management_Clicked_CreatePageModalSearchButton:
    'Flowpage_Management_Clicked_CreatePageModalSearchButton',
  Flowpage_Management_Clicked_CreatePageModalCloseButton:
    'Flowpage_Management_Clicked_CreatePageModalCloseButton',
  Flowpage_Management_Typed_TemplateNameSearch: 'Flowpage_Management_Typed_TemplateNameSearch',
  Flowpage_Management_Hovered_PageTemplateCard: 'Flowpage_Management_Hovered_PageTemplateCard',
  Flowpage_Management_Clicked_PageTemplateCard: 'Flowpage_Management_Clicked_PageTemplateCard',
  Flowpage_Management_Clicked_PageTemplateCardUseTemplateButton:
    'Flowpage_Management_Clicked_PageTemplateCardUseTemplateButton',
  Flowpage_Management_Clicked_PageTemplateCardContextMenuButton:
    'Flowpage_Management_Clicked_PageTemplateCardContextMenuButton',
  Flowpage_Management_Clicked_CreateBlankPageCard:
    'Flowpage_Management_Clicked_CreateBlankPageCard',
  Flowpage_Management_Clicked_CloseCreatePageModalSearchInput:
    'Flowpage_Management_Clicked_CloseCreatePageModalSearchInput',
  userCreatedFlowpageFromTemplate: 'userCreatedFlowpageFromTemplate'
}
