export const bioPlaceholder = '/static/placeholders/bio-placeholder.svg'
export const shareInsta = '/static/placeholders/share_instagram.png'
export const shareTikTok = '/static/placeholders/share_tiktok.png'
export const shareEmail = '/static/placeholders/share_email.png'
export const shareSocial = '/static/placeholders/share_social.png'
export const restaurantExample = '/static/placeholders/scan_to_page.png'
export const embedPlaceholder = '/static/placeholders/embed.svg'
export const flowpageExamples = '/placeholders/flowpage-examples.svg'
export const themeBlack = '/placeholders/theme_black.svg'
export const themeWhite = '/placeholders/theme_white.svg'
export const themePink = '/placeholders/theme_pink.svg'
export const themeBlue = '/placeholders/theme_blue.svg'
export const tagPlaceholder = '/placeholders/flowtag.png'
export const digitalContactCard = '/placeholders/digitalContactCard.png'
