import styled, { css } from 'styled-components'

export const StyledInput = styled.div`
  width: 80px;
  height: 35px;
  justify-content: center;
  border: 1px solid;
  border-radius: ${({ theme }) => theme.radii[1]}px;
  color: ${({ theme }) => theme.colors.primary.flowBlue};
  background-color: ${({ theme }) => theme.colors.primary.white};
  border-color: ${({ theme }) => theme.colors.primary.flowBlue};
  cursor: pointer;
  text-align: center;
  line-height: 34px;
`

export const StyledLine = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  margin-top: 30px;
  margin-bottom: 10px;
  height: 1px;
`

export const HiddenInput = styled.input`
  display: none;
`

interface MaskButtonProps {
  $selected: boolean
}

export const CircleButton = styled.button<MaskButtonProps>`
  background-color: transparent;
  width: auto;
  border: none;
  outline: none;
  &:hover {
    background-color: transparent;
  }

  width: 31px;
  height: 31px;
  border: solid 2px #dadcdd;
  background-color: #fafdfe;
  border-radius: 16px;
  margin-right: 14px;

  ${props => {
    if (props.$selected) {
      return css`
        border-color: #033ddd;
      `
    }
    return
  }}
`
export const SquareButton = styled.button<MaskButtonProps>`
  background-color: transparent;
  width: auto;
  border: none;
  outline: none;
  &:hover {
    background-color: transparent;
  }

  width: 31px;
  height: 31px;
  border-radius: 2px;
  border: solid 2px #dadcdd;
  background-color: #f3f5f6;

  ${props => {
    if (props.$selected) {
      return css`
        border-color: #033ddd;
      `
    }
    return
  }}
`

export const UnstyledButton = styled.button`
  :hover {
    background-color: unset;
  }
  background-color: unset;
  border: none;
  align-items: center;
  padding: 0px;
  display: flex;
  cursor: pointer;
  height: auto;
`

export const ButtonContentContainer = styled.span`
  display: flex;
`
