import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ColorInputHex } from '@dtx-company/shared-components/src'
import { FC } from 'react'
import { LinkThemeState } from './types'
import { Select } from '@dtx-company/shared-components/src/components/atoms/Select/index'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

export const TEXT_SIZE_OPTIONS = [
  { label: '8', value: '8' },
  { label: '10', value: '10' },
  { label: '12', value: '12' },
  { label: '16', value: '16' },
  { label: '20', value: '20' },
  { label: '24', value: '24' }
]

export const LinkStyleForm: FC<LinkThemeState> = ({
  linkTheme,
  setLinkTheme,
  hiddenFields,
  disabled
}) => {
  return (
    <Box flexDirection="column" data-testid="link-style-form">
      <Box>
        {!hiddenFields?.fontColor && (
          <ColorInputHex
            label="Font Color"
            color={(!disabled && linkTheme?.fontColor) || ''}
            onColorChange={color => {
              events.Flowpage_PageProfile_Selected_Link_Level_Font_Color(color)
              setLinkTheme({ fontColor: color })
            }}
            width={{ _: '50%', md: '144px' }}
            disabled={disabled}
          />
        )}
        <Spacer mr="8px" />
        {!hiddenFields?.textSize && (
          <Select
            label="Text Size"
            labelProps={{ mb: '8px' }}
            options={TEXT_SIZE_OPTIONS}
            value={{
              label: (!disabled && linkTheme?.textSize?.toString()) || '',
              value: (!disabled && linkTheme?.textSize?.toString()) || ''
            }}
            name="textSize"
            onChange={option => {
              const size = parseInt(option.value)
              setLinkTheme({ textSize: size })
              events.Flowpage_PageProfile_Selected_Link_Level_Text_Size(size)
            }}
            selectButtonProps={{ bg: 'primary.white' }}
            selectContainerProps={{ width: '144px' }}
            disabled={disabled}
          />
        )}
      </Box>
      <Spacer mb="8px" />
      <Box>
        {!hiddenFields?.linkColor && (
          <ColorInputHex
            label="Link Color"
            color={(!disabled && linkTheme?.linkColor) || ''}
            onColorChange={color => {
              events.Flowpage_PageProfile_Selected_Link_Level_Link_Color(color)
              setLinkTheme({ linkColor: color })
            }}
            width={{ _: '50%', md: '144px' }}
            disabled={disabled}
          />
        )}
        <Spacer mr="8px" />
        {!hiddenFields?.borderColor && (
          <ColorInputHex
            label="Border Color"
            color={(!disabled && linkTheme?.borderColor) || ''}
            onColorChange={color => {
              events.Flowpage_PageProfile_Selected_Link_Level_Border_Color(color)
              setLinkTheme({ borderColor: color })
            }}
            width={{ _: '50%', md: '144px' }}
            disabled={disabled}
          />
        )}
        {!hiddenFields?.shadowColor && (
          <ColorInputHex
            label="Shadow Color"
            color={(!disabled && linkTheme?.shadowColor) || ''}
            onColorChange={color => {
              events.Flowpage_PageProfile_Selected_Link_Level_Shadow_Color(color)
              setLinkTheme({ shadowColor: color })
            }}
            width={{ _: '50%', md: '144px' }}
            disabled={disabled}
          />
        )}
      </Box>
    </Box>
  )
}
