import { EditTheme } from './types'
import { LinkStyle } from '../../../constants/customizeTheme'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface UseLinkStyleOptionsArgs {
  handleSelect: (values: EditTheme) => void
  currStyle?: string
}

interface UseLinkStyleOptionsReturnValue {
  linkStylesOptions: LinkStyle[]
  selectedLinkStyle?: string
  handleLinkStyleChange: (styleOption: LinkStyle) => void
  canUserEditLinkStyle: boolean
}

export const useLinkStyleOptions = ({
  handleSelect,
  currStyle
}: UseLinkStyleOptionsArgs): UseLinkStyleOptionsReturnValue => {
  const { isViewer } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  const canUserEditLinkStyle = !(isViewer || hasViewOnlyAccess)

  const availableLinkStyles = Object.values(LinkStyle)

  const handleLinkStyleChange = (styleOption: LinkStyle): void => {
    if (canUserEditLinkStyle) {
      handleSelect({ linkStyle: styleOption })
      events.Flowpage_PageProfile_Selected_LinkStyle(styleOption)
    }
  }

  return {
    linkStylesOptions: availableLinkStyles,
    selectedLinkStyle: currStyle,
    handleLinkStyleChange,
    canUserEditLinkStyle
  }
}
