export const arrowDownBlack = '/static/icons/arrow-down-black.svg'
export const arrowDownBlue = '/static/icons/arrow-down-blue.svg'
export const arrowUpBlue = '/static/icons/arrow-up-blue.svg'
export const arrowNextBlue = '/static/icons/arrow-next-blue.svg'
export const arrowNextWhite = '/static/icons/arrow-next-white.svg'
export const arrowNextBlack = '/static/icons/arrow-next.svg'
export const arrowPrev = '/static/icons/arrow-prev.svg'
export const arrowRightWhite = '/static/icons/arrow-right-white.svg'
export const checkmarkBlack = '/static/icons/checkmark-black.svg'
export const clock = '/static/icons/clock.svg'
export const close = '/static/icons/close.svg'
export const copyTransparent = '/static/icons/copy-transparent.svg'
export const copy = '/static/icons/copy.svg'
export const download = '/static/icons/download.svg'
export const embed = '/static/icons/embed.svg'
export const gear = '/icons/navigation/gear.svg'
export const gearBlack = '/icons/navigation/gear_black.svg'
export const googlesheets = '/static/icons/googlesheets.svg'
export const gsheetsLogo = '/logos/gsheets_name_and_logo.png'
export const handle = '/static/icons/handle.svg'
export const handleV2 = '/static/icons/handleV2.svg'
export const handleDark = '/static/icons/handle-dark.svg'
export const hide = '/static/icons/hide.svg'
export const lightning = '/static/icons/lightning-outline.svg'
export const lock = '/static/icons/lock.svg'
export const photoGrey = '/static/icons/photo-grey.svg'
export const plus = '/static/icons/plus.svg'
export const share = '/static/icons/share.svg'
export const checkInAllowed = '/static/icons/check-in-allowed.svg'
export const checkInDenied = '/static/icons/check-in-denied.svg'
export const customizable = '/icons/customizable.svg'
export const shareWhite = '/static/icons/share-white.svg'
export const contact = '/static/icons/contact.svg'
export const bio = '/static/icons/bio.svg'
export const move = '/static/icons/move.svg'
export const myFlowpagesDefaultUserIcon = '/icons/my-flowpages-default-user-icon.svg'
export const color = '/static/icons/color.svg'
export const display = '/static/icons/display.svg'
export const dropper = '/static/icons/dropper.svg'
export const photoWhite = '/static/icons/photo-white.svg'
export const eye = '/static/icons/eye.svg'
export const circleX = '/static/icons/circle-x.svg'
export const product = '/static/icons/product.svg'
export const under60secondsWhite = '/icons/under60secondsWhite.svg'
export const under60secondsBlack = '/icons/under60secondsBlack.svg'
export const engagementWhite = '/icons/engagementWhite.svg'
export const engagementBlack = '/icons/engagementBlack.svg'
export const holdsAllSocialWhite = '/icons/holdsAllSocialWhite.svg'
export const holdsAllSocialBlack = '/icons/holdsAllSocialBlack.svg'
export const customizableWhite = '/icons/customizableWhite.svg'
export const customizableBlack = '/icons/customizableBlack.svg'
export const graph = '/static/icons/graph.svg'
export const embedContent = '/static/icons/embedContent.svg'
export const stack = '/static/icons/stack.svg'
export const people = '/static/icons/people.svg'
export const callIcon = '/icons/contact-sharing/call.svg'
export const emailIcon = '/icons/contact-sharing/mail.svg'
export const textIcon = '/icons/contact-sharing/text.svg'
export const locateIcon = '/icons/contact-sharing/locate.svg'
export const google = '/logos/google.svg'
export const facebook = '/logos/facebook-circle.svg'
export const facebookPixelFilled = '/icons/social-media/facebook-pixel-filled.svg'
export const under60seconds = '/icons/under60seconds.svg'
export const trackEngagement = '/icons/trackEngagement.svg'
export const control = '/icons/control.svg'
export const coupon = '/icons/coupon.svg'
export const tagOutline = '/icons/tag-outline.svg'
export const shapes = '/static/icons/shapes.svg'
export const styles = '/static/icons/styles.svg'
export const shapesDark = '/icons/shapes-dark.svg'
export const shapes2Dark = '/icons/shapes2-dark.svg'
export const shapes3Dark = '/icons/shapes3-dark.svg'
export const shapes4Dark = '/icons/shapes4-dark.svg'
export const vennDiagram = '/icons/venn-diagram.svg'
export const paintPalette = '/icons/paint-palette.svg'
export const verifiedCheckmark = '/icons/verified-checkmark.svg'
export const disableMove = '/icons/actions/move-disabled.svg'
export const paintbrush = '/icons/everyday/paintbrush.svg'
export const paintbrushBlack = '/icons/everyday/paintbrush_black.svg'
export const paintbrushWhite = '/icons/everyday/paintbrush_white.svg'
export const rename = '/icons/rename.svg'
export const label = '/icons/everyday/label.svg'
export const labelWhite = '/icons/everyday/label-white.svg'
export const textOutlineWhite = '/icons/text-outline-white.svg'
export const textOutlineBlack = '/icons/text-outline-black.svg'
export const textOutlineBlue = '/icons/text-outline-blue.svg'
export const linkBlack = '/icons/product/link_black.svg'
export const plusBlack = '/icons/actions/plus_black.svg'
export const analyticsBlack = '/icons/charts/analytics-black.svg'
export const content = '/icons/product/content.svg'
export const plusWhite = '/icons/whiteplus.svg'
export const photoOutlined = '/static/icons/photo-outlined.svg'
export const photoOutlinedGray = '/static/icons/photo-outlined-gray.svg'
export const photoOutlinedBlue = '/static/icons/photo-outlined-blue.svg'
export const photoOutlinedWhite = '/static/icons/photo-outlined-white.svg'
export const linkOptionFeatured = '/static/icons/LinkOption-Featured.svg'
export const linkOptionDefault = '/static/icons/LinkOption-Thumbnail.svg'
export const pencilOutlineBlue = '/icons/pencil-outline-blue.svg'
export const upload = '/icons/actions/upload.svg'
export const uploadGrey = '/icons/actions/upload_grey.svg'
export const uploadBlue = '/icons/actions/upload_blue.svg'
export const saveContactBlue = '/icons/save-contact-blue.svg'
export const saveContactGray = '/icons/save-contact-gray.svg'
export const saveContactOutlined = '/icons/save-contact-outlined.svg'
export const show = '/icons/action/show.svg'
export const refreshBlue = '/icons/actions/refresh-blue.svg'
export const warningFilled = '/icons/warning-filled.svg'
export const internetBlack = '/icons/internet-black.svg'
export const internetOutline = '/icons/internet-outline.svg'
export const compassOutline = '/icons/compass-outline.svg'
export const linkVariantWhite = '/icons/link-variant-white.svg'

//explore modal icon assets
export const appStoreLinkColor =
  '/icons/social-media/featured/exploreWidgetIcons/app-store-link.svg'
export const contactCollectionColor =
  '/icons/social-media/featured/exploreWidgetIcons/contact-collection.svg'
export const contactInfoColor = '/icons/social-media/featured/exploreWidgetIcons/contact-info.svg'
export const embedMediaColor = '/icons/social-media/featured/exploreWidgetIcons/embed-media.svg'
export const embedSpotifyColor = '/icons/social-media/featured/exploreWidgetIcons/embed-spotify.svg'
export const embedYoutubeColor = '/icons/social-media/featured/exploreWidgetIcons/embed-youtube.svg'
export const imageColor = '/icons/social-media/featured/exploreWidgetIcons/image.svg'
export const pdfColor = '/icons/social-media/featured/exploreWidgetIcons/pdf.svg'
export const pollingColor = '/icons/social-media/featured/exploreWidgetIcons/polling.svg'
export const promotedItemColor = '/icons/social-media/featured/exploreWidgetIcons/promoted-item.svg'
export const smsColor = '/icons/social-media/featured/exploreWidgetIcons/sms.svg'
export const socialLinksColor = '/icons/social-media/featured/exploreWidgetIcons/social-links.svg'
export const standardLinkColor = '/icons/social-media/featured/exploreWidgetIcons/standard-link.svg'
export const storeHoursColor = '/icons/social-media/featured/exploreWidgetIcons/store-hours.svg'
export const storeFrontColor = '/icons/social-media/featured/exploreWidgetIcons/storefront.svg'
export const textBoxColor = '/icons/social-media/featured/exploreWidgetIcons/text-box.svg'
export const topFriendsColor = '/icons/social-media/featured/exploreWidgetIcons/top-friends.svg'
export const contactCollectionFeatured =
  '/icons/social-media/featured/exploreWidgetIcons/contact-collection-featured.svg'
export const socialLinkFeatured =
  '/icons/social-media/featured/exploreWidgetIcons/social-link-featured.svg'
export const embedYoutubeFeatured =
  '/icons/social-media/featured/exploreWidgetIcons/embed-youtube-featured.svg'
export const contactInfoFeatured =
  '/icons/social-media/featured/exploreWidgetIcons/contact-info-featured.svg'
export const ageGate = '/icons/social-media/featured/exploreWidgetIcons/age-gate.svg'
