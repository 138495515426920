import * as icons from '../../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { ChildlinkManager } from './components/ChildlinkManager'
import {
  DEFAULT_DISPLAY_TYPE,
  FEATURED_DISPLAY_TYPE,
  ICONS_ONLY,
  IconSize,
  IconStyle
} from '../types'
import { DISPLAY_TYPE } from '../../Destination/constants'
import { DefaultLinkStyle } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/DefaultLinkStyle'
import { DisplayToggleV2 } from './components/DisplayToggleV2'
import { Divider } from '@dtx-company/shared-components/src/components/atoms/Divider/index'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { FC, useMemo } from 'react'
import { FeaturedLinkStyle } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/FeaturedLinkStyle'
import { FormProps, StyledForm } from '../../components/Layout'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import { IconsOnlyStyle } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/IconsOnlyStyle'
import { Link, SocialLinkSmileMinimal, theme } from '@dtx-company/shared-components/src'
import { LinkStyleGated } from '../../components/LinkStyle/LinkStyleGated'
import { SettingsAndSaveLink } from '../../components/Settings'
import { SocialAdvLinkType, SocialLinkUrlFields } from '../../Destination/types'
import { SocialChip } from './components/SocialChip'
import { SocialLinkType } from '@dtx-company/inter-app/src/constants/linkTypes'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/index'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { reorderLinks } from './utils'
import { useDraggableInPortal } from '../../../../utils/hooks'
import { useSocialLinkFormState } from '../hooks'
import { v4 as uuid } from 'uuid'

export const SocialLinkFormV2: FC<FormProps> = ({ order, curr, handleClose }: FormProps) => {
  /*eslint-disable*/
  const memoizedCurr = useMemo(() => curr, [])
  const id = memoizedCurr ? memoizedCurr.id : uuid()
  const {
    watchAll,
    setValue,
    handleSubmit,
    onSubmit,
    updateSelectedLinks,
    selectedLinks,
    allowedSocialLinks,
    updateFormFieldValue,
    setSelectedLinks,
    errors,
    setLinkTheme,
    linkTheme,
    trigger,
    disabled,
    expandedChildLinks,
    setExpandedChildLinks
  } = useSocialLinkFormState(order, memoizedCurr, handleClose, id)
  const renderDraggable = useDraggableInPortal()
  const handleExpandChildlink = (provider: string): void => setExpandedChildLinks([provider])
  const handleDeleteChildlink = (provider: SocialLinkUrlFields): void =>
    updateSelectedLinks(provider)

  const displayIconEditors = watchAll.displayType === ICONS_ONLY
  return (
    <Box width="100%">
      <StyledForm
        padding="16px"
        onSubmit={handleSubmit(onSubmit)}
        title={
          <Box alignItems="center">
            <SocialLinkSmileMinimal height="26px" width="26px" />
            <Spacer mr="9px" />
            <Text fontSize="16px" variant="navigation/header">
              Choose your social links
            </Text>
          </Box>
        }
      >
        <Box width="100%" flexDirection="column">
          <Text variant="body/small" color="primary.grey">
            Select one or more socials to add to your page.
          </Text>
          <Spacer mb="24px" />
          <Box flexWrap="wrap">
            <Box width="100%" flexWrap="wrap">
              {allowedSocialLinks.map((linkType: SocialAdvLinkType) => (
                <SocialChip
                  provider={linkType.provider}
                  displayText={linkType.displayText ?? capitalize(linkType.provider)}
                  selected={selectedLinks.includes(linkType.provider)}
                  updateSelectedLinks={updateSelectedLinks}
                  key={`${linkType.provider}-social-chip`}
                />
              ))}
            </Box>
          </Box>
        </Box>
        <Box
          flexDirection="column"
          maxHeight={selectedLinks.length === 0 ? '0' : 'none'}
          overflow="hidden"
        >
          <Divider width="100%" />
          <Spacer mb="22px" />
          <Box alignItems="center">
            <Icon src={icons.paintPalette} height="26px" width="26px" alt="style section icon" />
            <Spacer mr="9px" />
            <Text fontSize="16px" variant="navigation/header">
              Choose your link style
            </Text>
          </Box>
          <Spacer mb="10px" />
          <Text variant="body/small" color="primary.grey">
            Select how you want your links to appear on your page.
          </Text>
          <Spacer mb="24px" />
          <Box alignItems="center" justifyItems="space-between">
            <Box width="97px">
              <Text fontSize="14px" color="primary.grey">
                Link format
              </Text>
            </Box>
            <DisplayToggleV2
              options={[
                {
                  title: 'Icon',
                  value: ICONS_ONLY,
                  image: (
                    <IconsOnlyStyle
                      width="32px"
                      height="32px"
                      stroke={
                        watchAll.displayType === ICONS_ONLY
                          ? theme.colors.primary.flowBlue
                          : theme.colors.primary.grey
                      }
                    />
                  )
                },
                {
                  title: 'Card',
                  value: DEFAULT_DISPLAY_TYPE,
                  image: (
                    <DefaultLinkStyle
                      width="32px"
                      height="32px"
                      stroke={
                        watchAll.displayType === DEFAULT_DISPLAY_TYPE
                          ? theme.colors.primary.flowBlue
                          : theme.colors.primary.grey
                      }
                    />
                  )
                },
                {
                  title: 'Featured',
                  value: FEATURED_DISPLAY_TYPE,
                  image: (
                    <FeaturedLinkStyle
                      width="32px"
                      height="32px"
                      stroke={
                        watchAll.displayType === FEATURED_DISPLAY_TYPE
                          ? theme.colors.primary.flowBlue
                          : theme.colors.primary.grey
                      }
                    />
                  )
                }
              ]}
              selectedValue={watchAll.displayType}
              onChange={(_e, type) => {
                setValue(DISPLAY_TYPE, type)
              }}
            />
          </Box>
          <Box
            flexDirection="column"
            transition="max-height, 0.8s"
            maxHeight={displayIconEditors ? '1000px' : '0px'}
            overflow="hidden"
          >
            <Spacer mb="16px" />
            <Box alignItems="center" justifyItems="space-between">
              <Box width="97px">
                <Text fontSize="14px" color="primary.grey">
                  Icon size
                </Text>
              </Box>
              <DisplayToggleV2
                options={[
                  { title: 'Standard', value: IconSize.MEDIUM },
                  { title: 'Large', value: IconSize.LARGE }
                ]}
                selectedValue={watchAll.iconSize}
                onChange={(_e, type) => {
                  setValue('iconSize', type)
                }}
              />
            </Box>
            <Spacer mb="16px" />
            <Box alignItems="center" justifyItems="space-between">
              <Box width="97px">
                <Text fontSize="14px" color="primary.grey">
                  Icon style
                </Text>
              </Box>
              <DisplayToggleV2
                options={[
                  { title: 'Circle', value: IconStyle.CIRCLE },
                  { title: 'Floating', value: IconStyle.FLOATING }
                ]}
                selectedValue={watchAll.iconStyle}
                onChange={(_e, type) => {
                  setValue('iconStyle', type)
                }}
              />
            </Box>
          </Box>
          <Spacer mb="20px" />
          <Divider width="100%" />
          <Box flexDirection="column">
            <Spacer mb="10px" />
            <Box alignItems="center">
              <Link height="18px" width="18px" stroke="black" />
              <Spacer mr="9px" />
              <Text fontSize="16px" variant="navigation/header">
                Edit your links
              </Text>
            </Box>
            <Spacer mb="10px" />
            <Text variant="body/small" color="primary.grey">
              Add your information to each link.
            </Text>
            <Spacer mb="24px" />
            <DragDropContext
              onDragEnd={dropResult =>
                reorderLinks(
                  dropResult,
                  selectedLinks,
                  updateFormFieldValue,
                  watchAll,
                  setSelectedLinks
                )
              }
            >
              <Droppable droppableId="socialLinkList">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {selectedLinks.map((linkType: string, index) => {
                      const socialLinkType = allowedSocialLinks.find(
                        (item: SocialLinkType) => item.provider === linkType
                      )
                      return (
                        <ChildlinkManager
                          idx={index}
                          key={`${socialLinkType?.provider}-childlink-manager`}
                          socialLinkType={socialLinkType}
                          renderDraggable={renderDraggable}
                          watchAll={watchAll}
                          errors={errors}
                          trigger={trigger}
                          updateFormFieldValue={updateFormFieldValue}
                          expanded={
                            socialLinkType?.provider
                              ? expandedChildLinks.includes(socialLinkType?.provider)
                              : false
                          }
                          handleExpand={handleExpandChildlink}
                          handleDeleteChildlink={handleDeleteChildlink}
                        />
                      )
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>

        <Spacer mb="16px" />
        <LinkStyleGated linkTheme={linkTheme} setLinkTheme={setLinkTheme} />

        <SettingsAndSaveLink disabled={disabled} curr={memoizedCurr} handleClose={handleClose} />
      </StyledForm>
    </Box>
  )
}
