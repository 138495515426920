export const TITLE_MAX = 64
export const PROMOTED_ITEM_TITLE_MAX_LEN = 40
export const TITLE_MAX_ERROR = `Title too long (max ${TITLE_MAX} characters)`
export const PROMOTED_ITEM_TITLE_MAX_ERROR = `Title too long (max ${PROMOTED_ITEM_TITLE_MAX_LEN} characters)`

export const DESCRIPTION_MAX = 500
export const DESCRIPTION_MAX_ERROR = `Description too long (max ${DESCRIPTION_MAX} characters)`

export const TEXTBOX_MAX = 160
export const TEXTBOX_MAX_ERROR = `Text field too long (max ${TEXTBOX_MAX} characters)`

export const CUSTOM_CRM_TERMS_MAX = 250
export const CUSTOM_CRM_TERMS_MAX_ERROR = `Custom terms and conditions text is too long (max ${CUSTOM_CRM_TERMS_MAX} characters)`
