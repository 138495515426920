// src - https://github.com/samuelmeuli/font-manager/blob/master/types.ts
export interface GoogleFont {
  family: string
  category: GoogleFontCategory
  subsets: GoogleFontScript[]
  variants: GoogleFontVariant[]
  kind?: string
  version?: string
  lastModified?: string
  files?: Record<'regular', string>
}

export interface UseGoogleFontsType {
  fonts: Array<GoogleFont>
  loading: boolean
}

export enum GoogleFontsSortBy {
  ALPHA = 'alpha',
  DATE = 'date',
  POPULARITY = 'popularity',
  STYLE = 'style',
  TRENDING = 'trending'
}

export type GoogleFontCategory = 'serif' | 'sans-serif' | 'handwriting' | 'display' | 'monospace'
export const GoogleFontCategoryList: GoogleFontCategory[] = [
  'serif',
  'sans-serif',
  'handwriting',
  'display',
  'monospace'
]

export type GoogleFontScript =
  | 'arabic'
  | 'bengali'
  | 'chinese-simplified'
  | 'chinese-traditional'
  | 'cyrillic'
  | 'cyrillic-ext'
  | 'devanagari'
  | 'greek'
  | 'greek-ext'
  | 'gujarati'
  | 'gurmukhi'
  | 'hebrew'
  | 'japanese'
  | 'kannada'
  | 'khmer'
  | 'korean'
  | 'latin'
  | 'latin-ext'
  | 'malayalam'
  | 'myanmar'
  | 'oriya'
  | 'sinhala'
  | 'tamil'
  | '​telugu'
  | 'thai'
  | 'vietnamese'

export type GoogleFontVariant =
  | '100'
  | '100italic'
  | '200'
  | '200italic'
  | '300'
  | '300italic'
  | 'regular'
  | 'italic'
  | '500'
  | '500italic'
  | '600'
  | '600italic'
  | '700'
  | '700italic'
  | '800'
  | '800italic'
  | '900'
  | '900italic'

export interface CategoryFilterProps {
  filteredFontCount: number
  totalFontCount: number
  selectedFilter: GoogleFontCategory | undefined
  setFilter: (filter: GoogleFontCategory | undefined) => void
}
