import { AgeGate as AgeGateIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/AgeGate'
import { AppStoreLinkIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/AppStoreLink'
import { ComponentType, SVGProps } from 'react'
import { DefaultManager, ManagerProps } from './components/Manager/Manager'
import { DestinationAdd } from './Destination/DestinationAdd'
import { DestinationFlowpage } from './Destination/DestinationFlowpage'
import { DestinationManager } from './Destination/DestinationManager'
import { EmbedOther } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/EmbedOther'
import { EmbedSpotify } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/EmbedSpotify'
import { EmbedVideo } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/EmbedVideo'
import { FileUpload } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/FileUpload'
import { FlowpageProps } from './types/FlowpageProps'
import { FormProps } from './components/Layout'
import { LinkProvider } from '@dtx-company/inter-app/src/constants/linkTypes'
import { Marketplace } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/Marketplace'
import { Message } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/Message'
import { Polling as PollingIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/Polling'
import { PromotedItem as PromotedItemIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/PromotedItem'
import { StandardLinkForm } from './Destination/DestinationForm'
import { StoreHours as StoreHoursIcon } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/StoreHours'
import { TopFriends } from '@dtx-company/shared-components/src/foundation/Icons/Flowpage/Widgets/TopFriends'
import dynamic from 'next/dynamic'

interface WidgetConfig {
  Form: ComponentType<FormProps>
  Manager: ComponentType<ManagerProps>
  Flowpage: ComponentType<FlowpageProps> | null
  Add: {
    title: string
    description: string
    icon: ({ ref, ...props }: SVGProps<SVGSVGElement>) => JSX.Element
  } | null
  inHeader?: boolean
  disableFormPreview?: boolean
  isFullPage?: boolean
  disableFlowpagePreview?: boolean
}

export const WIDGETS: Record<string, WidgetConfig> = {
  userInfo: {
    Form: dynamic<FormProps>(() => import('./Bio/BioForm').then(mod => mod.BioForm)),
    Manager: DefaultManager,
    Flowpage: null,
    Add: null,
    inHeader: true
  },
  contactInfo: {
    Form: dynamic<FormProps>(() =>
      import('./Contact/ContactForm').then(mod => mod.ContactInfoFormV2)
    ),
    Manager: dynamic<ManagerProps>(() =>
      import('./Contact/Manager').then(mod => mod.ContactManager)
    ),
    Add: null,
    Flowpage: dynamic<FlowpageProps>(() => import('./ContactInfo').then(mod => mod.Flowpage)),
    disableFormPreview: true
  },
  embedYoutube: {
    Form: dynamic<FormProps>(() => import('./EmbeddableMedia').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./EmbeddableMedia').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./EmbeddableMedia').then(mod => mod.Manager)),
    Add: {
      title: 'Embed Video',
      description: 'Embed Youtube videos directly on your Flowpage',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <EmbedVideo {...props} />
    }
  },
  pdf: {
    Form: dynamic<FormProps>(() => import('./PDF').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./PDF').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./PDF').then(mod => mod.Manager)),
    Add: {
      title: 'Upload a File',
      description: 'Upload a PDF, MP4 video or audio file from your device',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <FileUpload {...props} />
    }
  },

  sendMessage: {
    Form: dynamic<FormProps>(() => import('./Messages').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./Messages').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./Messages').then(mod => mod.Manager)),
    Add: {
      title: 'Communications',
      description: 'Make a call or send SMS, Whatsapp and Email messages',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <Message {...props} />
    },

    inHeader: false
  },
  embeddableMedia: {
    Form: dynamic<FormProps>(() => import('./EmbeddableMedia').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./EmbeddableMedia').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./EmbeddableMedia').then(mod => mod.Manager)),
    Add: {
      title: 'Embed Other',
      description: 'Embed audio, media, live stream, etc.',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <EmbedOther {...props} />
    }
  },
  storeHours: {
    Form: dynamic<FormProps>(() => import('./StoreHours').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./StoreHours').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./StoreHours').then(mod => mod.Manager)),
    Add: {
      title: 'Store Hours',
      description: 'Let people know when your business is open',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <StoreHoursIcon {...props} />
    }
  },
  embedSpotify: {
    Form: dynamic<FormProps>(() => import('./EmbeddableMedia').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./EmbeddableMedia').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./EmbeddableMedia').then(mod => mod.Manager)),
    Add: {
      title: 'Embed Spotify',
      description: 'Embed songs, albums, and playlists',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <EmbedSpotify {...props} />
    }
  },
  promotedItem: {
    Form: dynamic<FormProps>(() => import('./promotedItem').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() =>
      import('./promotedItem/Flowpage').then(mod => mod.Flowpage)
    ),
    Manager: dynamic<ManagerProps>(() => import('./promotedItem').then(mod => mod.Manager)),
    Add: {
      title: 'Promoted Item',
      description: 'Feature a product on your Flowpage',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <PromotedItemIcon {...props} />
    }
  },
  productForSale: {
    Form: dynamic<FormProps>(() => import('./ProductForSale').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./ProductForSale').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./ProductForSale').then(mod => mod.Manager)),
    Add: {
      title: 'Storefront',
      description: 'Sell products and services directly from your Flowpage',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <Marketplace {...props} />
    }
  },
  polling: {
    Add: {
      title: 'Polling',
      description: 'Create and conduct surveys or polls',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <PollingIcon {...props} />
    },
    Form: dynamic<FormProps>(() => import('./Polling').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./Polling').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./Polling').then(mod => mod.Manager)),
    inHeader: false
  },
  /**
   * The NBA Id Gate widget is a short-term custom solution for our client; we need to devise a long-term
   * abstraction for custom auth-gates on Flowpages. Once that is completed, must migrate
   * this NBA approach into our universal solution
   */
  nbaIdGate: {
    Form: dynamic<FormProps>(() => import('./__NBAIdGate/NBAIdGate').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() =>
      import('./__NBAIdGate/NBAIdGate').then(mod => mod.Flowpage)
    ),
    Manager: dynamic<ManagerProps>(() =>
      import('./__NBAIdGate/NBAIdGate').then(mod => mod.Manager)
    ),
    Add: {
      title: 'Log in to your NBA account!', // TODO: Confirm desired copy
      description: 'To access this content, log in or create a new NBA account', //
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <AgeGateIcon {...props} /> // TODO: Define an icon for this
    },
    isFullPage: true,
    disableFlowpagePreview: true
  },
  ageGate: {
    Form: dynamic<FormProps>(() => import('./AgeGate').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./AgeGate').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./AgeGate').then(mod => mod.Manager)),
    Add: {
      title: 'Age Gating',
      description: 'Ensure your visitors are eligible to view your content',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <AgeGateIcon {...props} />
    },
    isFullPage: true,
    disableFlowpagePreview: true
  },
  discover: {
    Form: dynamic<FormProps>(() => import('./discover').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./discover').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./discover').then(mod => mod.Manager)),
    Add: {
      title: 'Top Friends',
      description: 'Show off your top friends',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <TopFriends {...props} />
    }
  },
  bigImage: {
    Form: dynamic<FormProps>(() => import('./BigImage').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./BigImage').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./BigImage').then(mod => mod.Manager)),
    Add: null
  },
  appStoreLink: {
    Form: dynamic<FormProps>(() => import('./AppStoreLink').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./AppStoreLink').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./AppStoreLink').then(mod => mod.Manager)),
    Add: {
      title: 'App Store Link',
      description: 'Link to the iOS App Store or Google Play Store',
      icon: ({ ref: _ref, ...props }: SVGProps<SVGSVGElement>) => <AppStoreLinkIcon {...props} />
    }
  },
  socialLink: {
    Form: dynamic<FormProps>(() => import('./SocialLink').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() => import('./SocialLink').then(mod => mod.Flowpage)),
    Manager: dynamic<ManagerProps>(() => import('./SocialLink').then(mod => mod.Manager)),
    Add: null
  },
  textBoxSection: {
    Form: dynamic<FormProps>(() => import('./TextBoxSection/Form').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() =>
      import('./TextBoxSection/Flowpage').then(mod => mod.Flowpage)
    ),
    Manager: dynamic<ManagerProps>(() => import('./TextBoxSection/index').then(mod => mod.Manager)),
    Add: null
  },
  contactCollection: {
    Form: dynamic<FormProps>(() => import('./ContactCollectionForm').then(mod => mod.Form)),
    Flowpage: dynamic<FlowpageProps>(() =>
      import('./ContactCollectionForm').then(mod => mod.Flowpage)
    ),
    Manager: dynamic<ManagerProps>(() =>
      import('./ContactCollectionForm').then(mod => mod.Manager)
    ),
    Add: null
  }
}

export const Destination = {
  Form: StandardLinkForm,
  Flowpage: DestinationFlowpage,
  Manager: DestinationManager,
  Add: DestinationAdd
}

export const widgetTypes = Object.entries(WIDGETS).map(([k, v]) => ({
  provider: LinkProvider.WIDGET,
  type: k,
  id: k
}))

export const WIDGET_INFO: Record<
  string,
  Pick<WidgetConfig, 'inHeader' | 'disableFlowpagePreview' | 'isFullPage' | 'disableFormPreview'>
> = Object.entries(WIDGETS).reduce(
  (acc, [k, v]) => ({
    ...acc,
    [k]: {
      inHeader: v.inHeader,
      disableFormPreview: v.disableFlowpagePreview,
      isFullPage: v.isFullPage,
      disableFlowpagePreview: v.disableFlowpagePreview
    }
  }),
  {}
)
