import { KvOperation } from '@dtx-company/flow-codegen/src/page/generated.types'
import { SlotType } from '../../../containers/settings/settings.types'
import {
  sendErrorNotification,
  sendSuccessNotification
} from '@dtx-company/inter-app/src/utils/notifications'
import { useCallback, useEffect } from 'react'
import {
  useCreateSlotMutation,
  useUpdateSlotMutation
} from '@dtx-company/inter-app/src/redux/slices/flowpageApiSlice/slots'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

type CreateOrUpdateRemoveFCBrandingSlotFn = (args: {
  pageId: string
  slotId?: string
  enabled?: boolean
}) => Promise<void>

export const useCreateOrUpdateRemoveFCBrandingSlot = ({
  pageId
}: {
  pageId: string
}): {
  isLoading: boolean
  createOrUpdateRemoveFCBrandingSlot: CreateOrUpdateRemoveFCBrandingSlotFn
} => {
  const [
    createSlot,
    { isLoading: isCreateSlotLoading, data: createSlotData, error: createSlotError }
  ] = useCreateSlotMutation()
  const [
    updateSlot,
    { isLoading: isUpdateSlotLoading, data: updateSlotData, error: updateSlotError }
  ] = useUpdateSlotMutation()

  const createOrUpdateRemoveFCBrandingSlot: CreateOrUpdateRemoveFCBrandingSlotFn = useCallback(
    async ({ pageId, slotId, enabled }) => {
      if (!slotId) {
        await createSlot({
          pageId,
          slotMetaType: SlotType.SETTINGS_REMOVE_FOOTER,
          value: {
            isEnabled: Boolean(enabled)
          }
        })
      } else {
        updateSlot({
          pageId,
          slotId,
          input: {
            path: [''],
            value: JSON.stringify({ isEnabled: Boolean(enabled) }),
            operation: KvOperation.Concatenate
          }
        })
      }
    },
    [createSlot, updateSlot]
  )

  useEffect(() => {
    if (createSlotError) console.error(createSlotError)
    if (updateSlotError) console.error(updateSlotError)
    if (createSlotError || updateSlotError)
      sendErrorNotification(`Error setting Flowcode branding preference`)
  }, [createSlotError, updateSlotError])

  useEffect(() => {
    if (createSlotData || updateSlotData) {
      sendSuccessNotification('Success setting Flowcode branding preference')
      events.userToggledRemoveBranding({ page_id: pageId })
    }
  }, [createSlotData, updateSlotData, pageId])

  return {
    isLoading: isCreateSlotLoading || isUpdateSlotLoading,
    createOrUpdateRemoveFCBrandingSlot
  }
}
