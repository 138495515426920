import { SVGProps, memo } from 'react'

function EmbedVideoSvg({
  stroke = '#111',
  color = '#FF0000',
  ...rest
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg width="48" height="34" viewBox="0 0 48 34" fill="none" {...rest}>
      <path
        d="M46.9412 5.487C46.6658 4.4696 46.1287 3.54195 45.3833 2.79659C44.638 2.05123 43.7104 1.51412 42.6929 1.23873C38.9701 0.223141 23.9755 0.223141 23.9755 0.223141C23.9755 0.223141 8.98385 0.253651 5.25907 1.27022C4.24144 1.54534 3.3137 2.08236 2.5683 2.82776C1.82289 3.57317 1.28587 4.5009 1.01075 5.5185C-0.116036 12.1365 -0.552972 22.2215 1.04126 28.5758C1.31683 29.5932 1.854 30.5207 2.59933 31.266C3.34467 32.0113 4.27217 32.5485 5.28957 32.8241C9.01436 33.8397 24.0119 33.8397 24.0119 33.8397C24.0119 33.8397 39.0035 33.8397 42.7283 32.8241C43.7458 32.5487 44.6734 32.0116 45.4188 31.2662C46.1641 30.5209 46.7012 29.5933 46.9766 28.5758C48.1605 21.9479 48.5266 11.8689 46.9412 5.487Z"
        fill={color}
      />
      <path d="M19.2065 24.2352L31.6435 17.0317L19.2065 9.8281V24.2352Z" fill="white" />
    </svg>
  )
}

export const EmbedVideo = memo(EmbedVideoSvg)
