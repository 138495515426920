import { IS_CI } from '@dtx-company/true-common/src/constants/env'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useMemo } from 'react'

export function useIsFlowcodeEmployee(): {
  isFlowcodeEmployee: boolean
} {
  const { jwt } = useAuthState()

  const isFlowcodeEmployee = useMemo(() => {
    const userHasDtxEmail = jwt?.email?.endsWith('@thedtxcompany.com')
    const userHasFlowcodeEmail = jwt?.email?.endsWith('@flowcode.com')
    const userIsTest = jwt?.email?.endsWith('@flowdtx.com') && IS_CI
    return Boolean(userHasDtxEmail || userHasFlowcodeEmail || userIsTest)
  }, [jwt])

  return {
    isFlowcodeEmployee
  }
}
