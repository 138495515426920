import * as icons from '../../../assets/icons'
import { Box } from '@dtx-company/shared-components/src/components/atoms/Box/index'
import { Button } from '@dtx-company/shared-components/src/components/atoms/Button/index'
import { Card } from '@dtx-company/shared-components/src/components/atoms/Card/index'
import { Chip } from '@dtx-company/shared-components/src/components/atoms/Chip/index'
import { ChipSelector } from '@dtx-company/shared-components/src/types/atoms/chip.types'
import { EditTheme } from './types'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import {
  LINK_SHAPE_LABELS,
  LINK_STYLE_LABELS,
  LinkShape,
  LinkStyle
} from '../../../constants/customizeTheme'
import { LabelText } from './subcomponents'
import { Spacer } from '@dtx-company/shared-components/src/components/atoms/Spacer/Spacer'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text'
import { getLinkSelectorShape, getLinkSelectorStyles } from '../../../utils/theme'
import { stopPropagationEvents } from '../PageEditor/components/LinkEditDrawer/LinkEditDrawer.utils'
import { useAuthState } from '@dtx-company/inter-app/src/hooks/useAuthState'
import { useCurrentPagePermissions } from '../../../utils/useGetCurrentPagePermissions'
import { useLinkStyleOptions } from './LinkStyle.hooks'
import events from '@dtx-company/inter-app/src/event-tracking/events/flowpage'

interface LinkStyleCardProps {
  id?: string
  handleSelect: (values: EditTheme, reset?: boolean) => void
  currShape?: string
  currStyle?: string
  disabled?: boolean
}

export const LinkShapePicker: FC<LinkStyleCardProps> = ({ handleSelect, currShape, disabled }) => {
  const { isViewer } = useAuthState()
  const { hasViewOnlyAccess } = useCurrentPagePermissions()
  return (
    <Box flexDirection="column">
      <Box>
        <Icon src={icons.shapesDark} display={['none', 'none', 'flex']} />
        <Spacer mr="8px" display={['none', 'none', 'flex']} />
        <Text
          color="primary.black"
          variant={['body/small', 'body/small', 'body/medium']}
          fontWeight={[600, 600, 'bold']}
          opacity={disabled ? 0.35 : 1}
        >
          Choose a link shape
        </Text>
      </Box>
      <Spacer mb="16px" />
      <Box flexWrap={'wrap'}>
        {Object.values(LinkShape).map(shape => {
          const selected = currShape === shape
          const styles = getLinkSelectorShape(shape)
          return (
            <Box
              data-testid={shape}
              key={shape}
              position="relative"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              onClick={() => {
                if (!(isViewer || hasViewOnlyAccess) && !disabled) {
                  handleSelect({ linkShape: shape })
                  events.Flowpage_PageProfile_Selected_Shape(shape)
                }
              }}
              mx="8px"
              {...stopPropagationEvents}
            >
              <Chip
                selected={selected}
                selectorType={ChipSelector.FLOAT}
                {...styles}
                cursor={disabled ? 'default' : 'pointer'}
                opacity={isViewer || hasViewOnlyAccess || disabled ? 0.5 : 1}
              >
                <Button
                  key={shape}
                  colorVariant="secondaryOnDark"
                  sizeVariant="medium"
                  minWidth="fit-content"
                  disabled={disabled}
                  {...styles}
                />
              </Chip>
              <Spacer mb="16px" />
              <LabelText
                display={['none', 'none', 'flex']}
                selected={selected}
                opacity={disabled ? 0.35 : 1}
                label={LINK_SHAPE_LABELS[shape]}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export const LinkStylePicker: FC<LinkStyleCardProps> = ({ handleSelect, currStyle, disabled }) => {
  const { linkStylesOptions, selectedLinkStyle, handleLinkStyleChange, canUserEditLinkStyle } =
    useLinkStyleOptions({ handleSelect, currStyle })
  const isDisabled = !canUserEditLinkStyle || disabled
  return (
    <Box flexDirection="column">
      <Box opacity={isDisabled ? 0.35 : 1}>
        <Icon display={['none', 'none', 'flex']} src={icons.shapes4Dark} />
        <Spacer display={['none', 'none', 'flex']} mr="8px" />
        <Text
          color="primary.black"
          variant={['body/small', 'body/small', 'body/medium']}
          fontWeight={[600, 600, 'bold']}
        >
          Choose a link style
        </Text>
      </Box>
      <Spacer mb="16px" />
      <Box flexWrap={'wrap'}>
        {linkStylesOptions.map((styleOption, i) => {
          const styles = getLinkSelectorStyles(styleOption)
          const selected = selectedLinkStyle === styleOption
          return (
            <Box
              {...stopPropagationEvents}
              key={styleOption + i}
              position="relative"
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
              onClick={() => {
                !isDisabled && handleLinkStyleChange(styleOption)
              }}
              opacity={isDisabled ? 0.35 : 1}
              cursor={isDisabled ? 'default' : 'pointer'}
              m="8px 8px 0 8px"
            >
              <Chip selected={selected} selectorType={ChipSelector.FLOAT} borderRadius="10px">
                <Card
                  maxWidth="80px"
                  maxHeight="44px"
                  justifyContent="center"
                  alignItems="center"
                  cursor={isDisabled ? 'default' : 'pointer'}
                  {...styles}
                  opacity={isDisabled ? 0.35 : 1}
                >
                  {styleOption === LinkStyle.OUTLINED_BW && (
                    <Text color="secondary.placeholder" textAlign="center">
                      {'B/W'}
                    </Text>
                  )}
                </Card>
              </Chip>
              <Spacer mb="8px" />
              <LabelText
                display={['none', 'none', 'flex']}
                selected={selected}
                opacity={isDisabled ? 0.35 : 1}
                label={LINK_STYLE_LABELS[styleOption]}
              />
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}

export const LinkStyleCard: FC<LinkStyleCardProps> = ({
  handleSelect,
  currStyle,
  currShape,
  disabled
}) => {
  return (
    <>
      <LinkShapePicker disabled={disabled} handleSelect={handleSelect} currShape={currShape} />
      <Spacer mt="24px" />
      <LinkStylePicker disabled={disabled} handleSelect={handleSelect} currStyle={currStyle} />
    </>
  )
}
