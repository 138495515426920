import * as icons from '../../assets/icons'
import { FC } from 'react'
import { Icon } from '@dtx-company/shared-components/src/components/atoms/Icon/index'
import styled from 'styled-components'

const MenuIconWrapper = styled.div`
  margin-right: 8px;
  padding: 0 8px;
`

export const LinkHandle: FC = props => (
  <MenuIconWrapper {...props}>
    <Icon src={icons.handleV2} height="24px" width="8px" />
  </MenuIconWrapper>
)

export const SocialIcon = styled.img`
  width: ${({ size }: { size: string }) => {
    switch (size) {
      case 'sm':
        return '40px'
      case 'md':
        return '48px'
      case 'lg':
        return '60px'
      default:
        return ''
    }
  }};
  height: ${({ size }: { size: string }) => {
    switch (size) {
      case 'sm':
        return '40px'
      case 'md':
        return '48px'
      case 'lg':
        return '60px'
      default:
        return ''
    }
  }};
`
