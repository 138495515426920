import { AnalyticsIntegrations, fireAnalyticsEvent } from '../helpers/fireAnalyticsEvent'
import { fireHeapEvent } from '../helpers'
import eventNames from '../constants/flowpageTemplates'

const flowpageTemplates = {
  Flowpage_Management_Clicked_TemplatesTab(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_TemplatesTab)
  },
  Flowpage_Management_Clicked_CreateTemplateUpsellButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreateTemplateUpsellButton)
  },
  Flowpage_Management_Clicked_CreateTemplateButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreateTemplateButton)
  },
  Flowpage_Management_Clicked_WatchTemplatesDemoButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_WatchTemplatesDemoButton)
  },
  Flowpage_Management_Clicked_DeleteTemplateButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_DeleteTemplateButton, {
      templateId
    })
  },
  Flowpage_Management_Clicked_CancelDeleteTemplateButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CancelDeleteTemplateButton, {
      templateId
    })
  },
  Flowpage_Management_Clicked_BrowseTemplateButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_BrowseTemplateButton)
  },
  Flowpage_Management_Clicked_LearnMoreTemplateButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_LearnMoreTemplateButton)
  },
  Flowpage_Management_Clicked_DismissTemplateBannerTooltipButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_DismissTemplateBannerTooltipButton)
  },
  Flowpage_Management_Clicked_DismissTemplateBannerButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_DismissTemplateBannerButton)
  },
  Flowpage_Management_Clicked_EditTemplateButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_EditTemplateButton, {
      templateId
    })
  },
  Flowpage_Management_Clicked_CancelEditTemplateButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CancelEditTemplateButton, {
      templateId
    })
  },
  Flowpage_Management_Clicked_PreviewTemplateButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_PreviewTemplateButton, {
      templateId
    })
  },
  Flowpage_Management_Clicked_UseTemplateButton(args: {
    templateId: string
    isUnifiedTemplates: boolean
  }): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_UseTemplateButton, args)
  },
  Flowpage_Management_Closed_PreviewTemplateModal(args: {
    templateId: string
    isUnifiedTemplates: boolean
  }): void {
    fireHeapEvent(eventNames.Flowpage_Management_Closed_PreviewTemplateModal, args)
  },
  Flowpage_Management_Opened_PreviewTemplateModalSheet(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Opened_PreviewTemplateModalSheet, {
      templateId
    })
  },
  Flowpage_Management_Clicked_OpenPreviewTemplateModalContextMenuButton(args: {
    templateId: string
    isUnifiedTemplates: boolean
  }): void {
    fireHeapEvent(
      eventNames.Flowpage_Management_Clicked_OpenPreviewTemplateModalContextMenuButton,
      args
    )
  },
  Flowpage_Management_Clicked_ClosePreviewTemplateModalContextMenuButton(templateId: string): void {
    fireHeapEvent(
      eventNames.Flowpage_Management_Clicked_ClosePreviewTemplateModalContextMenuButton,
      {
        templateId
      }
    )
  },
  Flowpage_Management_Clicked_PreviewTemplateModalPreviewPageButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_PreviewTemplateModalPreviewPageButton, {
      templateId
    })
  },
  Flowpage_Management_Clicked_TemplateCardContextMenuItem(
    templateId: string,
    menuItem: string
  ): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_TemplateCardContextMenuItem, {
      templateId,
      menuItem
    })
  },
  Flowpage_Mobile_Editor_Clicked_TemplateCardContextMenuItem(
    templateId: string,
    menuItem: string
  ): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_TemplateCardContextMenuItem, {
      templateId,
      menuItem
    })
  },
  Flowpage_Management_Clicked_PreviewTemplateContextMenuItem(
    templateId: string,
    menuItem: string,
    isUnifiedTemplates: boolean
  ): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_PreviewTemplateContextMenuItem, {
      templateId,
      menuItem,
      isUnifiedTemplates
    })
  },
  Flowpage_TemplateEditor_Clicked_ToggleLockedField(field: string): void {
    fireHeapEvent(eventNames.Flowpage_TemplateEditor_Clicked_ToggleLockedField, {
      field
    })
  },
  Flowpage_Management_Clicked_CreateBlankPageButton(modalType: 'mobile' | 'desktop'): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreateBlankPageButton, {
      modalType
    })
  },
  Flowpage_Management_Clicked_CreateBlankPageCard(modalType: 'mobile' | 'desktop'): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreateBlankPageCard, {
      modalType
    })
  },
  Flowpage_Management_Clicked_CreatePageModalFilter(id: string, label: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreatePageModalFilter, {
      id,
      label
    })
  },
  Flowpage_Management_Focused_CreatePageModalSearchInput(modalType: 'mobile' | 'desktop'): void {
    fireHeapEvent(eventNames.Flowpage_Management_Focused_CreatePageModalSearchInput, {
      modalType
    })
  },
  Flowpage_Management_Clicked_CloseCreatePageModalSearchInput(
    modalType: 'mobile' | 'desktop'
  ): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CloseCreatePageModalSearchInput, {
      modalType
    })
  },
  Flowpage_Management_Clicked_CreatePageModalSearchButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreatePageModalSearchButton, {})
  },
  Flowpage_Management_Clicked_CreatePageModalCloseButton(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_CreatePageModalCloseButton, {})
  },
  Flowpage_Management_Typed_TemplateNameSearch(): void {
    fireHeapEvent(eventNames.Flowpage_Management_Typed_TemplateNameSearch, {})
  },
  Flowpage_Management_Hovered_PageTemplateCard(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Hovered_PageTemplateCard, {
      templateId
    })
  },
  Flowpage_Management_Clicked_PageTemplateCard(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_PageTemplateCard, {
      templateId
    })
  },
  Flowpage_Management_Clicked_PageTemplateCardUseTemplateButton(templateId: string): void {
    fireAnalyticsEvent(
      eventNames.Flowpage_Management_Clicked_PageTemplateCardUseTemplateButton,
      {
        templateId
      },
      [AnalyticsIntegrations.HEAP, AnalyticsIntegrations.INTERCOM]
    )
  },
  Flowpage_Management_Clicked_PageTemplateCardContextMenuButton(templateId: string): void {
    fireHeapEvent(eventNames.Flowpage_Management_Clicked_PageTemplateCardContextMenuButton, {
      templateId
    })
  }
}

export default flowpageTemplates
